import { Accordion, AccordionDetails, AccordionSummary, AppBar, Autocomplete, Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Slide, Slider, Stack, Step, StepButton, StepLabel, Stepper, TextField, Theme, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import '../../../Styles.css'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    DateTimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
import * as Yup from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TransitionProps } from '@mui/material/transitions';
import AnchorOutlinedIcon from '@mui/icons-material/AnchorOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from "dayjs";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import axios from 'axios';
import { SeaRatesServices } from '../../../Services/SeaRatesService';
import { LanTwoTone } from '@mui/icons-material';
import moment from 'moment';
import { Bars } from "react-loader-spinner";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SeaRatesMap from './SeaRatesMap';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
const schemavalidation = Yup.object().shape({
    productName: Yup.string()
        .required("Product field is required"),
    phone: Yup.string()
        .required("Phone Number is required"),
    name: Yup.string()
        .required('Full Name is required'),
    eMail: Yup.string()
        .required('Email is required')
});
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        fontSize: "10px",
        // fontWeight: "bold",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
    tab: {
        backgroundColor: "#f1f1f1",
        marginBottom: "10px",
    },
    content: {
        '& .MuiAccordionSummary-content': {
            margin: '0 !important',
        },
    },

}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const chipsData = ['FEATURED', 'CHEAPER', 'FASTER']
export default function SearatesInfo(props: any) {
    const [productName,setProductName] = useState("");
    const handleProduct =(e:any) =>{
            setProductName(e.target.value)
    }

    const { register, handleSubmit,reset,getValues  } = useForm<any>({
        defaultValues: {
                        phone: '',
                        name: '',
                        eMail: '',
                        description: '',
                    }
    });
    const onSubmit:SubmitHandler<any> = data => console.log(data);

    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = React.useState('Truck');
    const [shipment, setShipment] = useState('fcl')
    const [container, setContainer] = useState('')
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [checked, setChecked] = React.useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [transporatationName, setTransportationName] = useState('sea')
    const [toggleValue, setToggleValue] = useState<any>('tariff')
    const [typeOfContainer, setTypeOfContainer] = useState<any>("")
    const [loadDate, setLoadDate] = React.useState<Dayjs | any>(null);
    const [isViewDetailsShow, setViewDetailsShow] = useState(false)
    const [sliderValue, setSliderValue] = useState(1)
    const [sliderValue1, setSliderValue1] = useState(1);
    const [open1, setOpen1] = React.useState(false);
    const [options, setOptions] = React.useState<any>([]);
    const loading = open1 && options.length === 0;
    const [level, setLevel] = useState<any>(0)
    const [loader, setLoader] = useState(false)
    const [activeStep, setActiveStep] = React.useState(2);
    const [sliderUnits, setSliderUnits] = useState('TON')
    const [slider1Units, setSlider1Units] = useState('CBM')
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    let arr: any = []
    let arr1: any = []
    let hsCodesData: any = [];
    let containerType: any = 'ST20'
    const [open, setOpen] = React.useState(props.data);
    const [origin, setOrigin] = useState<any>({})
    const [dest, setdest] = useState<any>({})
    const seaRatesServices = new SeaRatesServices()
    const [OriginLatLang, setOriginLatLang] = useState<any>()
    const [ratesResp, setRatesResp] = useState<any>([])
    const [products, setProducts] = useState<any>([])
    const [subProducts, setSubProducts] = useState<any>([])
    const [openDialog, setOpenDialog] = useState(false)
    const [isPickUpActive, setPickUpActive] = useState(false)
    const [isPortOfOriginActive, setPortOfOriginActive] = useState(false)
    const [isPortOfDischargeActive, setPortOfDischargeActive] = useState(false)
    const [isDeliveryActive, setDeliveryActive] = useState(false)
    const [PRoductsByHsCode, setProductsByHsCode] = useState<any>(['Main Categories'])
    const [totalBookNowPrice, setTotalBookNowPrice] = useState('')
    const [totalCo2Count, setTotalCo2Count] = useState('')
    const [selectProduct, setSelectProduct] = useState('')
    // const formik = useFormik({
    //     initialValues: {
    //         productName: '',
    //         phone: '',
    //         name: '',
    //         eMail: '',
    //         description: '',
    //     },
    //     validationSchema: schemavalidation,
    //     onSubmit: (values, { resetForm }) => {
    //         let obj = {
    //             productName: values.productName,
    //             phone: values.phone,
    //             name: values.name,
    //             eMail: values.eMail,
    //             description: values.description
    //         };

    //         handleSubmit(obj);
    //         resetForm({
    //             values: {
    //                 productName: '',
    //                 phone: '',
    //                 name: '',
    //                 eMail: '',
    //                 description: '',
    //             },
    //         });
    //     },
    // })
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen1(false);
    };
    const handleStep = (index: any) => {
        console.log(index)
        setActiveStep(index);
    }
    const typesOfData = [
        {
            transportationType: transporatationName,
            shipmentType: shipment,
        }
    ]
    const sliderHandleChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
        console.log(Number(newValue) * 1000, "new valueeeee")
        latlngAddress(shipment)
    };
    const sliderHandleChange1 = (event: Event, newValue: number | number[]) => {
        setSliderValue1(newValue as number);
        latlngAddress(shipment)
    };
    // const handleSubmit = (values: any) => {
    //     console.log(values, "request form")
    // }
    const handleChange = (e: any) => {
        console.log(e.target.value, "value")
        setShipment(e.target.value)
    }
    const handleChangeaccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    React.useEffect(() => {
        let active = true;
        seaRatesServices.getGraphqlProducts().then((res: any) => {
            setProducts(res.data.data.hs_codes_list)
        })
        if (!loading) {
            return undefined;
        } (async () => {

            // await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...products]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open1) {
            setOptions([]);
        }
    }, [open1]);
    useEffect(() => {
        console.log(shipment, "updated shipment data")
    }, [shipment])
    const submit = () => {
        setSubmit(true)
        if (!sessionStorage.getItem('seaRates_Token')) {
            //---------------------- platform token api --------------
            seaRatesServices.getPlatFormToken().then((res: any) => {
                sessionStorage.setItem('seaRates_Token', res.data['s-token'])
            })
        }
        latlngAddress(shipment)

    }
    const latlngAddress = (shipment: any) => {
        console.log(typeOfContainer, shipment, "container type")
        geocodeByAddress(origin.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            setOriginLatLang(data)
            let RequestObj = {
                originLat: data.lat,
                originLng: data.lng,

            }
            arr.push(RequestObj)
            getLatLngData(shipment)
        });
        geocodeByAddress(dest.value.description).then((results: any) => getLatLng(results[0])).then((data) => {
            let RequestObj = {
                destLat: data.lat,
                destLng: data.lng,

            }
            arr1.push(RequestObj)
            getLatLngData(shipment)
        });
    }
    const getLatLngData = (shipment: any) => {
        // ---------------------- graphql Rates ---------------------
        if (transporatationName === 'sea') {
            if (shipment === 'fcl') {
                setSliderValue1(0)
                setSlider1Units('')
                setSliderValue(0)
                setSliderUnits('')
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    containerType: typeOfContainer,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj")
                setLoader(true)
                let PortOfFrom: any = []
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    console.log(res, "resp")
                    setLoader(false)
                    setRatesResp(res.data.data.shipment)
                    let totalKgs: any = [];
                    for (let i = 0; i < res.data.data.shipment.length; i++) {
                        totalKgs.push(res.data.data.shipment[i].freight[0].co2 / 1000 + res.data.data.shipment[i].freight[0].truckFrom.co2 / 1000 + res.data.data.shipment[i].freight[0].truckTo.co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesFrom[0].co2 / 1000 + res.data.data.shipment[i].freight[0].portFeesTo[0].co2 / 1000)

                        PortOfFrom.push(res.data.data.shipment[i].portFrom.name)
                        PortOfFrom.push(res.data.data.shipment[i].portFrom.code)
                    }
                    console.log(PortOfFrom, "total kgs")
                })
            } else if (shipment === 'lcl') {
                setTypeOfContainer('')
                containerType = ''
                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    weight: sliderValue,
                    volume: sliderValue1,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, "request obj for lcl")
                setLoader(true)
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    setLoader(false)
                    setSubmit(true)
                    setRatesResp(res.data.data.shipment)
                    console.log(res.data.data.shipment, "shipment")
                })
            } else if (shipment === 'bulk') {
                setTypeOfContainer('')
                containerType = ''
                setSliderValue1(0)
                setSlider1Units('')

                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    weight: sliderValue,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, containerType, "request obj for buk")
                setLoader(true)
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    setLoader(false)
                    setSubmit(true)
                    setRatesResp(res.data.data.shipment)
                    console.log(res.data.data.shipment, "shipment")
                })
            }
        } else if (transporatationName === 'land') {
            if (shipment === 'ftl') {
                setTypeOfContainer('')
                containerType = ''
                setSliderValue(0)
                setSliderUnits('')

                let RequestObject = {
                    shipment: shipment,
                    originLat: arr[0].originLat,
                    originLng: arr[0].originLng,
                    destinationLat: arr1[0].destLat,
                    destinationLng: arr1[0].destLng,
                    volume: sliderValue1,
                    transport: transporatationName,
                    date: loadDate
                }
                console.log(RequestObject, containerType, "request obj for buk")
                setLoader(true)
                seaRatesServices.getGraphqlRates(RequestObject).then((res: any) => {
                    setLoader(false)
                    setSubmit(true)
                    setRatesResp(res.data.data.shipment)
                    console.log(res.data.data.shipment, "shipment")
                })
            }
        }

    }
    const addChip = (value: any, index: any) => {
        console.log(index, "index", value)
        setActiveIndex(index)
    }
    const readyToLoad = (newValue: Dayjs | any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate);
        setLoadDate(responseDate);

    };
    const transportationChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        console.log(newAlignment, "namee")
        if (newAlignment === 'sea') {
            setShipment('fcl')
        } else if (newAlignment === 'land') {
            setShipment('ftl')
            setSlider1Units('m3')
        } else if (newAlignment === 'air') {
            setShipment('air')
        }
        setTransportationName(newAlignment);

    };
    const TypeOfContainerChange = (
        event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "target value")
        setTypeOfContainer(event.target.value);
        containerType = event.target.value
        console.log(containerType, "typeofcontainer")
        latlngAddress(shipment)
    };
    const viewDetails = (index: any) => {
        setViewDetailsShow(!isViewDetailsShow)
    }
    const valuehandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue(Number(event.target.value));
        console.log(Number(event.target.value) + '000', "slider valueee")
        latlngAddress(shipment);
    };
    const valuehandleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue1(Number(event.target.value));
        latlngAddress(shipment);
    };
    const hsCodeDetails = () => {
        console.log("scroll", products)
        setSubProducts(products)
        setOpenDialog(true)
    }
    const onSelectProducts = (item: any) => {
        console.log(PRoductsByHsCode, "hs data1111111111")
        let levelCount = level + 1
        hsCodesData.push(item.description)
        console.log(hsCodesData, "hs data")
        setLevel(levelCount)
        setProductsByHsCode((oldArray: any) => [...oldArray, item.description])
        let objRequest = {
            level: levelCount,
            hsCode: item.code
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            console.log("scroll res", res.data.data)
            setProducts(res.data.data.hs_codes_list)
        })
    }
    const handleSelect = (item: any) => {
        console.log(item, "item")
        let objRequest = {
            hsCode: item
        }
        seaRatesServices.getrGraphqlProductsByHsCode(objRequest).then((res: any) => {
            setOpenDialog(false)
            setProducts(res.data.data.hs_autocomplete)

        })
    }
    const OnpickupClick = (item: any) => {
        console.log(item, "pickup click")
        setPickUpActive(!isPickUpActive)

        setPickUpActive((state) => {
            console.log(state, "state")
            console.log(isPickUpActive, "ispickup")
            if (state) {
                console.log(totalBookNowPrice, "total price")
                setPortOfOriginActive(true)
                if (totalBookNowPrice != '') {
                    let price: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice : 0
                    let co2: any = !isPortOfOriginActive ? item.freight[0].portFeesFrom[0].co2 / 1000 : 0
                    console.log(co2, "co22222222")
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].truckFrom.price + price)
                    setTotalCo2Count(Number(totalCo2Count) + item.freight[0].truckFrom.co2 / 1000 + co2)

                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].truckFrom.co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckFrom.price + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)

                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].truckFrom.price)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].truckFrom.co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfOriginClick = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        setPortOfOriginActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(Number(totalCo2Count) + co2)
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesFrom[0].co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfDischargeClick = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        setPortOfDischargeActive((state) => {
            if (state) {
                if (totalBookNowPrice != '') {
                    let co2: any = Number(totalCo2Count) + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(Number(totalBookNowPrice) + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                } else {
                    let co2: any = item.freight[0].co2 / 1000 + item.freight[0].portFeesTo[0].co2 / 1000
                    setTotalCo2Count(co2)
                    setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                }
            } else {
                let price: any = Number(totalBookNowPrice) - (item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice)
                setTotalBookNowPrice(price)
                let co2: any = Number(totalCo2Count) - (item.freight[0].portFeesTo[0].co2 / 1000)
                setTotalCo2Count(co2)
            }
            return state
        })
    }
    const onPortOfDeliveryClick = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        setDeliveryActive((state) => {
            // if(state){
            //     setTotalBookNowPrice(item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            // }else{
            //     let price:any = Number(totalBookNowPrice) - (item.freight[0].originalPrice + item.freight[0].truckTo[0].originalPrice + item.freight[0].truckTo[1].originalPrice)
            //     setTotalBookNowPrice(price)
            // }
            return state
        })
    }
    const PickUpHandleChange = (item: any) => {
        setPickUpActive(!isPickUpActive)
        OnpickupClick(item)
    }
    const portOfOriginHandleChange = (item: any) => {
        setPortOfOriginActive(!isPortOfOriginActive)
        onPortOfOriginClick(item)
    }
    const portOfDischargeHandleChange = (item: any) => {
        setPortOfDischargeActive(!isPortOfDischargeActive)
        onPortOfDischargeClick(item)
    }
    const portOfDeliveryHandleChange = (item: any) => {
        setDeliveryActive(!isDeliveryActive)
        onPortOfDeliveryClick(item)
    }
    const toggleHandleChange = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        console.log(newFormats, "new formats")
        setToggleValue(newFormats)
    }
    const seaHandleChange = (e: any) => {
        setShipment(e.target.value)
        if (e.target.value === 'lcl') {
            setSliderUnits('TON')
            setSliderValue(1)
            setSliderValue1(1)
            setSliderUnits((state) => {
                return state
            })
            setSlider1Units('CBM')
        } else if (e.target.value === 'bulk') {
            setSliderUnits('MT')
            setTypeOfContainer('')
            containerType = ''
        } else if (e.target.value === 'ftl') {
            setSlider1Units('m3')
            setSliderValue1(1)
        }
        latlngAddress(e.target.value)
    }
    const requestFormSubmit = (values: any) => {
        console.log( "valuesssss",values)
        let formData: any = {
            product: selectProduct,
            phoneNumber: values.phone,
            name: values.name,
            email: values.eMail,
            description: values.description || '',
            transportationBy: transporatationName || '',
            originOfShipment: origin.label || '',
            destinationOfShipment: dest.label || '',
            readyToLoad: loadDate || '',
            typeOfShipment: shipment || '',
            typeOfContainer: typeOfContainer,
            weight: sliderValue,
            volume: sliderValue1,
            weightUnits: sliderUnits,
            volumeUnits: slider1Units
        }
        console.log(formData, "formdatataaa")
        axios.post(process.env.REACT_APP_PREPROCESSOR_API +'api/v1/seaRates/requestForm', formData).then((res: any) => {
            if (res.data.status) {
                toast.success(res.data.message)


            } else {
                toast.error(res.data.message)
            }
        })
        setSelectProduct("")
    }
    const handleChangeProduct = (values: any) => {
        setSelectProduct(values.description)
        // console.log(prodct)
    }
    return (
        <div className='root'>
            <Paper elevation={0} className='grid-header'>
                <Grid container spacing={2} >
                    <Grid xs={12} item lg={4} sm={6}>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>TRANSPORTATION BY</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={transporatationName}
                            size='small'
                            exclusive
                            onChange={transportationChange}
                            aria-label="Platform"
                            fullWidth
                            sx={{ backgroundColor: '#fff' }}
                        >
                            <ToggleButton value="sea">SEA</ToggleButton>
                            <ToggleButton value="land" >LAND</ToggleButton>
                            <ToggleButton value="air" >AIR</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>ORIGIN OF SHIPMENT</Typography>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q"
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                onChange: setOrigin,
                                placeholder: 'Select Origin',
                            }}
                        />

                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>DESTINATION OF SHIPMENT</Typography>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q"
                            apiOptions={{ language: 'en' }}
                            selectProps={{
                                onChange: setdest,
                                placeholder: 'Select Destionation',

                            }}

                        />
                    </Grid>
                    <Grid xs={12} item lg={4} sm={6} className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>READY TO LOAD</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={loadDate}
                                onChange={readyToLoad}

                                renderInput={(params: any) => (
                                    <TextField {...params} required size="small" fullWidth sx={{ backgroundColor: '#fff !important' }} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} lg={4} sm={6} item className='tabFilter'>
                        <Typography sx={{ fontSize: '12px', pb: 1 }}>TYPE OF SHIPMENT</Typography>
                        <FormControl fullWidth size="small" sx={{ backgroundColor: '#fff !important' }}>
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            {transporatationName === 'sea' ?
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={shipment}
                                    onChange={seaHandleChange}
                                    sx={{ fontSize: '14px' }}
                                    MenuProps={{ disableScrollLock: true }}
                                >
                                    <MenuItem value="fcl">
                                        FCL
                                    </MenuItem>
                                    <MenuItem value="lcl">LCL</MenuItem>
                                    <MenuItem value='bulk'>BULK</MenuItem>
                                </Select> : transporatationName === 'land' ?
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={shipment}
                                        onChange={seaHandleChange}
                                        sx={{ fontSize: '14px' }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value="ftl">FTL</MenuItem>
                                        <MenuItem value="ltl">LTL</MenuItem>
                                        <MenuItem value='fcl'>FCL</MenuItem>
                                        <MenuItem value='fcl'>FCL</MenuItem>
                                    </Select> :
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={shipment}
                                        onChange={handleChange}
                                        sx={{ fontSize: '14px' }}
                                        MenuProps={{ disableScrollLock: true }}
                                    >
                                        <MenuItem value="air">AIR</MenuItem>
                                    </Select>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" sx={{ padding: '5px', marginTop: '25px', textTransform: 'capitalize', color: '#f1f1f1' }} onClick={submit} size="small">
                            Search
                        </Button>
                    </Grid>

                </Grid>
            </Paper>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                {shipment === 'fcl' && isSubmit ?
                    <Grid xs={12} lg={3} sm={12} md={3} item className='tabFilter'>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant='h6' className='seaRates_Filter_Headers'>TYPE OF CONTAINER</Typography>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    value={typeOfContainer === '' ? 'ST20' : typeOfContainer}
                                    name="controlled-radio-buttons-group"
                                    onChange={TypeOfContainerChange}
                                    className='seaRates_Filter_Content'
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '14px !important'
                                        }
                                    }}
                                >
                                    <FormControlLabel value="ST20" control={<Radio />} label="20' Standard" />
                                    <FormControlLabel value="ST40" control={<Radio />} label="40' Standard" />
                                    <FormControlLabel value="HQ40" control={<Radio />} label="40' High Cube" />
                                    <FormControlLabel value="REF20" control={<Radio />} label="20' Refrigerated" />
                                    <FormControlLabel value="REF40" control={<Radio />} label="40' Refrigerated" />
                                    <FormControlLabel value="HQ45" control={<Radio />} label="45' High Cube" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    </Grid>
                    : shipment === 'lcl' || shipment === 'ltl' ?
                        <Grid item xs={3}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant='h6'>{shipment === 'lcl' ? 'Cargo Details' : 'Total Shipment'}</Typography>
                                <Box>
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Weight</Typography>
                                        <Box className="custome_Input_dropdown">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                id="truck-number"
                                                name="truckNumber"
                                                value={sliderValue}
                                                type="number"

                                                inputProps={{ maxLength: 15 }}
                                                onChange={valuehandleChange}
                                                sx={{
                                                    width: '80px',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important',

                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '5px !important'

                                                    }
                                                }}
                                            />
                                            <FormControl >
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sliderUnits}
                                                    onChange={handleChange}
                                                    size="small"
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            backgroundColor: '#e9f0f7',
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                    MenuProps={{ disableScrollLock: true }}
                                                >
                                                    <MenuItem value='TON'>TON</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Slider
                                        size="small"
                                        value={sliderValue}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        onChange={sliderHandleChange}
                                        min={1}
                                        max={shipment === 'lcl' ? 15 : 100}
                                    />
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Volume</Typography>
                                        <Box className="custome_Input_dropdown">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                id="truck-number"
                                                name="truckNumber"

                                                type="number"
                                                onChange={valuehandleChange1}
                                                value={sliderValue1}
                                                sx={{
                                                    width: '80px',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important',

                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '5px !important'

                                                    }
                                                }}
                                            />
                                            <FormControl >
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={slider1Units}
                                                    onChange={handleChange}
                                                    size="small"
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            backgroundColor: '#e9f0f7',
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                    MenuProps={{ disableScrollLock: true }}
                                                >
                                                    <MenuItem value='CBM'>CBM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Slider
                                        size="small"
                                        value={sliderValue1}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        onChange={sliderHandleChange1}
                                        min={1}
                                        max={15}
                                    />
                                </Box>
                            </Paper>
                        </Grid> : shipment === 'bulk' ?
                            <Grid item xs={3}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant='h6'>Total Shipment</Typography>
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Weight</Typography>
                                        <Box className="custome_Input_dropdown">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                id="truck-number"
                                                name="truckNumber"
                                                value={sliderValue}
                                                type="number"

                                                inputProps={{ maxLength: 15 }}
                                                onChange={valuehandleChange}
                                                sx={{
                                                    width: '80px',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none !important',

                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '5px !important'

                                                    }
                                                }}
                                            />
                                            <FormControl >
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sliderUnits}
                                                    onChange={handleChange}
                                                    size="small"
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            backgroundColor: '#e9f0f7',
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                    MenuProps={{ disableScrollLock: true }}
                                                >
                                                    <MenuItem value='MT'>MT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Slider
                                        size="small"
                                        value={sliderValue}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        onChange={sliderHandleChange}
                                        min={500}
                                        max={250000}

                                    />
                                </Paper>
                            </Grid> : shipment === 'ftl' ?
                                <Grid item xs={3}>
                                    <Paper sx={{ p: 2 }}>
                                        <Typography variant='h6'>Total Shipment</Typography>
                                        <Box sx={{ display: 'flex', mt: 2 }}>
                                            <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Volume</Typography>
                                            <Box className="custome_Input_dropdown">
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    id="truck-number"
                                                    name="truckNumber"
                                                    value={sliderValue1}
                                                    type="number"

                                                    inputProps={{ maxLength: 15 }}
                                                    onChange={valuehandleChange1}
                                                    sx={{
                                                        width: '80px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important',

                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '5px !important'

                                                        }
                                                    }}
                                                />
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={slider1Units}
                                                        onChange={handleChange}
                                                        size="small"
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none !important',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                backgroundColor: '#e9f0f7',
                                                                padding: '5px !important'

                                                            }
                                                        }}
                                                        MenuProps={{ disableScrollLock: true }}
                                                    >
                                                        <MenuItem value='m3'>m3</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Slider
                                            size="small"
                                            value={sliderValue1}
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            onChange={sliderHandleChange1}
                                            min={1}
                                            max={100}

                                        />
                                    </Paper>
                                </Grid> : shipment === 'air' ?
                                    <Grid item xs={3}>
                                        <Paper sx={{ p: 2 }}>
                                            <Typography variant='h6'>Total Shipment</Typography>
                                            <Box sx={{ display: 'flex', mt: 2 }}>
                                                <Typography sx={{ width: '100px' }} className='seaRates_Filter_Headers'>Weight</Typography>
                                                <Box className="custome_Input_dropdown">
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        id="truck-number"
                                                        name="truckNumber"
                                                        value={sliderValue}
                                                        type="number"

                                                        inputProps={{ maxLength: 15 }}
                                                        onChange={valuehandleChange}
                                                        sx={{
                                                            width: '80px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: 'none !important',

                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                padding: '5px !important'

                                                            }
                                                        }}
                                                    />
                                                    <FormControl >
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value='KG'
                                                            onChange={handleChange}
                                                            size="small"
                                                            sx={{
                                                                width: '80px',
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none !important',
                                                                },
                                                                '& .MuiOutlinedInput-input': {
                                                                    backgroundColor: '#e9f0f7',
                                                                    padding: '5px !important'

                                                                }
                                                            }}
                                                            MenuProps={{ disableScrollLock: true }}
                                                        >
                                                            <MenuItem value='KG'>KG</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Slider
                                                size="small"
                                                value={sliderValue}
                                                aria-label="Small"
                                                valueLabelDisplay="auto"
                                                onChange={sliderHandleChange}

                                            />
                                        </Paper>
                                    </Grid> : ''
                }
                {
                    isSubmit ?
                        <Grid item xs={12} lg={9} sm={12} md={9}>
                            {loader ? <Bars
                                height="30"
                                width="30"
                                color="#2a76f4"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            /> :
                                ratesResp.length > 0 ?
                                    <div>
                                        <Box sx={{ borderRadius: '30px', backgroundColor: '#e9f0f7', justifyContent: 'space-evenly' }}>
                                            <Stack direction="row" spacing={1}>
                                                {
                                                    chipsData.map((value, index) => (
                                                        <Chip label={value}
                                                            key={index}
                                                            onClick={() => addChip(value, index)}
                                                            size='small'
                                                            id={`chip_${value}`}
                                                            className={index === activeIndex ? 'activeChips' : 'inActiveChip'}
                                                        />
                                                    ))
                                                }
                                            </Stack>

                                        </Box>
                                        {ratesResp.map((item: any, index: any) => (
                                            <Box>
                                                <Paper sx={{ mt: 2 }} key={index}>
                                                    <Grid sx={{ display: 'flex' }}>
                                                        <Grid item xs={8}>
                                                            <Box sx={{ flexDirection: 'row', alignItems: 'center', }}>
                                                                <Box sx={{ display: 'flex', width: '100%', padding: '10px 20px' }}>
                                                                    <img src={item.freight[0].logo} className='shipments_img' />
                                                                    <Typography sx={{ padding: '10px 20px' }} className='rates_info'>{item.freight[0].shippingLine}</Typography>
                                                                </Box>
                                                                <Divider />
                                                                {/* <Stepper activeStep={activeStep} alternativeLabel={true} sx={{
                                                                padding: '10px 20px', mt: 2,
                                                               
                                                                ".MuiSvgIcon-root": {
                                                                    borderRadius: "50%",
                                                                    border: "1px solid #1976d2"
                                                                },
                                                                ".MuiSvgIcon-root:not(.Mui-completed)": {
                                                                    color: "white"
                                                                },
                                                                ".MuiStepIcon-text": {
                                                                    fill: "#1976d2",
                                                                    fontWeight: 500
                                                                },
                                                                ".MuiSvgIcon-root.Mui-active": {
                                                                    color: "#1976d2",
                                                                    padding: "3px",
                                                                    borderRadius: "50%",
                                                                    border: "1px solid #1976d2",
                                                                    marginY: "-3px"
                                                                },
                                                                ".Mui-active .MuiStepIcon-text": {
                                                                    fill: "white"
                                                                },
                                                                
                                                            }} >

                                                                <Step key={item.portFrom.name} completed={completed['1']}>
                                                                    <StepButton color="inherit" onClick={() => handleStep('1')}>
                                                                        <StepLabel>{item.portFrom.name}</StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                                <Step key={item.portFrom.code} completed={completed['2']}>
                                                                    <StepButton color="inherit" onClick={() => handleStep('2')}>
                                                                        <StepLabel>{item.portFrom.code}</StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                                <Step key={item.portTo.code} completed={completed['3']}>
                                                                    <StepButton color="inherit" onClick={() => handleStep('3')}>
                                                                        <StepLabel>{item.portTo.code}</StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                                <Step key={item.portTo.name} completed={completed['4']}>
                                                                    <StepButton color="inherit" onClick={() => handleStep('4')}>
                                                                        <StepLabel>{item.portTo.name}</StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                            </Stepper> */}
                                                                <div className='stepper'>
                                                                    <div className='stepper_content_top'>
                                                                        <Typography className='step_title'>{item.portFrom.name}</Typography>
                                                                        <Typography className='step_title'>{item.portTo.name}</Typography>
                                                                    </div>
                                                                    <div className='steps'>
                                                                        <div className={isPickUpActive === true ? 'steps_main steps_cyan steps_cyan_active' : 'steps_main steps_cyan steps_cyan_inactive'}>
                                                                            {isPickUpActive === true ? <LocalShippingOutlinedIcon className='steps_icon' /> : ''}
                                                                            <div className={isPickUpActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => OnpickupClick(item)}>
                                                                                <div className='steps_tooltip'>
                                                                                    <div className='steps_tooltip_header'>
                                                                                        <div className='steps_tooltip_content'>
                                                                                            <Typography className='steps_tooltip_content_info'>
                                                                                                Pick up
                                                                                            </Typography>
                                                                                            <Divider />
                                                                                            <Typography>Port Of Everglades</Typography>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='steps_main steps_blue'>
                                                                            <Typography className='steps_delay_days'>18 days</Typography>
                                                                            <DirectionsBoatFilledOutlinedIcon className='steps_icon' />
                                                                            <div className={isPortOfOriginActive === true ? 'steps_circle steps_circle_active ' : 'steps_circle steps_circle_inactive'} onClick={() => isPickUpActive != true ? onPortOfOriginClick(item) : ''}>
                                                                                <div className='steps_tooltip'>
                                                                                    <div className='steps_tooltip_header'>
                                                                                        <div className='steps_tooltip_content'>
                                                                                            <Typography className='steps_tooltip_content_info'>
                                                                                                Port of origin
                                                                                            </Typography>
                                                                                            <Divider />
                                                                                            <Typography>Port Of Everglades</Typography>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='stepper_content_bottom'>
                                                                                <Typography className='step_bottom-title'>{item.portFrom.code}</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className={isDeliveryActive === true ? 'steps_main steps_blue steps-blue-cyan_active' : 'steps_main steps_blue steps-blue-cyan_inactive'}>
                                                                            {isDeliveryActive ? <LocalShippingOutlinedIcon className='steps_icon' /> : ''}
                                                                            <div className={isPortOfDischargeActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => onPortOfDischargeClick(item)}>
                                                                                <div className='steps_tooltip'>
                                                                                    <div className='steps_tooltip_header'>
                                                                                        <div className='steps_tooltip_content'>
                                                                                            <Typography className='steps_tooltip_content_info'>
                                                                                                Port of discharge
                                                                                            </Typography>
                                                                                            <Divider />
                                                                                            <Typography>Bremerhaven</Typography>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='stepper_content_bottom'>
                                                                                <Typography className='step_bottom-title'>{item.portTo.code}</Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className='steps_main steps_cyan'>
                                                                            <div className={isDeliveryActive === true ? 'steps_circle steps_circle_active' : 'steps_circle steps_circle_inactive'} onClick={() => onPortOfDeliveryClick(item)}>
                                                                                <div className='steps_tooltip'>
                                                                                    <div className='steps_tooltip_header'>
                                                                                        <div className='steps_tooltip_content'>
                                                                                            <Typography className='steps_tooltip_content_info'>
                                                                                                Delivery
                                                                                            </Typography>
                                                                                            <Divider />
                                                                                            <Typography>Bremerhaven</Typography>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Box>


                                                        </Grid>
                                                        <Divider orientation="vertical" variant="middle" flexItem />
                                                        <Grid item xs={4} sx={{ ml: 2 }}>
                                                            <Box sx={{ display: 'flex', padding: '5px' }}>
                                                                <Typography sx={{ color: '#9fb1c1', fontSize: '14px' }}>
                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '50px' }} />
                                                                </Typography>
                                                                <span style={{ fontSize: '20px', marginTop: '10px' }}>
                                                                    {totalCo2Count != '' ? totalCo2Count : item.freight[0].co2 / 1000}
                                                                    {/* {item.freight[0].co2 / 1000 + item.freight[0].truckFrom.co2 / 1000 + item.freight[0].truckTo.co2 / 1000 + item.freight[0].portFeesFrom[0].co2 / 1000 + item.freight[0].portFeesTo[0].co2 / 1000} */}
                                                                    Kg CO2</span>

                                                            </Box>
                                                            <Box sx={{ padding: '5px' }} >
                                                                <Button variant="contained" size="large">
                                                                    ${totalBookNowPrice != '' ? totalBookNowPrice : item.freight[0].originalPrice}
                                                                    {/* $ {item.freight[0].originalPrice + item.freight[0].truckFrom.originalPrice + item.freight[0].truckTo.originalPrice + item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesTo[0].originalPrice}  */}
                                                                    Book Now
                                                                </Button>
                                                                <Typography sx={{ color: '#333', fontSize: '12px' }}><small>Applicable till: {item.freight[0].validTo}</small></Typography>

                                                            </Box>
                                                            <Box sx={{ display: 'flex', padding: '5px' }}>
                                                                <Typography sx={{ color: '#333', fontSize: '14px', }}><small>Estimated Delivery (in days): {item.freight[0].transitTime}</small></Typography>
                                                            </Box>
                                                            <Box>
                                                                <Button variant="text" size="large" onClick={() => viewDetails(index)}>
                                                                    View Details
                                                                </Button>
                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                    <Divider />
                                                </Paper>
                                                {isViewDetailsShow ?
                                                    <Grid>
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={toggleValue}
                                                                exclusive
                                                                onChange={toggleHandleChange}
                                                                aria-label="Platform"
                                                                sx={{ margin: '10px' }}
                                                            >
                                                                <ToggleButton value="tariff" size='small' sx={{ padding: '2px 20px', }}>Tariff</ToggleButton>
                                                                <ToggleButton value="map" size='small' sx={{ padding: '2px 20px' }}>Map</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Box>
                                                        {toggleValue === 'tariff' ?
                                                            <Box>
                                                                <Accordion expanded={expanded === 'panel1-1'} onChange={handleChangeaccordion('panel1-1')}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1bh-content"
                                                                        id="panel1bh-header"
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                margin: '0px !important'
                                                                            },
                                                                            "& .MuiAccordionSummary-root .Mui-expanded": {
                                                                                minHeight: '10px !important'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                                                            <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                                                                <Checkbox
                                                                                    checked={isPickUpActive}
                                                                                    onChange={() => PickUpHandleChange(item)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                />
                                                                                <LocalShippingOutlinedIcon sx={{ m: '10px', }} />
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Pick up</Typography>
                                                                            </Typography>
                                                                            <Box className="Tariff_info">
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem', }} className='info_1'>
                                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].truckFrom.co2 / 1000} <span> kg CO2</span>
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', }} className='info_2'>
                                                                                    <strong>${item.freight[0].truckFrom.originalPrice}</strong>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                                                        <FormControl sx={{ width: '100%' }}>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                name="controlled-radio-buttons-group"
                                                                                value={value}
                                                                                onChange={handleChange}
                                                                            >
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                    <FormControlLabel value="Truck" control={<Radio />} label="Truck" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckFrom.transitTime}</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckFrom.distance}</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].truckFrom.originalPrice}</Typography>
                                                                                </Box>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                    <FormControlLabel value="Rail" control={<Radio />} label="Rail" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].railFrom.originalPrice === null ? 0 : item.freight[0].railFrom.originalPrice}</Typography>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion expanded={expanded === 'panel1-2'} onChange={handleChangeaccordion('panel1-2')}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel2bh-content"
                                                                        id="panel2bh-header"
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                margin: '0px !important'
                                                                            },
                                                                            "& .MuiAccordionSummary-root": {
                                                                                minHeight: '10px !important'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                                                            <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                                                                <Checkbox
                                                                                    checked={isPortOfOriginActive}
                                                                                    onChange={() => portOfOriginHandleChange(item)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    disabled={isPickUpActive === true ? true : false}
                                                                                    sx={{
                                                                                        '&.Mui-disabled': {
                                                                                            pointerEvents: 'auto',
                                                                                            cursor: 'not-allowed',

                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <AnchorOutlinedIcon sx={{ m: '10px' }} />
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>
                                                                                    Port of origin (Port Everglades)
                                                                                </Typography>
                                                                            </Typography>
                                                                            <Box className="Tariff_info">
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].portFeesFrom[0].co2 / 1000} <span> kg CO2</span>
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                                                                    <strong>${item.freight[0].portFeesFrom[0].originalPrice + item.freight[0].portFeesFrom[1].originalPrice}</strong>

                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </AccordionSummary>

                                                                    <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                                                        <FormControl sx={{ width: '100%' }}>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                name="controlled-radio-buttons-group"
                                                                                value={value}
                                                                                onChange={handleChange}
                                                                            >

                                                                                {item.freight[0].portFeesFrom.map((item1: any) => (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 1 }}>
                                                                                        <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item1.abbr} - {item1.title}</Typography>
                                                                                        <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item1.originalPrice === 0 ? 'included' : item1.originalPrice}</Typography>
                                                                                    </Box>
                                                                                ))}
                                                                            </RadioGroup>

                                                                        </FormControl>

                                                                    </AccordionDetails>

                                                                </Accordion>
                                                                <Accordion expanded={expanded === 'panel1-3'} onChange={handleChangeaccordion('panel1-3')}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel3bh-content"
                                                                        id="panel3bh-header"
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                margin: '0px !important'
                                                                            },
                                                                            "& .MuiAccordionSummary-root": {
                                                                                minHeight: '10px !important'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                                                            <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                                                                <Checkbox
                                                                                    checked={true}
                                                                                    onChange={handleChange}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    disabled
                                                                                    sx={{
                                                                                        '&.Mui-disabled': {
                                                                                            pointerEvents: 'auto',
                                                                                            cursor: 'not-allowed',

                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <DirectionsBoatFilledOutlinedIcon sx={{ m: '10px' }} />
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>
                                                                                    Ocean Freight (LIFO)
                                                                                </Typography>
                                                                            </Typography>

                                                                            <Box className="Tariff_info">
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} /> {item.freight[0].co2 / 1000} <span> kg CO2</span>
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                                                                    <strong>${item.freight[0].originalPrice}</strong>
                                                                                </Typography>
                                                                            </Box>


                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                                                        <FormControl sx={{ width: '100%' }}>

                                                                            <RadioGroup
                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                name="controlled-radio-buttons-group"
                                                                                value={value}
                                                                                onChange={handleChange}
                                                                            >
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item.freight[0].containerType}</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>${item.freight[0].originalPrice}</Typography>

                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion expanded={expanded === 'panel1-4'} onChange={handleChangeaccordion('panel1-4')}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel4bh-content"
                                                                        id="panel4bh-header"
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                margin: '0px !important'
                                                                            },
                                                                            "& .MuiAccordionSummary-root": {
                                                                                minHeight: '10px !important'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                                                            <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                                                                <Checkbox
                                                                                    checked={isPortOfDischargeActive}
                                                                                    onChange={() => portOfDischargeHandleChange(item)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}

                                                                                />
                                                                                <AnchorOutlinedIcon sx={{ m: '10px' }} />
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Port of discharge (Hazira)</Typography>

                                                                            </Typography>
                                                                            <Box className="Tariff_info">
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} /> {item.freight[0].portFeesTo[0].co2 / 1000} <span> kg CO2</span>
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                                                                    <strong>${item.freight[0].portFeesTo[0].originalPrice + item.freight[0].portFeesTo[1].originalPrice}</strong>
                                                                                </Typography>
                                                                            </Box>

                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                                                        <FormControl sx={{ width: '100%' }}>

                                                                            <RadioGroup
                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                name="controlled-radio-buttons-group"
                                                                                value={value}
                                                                                onChange={handleChange}
                                                                            >
                                                                                {item.freight[0].portFeesTo.map((item1: any) => (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 1 }}>
                                                                                        <Typography sx={{ fontSize: '0.75rem', width: '300px' }}>{item1.abbr} - {item1.title}</Typography>
                                                                                        <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item1.originalPrice === 0 ? 'included' : item1.originalPrice}</Typography>
                                                                                    </Box>
                                                                                ))}
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion expanded={expanded === 'panel1-5'} onChange={handleChangeaccordion('panel1-5')}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel4bh-content"
                                                                        id="panel4bh-header"
                                                                        sx={{
                                                                            "& .MuiAccordionSummary-content": {
                                                                                margin: '0px !important'
                                                                            },
                                                                            "& .MuiAccordionSummary-root": {
                                                                                minHeight: '10px !important'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                                                            <Typography sx={{ flexShrink: 0, width: '500px', display: 'flex' }}>
                                                                                <Checkbox
                                                                                    checked={isDeliveryActive}
                                                                                    onChange={() => portOfDeliveryHandleChange(item)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                />
                                                                                <LocalShippingOutlinedIcon sx={{ m: '10px' }} />
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '15px', fontSize: '0.75rem' }}>Delivery</Typography>

                                                                            </Typography>
                                                                            <Box className="Tariff_info">
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px', fontSize: '0.75rem' }} className='info_1'>
                                                                                    <img src={require('../../../asserts/leaf.png')} style={{ width: '20px' }} />{item.freight[0].truckTo.co2} <span> kg CO2</span>
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'text.secondary', marginTop: '10px' }} className='info_2'>
                                                                                    <strong>${item.freight[0].truckTo.originalPrice}</strong>
                                                                                </Typography>

                                                                            </Box>

                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails sx={{ border: '1px solid #e9f0f7', width: '100%' }}>
                                                                        <FormControl sx={{ width: '100%' }}>

                                                                            <RadioGroup
                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                name="controlled-radio-buttons-group"
                                                                                value={value}
                                                                                onChange={handleChange}
                                                                            >
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                    <FormControlLabel value="Truck" control={<Radio />} label="Truck" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckTo.transitTime}</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>{item.freight[0].truckTo.distance}</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem' }}>${item.freight[0].truckTo.originalPrice}</Typography>
                                                                                </Box>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                                    <FormControlLabel value="Rail" control={<Radio />} label="Rail" sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.75rem' } }} />
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', width: '200px' }}>&nbsp;</Typography>
                                                                                    <Typography sx={{ fontSize: '0.75rem', }}>${item.freight[0].railTo.originalPrice === null ? 0 : item.freight[0].railTo.originalPrice}</Typography>
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Box> : <SeaRatesMap data={{ origin: { name: item.cityFrom.name, lat: item.cityFrom.lat, lng: item.cityFrom.lng }, destination: { name: item.cityTo.name, lat: item.cityTo.lat, lng: item.cityTo.lng } }} />}
                                                    </Grid> : ''}
                                            </Box>

                                        ))
                                        }

                                    </div>
                                    : <Paper>
                                        <Box sx={{ p: 2, }}>
                                            <Typography>
                                                No rates are currently available. But please fill just a few fields and get an individual offer!
                                            </Typography>
                                            <Typography variant='h6'>
                                                Quick Request
                                            </Typography>
                                        </Box>
                                        <Divider flexItem sx={{ mx: 0.5, my: 1 }} />
                                        {/* <Formik
                                            initialValues={{
                                                productName: "",
                                                phone: "",
                                                name: "",
                                                eMail: "",
                                                description: ""
                                            }}
                                            validationSchema={schemavalidation}

                                        > */}
                                            {/* {({ errors, touched, values, handleChange }) => ( */}
                                                {/* <form onSubmit={formik.handleSubmit}>
                                                    <Grid container sx={{ p: 2 }} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>PRODUCT</Typography>
                                                                <a onClick={() => hsCodeDetails()}>
                                                                    <Typography className='hsCodes'>HS Codes</Typography>
                                                                </a>
                                                            </Box>
                                                            <Autocomplete
                                                                id="asynchronous-demo"
                                                                fullWidth
                                                                size='small'
                                                                open={open1}
                                                                onOpen={() => {
                                                                    setOpen1(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen1(false);
                                                                }}
                                                                onChange={(event, value) => handleChangeProduct(value)}
                                                                isOptionEqualToValue={(option: any, value: any) => option.description === value.description}
                                                                getOptionLabel={(option) => option.description}
                                                                options={options}
                                                                loading={loading}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        name='produtName'
                                                                        value={formik.values.productName}
                                                                        onChange={handleChange}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        required
                                                                    // error={
                                                                    //     Boolean(touched.productName) && Boolean(errors.productName)
                                                                    // }
                                                                    // helperText={,
                                                                    //     Boolean(touched.productName) && errors.productName
                                                                    // }
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>PHONE</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                    name="phone"
                                                                    value={formik.values.phone}
                                                                    onChange={handleChange}
                                                                    id="component-outlined"
                                                                    label="Phone"
                                                                    placeholder="Phone"
                                                                    type="text"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    required
                                                                // error={
                                                                //     Boolean(touched.phone) && Boolean(errors.phone)

                                                                // helperText={
                                                                //     Boolean(touched.phone) && errors.phone
                                                                // }
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>FULL NAME</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                    name="name"
                                                                    value={formik.values.name}
                                                                    onChange={handleChange}
                                                                    id="component-outlined"
                                                                    label="Full Name"
                                                                    placeholder="Full Name"
                                                                    type="text"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    required
                                                                // error={
                                                                //     Boolean(touched.name) && Boolean(errors.name)
                                                                // }
                                                                // helperText={
                                                                //     Boolean(touched.name) && errors.name
                                                                // }

                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>E-MAIL</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                    name="eMail"
                                                                    value={formik.values.eMail}
                                                                    onChange={handleChange}
                                                                    id="component-outlined"
                                                                    label="E-Mail"
                                                                    placeholder="E-Mail"
                                                                    type="email"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    required
                                                                // error={
                                                                //     Boolean(touched.eMail) && Boolean(errors.eMail)
                                                                // }
                                                                // helperText={
                                                                //     Boolean(touched.eMail) && errors.eMail
                                                                // }

                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>DESCRIPTION (OPTIONAL)</Typography>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    multiline={true}
                                                                    rows={4}
                                                                    minRows={10}
                                                                    margin="dense"
                                                                    id="component-outlined"
                                                                    label="Description"
                                                                    type="text"
                                                                    name="description"
                                                                    value={formik.values.description}


                                                                />
                                                            </FormControl>

                                                        </Grid>
                                                        <Button variant="contained" type='submit' sx={{ padding: '5px', marginTop: '25px', color: '#f1f1f1', margin: 'auto', width: '300px' }} size="small" >
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>

                                                </form> */}
                                                                                         {/* <form 
                                         onSubmit={handleSubmit(requestFormSubmit)
                                            
                                          }>
                                                    <Grid container sx={{ p: 2 }} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>PRODUCT</Typography>
                                                                <a onClick={() => hsCodeDetails()}>
                                                                    <Typography className='hsCodes'>HS Codes</Typography>
                                                                </a>
                                                            </Box>
                                                            <Autocomplete
                                                                id="asynchronous-demo"
                                                                fullWidth
                                                                size='small'
                                                                open={open1}
                                                                onOpen={() => {
                                                                    setOpen1(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen1(false);
                                                                }}
                                                              
                                                                isOptionEqualToValue={(option: any, value: any) => option.description === value.description}
                                                                getOptionLabel={(option) => option.description}
                                                                options={options}
                                                                loading={loading}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                   
                                                                        {...params}
                                                                        label="Enter Commodity type or Hs code"
                                                                       {...register("productName")}
                                                                       name="productName"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        // error={
                                                                        //     Boolean(touched.productName) && Boolean(errors.productName)
                                                                        // }
                                                                        // helperText={
                                                                        //     Boolean(touched.productName) && errors.productName
                                                                        // }
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>PHONE</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                    {...register("phone")}
                                                                   name="phone"
                                                                    id="component-outlined"
                                                                    label="Phone"
                                                                    placeholder="Phone"
                                                                    type="text"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    // error={
                                                                    //     Boolean(touched.phone) && Boolean(errors.phone)
                                                                    //   }
                                                                    //   helperText={
                                                                    //     Boolean(touched.phone) && errors.phone
                                                                    //   }
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>FULL NAME</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                 
                                                                  {...register("name")}
                                                                  name="name"
                                                                    // onChange={handleChange}
                                                                    id="component-outlined"
                                                                    label="Full Name"
                                                                    placeholder="Full Name"
                                                                    type="text"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    // error={
                                                                    //     Boolean(touched.name) && Boolean(errors.name)
                                                                    //   }
                                                                    //   helperText={
                                                                    //     Boolean(touched.name) && errors.name
                                                                    //   }

                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>E-MAIL</Typography>
                                                            <FormControl fullWidth size="small">
                                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                <TextField
                                                                  
                                                                    {...register("eMail")}
                                                                      name="eMail"
                                                                    // onChange={handleChange}
                                                                    id="component-outlined"
                                                                    label="E-Mail"
                                                                    placeholder="E-Mail"
                                                                    type="email"
                                                                    sx={{
                                                                        width: "100%",
                                                                        marginBottom: 2,
                                                                    }}
                                                                    size='small'
                                                                    // error={
                                                                    //     Boolean(touched.eMail) && Boolean(errors.eMail)
                                                                    //   }
                                                                    //   helperText={
                                                                    //     Boolean(touched.eMail) && errors.eMail
                                                                    //   }

                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>DESCRIPTION (OPTIONAL)</Typography>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    multiline={true}
                                                                    rows={4}
                                                                    minRows={10}
                                                                    autoFocus
                                                                    margin="dense"
                                                                    id="name"
                                                                    label="Description"
                                                                    type="email"

                                                                    variant="outlined"

                                                                />
                                                            </FormControl>

                                                        </Grid>
                                                        <Button variant="contained" type='submit' sx={{ padding: '5px', marginTop: '25px', color: '#f1f1f1', margin: 'auto', width: '300px' }}  size="small" onClick={()=>reset()}>
                                                            SUBMIT
                                                        </Button>
                                                    </Grid>

                                                </form> */}

                                                <form onSubmit={
                                                    handleSubmit((values)=>{console.log(values); requestFormSubmit(values);reset()})
                                                    
                                                    }>
                                                        <Box sx={{marginBottom:'10px',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                        <Autocomplete
                                                                id="asynchronous-demo"
                                                                sx={{width:'49%'}}
                                                                size='small'
                                                                open={open1}
                                                                onOpen={() => {
                                                                    setOpen1(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen1(false);
                                                                }}
                                                                onChange={(event, value) => handleChangeProduct(value)}
                                                                isOptionEqualToValue={(option: any, value: any) => option.description === value.description}
                                                                getOptionLabel={(option) => option.description}
                                                                options={options}
                                                                loading={loading}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                  
                                                                        {...params}
                                                                        value={productName}
                                                                        // onChange={handleProduct}
                                                                        {...register('produtName')}
                                                                        
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                        required
                                                                    // error={
                                                                    //     Boolean(touched.productName) && Boolean(errors.productName)
                                                                    // }
                                                                    // helperText={,
                                                                    //     Boolean(touched.productName) && errors.productName
                                                                    // }
                                                                    />
                                                                )}
                                                            />
                                                         <TextField sx={{width:'49%'}} {...register("name")} type='text' size='small' placeholder=' Full name' variant='outlined' label=" Full name"/>
                                                         </Box>
                                                         <Box sx={{marginBottom:'10px',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                    <TextField sx={{width:'49%'}}{...register("phone")} type='number' size='small' placeholder=' Phone Number' variant='outlined' label=" Phone Number"/>
                                                    <TextField sx={{width:'49%'}} {...register("eMail")} type="email" size='small' placeholder=' Email '  variant='outlined' label=" Email "/>
                                                    </Box>
                                                    <Box>
                                                    <TextField
                                                    sx={{width:'100%'}}
                                                                    multiline={true}
                                                                    rows={4}
                                                                    minRows={10}
                                                                    autoFocus
                                                                    margin="dense"
                                                                    id="name"
                                                                    label="Description"
                                                                    type="email"
                                                                    {...register('description')}
                                                                    variant="outlined"

                                                                />
                                                                </Box>
                                                    <Button type='submit' variant='contained' >submit</Button>
                                                </form>
                                        {/* //     )}
                                        // </Formik> */}
                                    </Paper>
                            }
                        </Grid>
                        : ''
                }
            </Grid >

            <Dialog
                fullWidth
                maxWidth="md"
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"

            >

                <DialogTitle id="responsive-dialog-title">
                    HS CODES
                </DialogTitle>
                <DialogContent>
                    <Box className="hscodes_header">
                        {/* <Breadcrumbs aria-label="breadcrumb" className='breadCrumbs'>
                                <Typography>Main Categories</Typography>
                                <Box sx={{display:'flex'}}>
                                    {PRoductsByHsCode.map((item:any)=>(
                                        <Typography>{item}</Typography>
                                    ))}
                                </Box>
                            </Breadcrumbs> */}
                        <Breadcrumbs aria-label='breadcrumb' className='breadCrumbs'>
                            {PRoductsByHsCode &&
                                PRoductsByHsCode.map((b: any, index: any) =>
                                    index !== PRoductsByHsCode.length - 1 ? (
                                        <Box key={b}>
                                            <Typography>{b}</Typography>
                                        </Box>
                                    ) : (
                                        <Typography key={b} color='text.primary'>
                                            {b}
                                        </Typography>
                                    ),
                                )}
                        </Breadcrumbs>
                        <Box className="hscodes_content">
                            {products.map((item: any) => (
                                <Box className="hscodes_info" onClick={() => onSelectProducts(item)}>
                                    <Typography>{item.description}</Typography>
                                    <Typography>{item.code}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    {PRoductsByHsCode.length > 1 ?
                        <Button onClick={() => handleSelect(PRoductsByHsCode[PRoductsByHsCode.length - 1])} variant="contained">
                            Select
                        </Button> : ''}
                </DialogActions>
            </Dialog>
        </div >
    )
}

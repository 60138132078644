import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";

const ChaTabData = [
  // {
  //   id: 0,
  //   parentnav: "Shipments",
  //   icon: <LocalShippingSharpIcon />,
  //   children: [
  //     {
  //       cid: 1,
  //       childnav: "Pending Custom Clearance",
  //       route: "/pending_custom_clearance",
  //       access: true,
  //     },
  //     {
  //       cid: 2,
  //       childnav: "Export Custom Clearence",
  //       route: "/export_custom_clearence",
  //       access: true,
  //     },
  //     {
  //       cid: 2,
  //       childnav: "Import Custom Clearence",

  //       access: true,
  //     },
  //     {
  //       cid: 3,
  //       childnav: "GateOut Shipments",
  //       route: "/gateOut_shipments",
  //       access: true,
  //     },
  //   ],
  // },
  {
    id: 0,
    parentnav: "Pending  clearence",
    route: "/pending_custom_clearance",
    icon: <UploadFileOutlinedIcon />,
    access: true,
  },
  {
    id: 1,
    parentnav: "Export  clearence",
    route: "/export_custom_clearence",
    icon: <UpgradeIcon />,
    access: true,
  },
  {
    id: 2,
    parentnav: "Import  clearence",
    route: "/import_custom_clearence",
    icon: <ArrowDownwardIcon />,
    access: true,
  },
  {
    id: 3,
    parentnav: "Gate Out Shipments",
    route: "/gateOut_shipments",
    icon: <AssistantDirectionIcon />,
    access: true,
  },
  {
    id: 4,
    parentnav: "UploadDoc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    children: [
      {
        cid: 0,
        title: "UPLOAD DOCUMENTS",
        icon: <UploadFileOutlinedIcon />,
      },
      {
        id: 1,
        childnav: "Upload Documents",
        route: "/upload_documents",
        access: true,
      },
      // {
      //   id: 2,
      //   childnav: "Manage Documents",
      //   route: "/manage_documents",
      //   access: true,
      // },
    ],
  },
];

export default ChaTabData;

import { Navigate, Outlet } from "react-router-dom";
import {MainLayout} from "../components/layouts/MainLayout";
const PrivateRoutes = () => {
  let token = sessionStorage.getItem("token");
  let bool = token ? true : false;
  let auth = { token: bool };

  return auth.token ? <MainLayout /> : <Navigate to="/login" />;
};

export default PrivateRoutes;

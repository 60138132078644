import { Box, Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";

import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import axios from "axios";
import { Button, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import { IconButton, Paper } from "@mui/material";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { Alert, AlertTitle, LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Endpoints } from "../Enviornment/Endpoints";
import DataTable,{TableColumn} from "react-data-table-component";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ManageDocs() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [referencenumber, setReferenceNumber] = useState<any>("");
  const [showgrid, setShowGrid] = useState(false);
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
        backgroundColor: '#0b51b7'
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: 'white'
      },
    },
    rows: {
  
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        outline: '1px solid #FFFFFF',
      },
    },
  
  };
  const columns:TableColumn<any>[] = [
    {
      name:'REFERENCE #',
      selector:row => row.documentReference,
      width:'200px'
    },
    {
      name:'DOCUMNET TYPE',
      selector:row => row.documentTypeId?.name || <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width:'200px'
    },
    {
      name:'FILE NAME',
      selector:row => row.originalName,
      width:'200px'
    },
    {
      name:'UPLOAD BY',
      selector:row => row.uploadedBy,
      width:'200px'
    },
    {
      name: "VIEW DOC",
      sortable: true,
       width: '200px',
      cell: (row) => (
        <>
          <a href={row.docPath} target="_blank">
            <IconButton
              sx={{ padding: "0", margin: "0" }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <VisibilitySharpIcon />
            </IconButton>
          </a>
        </>
      ),
    },
  ]

  function  getdocs (){
    axios.get(Endpoints.Get_Docs, gconfig).then((res) => {
      console.log("MANAGE DOCUMNETS API RESPONSE..", res);
      setLoad(false)
      if (res.data.data.length === 0) {
        setShowGrid(false);
      } else {
        setShowGrid(true)
        setRowData(res.data.data);
      }
    })
      .catch((err: any) => {
        setShowGrid(false);
        setLoad(false)
      });
  }
  console.log("...................", state);
  useEffect(() => {
    console.log("Intrnasit File Viewer Screen .....");
    getdocs();
    
  }, []);
  const handleRefNumber = (e: any) => {
    setReferenceNumber(e.target.value.toLowerCase());
  };
  const goptions = {
    docAgainst: state.docAgainst,
    documentReference: state.documentReference,
  };
  const gconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: goptions,
  };
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onGridReady = useCallback((params: GridReadyEvent) => {
    setLoad(true)
    axios.get(Endpoints.Get_Docs, gconfig).then((res) => {
      console.log("MANAGE DOCUMNETS API RESPONSE..", res);
      setLoad(false)
      if (res.data.data.length === 0) {
        setShowGrid(false);
      } else {
        setShowGrid(true)
        setRowData(res.data.data);
      }
    })
      .catch((err: any) => {
        setShowGrid(false);
        setLoad(false)
      });
  }, []);
  const backNavigation = () => {
    navigate(-1)
  }

  return (
    <div className={classes.root}>
      <LoadingButton
            loading={false}
            variant="contained"
            type="submit"
            className="submitbtn"
            color="warning"
            sx={{
              justifyContent: "end",
              fontWeight: 500,
              ml: 'auto',
              display:'block',
              backgroundColor: "#555a64",
              color: "#fff",
              "& .MuiButtonBase-root:hover": {
                backgroundColor: "#555a64",
                color: "#fff",
              },
            }}
            onClick={() => backNavigation()}
          >
            Back
          </LoadingButton>
      <Paper elevation={0}>
      
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            // <AgGridReact
            //   className="ag-theme-balham"
            //   animateRows={true}
            //   defaultColDef={defaultColDef}
            //   rowData={rowData}
            //   domLayout={domLayout}
            //   columnDefs={columnDefs}
            //   sideBar={sideBar}
            //   pagination={true}
            //   paginationPageSize={10}
            //   ref={gridRef}
            //   onGridReady={onGridReady}
            // // onFirstDataRendered={onFirstDataRendered}
            // ></AgGridReact>
            <DataTable
            style={{ borderRadius: 3 }}
            columns={columns}
            data={rowData}
            responsive={true}
            customStyles={customStyles}
            theme="default"

            pagination
            dense
            striped
            highlightOnHover={true}
            // onRowClicked={(data) => handleLine(data)}

          />
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No Documents Found.
            </Alert>
          )}
        </Box>
      </Paper>
    </div>
  );
}

export default ManageDocs;

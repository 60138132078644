import { makeStyles, Theme } from "@material-ui/core/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
import { GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { Container } from "@mui/system";
import { sideBar,defaultColDef } from '../../common/AgGridConfig';

import { useEffect } from "react";
//import Button from "../../Layout/Button";
import { Alert, AlertTitle, Box, Button, Paper } from "@mui/material";
import { DesgService } from "../../../Services/DesgService";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import axios from "axios";
import RejectedItemDetail from "./Rejected_lines";
import { useOutletContext } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function AcceptedReturns() {
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState();
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  useEffect(() => {}, [openDetail, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleData = (params: any) => {
    setData(params.data);
    setOpenDetail(true);
  };

  useEffect(() => {
    setLoad(true);
    axios.get(process.env.REACT_APP_BASE_URL + '/returnOrderList', config).then((res: any) => {
      console.log("23456789876543", res);
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      } else {
        setLoad(false);
        setShowGrid(true);
        setRowData(res.data.primary);
        console.log(res.data.primary, "$rowdata");
      }
    });
  }, [fromDate, toDate]);

  const classes = useStyles();
  const [columnDefs] = useState([
    {
      headerName: "RETURN NUMBER",
      field: "returnNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    { headerName: "RETURN VERSION", field: "returnVersion" },
    { headerName: "CUSTOMER ID", field: "returnDate"},
    { headerName: "CUSTOMER NAME", field: "supplierId"},
    { headerName: "CUSTOMER DATE", field: "supplierName" },
    {
      headerName: "SHIP TO ADDRESS DATE",
      field: "shipToAddress",
    },
    { headerName: "CREATED BY", field: "createdBy" },
    { headerName: "ACCEPTED BY", field: "createdBy" },
  ]);
  const [rowData, setRowData] = useState<any>([]);

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    returnStatus: "accepted",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

 
  const onGridReady = useCallback((params: GridReadyEvent) => {
    console.log(params, "$$");
    axios.get(process.env.REACT_APP_BASE_URL + "/returnOrderList", config).then((res: any) => {
      console.log("23456789876543", res);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
      } else {
        setRowData(res.data.primary);
        console.log(res.data.primary, "$rowdata");
      }
    });
  }, []);

  const domLayout = "autoHeight";
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              // ref={gridRef}
              // getRowHeight={getRowHeight}
              rowSelection="multiple"
              // onVirtualColumnsChanged={autosize}
              // onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Paper>
      <div className="dialog">
        {openDetail ? (
          <RejectedItemDetail
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={data}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AcceptedReturns;

import React from 'react';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from '@mui/material'
import CreateCarrierBookingRequest from './CreateCarrierBookingRequest';
import CrFileupload from '../UploadFiles/CarrierUpload';

export default function CarrierBookingRequest() {
    const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
               <Tab label="Upload Booking Request" value="1" />
              <Tab label="Create Booking Request" value="2" />
             
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: '10px 0px' }}>
            <CrFileupload />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '10px 0px' }}>
            <CreateCarrierBookingRequest showList={true}/>
          </TabPanel>
       
        </TabContext>

      </Box>
    </div>
  )
}

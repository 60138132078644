import { Alert, AlertTitle, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import {
    DateTimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
    import swal from "sweetalert";
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import { FirstDataRenderedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { LoadingButton } from '@mui/lab';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
export default function FinalizeBookingRequest(props: any) {
    const { register, resetField, reset, setValue, getValues, formState: { errors }, control, handleSubmit, } = useForm({
        defaultValues: {
            bookingInfo: {
                forwarderID: '',
                forwarderBookingReference: '',
                pickupLocation: '',
                pickupCountry: '',
                pickupDate: '',
                incoTerms: '',
                dropoffLocation: '',
                dropoffCountry: '',
                dropoffZipCode: '',
                dropoffDate: '',
                chaID: '',
                totalPackage: '',
                totalPackageWeight: '',
                totalPackageVolume: '',
                consigneeID: ''
            },
        },
    });
    const [acceptedPoRowData, setAcceptedPoRowData] = useState([]);
    const [consigneeList, setConsigneeList] = useState<any>([])
    const [shipperList, setShipperList] = useState<any>([])
    const [forwarderList, setForwarderList] = useState<any>([])
    const [incoTerms, setIncoTerms] = useState([])
    const [load, setLoad, fromDate, toDate, setFd, setTd] = useOutletContext<any>();
    const [pickupDate, setpickupDate] = React.useState<Dayjs | any>(null);
    const [dropoffDate, setDropoffDate] = React.useState<Dayjs | any>(null);
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {},
    };
    const [acceptedPoColumnDefs] = useState([
        {
            headerName: "PURCHASE ORDER #",
            field: "purchaseOrderNumber",
            headerTooltip: "PURCHASE ORDER #",
            tooltipField: "purchaseOrderNumber",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "INVOICE #",
            field: "packageInfo.invoiceNo",
            headerTooltip: "INVOICE #",
            tooltipField: "purchaseOrderNumber",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "PURCHASE ORDER DATE",
            field: "purchaseOrderDate",
            headerTooltip: "PURCHASE ORDER DATE",
            tooltipField: "purchaseOrderDate",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "CUSTOMER CODE",
            field: "customerId",
            headerTooltip: "CUSTOMER CODE",
            tooltipField: "customerId",
            cellClass: "ag-left-aligned-cell",
        },
        {
            headerName: "CUSTOMER NAME",
            field: "customerName",
            headerTooltip: "CUSTOMER NAME",
            tooltipField: "customerName",
        },
        {
            headerName: "CATEGORY",
            field: "category",
            headerTooltip: "CATEGORY",
            tooltipField: "category",
        },
        {
            headerName: "ITEM ID",
            field: "partId",
            headerTooltip: "ITEM ID",
            tooltipField: "partId",
        },
        {
            headerName: "QUANTITY",
            field: "quantity",
            headerTooltip: "QUANTITY",
            tooltipField: "quantity",
        },
        {
            headerName: "UOM",
            field: "primaryUom",
            headerTooltip: "UOM",
            tooltipField: "primaryUom",
        },
        {
            headerName: "HZ CODE",
            field: "packageInfo.hzCode",
            headerTooltip: "HZ CODE",
            tooltipField: "packageInfo.hzCode",
        },
        {
            headerName: "NO.OF PACKAGES",
            field: "packageInfo.noOfPackages",
            headerTooltip: "NO.OF PACKAGES",
            tooltipField: "packageInfo.noOfPackages",
        },
        {
            headerName: "PACKAGE TYPE",
            field: "packageInfo.packageType",
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packageInfo.packageType",
        },
        {
            headerName: "PACKAGE WEIGHT",
            field: "packageInfo.packageWeight",
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageInfo.packageWeight",
        },
        {
            headerName: "PACKAGE VOLUME",
            field: "packageInfo.packageWeight",
            headerTooltip: "PACKAGE VOLUME",
            tooltipField: "packageInfo.packageWeight",
        },
        {
            headerName: "REQ.DEL.DATE",
            field: "needByDate",
            headerTooltip: "REQ.DEL.DATE",
            tooltipField: "needByDate",
        },
        {
            headerName: "ACTIONS",
            field: "createdBy",
            headerTooltip: "ACTIONS",
            tooltipField: "createdBy",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Box>
                        <LoadingButton
                            loading={false}
                            variant="contained"
                            onClick={() => editPackageInfo(params)}
                            className="btn btn-info"
                            color='success'
                        >
                            Edit
                        </LoadingButton>
                        &nbsp;&nbsp;
                        <LoadingButton
                            loading={false}
                            variant="contained"
                            onClick={() => deletePackageInfo(params)}
                            className="btn btn-info"
                            color="warning"
                        >
                            Delete
                        </LoadingButton>
                    </Box>
                );
            },
        },
    ]);
   
    const gridRef = useRef<any>();
    const editPackageInfo = (params: any) => {
        props.parentCallback(params)
    }
    const deletePackageInfo = (params: any) => {
        let payload = {
            'ids': [params.data._id]
        }
        swal({
            title: "Are you sure you want to delete ?",
            icon: "info",
            buttons: {

                delete: "Delete",
                cancel: "Cancel",
            },
        } as any).then(result => {
            if (result === 'delete') {
                axios.post(process.env.REACT_APP_PREPROCESSOR_API +'api/v1/deleteSelectedFBRfromList', payload, config).then(async (res: any) => {
                    console.log("respppp", res)
                    if (res.data.status === true) {
                        await swal('Success', res.data.message, 'success')
                        getSelectedAcceptedPoLines()
                    }
                })
            }
        })

    }
    const finalizeBooking = (data: any) => {
        console.log(data, "dattaaa")
        let lines: any = acceptedPoRowData
        let payload: any = { ...data.bookingInfo, lines }
        console.log(payload, "payload")
        axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/createBRforForwarder', payload, config).then(async (res: any) => {
            console.log(res.data, "final")
            if (res.data.status === true) {
                await swal('Success', res.data.message, 'success')
                props.parentCallback(!props.finalize)
            }else{
                swal('Error',res.data.message[0])
            }
        }).catch((e: any) => {
            return e;
        })
    }
    const handleChangeForwarder = (e: any) => {
        console.log(e, "eveent")
        forwarderList.filter((item: any) => {
            if (item.siteId === e.target.value) {
                setValue(`bookingInfo.pickupCountry`, item.country)
            }
        })
    }
    const handleChangeConsignee = (e: any) => {
        consigneeList.filter((item: any) => {
            if (item.siteId === e.target.value) {
                setValue(`bookingInfo.dropoffCountry`, item.country)
            }
        })
    }
    const handleChangePickupLocation = (event: any) => { }
    const handlePickupDate = (newValue: Dayjs | any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate);
        setpickupDate(newValue);
        setValue(`bookingInfo.pickupDate`, responseDate)
    };
    const handleChangeIncoTerms = () => { }
    const handleDropoffDate = (newValue: Dayjs | any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate);
        setDropoffDate(newValue);
        setValue(`bookingInfo.dropoffDate`, responseDate)
    };
    const handleChangeCha = () => { }
    const clickToBack = () => {
        props.parentCallback(!props.finalize)
    }
    const getIncoTerms = () => {
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        axios.get(process.env.REACT_APP_API_URL + 'cargoEs/fetchMasterIncoTerms', config).then((res: any) => {
            setIncoTerms(res.data.primary)
        })
    }
    const getSelectedAcceptedPoLines = () => {
        let poIds = props.selectedData.map((value:any) => `${value}`).join(',')
        console.log(poIds,"poids")
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
            finalPOlinesWithDrafts: true,
            selectedPoIds:poIds
        }
        axios.get(process.env.REACT_APP_PREPROCESSOR_API +'api/v1/getAcceptedPOlines', config).then((res: any) => {
            setLoad(false)
            console.log(res.data.data)
            if (res.data.status === true) {
                setAcceptedPoRowData(res.data.data)
            }
        })
    }
    const getPartnersByDesignation = () => {
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        axios.get(process.env.REACT_APP_API_URL + 'parties/getPartnersByDesignation', config).then((res: any) => {
            setShipperList(res.data.primary.shippers)
            setForwarderList(res.data.primary.forwarders)
            setConsigneeList(res.data.primary.consignees)
        })
    }
    const onGridReady = useCallback((params: GridReadyEvent) => { }, []);
    const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
        params.columnApi.autoSizeAllColumns(false);
    }, []);
    const resetBooking = () => { }
    useEffect(() => {
        getPartnersByDesignation()
        getIncoTerms()
        getSelectedAcceptedPoLines()
        console.log(props.selectedData,"selected data")
    }, [])
    return (
        <div>
            <form onSubmit={handleSubmit((data: any) => {
                finalizeBooking(data)
            })}>
                {
                    props.step === 'finalizeBooking' ?
                        <Box sx={{ mt: 2, p: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Forwarder</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Forwarder</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.forwarderID`, {
                                                required: "forwarder is required",
                                            })}
                                            onChange={(e: any) => handleChangeForwarder(e)}
                                            placeholder="Forwarder"
                                            sx={{ fontSize: '14px' }}
                                            label="Forwarder"
                                            MenuProps={{ disableScrollLock: true }}
                                        >
                                            {forwarderList.map((item: any, index: any) => (
                                                <MenuItem value={item.siteId}>{item.siteId}</MenuItem>
                                            ))}

                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Forwarder Booking #</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.forwarderBookingReference`, {
                                                required: "Forwarder Booking # is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Forwarder Booking #"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                        />
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Pickup Location</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Pickup Location</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.pickupLocation`, {
                                                required: "pickupLocation is required",
                                            })}
                                            onChange={(e: any) => handleChangePickupLocation(e)}
                                            placeholder="Pickup Location"
                                            sx={{ fontSize: '14px' }}
                                            label="Pickup Location"
                                            MenuProps={{ disableScrollLock: true }}
                                        >
                                            {forwarderList.map((item: any, index: any) => (
                                                <MenuItem value={item.address}>{item.address}</MenuItem>
                                            ))}

                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Pickup Country</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.pickupCountry`, {
                                                required: "Pickup Country is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Pickup Country"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled
                                        />
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Pickup Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Pickup Date"
                                            inputFormat="DD/MM/YYYY"
                                            // {...register(`bookingInfo.pickupDate`, {
                                            //     required: "Pickup Date is required",
                                            // })}
                                            value={pickupDate}
                                            onChange={handlePickupDate}
                                            renderInput={(params: any) => (
                                                <TextField {...params} required size="small" fullWidth />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>IncoTerms</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">IncoTerms</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.incoTerms`, {
                                                required: "IncoTerms is required",
                                            })}
                                            onChange={() => handleChangeIncoTerms}
                                            placeholder="incoTerms"
                                            sx={{ fontSize: '14px' }}
                                            label="IncoTerms"
                                            MenuProps={{ disableScrollLock: true }}
                                        >
                                            {incoTerms.map((item: any, index: any) => (
                                                <MenuItem value={item.incotermName}>{item.incotermName}</MenuItem>
                                            ))}
                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Consignee</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Consignee</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.consigneeID`, {
                                                required: "Consignee is required",
                                            })}
                                            onChange={(e: any) => handleChangeConsignee(e)}
                                            placeholder="incoTerms"
                                            sx={{ fontSize: '14px' }}
                                            label="Consignee"
                                            MenuProps={{ disableScrollLock: true }}
                                        >
                                            {consigneeList.map((item: any, index: any) => (
                                                <MenuItem value={item.siteId}>{item.siteId}</MenuItem>
                                            ))}
                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Dropoff Location</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Dropoff Location</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.dropoffLocation`, {
                                                required: "Dropoff is required",
                                            })}
                                            onChange={() => handleChangePickupLocation}
                                            placeholder="Dropoff Location"
                                            sx={{ fontSize: '14px' }}
                                            label="Dropoff Location"
                                            MenuProps={{ disableScrollLock: true }}
                                        >
                                            {consigneeList.map((item: any, index: any) => (
                                                <MenuItem value={item.address}>{item.address}</MenuItem>
                                            ))}

                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Dropoff Country</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.dropoffCountry`, {
                                                required: "Dropoff Country is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Dropoff Country"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled
                                        />
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Dropoff ZipCode</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.dropoffZipCode`, {
                                                required: "Dropoff ZipCode is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Dropoff ZipCode"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                        />
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Dropoff Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Dropoff Date"
                                            inputFormat="DD/MM/YYYY"
                                            // {...register(`bookingInfo.dropoffDate`, {
                                            //     required: "Dropoff Date is required",
                                            // })}

                                            value={dropoffDate}
                                            onChange={handleDropoffDate}
                                            renderInput={(params: any) => (
                                                <TextField {...params} required size="small" fullWidth />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>CHA</Typography>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">CHA</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...register(`bookingInfo.chaID`, {
                                                required: "CHA is required",
                                            })}
                                            onChange={() => handleChangeCha}
                                            placeholder="CHA"
                                            sx={{ fontSize: '14px' }}
                                            label="CHA"
                                            MenuProps={{ disableScrollLock: true }}
                                        >

                                            <MenuItem value="cha001">CHA</MenuItem>

                                        </Select>
                                        {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Total No.of Packages</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.totalPackage`)}
                                            id="component-outlined"
                                            placeholder="Total No.of Packages"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Total Package Weight</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.totalPackageWeight`)}
                                            id="component-outlined"
                                            placeholder="Total Package Weight"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Total Package Volume</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`bookingInfo.totalPackageVolume`)}
                                            id="component-outlined"
                                            placeholder="Total Package Volume"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled
                                        />
                                    </FormControl>

                                </Grid>
                            </Grid>
                            <Box sx={{ float: 'right', mt: 2 }}>
                                <LoadingButton
                                    loading={false}
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    Finalize Booking
                                </LoadingButton>
                                &nbsp;&nbsp;
                                <LoadingButton
                                    loading={false}
                                    variant="contained"
                                    type="submit"
                                    className="submitbtn"
                                    sx={{

                                        fontWeight: 500,
                                        backgroundColor: "#555a64",
                                        color: "#fff",
                                        "& .MuiButtonBase-root:hover": {
                                            backgroundColor: "#555a64",
                                            color: "#fff",
                                        },
                                    }}
                                    onClick={(event) => resetBooking()}
                                >
                                    Cancel Booking
                                </LoadingButton>
                            </Box>
                        </Box> :
                        <Box sx={{ mt: 2 }}>
                            <Box>
                                {acceptedPoRowData.length > 0 ?
                                    <AgGridReact className="ag-theme-balham"
                                        animateRows={true}
                                        rowSelection={"single"}
                                        defaultColDef={defaultColDef}
                                        rowData={acceptedPoRowData}
                                        domLayout={"autoHeight"}
                                        columnDefs={acceptedPoColumnDefs}
                                        sideBar={sideBar}
                                        pagination={true}
                                        paginationPageSize={10}

                                        onGridReady={onGridReady}
                                        onFirstDataRendered={onFirstDataRendered}
                                    ></AgGridReact> :
                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        No data found
                                    </Alert>}
                            </Box>


                        </Box>
                }
            </form>
        </div >
    )
}

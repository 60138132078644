import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";

import swal from "sweetalert";
import Input from "@mui/material/Input";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// import { DropzoneDialog } from "mui-file-dropzone";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useOutletContext } from "react-router-dom";
import { ApiRequestFunction } from "../../../Services/BaseService";
import { Endpoints } from "../../../Enviornment/Endpoints";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function CarrierConfirmation() {
  const [rowData, setRowData] = useState<any>([]);
  const domLayout = "autoHeight";
  const gridRef = useRef<any>();
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const Formref = useRef<any>();
  const [validationPoNumber, setValidationPoNumber] = useState();
  const [loader, setLoader, fromDate, toDate, format] = useOutletContext<any>();
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} /> 
              {params.data.preCheckValidationMessage[0]}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                /> 
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            return <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
              /> 
              NO VALIDATION
            </p>;
          }
        }
      },
    },

    {
      headerName: "FORWRDER REQUEST #",
      headerTooltip: "FORWRDER REQUEST #",
      tooltipField: "bookingrequestNumber",
      field: "bookingrequestNumber",
    },
    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReference",
      headerTooltip: "CARRIER REFERENCE #",
      tooltipField: "carrierReference",
    },

    {
      headerName: "CONSIGNEE ID",
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeID",
      tooltipField: "consigneeID",
    },
    {
      headerName: "CONSIGNEE",
      headerTooltip: "CONSIGNEE",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
    },

    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
    },
    {
      headerName: "SHIPPER",
      headerTooltip: "SHIPPER",
      tooltipField: "ShipperName",
      field: "ShipperName",
      columnGroupShow: "closed",
    },

    {
      headerName: "INCO TERMS",
      headerTooltip: "INCO TERMS",
      tooltipField: "incoTerms",
      field: "incoTerms",
    },
    {
      headerName: "BOOKING DATE ",
      headerTooltip: "BOOKING DATE",
      tooltipField: "bookingDate",
      field: "bookingDate",
    },
  ]);
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        columnDefs: [
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "HBL #",
            field: "hblNumber",
            headerTooltip: "HBL #",
            tooltipField: "hblNumber",
          }:
          {
            headerName: "HAWB #",
            field: "hawbNumber",
            headerTooltip: "HAWB #",
            tooltipField: "hawbNumber",
          },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION STATUS",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params, "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    />{''}
                    {params.data.preCheckValidationMessage[0]}
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      /> 
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  return <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    /> 
                    NO VALIDATION
                  </p>;
                }
              }
            },
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "MBL #",
            field: "mblNumber",
            headerTooltip: "MBL #",
            tooltipField: "mblNumber",
          }:{
            headerName: "MAWB #",
            field: "awbNumber",
            headerTooltip: "MAWB #",
            tooltipField: "awbNumber",
          },
          {
            headerName: "HS CODE",
            field: "hscode",
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hsCodeDescription",
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hsCodeDescription",
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "CONTAINER #",
            field: "containerNumber",
            headerTooltip: "CONTAINER #",
            tooltipField: "containerNumber",
          }:{
            headerName: "SHIPMENT #",
            field: "shipmentNumber",
            headerTooltip: "SHIPMENT #",
            tooltipField: "shipmentNumber",
          },
          {
            headerName: "CONTAINER TYPE",
            field: "containerType",
            headerTooltip: "CONTAINER TYPE",
            tooltipField: "containerType",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },
          {
            headerName: "CONTAINER SIZE",
            field: "containerSize",
            headerTooltip: "CONTAINER SIZE",
            tooltipField: "containerSize",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },
          {
            headerName: "CONTAINER TARE WEIGHT",
            field: "payloadCapacity",
            headerTooltip: "CONTAINER TARE WEIGHT",
            tooltipField: "payloadCapacity",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "CONTAINER GROSS WEIGHT",
            headerTooltip: "CONTAINER GROSS WEIGHT",
            field: "containerGrossWeight",
            tooltipField: "containerGrossWeight",
          }:{
            headerName: "SHIPMENT WEIGHT",
            headerTooltip: "SHIPMENT WEIGHT",
            tooltipField: "shipmentWeight",
            field:'shipmentWeight'
          },
          {
            headerName: "CONTAINER NET WEIGHT",
            field: "containerNetWeight",
            headerTooltip: "CONTAINER NET WEIGHT",
            tooltipField: "containerNetWeight",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },
          {
            headerName: "EMPTY CONTAINER PICKUP DEPOT",
            field: "containerPickUpDepot",
            headerTooltip: "EMPTY CONTAINER PICKUP DEPOT",
            tooltipField: "containerPickUpDepot",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },

          {
            headerName: "EMPTY CONTAINER PICKUP DATE",
            field: "pickUpDate",
            headerTooltip: "EMPTY CONTAINER PICKUP DATE",
            tooltipField: "pickUpDate",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },

          {
            headerName: "EMPTY CONTAINER PICKUP TIME",
            field: "pickUpTime",
            headerTooltip: "EMPTY CONTAINER PICKUP TIME",
            tooltipField: "pickUpTime",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },

          {
            headerName: "PORT OF ORIGIN",
            tooltipField: "portOfOrigin",
            field: "portOfOrigin",
            headerTooltip: "PORT OF ORIGIN",
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "VESSEL ID",
            tooltipField: "vessel",
            field: "vessel",
            headerTooltip: "VESSEL ID",
          }:{
            headerName: "CARGO OPERATOR ID",
            tooltipField: "cargoOperatorId",
            field: "cargoOperatorId",
            headerTooltip: "CARGO OPERATOR ID",
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "VESSEL NAME",
            tooltipField: "vesselName",
            field: "vesselName",
            headerTooltip: "VESSEL NAME",
          }:{
            headerName: "CARGO OPERATOR NAME",
            tooltipField: "cargoOperatorName",
            field: "cargoOperatorName",
            headerTooltip: "CARGO OPERATOR NAME",
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "VOYAGE #",
            tooltipField: "voyage",
            field: "voyage",
            headerTooltip: "VOYAGE #",
          }: {
            headerName: "FLIGHT #",
            tooltipField: "flightNo",
            field: "flightNo",
            headerTooltip: "Flight #",
          },
          {
            headerName: "DEPARTURE OF TERMINAL",
            tooltipField: "departureTerminal",
            field: "departureTerminal",
            headerTooltip: "DEPARTURE OF TERMINAL",
          },
          {
            headerName: "VGA CUT OFF TIME",
            tooltipField: "vgaCutOffTime",
            field: "vgaCutOffTime",
            headerTooltip: "VGA CUT OFF TIME",
            hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "VESSEL DEPARTURE TIME",
            tooltipField: "vesselDepartureTime",
            field: "vesselDepartureTime",
            headerTooltip: "VESSEL DEPARTURE TIME",
          }:{
            headerName: "FLIGHT DEPARTURE TIME",
            tooltipField: "flightDepartureTime",
            field: "flightDepartureTime",
            headerTooltip: "FLIGHT DEPARTURE TIME",
          },
          sessionStorage.getItem('shipmentType') === 'Ocean' ?
          {
            headerName: "OCEAN TRANSMIT TIME",
            headerTooltip: "OCEAN TRANSMIT TIME",
            tooltipField: "oceanTransitTime",
            field: "oceanTransitTime",
          }:{
            headerName: "AIR TRANSMIT TIME",
            headerTooltip: "AIR TRANSMIT TIME",
            tooltipField: "airTransitTime",
            field: "airTransitTime",
          },

          {
            headerName: "PORT OF  DISCHARGE",
            tooltipField: "portOfDischarge",
            field: "portOfDischarge",
            headerTooltip: "PORT OF  DISCHARGE",
          },

          {
            headerName: "DISCHARGE TERMINAL",
            tooltipField: "dischargeTerminal",
            field: "dischargeTerminal",
            headerTooltip: "DISCHARGE TERMINAL",
          },
          {
            headerName: "ETD",
            headerTooltip: "ETD",
            tooltipField: "etd",
            field: "etd",
            cellClass: "ag-left-aligned-cell",
          },

          {
            headerName: "ETA",
            headerTooltip: "ETA",
            tooltipField: "eta",
            field: "eta",
            cellClass: "ag-left-aligned-cell",
          },

          {
            headerName: "MODE OF SHIPMENT",
            headerTooltip: "MODE OF SHIPMENT",
            tooltipField: "ModeOfShipment",
            field: "ModeOfShipment",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "TRANSHIP",
            field: "tranship",
          },
        ],

        defaultColDef: {
          sortable: true,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);

        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);
  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let Excel_Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-date",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }
  //------------------- upload preview ------------------
  let previewPayload:any={
    file:'',
    shipmentType: selectedValue
  }
  const Preview = () => {
    if (fileExtError == false) {
      if (file === undefined) {
        toast.warning("Please Select a File...");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      setLoader(true)
      axios.post(Endpoints.Carrier_Booking_Confirmation_Preview, formData, Excel_Api_Config).then((res: any) => {
        setLoader(false)
        setPreviewData(res.data);
        setRowData(res.data.data);
        res.data.data.map((disables: any) => {
          if (disables.preCheckValidationStatus === false) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        });
      }).catch((e: any) => {
        setLoader(false);
      });
      setShowFile(true);
    }
  };
  // ----------------------- shipment type changes ----------------
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType',event.target.value)
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };
  const closeAlert = () => setAlertOpen(false);
// ------------------ upload file submit --------------------
  const SubmitFile = () => {
    let submitPayload: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios.post(Endpoints.Carrier_Booking_Confirmation_Submit, submitPayload, Api_Config)
      .then((res: any) => {
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Error", err.res.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };
  // ------------------------ file reset ------------------
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  function handleAlert() {
    setShowAlert(false);
  }
  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  useEffect(() => {
    setLoader(false)
    sessionStorage.setItem('shipmentType',selectedValue)
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column:any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column:any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column:any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column:any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, format])

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={shipmentModeHandleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + selectedValue === 'Ocean' ? "/carrierConfirmation.xlsx" : '/carrierAirConfirmation.xlsx'}
            download={selectedValue === 'Ocean' ? "carrierConfirmation.xlsx" : 'carrierAirConfirmation.xlsx'}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton> 
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              color="warning"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
            ref={gridRef}
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showAlert} onClose={handleAlert}>
          <Box sx={{ padding: "25px", borderRadius: "5px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {/* <img
              src={require("../asserts/sucess.png")}
              alt="sucess Image"
              width={60}
              height={60}
            /> */}
            </Box>
            <Typography sx={{ marginBottom: "15px" }}>{message}</Typography>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="success" onClick={handleAlert}>
                Ok
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Paper>
    </div>
  );
}

export default CarrierConfirmation;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import ReCAPTCHA from 'react-google-recaptcha';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, IconButton } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import swal from "sweetalert";
import { Endpoints } from "../Enviornment/Endpoints";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom";
import { getCountries, getStates } from 'country-state-picker';

function SignupOrganization() {
    // const { register, handleSubmit, reset, setValue, control, trigger, formState: { errors } } = useForm({
    //     mode: "onChange",
    //     defaultValues: {
    //         firstName: '',
    //         lastName: '',
    //         // location: '',
    //         // place: '',
    //         state: '',
    //         gmail: '',
    //         Mobile: '',
    //         countries: '',
    //         promocode: '',
    //         _operations: []
    //     },
    // })
    const [promocode, setPromocode] = useState()
    const { register: register2, control: control2, resetField: resetFiled2, reset, watch, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({
        mode: "onChange",
        defaultValues: {
            organisationName: '',
            countries: '',
            email: '',
            mobileNumber: '',
            promocode: '',
            operations: []
        },
    });
    const [iscaptchamessage, setCaptchaMessage] = useState<any>(false)
    const [ischeckChaptcha, setIsCheckChaptcha] = useState(false)
    const [count, setCount] = useState<any>([])
    const [isApiCallSuccess, setIsApiCallSuccess] = useState(false);
    const promoCodeValue = watch('promocode');
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [selectedValue, setSelectedValue] = useState("Organisation")
    const operationsList = ['Shipper', 'Forwarder', 'Consignee']
    const operationsList1 = ['Shipper', 'Forwarder', 'Consignee']
    const [_operatonTypes] = useState(['Shipper', 'Consignee', 'Forwarder'])
    const [width] = useState('49%')
    const [phcode, setPhoneCode] = useState<any>('')
    const [operations1, setOperations1] = useState('');
    const [countries1, setcountries1] = useState('');
    const navigate = useNavigate();
    const [phoneCode, setPhoneCodes] = useState<any | null>()
    const [isSelected2, setIsSelected2] = useState(false);
    const [checkboxEnabled, setCheckboxEnabled] = useState<boolean>(false);
    const [promoCodeResp, setPromoCodeResp] = useState<any>()
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",

    }
    const signuphere = (data: any) => {
        if (ischeckChaptcha) {
            console.log("payload....", data)
            setLoader(true)
            axios.post(Endpoints.Signup, data, { headers }).then((res: any) => {
                console.log(data)
                console.log(res.data.status.code);
                if (res.data.status.code === "error") {
                    swal('Error', res.data.status.message, 'error');
                    setLoader(false)
                    setLoader2(false)
                }
                else {
                    // swal('success', "Verification mail has been sent to your registered mail.", 'success')
                    setLoader(false)
                    setLoader2(false)
                    navigate('/registered', { state: { email: data.email } })
                    reset({
                        organisationName: '',
                        countries: '',
                        email: '',
                        mobileNumber: '',
                        promocode: '',
                        operations: []
                    })
                }


            }).catch((err) => {
                console.log(err);
                setLoader(false)
                setLoader2(false)
            })
        } else {
            setCaptchaMessage(true)
        }
    }
    const validatePromoCodeApiCall = async (promoCode: any) => {
        try {
            const response = await fetch(`${Endpoints.Validate_PromoCode}?promocode=${promoCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseData = await response.json();
            if (responseData.primary) {
                setIsApiCallSuccess(true)
                setPromoCodeResp('Valid')
                return 'Valid'; // Validation passed
            } else {
                setIsApiCallSuccess(false);
                setPromoCodeResp('Invalid Promo Code')
                return 'Invalid Promo Code';
            }

        } catch (error) {
            console.error('API call error:', error);
            setIsApiCallSuccess(false);
            setPromoCodeResp('Error validating promo code.')
            return 'Error validating promo code.';
        }
    };
    useEffect(() => {
        data()
    }, [selectedValue]);
    useEffect(() => {
        if (promoCodeValue && promoCodeValue.length === 8) {
            // Call your API here
            validatePromoCodeApiCall(promoCodeValue);
        } else {
            // Reset the success status if the length is not 8
            setPromoCodeResp('')
            setIsApiCallSuccess(false);
        }
    }, [promoCodeValue]);
    const data = () => {
        const data = getCountries();
        setCount(data)
    }
    const onSubmit = async (data: any) => {
        // setLoader2(true)
        let country: any;
        if (data.countries.name != undefined) {
            country = data.countries.name
        } else {
            country = data.countries
        }
        let p = {
            userName: data.organisationName,
            userType: selectedValue == "Organisation" ? "Org" : 'Self',
            operationsType: data.operations,
            country: country,
            pincode: '',
            address: '',
            email: data.email,
            mobileNumber: data.mobileNumber,
            promocode: data.promocode
        }
        
        signuphere(p)
        setcountries1('')
        setOperations1('')
        try {
            // Your form submission logic, including API calls
            console.log('Form Data:', data);
        } catch (error) {
            console.error('Error during form submission:', error);
        }
    }
    const isAlpha = (value: any) => {
        // Regular expression to allow only alphabetic characters


        if (/\s/.test(value)) {
            return 'Spaces are not allowed.';
        }
        return /^[A-Za-z]+$/.test(value);
    };
    const onChange = (data: any) => {
        // Handle form submission, e.g., send data to the server
        setIsCheckChaptcha(true)
        setCaptchaMessage(false)
        console.log(data);
    };
    const getvalues = (value: any) => {
        console.log("Value is...", value.dial_code);
        // const index = count.findIndex((item: any) => item.name === value);
        // let phoneCode = count[index].dial_code;
        // console.log("PhoneCode", phoneCode)
        setPhoneCodes(value.dial_code)
    }
    const iconAdornment2 = isSelected2
        ? {
            startAdornment: (
                <InputAdornment position="start">
                    {phoneCode}
                </InputAdornment>
            )
        }
        : {};
    return (
        <div>
            <form onSubmit={handleSubmit2(onSubmit)}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width }}>
                        <Controller
                            name="organisationName"
                            control={control2}
                            rules={{
                                required: 'Organisation name is required.',
                                minLength: {
                                    value: 3,
                                    message: 'Organisation name should be at least 3 characters.',
                                },

                                maxLength: {
                                    value: 100,
                                    message: 'Organisation name should be at most 100 characters.',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    id="component-outlined"
                                    label="Organisation Name"
                                    placeholder="Organisation Name"
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors2.organisationName && errors2.organisationName.message}
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                        {/* <Controller
                                         name="_operations"
                                         control={control}
                                         defaultValue={[]}
                                         render={({ field }) => (
                                             <TextField
                                                 select
                                                 {...field}
                                                 id="component-outlined"
                                                 size='small'
                                                 label="Operations Type"
                                                 placeholder="Select"
                                                 SelectProps={{
                                                     multiple: true,
                                                     renderValue: (selected:any) => (
                                                         selected.join(', ')
                                                     ),
                                                 }}
                                             >
                                                 {['Shipper', 'Consignee', 'Forwarder'].map((value:any) => (
                                                     <MenuItem key={value} value={value}>
                                                         <FormControlLabel
                                                             control={<Checkbox checked={field.value.includes(value)} />}
                                                             label={value}
                                                         />
                                                     </MenuItem>

// {['Shipper', 'Consignee', 'Forwarder'].map((value) => (
//     <MenuItem key={value} value={value}>
//         <Checkbox checked={values.operations.includes(value)} />
//         {value}
//     </MenuItem>
// ))}


                                                 ))}
                                             </TextField>
                                         )}
                                         rules={{
                                             validate: (value) => value.length > 0 || 'Operations Type is Required'
                                         }}
                                     /> */}
                        <Controller
                            name="operations"
                            control={control2}
                            defaultValue={[]}
                            render={({ field }: { field: { onChange: any; onBlur: any; value: string[] } }) => (
                                <TextField
                                    select
                                    {...field}
                                    id="component-outlined"
                                    size='small'
                                    label="Operations Type"
                                    placeholder="Select"
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: (selected: any) => (selected as string[]).join(', ')
                                    }}
                                >
                                    {operationsList.map((value: any) => (
                                        <MenuItem key={value} value={value}>
                                            <FormControlLabel
                                                control={<Checkbox checked={field.value.includes(value)} />}
                                                label={value}
                                            />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                validate: (value) => value.length > 0 || 'Operations type is required.'
                            }}
                        />

                        {errors2.operations && <span style={{ color: 'red', fontSize: '12px' }}>{errors2.operations.message}</span>}
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginTop: '3px' }}>
                        {/* <Controller
                                         name="countries"
                                         control={control} 
                                         render={({ field }) => (
                                             <TextField
                                                 select
                                                 {...field}
                                                 id="component-outlined"
                                                 size='small'
                                                 label="Country"
                                                 placeholder="Select"
                                                 onChange={(event) => {
                                                     field.onChange(event);
                                                     getvalues(event.target.value);
                                                 }}
                                             >
                                                 {count.map((value: any, index: any) => (
                                                     <MenuItem key={index} value={value.name}>
                                                         {value.name}
                                                     </MenuItem>
                                                 ))}
                                             </TextField>
                                         )}
                                         rules={{
                                             required: 'Country is required.'
                                         }}
                                     />



                                     <span style={{ color: "red", fontSize: "12px" }}>
                                         {errors?.countries && "Country is required."}
                                     </span> */}
                        {/* <FormHelperText sx={{ color: 'red' }}>{errors.countries}</FormHelperText> */}
                        <Controller
                            name={'countries'}
                            control={control2}
                            rules={{
                                required: 'Country is required.'
                            }}
                            defaultValue='' // Set the default value as needed
                            render={({ field }) => (
                                <Autocomplete
                                    disablePortal
                                    id={`combo-box-demo`}
                                    options={count}
                                    getOptionLabel={(option: any) => (option ? option.name : "")}
                                    value={field.value}
                                    onChange={(_, newValue) => { field.onChange(newValue); getvalues(newValue) }}
                                    isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                                    // getOptionSelected={(option: any, value: any) => option.name === value.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={(newValue) => console.log(newValue.target.value)}
                                        />
                                    )}
                                />
                            )}
                        />

                        <span style={{ color: "red", fontSize: "12px" }}>
                            {errors2?.countries && "Country is required."}
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                        <TextField
                            size="small"
                            {...register2('email', {
                                required: 'Email is required.',

                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Please enter a valid email address.",
                                },

                            })}
                            id="component-outlined"
                            label="Email"
                            placeholder="Email"


                        />
                        <span style={{ color: "red", fontSize: "12px" }}>
                            {errors2.email && errors2.email.message}
                        </span>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                        <TextField
                            size="small"
                            {...register2('mobileNumber', {
                                required: 'Mobile number required.',
                                pattern: {
                                    value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                    message: 'Enter a valid phone number.'
                                },
                                // valueAsNumber: true,

                            })}
                            id="component-outlined"
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            // type="number"
                            InputProps={
                                iconAdornment2
                            }
                            onFocus={e => setIsSelected2(true)}
                            onBlur={e => setIsSelected2(false)}

                        />
                        <span style={{ color: "red", fontSize: "12px" }}>
                            {errors2.mobileNumber && errors2.mobileNumber.message}
                        </span>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                        <Controller
                            name="promocode"
                            control={control2}
                            defaultValue=""
                            render={({ field }) => (
                                <>
                                    <TextField
                                        size="small"
                                        {...field}
                                        id="component-outlined"
                                        label="Promo Code"
                                        placeholder="Promo Code"
                                        error={Boolean(errors2.promocode)}
                                        helperText={errors2.promocode?.message || ''}
                                        InputProps={{
                                            inputProps: {
                                                maxLength: 8,
                                            },
                                        }}
                                    />
                                    <span style={{ color: isApiCallSuccess ? 'green' : '#d32f2f', fontSize: '12px', fontWeight: 'bold' }}>{promoCodeResp}</span>
                                </>
                            )}
                            rules={{

                                validate: (value) =>
                                    value.length === 8 || 'Promo code must be exactly 8 characters.',
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <ReCAPTCHA sitekey="6LfUgFgpAAAAAKRDNWqQ-gHuJcxyUgdXSmQqE9z2" onChange={onChange} />
                        {!ischeckChaptcha && iscaptchamessage && <span style={{ color: '#d32f2f', fontSize: '12px', fontWeight: 'bold' }}>Please Validate Captcha</span>}
                    </Box>

                </Box>

                {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                     <TextField
                                         size="small"
                                         {...register('Mobile', {
                                             required: 'Mobile Number Required',
                                             pattern: {
                                                 value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                 message: 'Enter A Valid Phone Number'
                                             },
                                             // valueAsNumber: true,

                                         })}
                                         id="component-outlined"
                                         label="Mobile Number"
                                         placeholder="Mobile Number"
                                         // type="number"
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start">{phoneCode}</InputAdornment>,
                                         }}

                                     />
                                     <span style={{ color: "red", fontSize: "12px" }}>
                                         {errors.Mobile && errors.Mobile.message}
                                     </span>
                                 </Box> */}
                {/* </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <LoadingButton loading={loader2} type="submit" variant="contained">Submit</LoadingButton>
                    <Typography sx={{ textAlign: 'center', fontSize: '12px', }}>
                        Already have an account? <a style={{ color: '#6161e1', cursor: 'pointer' }} onClick={() => navigate('/login')}>LogIn</a>
                    </Typography>
                </Box>

            </form>
        </div>
    )

}
export default SignupOrganization;

import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef, useDeferredValue } from "react";
import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import IntransitEventsMapping from "../../../Helpers/IntransitEventsMapping";
import "../../../Styles.css";
import swal from "sweetalert";
import moment from "moment";
import React from "react";
import { Endpoints } from "../../../Enviornment/Endpoints";
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7'
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },

};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 15,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));


function IntransitEventDisplay() {
  const nav = useNavigate();
  const [showgrid, setShowGrid] = useState(false);
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]); 
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [hscode, setHscode] = useState("");
  const [itemname, setItemName] = useState("");
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  // const [selectedValue, setSelectedValue] = React.useState(mode);
  const [filterText, setFilterText] = React.useState('');
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const gridRef = useRef<any>();
  const classes = useStyles();
  const [rowData, setRowData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const navigate = useNavigate();
  const [showData,setShowData] = useState<any>([])

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value, "target value")
  };
  const handleClear = () => {
    setSearchTerm('')
  }
  const filterData = rowData.filter((data:any) => {
    return Object.values(data).some((value) => {
      if (value === null || value === undefined || value === '') {
        // Include null and undefined values
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  console.log("Filtered DATA..................", filterData)
  const columns: TableColumn<any>[] = [
    {
      name: "EVENTS AVAILABILITY",
      sortable: true,
      selector: row => row.lastEvent !== '' && row.lastEvent !== null && row.lastEvent !== undefined ? <span style={{ color: '#5fb25c', cursor: 'pointer', }}>YES</span> : <span style={{ color: '#ad2f33', cursor: 'pointer', }}>NO</span>,
      width: '200px'

    },
    {
      name: "LAST EVENT",
      sortable: true,
      width: '250px',
      selector: row => row.lastEvent !== '' && row.lastEvent !== null  && row.lastEvent != undefined? (
        // <span>
        //   {row.lastEvent}
        // </span>
        <Tooltip title={row.lastEvent} arrow><span>{row.lastEvent}</span></Tooltip>
      ) : (
        <span style={{ fontStyle: 'italic' }}>

          - NA -
        </span>
      ),

    },
    {
      name: "ETA",
      selector: row => row.eta !== '' && row.eta !== null && row.eta !== undefined ?  <Tooltip title={row.eta} arrow><span>{row.eta}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },
    
    {
      name: "CHANGE IN ETA",
      sortable: true,
      selector: row => row.changeInEta !== '' && row.changeInEta !== null && row.changeInEta != undefined &&  row.changeInEta !== 'NA'?  <Tooltip title={row.changeInEta} arrow><span>{row.changeInEta === row.eta ? <span style={{ fontStyle: 'italic' }}>- NA -</span> : row.changeInEta}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "INVOICE #",
      sortable: true,
      selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined ? row.invoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,

    },
    {
      name: "HBL #",
      sortable: true,
      selector: row => row.hblNumber != "" && row.hblNumber != null  && row.hblNumber != undefined ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '150px',
    },
    {
      name: "BOOKING #",
      sortable: true,
      selector: row => row.carrierReference !== '' && row.carrierReference !== null && row.carrierReference !== undefined ? row.carrierReference : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "MAWB #",
      sortable: true,
      selector: row => row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber !== undefined ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Ocean' ? true : false,
      width: '200px',
      style: mode != 'Ocean' ? {

        cursor: 'pointer',
        textDecaration: 'underline'
      } : null

    },
    {
      name: "MBL #",
      sortable: true,
      selector: row => row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber !== undefined ? row.mblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '150px',
    },
    {
      name: "CONTAINER #",
      sortable: true,
      selector: row => row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber !== undefined ? row.containerNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '200px',
    },
    {
      name: "HAWB #",
      sortable: true,
      selector: row => row.hawbNumber !== '' && row.hawbNumber !== null && row.hawbNumber !== undefined  ? row.hawbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Ocean' ? true : false,
      width: '200px',
    },
    {
      name: "CARRIER",
      selector: row => row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined ?<Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px',
    },

    {
      name: "SHIPPER",
      width: '150px',
      selector: row => row.shipperName !== '' && row.shipperName !== null && row.shipperName !== undefined ? <Tooltip title={row.shipperName} arrow><span>{row.shipperName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    },
    {
      name: "CONSIGNEE",
      selector: row => row.consigneeName !== '' && row.consigneeName !== null  && row.consigneeName !== undefined ? <Tooltip title={row.consigneeName} arrow><span>{row.consigneeName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      // wrap: false,
      width: '170px',
    },
    {
      name: "INCO TERMS",
      selector: row => row.incoTerms !== '' && row.incoTerms !== null && row.incoTerms !== undefined ? row.incoTerms : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px'
    },
    {
      name: "PICKUP DATE",
      selector: row => row.pickUpDate !== '' && row.pickUpDate !== null && row.pickUpDate !== undefined &&  row.pickUpDate !== 'NA'? row.pickUpDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px'
    },
    {
      name: "LAST EVENT UPDATED AT",
      sortable: true,
      selector: row => row.lastEventUpdatedAt !== '' && row.lastEventUpdatedAt !== null && row.lastEventUpdatedAt !== undefined ? row.lastEventUpdatedAt : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px'

    },
  ]
  let requestPayload: any = {
    type: role,
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    shipmentType: mode
  }
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  const filteredItems = rowData.filter(
    (item: any) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleData = (params: any) => {
    console.log("Event Screen Console....", params);
    setTransitData(params);
    setLineData(params);
    setOpenDetail(true);
    setEventData(params)
  };
  
  const handlemap = () => {
    setNodata(false);
  };

  const getIntransitList = () => {
    let array1: any;
    let array2: any;
    let array3: any;
    Api_Config.params = requestPayload
    setLoad(true);
    axios.get(Endpoints.Intransit_Header_List, Api_Config).then(async (res) => {
      setLoad(false)
      
      if (res.data.status.code === 'FAILURE') {
        console.log("...................", res.data.status.message)
        swal('Warning', res.data.status.message, 'warning')
      } else {
        array1 = res.data.primary
        console.log(res.data.primary);
        if (res.data.primary.length > 0) {
          console.log(array1)
          setShowGrid(true)
        }
      }
      setRowData(res.data.primary)

    }).catch((error) => {
      setShowGrid(false)
      setLoad(false)
    })
  }
  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
    setHscode(params.data.hscode);
    setItemName(params.data.itemName);
  };
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    requestPayload.shipmentType = event.target.value
    // setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType', event.target.value)
    setRowData([])
    getIntransitList()

  };

  useEffect(() => {
    // sessionStorage.setItem('shipmentType', selectedValue)
    console.log(mode, "modeee")
    getIntransitList();
  }, [fromDate, toDate, mode]);
  return (
    <div className={classes.root}>
      {rowData.length > 0 && showgrid &&
        <Box sx={{ display: 'flex', mt: 2, justifyContent:'space-between', width: '100%' }}>
          <Button variant="contained" onClick={()=> navigate('/shipmentRegistrations')}>Add Shipments</Button>
          <Paper
            component="form"
            elevation={0}
            className="custom-search"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', float: 'right', width: 400 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={mode === 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : 'Search By HAWB/MAWB/Invoice'}
              inputProps={{ 'aria-label': 'search google maps' }}
              size="small"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
              {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
            </IconButton>
          </Paper>
        </Box>}

      <Paper elevation={3} sx={{ borderRadius: 3 }}>
        {rowData.length > 0 && showgrid ? (
          <Box sx={{ p: 0, mt: 1 }}>
            <DataTable
              style={{ borderRadius: 3 }}
              columns={columns}
              data={filterData}
              responsive={true}
              customStyles={customStyles}
              theme="default"

              pagination
              dense
              striped
              highlightOnHover={true}
              onRowClicked={(data) => handleData(data)}

            />
          </Box>)
          : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        <div className="dialog">
          {openDetail ? (
            <IntransitEventsMapping
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              shipmentType={mode}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default IntransitEventDisplay;


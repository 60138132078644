import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle, Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import "../../../Styles.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import React from "react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { Endpoints } from "../../../Enviornment/Endpoints";
import { ApiRequestFunction } from "../../../Services/BaseService";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function CarrierAck() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [id, setId] = useState<any>();
  const [loader, setLoader, fromDate, toDate] = useOutletContext<any>();
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const [showgrid, setShowGrid] = useState(true);
  const nav = useNavigate();
  const gridRef = useRef<any>(null);
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);
  const gridApiRef = useRef(null);
  let gridColumnApi: any;
  let gridApi: any;
  const managedoc = (params: any) => {
    let docObject: any;
    if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber != 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber && params.data.invoiceNumber === 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber === 'NA' && params.data.invoiceNumber) {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.data.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };
  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "FORWARDER REFERENCE #",
      headerTooltip: "FORWARDER REFERENCE #",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },

    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReference",
      headerTooltip: "CARRIER REFERENCE #",
      tooltipField: "carrierReference",
    },
    {
      headerName: "CARRIER CREATION DATE",
      headerTooltip: "CARRIER CREATION DATE",
      tooltipField: "crCreatedDate",
      field: "crCreatedDate",
      cellClass: "ag-left-aligned-cell",
    },
    // sessionStorage.getItem('shipmentType') != 'Air' ?
    // {
    //   headerName: "HBL #",
    //   headerTooltip: "HBL #",
    //   tooltipField: "hblNumber",
    //   field: "hblNumber",
    //   cellClass: "ag-left-aligned-cell",
    // }: {
    //   headerName: "HAWB #",
    //   headerTooltip: "HAWB #",
    //   tooltipField: "hawbNumber",
    //   field: "hawbNumber",
    //   cellClass: "ag-left-aligned-cell",
    // },
    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
      hide: sessionStorage.getItem('role') != 'shipper' ? false : true
    },
    {
      headerName: "SHIPPER",
      headerTooltip: "SHIPPER",
      tooltipField: "shipperName",
      field: "shipperName",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'shipper' ? false : true
    },
    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP COUNTRY",
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE",
      headerTooltip: "CONSIGNEE",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE CONTACT #",
      headerTooltip: "CONSIGNEE CONTACT #",
      field: "consingneeContactNumber",
      tooltipField: "consingneeContactNumber",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE EMAIL",
      headerTooltip: "CONSIGNEE EMAIL",
      field: "consigneeEamilAddress",
      tooltipField: "consigneeEamilAddress",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "MODE OF SHIPMENT",
      hide: sessionStorage.getItem("role") === "carrier" ? false : true,
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF LOCATION",
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIP CODE",
      headerTooltip: "DROPOFF ZIP CODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "VIEW DOCS",
      headerTooltip: "VIEW DOCS",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            className="btn btn-info"
            onClick={() => managedoc(params)}
          >
            View Doc
          </a>
        );
      },
    },
  ]);
  useEffect(() => {
    sessionStorage.setItem('shipmentType', selectedValue)
    setLoader(true);
    getShipmentsList();
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column: any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column: any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, selectedValue]);
  const handleData = (params: any) => {
    setId(params.data.invoiceNumber);
    setPoData(params.data);
    setOpenDetail(true);
  };
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open",
    // fbrStatus: "open,processed",
    brStatus: "open",
    type: sessionStorage.getItem('role'),
    shipmentType: selectedValue,
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };
  //------------------ paending carrier list -------------------
  const getShipmentsList = () => {
    setLoader(true)
    Api_Config.params = options
    axios.get(Endpoints.Pending_Carrier_Booking_HeaderList, Api_Config).then((res) => {
      if (res.data.status.code === 'FAILURE') {
        setLoader(false);
        swal("warning", res.data.sttus.message);
      }else{
        setLoader(false)
        setRowData(res.data.primary);
      }
    });
  };
  // ----------------- close dialog --------------------
  const closeDialog = () => {
    setOpenDetail(false)
    getShipmentsList();
  }
  // --------------- shipment type changes -----------------
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    options.shipmentType = event.target.value
    sessionStorage.setItem('shipmentType', event.target.value)
    // updateColumnVisibility(event.target.value);
    setRowData([])
    getShipmentsList()
  };
  // ----------- update columns ----------------
  const updateColumnVisibility = (value: any) => {
    console.log(selectedValue, "selected value")
    const gridApi = gridRef.current.api;
    if (value === 'Ocean') {
      let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
        if (ele.userProvidedColDef.hide != undefined) {
          ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
        }
        return ele.userProvidedColDef
      });
      gridRef.current.api.setColumnDefs(a)
      autosize()
    } else {
      let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
        if (ele.userProvidedColDef.hide != undefined) {
          ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
        }
        return ele.userProvidedColDef.hide
      });
      autosize()
    }
    gridApi.refreshCells();
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    gridColumnApi = params.columnApi;
    gridApi.sizeColumnsToFit();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={shipmentModeHandleChange}
              value={selectedValue}

            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ p: 0, mt: 1 }}>
          {rowData.length > 0 ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              ref={gridRef}
              pagination={true}
              paginationPageSize={10}
              onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={closeDialog}
              data={PoData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default CarrierAck;

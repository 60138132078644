import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState, useMemo, useRef, useCallback, useEffect } from "react";
// import "./fileUpload.css";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import "../Styles.css";
import { sideBar,defaultColDef } from '../components/common/AgGridConfig';
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
// import { DropzoneDialog } from "mui-file-dropzone";
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useOutletContext } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FirstDataRenderedEvent, ICellRendererParams } from "ag-grid-community";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Endpoints } from "../Enviornment/Endpoints";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function Fileupload() {
  const [open, SetOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const fileref = useRef<any>();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<Boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const [validationData, setValidationData] = useState<any>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [validationPoNumber, setValidationPoNumber] = useState();
  const masterDetail = true;
  const [columnDefs] = useState([
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    // {
    //   headerName: "VALIDATION STATUS",
    //   field: "preCheckValidationStatus",
    //   filter: true,
    //   headerTooltip: "VALIDATION STATUS",
    //   tooltipField: "preCheckValidationStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === true) {
    //       return <p className="status">TRUE</p>;
    //     } else {
    //       setDisable(false);
    //       return <p className="rstatus">FALSE</p>;
    //     }
    //   },
    // },
    {
      headerName: "VALIDATION MESSAGE",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION MESSAGE",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} />
              {params.value[0]}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                />
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            return <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
              /> 
              NO VALIDATION
            </p>;
          }
        }
      },
    },
    {
      headerName: "PURCHASE ORDER DATE",
      field: "purchaseOrderDate",
      headerTooltip: "PURCHASE ORDER DATE",
      tooltipField: "purchaseOrderDate",
      cellClass: "ag-left-aligned-cell",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value;
        } else {
          return "NA";
        }
      },
    },
    {
      headerName: "PURCHASE ORDER VERSION",
      field: "purchaseOrderVersion",
      headerTooltip: "PURCHASE ORDER VERSION",
      tooltipField: "purchaseOrderVersion",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      field: "supplierId",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      tooltipField: "supplierId",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      field: "supplierName",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      tooltipField: "supplierName",
    },
    {
      headerName: "SHIP TO ADDRESS",
      field: "shipToAddress",
      headerTooltip: "SHIP TO ADDRESS",
      tooltipField: "shipToAddress",
    },

    {
      headerName: "SHIPPED BY SELF",
      field: "shippedBySelf",
      headerTooltip: "SHIPPED BY SELF",
      tooltipField: "shippedBySelf",
    },
  ]);

  function alerts(params: any, poNum: any) {
    console.log(params, poNum, "Po params");
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);
  useEffect(() => {
    setLoad(false)
  }, [fromDate, toDate, format])
 
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        columnDefs: [
          {
            headerName: "S.No",
            field: "number",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "SHIPMENT NUMBER",
            field: "shipmentNumber",
            cellClass: "ag-left-aligned-cell",
          },
          { headerName: "CATEGORY", field: "category" },
          { headerName: "PART ID", field: "partId" },
          { headerName: "PART TYPE", field: "partType" },
          { headerName: "PART DESCRIPTION", field: "partDescription" },
          {
            headerName: "NEED BY DATE",
            field: "needByDate",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "QUANTITY",
            field: "quantity",
            cellClass: "ag-left-aligned-cell",
          },
          { headerName: "PRIMARY UOM", field: "primaryUom" },
          { headerName: "SECONDARY UOM", field: "secondaryUom" },
          { headerName: "CANCEL STATUS", field: "cancelStatus" },
          // {
          //   headerName: "VALIDATION MESSAGE",
          //   field: "preCheckValidationMessage",
          // },
          // {
          //   headerName: "VALIDATION STATUS",
          //   field: "preCheckValidationStatus",
          //   filter: true,
          //   headerTooltip: "VALIDATION STATUS",
          //   tooltipField: "preCheckValidationStatus",
          //   cellRenderer: (params: ICellRendererParams) => {
          //     if (params.value === true) {
          //       return <p className="status">TRUE</p>;
          //     } else {
          //       setDisable(false);
          //       return <p className="rstatus">FALSE</p>;
          //     }
          //   },
          // },

          {
            headerName: "VALIDATION MESSAGE",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION MESSAGE",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params, "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    /> 
                    {params.value[0]} 
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      /> 
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  return <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    />&nbsp;
                    NO VALIDATION
                  </p>;
                }
              }
            },
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);
        params.successCallback(params.data.lines);
      },
    };
  }, []);

  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    console.log("Getting...", fileref.current);
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setExcelName(e.target.files[0].name);
    }
  }
  const handlePreview = () => {
    console.log(fileref.current);
    console.log(file, "$file");
    if (file === undefined) {
      // alert("Please Select a File...");
      toast.warning("Please Select a File...");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    setLoad(true)
    axios.post(Endpoints.Consignee_PO_Preview , formData, config).then((res: any) => {
      console.log(res);
      setLoad(false)
      setPreviewData(res.data);
      setRowData(res.data.data);
      res.data.data.map((disables: any) => {
        if (disables.preCheckValidationStatus === false) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      });
    }).catch((e: any) => {
      setLoad(false);
    });
    setShowFile(true);

    console.log(disable);
  };

  const SubmitFile = () => {
    let url =
      "https://api.ext-preprocessor.realtrace.realware.tech/api/v1/poSubmit";

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    let obj: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    console.log(obj);
    axios.post(Endpoints.Consignee_PO_Submit, obj, config)
      .then((res: any) => {
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
          
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Upload", err.res.data.msg, "error");
        }
      });
    setExcelName("Select File");
    setShowFile(false);
    fileref.current.reset();
  };
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    fileref.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/poUpload.xlsx"}
            download={"poUpload.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>&nbsp;
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={fileref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => handlePreview()}
            >
              Preview
            </LoadingButton>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {/* <Button variant="contained" type="submit" onClick={SubmitFile}>
                Submit
              </Button> */}
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{validationPoNumber} - Upload Error Logs</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any, index: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", color: "black" }}
                >
                  <Typography>
                    {index + 1} . {data}
                  </Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        // onClose={handleClose}
        message="I love snacks"
      />
    </div>
  );
}

export default Fileupload;

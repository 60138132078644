import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
// import "./Upload.css";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
// import swal from "sweetalert";

// import { DropzoneDialog } from "mui-file-dropzone";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { PostRequest } from "../Services/BaseService";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
// import { DesgService } from "../Services/DesgService";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import swal from "sweetalert";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { useOutletContext } from "react-router-dom";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function WhRequest() {
  const [rowData, setRowData] = useState<any>([]);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<any>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const [validationData, setValidationData] = useState<any>([]);
  const Formref = useRef<any>();
  const [columnDefs] = useState([
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value.length > 0) {
          return (
            <Button
              startIcon={<CheckCircleIcon sx={{ color: "green" }} />}
              onClick={() => alerts(params.value)}
            ></Button>
          );
        } else {
          return <p>NO VALIDATION</p>;
        }
      },
    },
    {
      headerName: "INVOICE DETAILS",
      children: [
        {
          headerName: "INVOICE NUMBER",
          headerTooltip: "INVOICE NUMBER",
          tooltipField: "invoiceNumber",
          field: "invoiceNumber",
          cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: "INVOICE DATE",
          field: "invoiceDate",
          headerTooltip: "INVOICE DATE",
          tooltipField: "invoiceDate",
          columnGroupShow: "closed",
        },
      ],
    },

    {
      headerName: "WAREHOUSE BOOKING REFERENCE NUMBER",
      field: "wareHouseBookingReferenceNumber",
      headerTooltip: "WAREHOUSE BOOKING REFERENCE NUMBER",
      tooltipField: "wareHouseBookingReferenceNumber",
    },

    {
      headerName: "CHECKIN DATE",
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "checkInDate",
    },
    {
      headerName: "CHECKOUT DATE",
      headerTooltip: "CHECKOUT DATE",
      tooltipField: "checkOutDate",
      field: "checkOutDate",
    },
  ]);
 
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        flex: 1,
        columnDefs: [
          {
            headerName: "COMMODITY TYPE",
            field: "commodityType",
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "commodityType",
          },
          {
            headerName: "NUMBER OF PACKAGES",
            field: "numberOfPackages",
            headerTooltip: "NUMBER OF PACKAGES",
            tooltipField: "numberOfPackages",
          },
          {
            headerName: "ITEM NAME",
            field: "itemName",
            headerTooltip: "ITEM NAME",
            tooltipField: "itemName",
          },
          {
            headerName: "ITEM DESCRIPTION",
            field: "itemDescription",
            headerTooltip: "ITEM DESCRIPTION",
            tooltipField: "itemDescription",
          },
          {
            headerName: "BATCH NUMBER",
            field: "batchNumber",
            headerTooltip: "BATCH NUMBER",
            tooltipField: "batch number",
          },
          {
            headerName: "LOT NUMBER",
            field: "lotNumber",
            headerTooltip: "LOT NUMBER",
            tooltipField: "lotNumber",
          },
          {
            headerName: "SERIAL NUMBER",
            field: "serialNumber",
            headerTooltip: "SERIAL NUMBER",
            tooltipField: "serialNumber",
          },

          {
            headerName: "PACKAGE TYPE",
            field: "packageType",
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packageType",
          },
          {
            headerName: "PACKAGE WEIGHT",
            field: "packageWeight",
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageWeight",
          },
          {
            headerName: "LENGTH",
            field: "length",
            headerTooltip: "LENGTH",
            tooltipField: "length",
          },
          {
            headerName: "WIDTH",
            field: "width",
            headerTooltip: "WIDTH",
            tooltipField: "width",
          },
          {
            headerName: "HEIGHT",
            field: "height",
            headerTooltip: "HEIGHT",
            tooltipField: "height",
          },
          {
            headerName: "HZ GOODS",
            field: "hzgoods",
            headerTooltip: "hzgoods",
            tooltipField: "HZ GOODS",
          },
          {
            headerName: "Special Instructions",
            field: "specialInstructions",
            headerTooltip: "specialInstructions",
            tooltipField: "Special Instructions",
          },

          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION STATUS",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              if (params.value.length > 0) {
                return (
                  <Button
                    startIcon={<CheckCircleIcon sx={{ color: "green" }} />}
                    onClick={() => alerts(params.value)}
                  ></Button>
                );
              } else {
                return <p>NO VALIDATION</p>;
              }
            },
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);

        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);

  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState(false);

  function handleAlert() {
    setShowAlert(false);
  }
  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }

  //   const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  //PreView Api
  useEffect(() => {
    setLoad(false)
  }, [fromDate, toDate, format])
  const Preview = () => {
    // setDisable(true);
    console.log(file, "$file");
    if (fileExtError === false) {
      if (file === undefined) {
        alert("Please Select a File...");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      setLoad(true)
      axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/wareHouseRequest', formData, config).then((res: any) => {
        setLoad(false)
        console.log(res);
        setPreviewData(res.data);
        setRowData(res.data.data);
        res.data.data.map((disables: any) => {
          if (disables.preCheckValidationStatus === false) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        });
      }).catch((e: any) => {
        setLoad(false);
      });
      setShowFile(true);
    }
  };

  function alerts(params: any) {
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);

  //File Submit Api
  const SubmitFile = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    let obj: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/wareHouseUploadSubmit', obj, config).then((res: any) => {
      if (res.data.status === true) {
        console.log("HI.........");
        setStatus(res.data.status);
        setShowAlert(true);
        setMessage(res.data.msg);
        swal("UPLOADS", res.data.msg, "success");
      }
    });
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/wareHouseRequest.xlsx"}
            download={"wareHouseRequest.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              color="warning"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box>
            <div className="ag-theme-balham">
              <AgGridReact
                animateRows={true}
                defaultColDef={defaultColDef}
                detailCellRendererParams={detailCellRendererParams}
                rowData={rowData}
                domLayout={domLayout}
                columnDefs={columnDefs}
                sideBar={sideBar}
                pagination={true}
                masterDetail={masterDetail}
                paginationPageSize={10}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
              ></AgGridReact>
            </div>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
        
      </Paper>
    </div>
  );
}

export default WhRequest;

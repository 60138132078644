import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
  TextField,
} from "@mui/material";

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import "./trcuk.css";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import "../../../Styles.css";

// import CancelIcon from "@mui/icons-material/Cancel";

import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import axios from "axios";
import swal from "sweetalert";
import TruckLineItems from "./TruckLineItems";

import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { Paper } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useOutletContext } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function InWardTruckDetails() {
  const [truckopen, setTruckOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [gid, setGid] = React.useState<Dayjs | any>(null);
  const [git, setGit] = React.useState<Dayjs | any>(null);
  const [load, setLoad] = useOutletContext<any>();
  const [showgrid, setShowGrid] = useState(true);
  const handletruckOpen = () => {
    setTruckOpen(false);
  };
  useEffect(() => {
    setLoad(true);
  }, []);
  const fileref = useRef<any>();
  const [trucknumber, setTruckNumber] = useState("");
  const update = (params: any) => {
    setTruckOpen(true);
    setTruckNumber(params.data.truckNumber);
  };

  const formik = useFormik({
    initialValues: {
      gateInDate: "",
      gateInTime: "",
    },
    onSubmit: (values) => {
      // setTruckOpen(true);
      let payload = {
        truckNumber: trucknumber,
        gateInDate: moment(gid).format("YYYY-MM-DD"),
        gateInTime: moment(git.$d).format("HH:mm"),
      };
      updategateinfo(payload);
    },
  });
  const updategateinfo = (payload: any) => {
    axios.post(process.env.REACT_APP_API_URL + 'po/updateGateInInfo', payload, config).then((res: any) => {
      if (res.status === 200) {
        swal("Update", res.data.primary, "success");
        truckdetails();
      }
    });
    fileref.current.reset();
    setTruckOpen(false);
  };

  const trucklines = (params: any) => {
    setTruckNumber(params.value);
    setOpen(true);
  };

  const [columnDefs] = useState([
    {
      headerName: "TRUCK NUMBER",
      headerTooltip: "TRUCK NUMBER",
      tooltipField: "truckNumber",
      field: "truckNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => trucklines(params)}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "DRIVER NAME",
      headerTooltip: "DRIVER NAME",
      tooltipField: "driverNumber",
      field: "driverNumber",
    },
    {
      headerName: "DRIVER NUMBER",
      headerTooltip: "DRIVER NUMBER",
      tooltipField: "driverName",
      field: "driverName",
    },
    {
      headerName: "ACTION",
      hide: sessionStorage.getItem("role") !== "wh",
      tooltipField: "action",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button onClick={() => update(params)}>Update</Button>;
      },
    },
  ]);

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  //  params:{
  //   type:'inward'
  //  }
  };

  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  const gridRef = useRef<any>();
  const truckdetails = () => {
    setLoad(true);
    setShowGrid(true);
    axios.get(process.env.REACT_APP_BASE_URL +"/truckDetailsAtWh" , config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    truckdetails();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Paper>

      <div>
        <Dialog
          maxWidth="xl"
          open={truckopen}
          onClose={handletruckOpen}
          sx={{ minWidth: "500px" }}
        >
          <DialogTitle>
            Update Truck Details Of Truck # :{" "}
            <span className="dialogTitle greyColor">{trucknumber}</span>
            <Button onClick={handletruckOpen}>Close</Button>
          </DialogTitle>
          <Box
            sx={{
              paddingLeft: "30px",
              paddingBottom: "30px",
              paddingRight: "30px",
            }}
          >
            {/* <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Enter ETD"
                  inputFormat="DD/MM/YYYY"
                  value={etd}
                  disablePast
                  onChange={handleetd}
                  renderInput={(params: any) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box> */}
            <form
              ref={fileref}
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "5px",
              }}
            >
              <Box sx={{ marginBottom: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label=" Gate In Date"
                    inputFormat="DD/MM/YYYY"
                    value={gid}
                    onChange={(newvalue: any) => setGid(newvalue)}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginBottom: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label=" Gate In  Time"
                    value={git}
                    onChange={(newvalue: any) => setGit(newvalue)}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              {/* <InputLabel id="gateintimeanddate">Gate In Date:</InputLabel>
              <TextField
                type="date"
                id="gateInDate"
                name="gateInDate"
                onChange={formik.handleChange}
                sx={{ marginBottom: "5px" }}
              />

              <InputLabel id="gateintimeanddate">Gate In Time:</InputLabel>
              <TextField
                type="time"
                id="gateInTime"
                name="gateInTime"
                value={formik.values.gateInTime}
                onChange={formik.handleChange}
                sx={{ marginBottom: "5px" }}
              /> */}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ maxWidth: "25%" }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Dialog>
      </div>

      <div>
        {open ? (
          <TruckLineItems
            onOpen={open}
            onClose={() => setOpen(false)}
            trucknumber={trucknumber}
          />
        ) : null}
      </div>
    </div>
  );
}

export default InWardTruckDetails;

import { makeStyles, Theme } from "@material-ui/core/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import moment from "moment";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  IServerSideDatasource,
} from "ag-grid-community";
import Alert from "@mui/material/Alert";
import { useCallback, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { useEffect } from "react";
import {
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DesgService } from "../../../Services/DesgService";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { PostRequest } from "../../../Services/BaseService";
import sizeConfigs from "../../../Config/sizeConfigs";
import colorConfigs from "../../../Config/colorConfig";
import ItemDetail from "../../../Helpers/ItemDetail";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
// import SubHeader from "../../common/SubHeader";
import axios from "axios";
import swal from "sweetalert";
import "../../../Styles.css";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useOutletContext } from "react-router-dom";
import TooltipCom from "../../../Helpers/ToolTipComponent";
import { Endpoints } from "../../../Enviornment/Endpoints";
//---------------  useStyles ----------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function PoForAcknowledgement() {
  const gridRef = useRef<any>();
  const [load, setLoad, fromDate, toDate, setFd, setTd] =
    useOutletContext<any>();
  const [errors, setErrors] = useState('')
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [data, setData] = useState();
  const [remarks, setRemarks] = useState(false);
  const [poNumber, setPoNumber] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [objData, setObjData] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const ref = useRef(null);
  const desgService = new DesgService();
  const classes = useStyles();
  const [showgrid, setShowGrid] = useState(true);
  const domLayout = "autoHeight";
  let params: GridReadyEvent;
  let obj: any = {};
  let prevstart: any;
  const [state, setState] = useState();
  const nav = useNavigate();
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    poStatus: "open",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };
  const [columnDefs] = useState([
    {
      // headerName: "SELECT SHIPMENTS",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      hide: sessionStorage.getItem("role") === "shipper" ? false : true,
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",

      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "PURCHASE ORDER VERSION #",
      field: "purchaseOrderVersion",
      headerTooltip: "PURCHASE ORDER VERSION #",
      tooltipField: "purchaseOrderVersion",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PURCHASE ORDER DATE",
      field: "poDate",
      headerTooltip: "PURCHASE ORDER DATE",
      tooltipField: "poDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      field:
        sessionStorage.getItem("role") === "consignee"
          ? "supplierId"
          : "customerId",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      tooltipField:
        sessionStorage.getItem("role") === "consignee"
          ? "supplierId"
          : "customerId",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      field:
        sessionStorage.getItem("role") === "consignee"
          ? "supplierName"
          : "customerName",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER NAME"
          : "CUSTOMER NAME",
      tooltipField:
        sessionStorage.getItem("role") === "consignee"
          ? "supplierName"
          : "customerName",
    },
    {
      headerName: "SHIP TO ADDRESS",
      field: "shipToAddress",
      headerTooltip: "SHIP TO ADDRESS",
      tooltipField: "shipToAddress",
    },
    {
      headerName: "CREATED BY",
      field: "createdBy",
      headerTooltip: "CREATED BY",
      tooltipField: "createdBy",
    },
    {
      headerName: "VIEW DOCS",
      headerTooltip: "VIEW DOCS",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button onClick={() => managedoc(params)}>View Doc</Button>;
      },
    },
    // {
    //   headerName: "ACTION",
    //   field: "Action",
    //   hide: sessionStorage.getItem("role") != "consignee" ? false : true,
    //   cellRenderer: (params: ICellRendererParams) => {
    //     return (
    //       <div>
    //         <Button
    //           style={{
    //             height: 25,
    //             lineHeight: 0.5,
    //             color: "white",
    //             backgroundColor: "#28a745",
    //             marginRight: "3px",
    //           }}
    //           onClick={() => handleClickOpen("accepted", params)}
    //           className="btn btn-info"
    //           startIcon={<CheckCircleIcon />}
    //         >
    //           Accept
    //         </Button>
    //         <Button
    //           style={{
    //             height: 25,
    //             width: "50%",
    //             lineHeight: 0.5,
    //             color: "white",
    //             backgroundColor: "#95535a",
    //           }}
    //           onClick={() => handleClickOpen("rejected", params)}
    //           className="btn btn-info"
    //           startIcon={<CancelIcon />}
    //         >
    //           Reject
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ]);
  useEffect(() => {
    console.log("PO ACK CALLING .......................", fromDate, toDate);
    setLoad(true);
    poList(fromDate, toDate);
  }, [openDetail, open, fromDate, toDate, showgrid]);
  

  //----------- table headers ---------------------

  const managedoc = (params: any) => {
    // console.log(params.data.purchaseOrderNumber);
    setState(params.data.purchaseOrderNumber);
    let docObject: any;
    if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber != 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber && params.data.invoiceNumber === 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber === 'NA' && params.data.invoiceNumber) {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.data.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };

  

  // ----------------- datasorce for server side pagination --------------------

  // const dataSorce: IServerSideDatasource = {
  //   getRows: (params) => {
  //     obj = {};
  //     obj["start"] = params.request.startRow;
  // console.log("startRow", Object.keys(params.request.filterModel));
  //     obj["end"] = params.request.endRow;
  //     obj["prevStart"] = prevstart !== undefined ? prevstart : "";
  //     obj["poStatus"] = "open";
  //     const headers = {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //     };

  //     // ------------------  Po acknowledgement List ---------------------

  //     desgService.getPoAcknowledgementList(obj, headers).then((res: any) => {
  //       if (res.data.status.code === "SUCCESS") {
  //         params.success({
  //           rowData: res.data.primary,
  //           rowCount: res.data.totalRowsCount,
  //         });
  //       } else {
  //         params.fail();
  //       }
  //       rowdata = res.data.primary;
  //     });
  //   },
  // };
  const poList = (fromDate: any, toDate: any) => {
    config.params = options;
    setLoad(true)
    axios.get(Endpoints.Po_List, config).then((res: any) => {
      if (res.data.status.code === "SUCCESS") {
        if (res.data.primary.length === 0) {
          setLoad(false);
          setShowGrid(false);
        } else {
          setRowData(res.data.primary);
          setLoad(false);
          setLoader(false);
          setShowGrid(true);
        }
      } else {
        console.log(res.data, "resp data")
        if (res.data.status.code === 'error') {
          setErrors('No data found')
          setShowGrid(false)
          setLoader(false);
          setLoad(false);
        }
      }
    })
  };

  const handleData = (params: any) => {
    setData(params.data);
    setOpenDetail(true);
  };

  const handleClose = (newValue?: string, action?: string) => {
    setRemarks(false);
    setConfirm(false);
    if (action === "RejectSubmit") {
      if (newValue && action === "RejectSubmit") {
        let url = Environment_Realtraceb_Dev.BASE_URL + "/updatePOStatus";
        objData["rejectedReason"] = newValue;
        PostRequest(url, objData);
        setLoader(true);
        poList(fromDate, toDate);
      } else {
        setMessage("");
      }
    } else if (action === "AcceptSubmit") {
      setConfirm(false);
      let url = Environment_Realtraceb_Dev.BASE_URL + "/updatePOStatus";
      PostRequest(url, objData);
      setLoader(true);
      poList(fromDate, toDate);
    } else if (action === "RejectCancel") {
      setMessage("");
      setLoader(false);
    } else if (action === "AcceptCancel") {
      // console.log(action, "$action");
      setConfirm(false);
      setLoader(false);
    }
  };
  const handleMessageChange = (event: any) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
    // console.log(event.target.value);
  };
  const getRowHeight = (params: any) => {
    const isDetailRow = params.node.detail;
    // console.log(params, "params data")

    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) {
      return undefined;
    }

    // otherwise return height based on number of rows in detail grid
    // const detailPanelHeight = params.data.children.length * 50;
    // return detailPanelHeight;
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // console.log(params, "$$");
    // poList(fromDate, toDate);
    autosize();
    // params.api!.setServerSideDatasource(dataSorce);
  }, []);

  // let url = Environment_Realtraceb_Dev.BASE_URL + "/updatePOStatus";

  const handleAccept = () => {
    let data = gridRef.current.api.getSelectedRows();
    let list: { poStatus: string; rejectedReason: string; poID: any }[] = [];
    data.map((key: any, value: number) => {
      let obj = {
        poStatus: "accepted",
        rejectedReason: "",
        poID: key.poID,
      };
      list.push(obj);
    });
    let obj = {
      data: list,
    };
    axios.post(Endpoints.Update_Po_Status, obj, config).then((res) => {
        if (res.data.status.code === "SUCCESS") {
          swal("success", "PO Accepted Successfully", "success");
          poList(fromDate, toDate);
        }
      })
      .catch((error) => {
        return error;
      });

    setRemarks(false);
    setConfirm(false);
  };

  
  function autosize() {
    // console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  }

  const handleselected = () => {
    let data = gridRef.current.api.getSelectedRows();
    let pos: any[] = [];
    if (data.length === 0) {
      swal("info", "Please Select Pucrchase Orders ", "info");
    } else {
      if (data.length === 1) {
        setPoNumber([data[0].purchaseOrderNumber]);
      } else {
        data.map((ele: any, index: number) => {
          pos.push(ele.purchaseOrderNumber);
        });
        setPoNumber(pos);
      }
      setConfirm(true);
    }
  };

  const handlereject = () => {
    let data = gridRef.current.api.getSelectedRows();
    if (data.length === 0) {
      swal("info", "Please Select Pucrchase Orders ", "info");
    } else {
      let pos: any[] = [];
      if (data.length === 1) {
        // console.log(typeof data[0].purchaseOrderNumber);
        setPoNumber([data[0].purchaseOrderNumber]);
      } else {
        data.map((ele: any, index: number) => {
          pos.push(ele.purchaseOrderNumber);
        });
        setPoNumber(pos);
        // setPoNumber("");
      }
      setRemarks(true);
    }
  };
  const handleRejectPo = () => {
    let data = gridRef.current.api.getSelectedRows();
    let list: { poStatus: string; rejectedReason: string; poID: any }[] = [];
    let obj = {
      data: list,
    };
    data.map((key: any, value: number) => {
      let obj = {
        poStatus: "rejected",
        rejectedReason: message,
        poID: key.poID,
      };
      list.push(obj);
    });
    axios.post(Endpoints.Update_Po_Status, obj, config).then((res) => {
        if (res.data.status.code === "SUCCESS") {
          swal("success", "PO Rejected Sucessfully", "success");
          poList(fromDate, toDate);
        }
      })
      .catch((error) => {
        return error;
      });
    console.log(
      "Po acknowledement selected data...",
      data[0].purchaseOrderNumber
    );

    setRemarks(false);
    setMessage("");
  };
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        {sessionStorage.getItem("role") === "shipper" ? (
          <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            {showgrid ?
              <Box>
                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#28a745",
                    marginRight: "3px",
                  }}
                  onClick={() => handleselected()}
                  className="btn btn-info"
                  startIcon={<CheckCircleIcon />}
                >
                  Accept
                </Button>

                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#95535a",
                  }}
                  onClick={() => handlereject()}
                  className="btn btn-info"
                  startIcon={<CancelIcon />}
                >
                  Reject
                </Button>
              </Box>
              : ''}
          </Box>
        ) : null}

        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              ref={gridRef}
              getRowHeight={getRowHeight}
              rowSelection="multiple"
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>

        <div className="dialog">
          {openDetail ? (
            <ItemDetail
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={data}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div>
          <Dialog
            open={remarks}
            onClose={() => handleClose()}
            disableEscapeKeyDown
          >
            <DialogTitle>
              {poNumber.length > 1 ? (
                <Tooltip title={<TooltipCom data={poNumber} />}>
                  <p>Purchase Orders: 1 or More Purchase Orders</p>
                </Tooltip>
              ) : (
                <Typography>Purchase Orders: {poNumber[0]} </Typography>
              )}
            </DialogTitle>

            <DialogContent>
              {/* <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Enter Remarks"
                style={{ width: 500, border: '2px solid #7c7c7c' }}
                name='message'
                value={message}
                onChange={handleMessageChange}
              /> */}

              <TextField
                multiline={true}
                rows={4}
                minRows={10}
                autoFocus
                margin="dense"
                id="name"
                label="Enter Remarks"
                type="email"
                fullWidth
                variant="outlined"
                value={message}
                onChange={handleMessageChange}
                sx={{
                  width: "500px",
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(event) => {
                  setMessage("");
                  setRemarks(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={(event) => handleRejectPo()}>Submit</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={confirm}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
          >
            <DialogTitle id="alert-dialog-title">
              {poNumber.length > 1 ? (
                <Tooltip title={<TooltipCom data={poNumber} />}>
                  <p>Purchase Orders: 1 or More Purchase Orders</p>
                </Tooltip>
              ) : (
                <Typography>Purchase Orders: {poNumber[0]}</Typography>
              )}
            </DialogTitle>
            <DialogContent sx={{ Width: "fit-content" }}>
              <DialogContentText id="alert-dialog-description">
                {poNumber
                  ? "Are you sure you want to accept?"
                  : "Are you sure you want to accept the selected purchase orders?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={(event) => handleClose('', 'AcceptCancel')}>Cancel</Button> */}
              <LoadingButton
                loading={loader}
                variant="text"
                type="submit"
                sx={{
                  justifyContent: "end",
                  fontWeight: 500,
                }}
                onClick={(event) => setConfirm(false)}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                loading={loader}
                variant="text"
                type="submit"
                sx={{
                  justifyContent: "end",
                  fontWeight: 500,
                }}
                onClick={(event) => handleAccept()}
              >
                Accept
              </LoadingButton>
              {/* <Button onClick={() => handleClose('', 'AcceptSubmit')} autoFocus>
                Submit
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </div>
  );
}

export default PoForAcknowledgement;

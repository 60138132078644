import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import Topbar from "../common/Topbar";
import sizeConfigs from "../../Config/sizeConfigs";
import Sidebar from "../common/Sidebar";
import colorConfigs from "../../Config/colorConfig";
import { Toolbar } from "@material-ui/core";
import { Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SubHeader } from "../common/SubHeader";
import TabData from "../../Data/Tabdata";
import dayjs, { Dayjs } from "dayjs";
import "../../Styles.css";
import moment from "moment";
const Main = () => {
    const [mode, setMode] = useState<any>('Ocean');
    const [fromDate, setFd] = useState<Dayjs | any>(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    const [toDate, setTd] = useState<Dayjs | any>(moment().format('YYYY-MM-DD'));
    const [format, setFormat] = useState<any>("");
    const location = useLocation();
    useEffect(() => {
        console.log(
            "main layout......................",
            fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
            toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : ""
        );
       
    }, [fromDate, toDate, mode]);
    const [subHeader, setSubHeader] = useState<string>("");
    const [filterOpen, SetFilterOpen] = useState(false);
    const [Tabdata, setTabData] = useState<any>(TabData);
    const handleFilters = () => SetFilterOpen(!filterOpen);
    const [load, setLoad] = useState<boolean>(true);

    const onsetData = (fd: any, tod: any, format: any) => {
        setFd(fd);
        setTd(tod);
        setFormat(format);
        console.log(fd, tod);
    };

    const modeSet = (mode: any) => {
        console.log("Mode is ....", mode)
        setMode(mode)
    }

    const onSetShipmentData = (shipment: any) => {
        console.log(shipment, "shipment");
    }
    return (
        <Box sx={{ display: "flex" }}>
            <Topbar head={subHeader} modeSet={modeSet}  />
            <Box
                component="nav"
                sx={{
                    width: sizeConfigs.sidebar.width,
                    flexShrink: 0,
                    backgroundColor: colorConfigs.sidebar.bg,
                    zIndex: colorConfigs.sidebar.zIndex,
                }}
            >
                <Sidebar />
            </Box>
            <Box
                component="main"
                className={location.pathname === '/shipmentRegistrations' && mode === 'Ocean' ? "ocean-image":location.pathname === '/shipmentRegistrations' && mode === 'Air'?'air-image':''}
                sx={{
                    flexGrow: 1,

                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    minHeight: "100vh",
                    backgroundColor: colorConfigs.mainBg,
                }}
            >
                <Box
                    className={location.pathname === '/shipmentRegistrations' && mode === 'Ocean' ? "ocean-image content":location.pathname === '/shipmentRegistrations' && mode === 'Air'?'air-image content':''}
                    sx={{
                        m: location.pathname === '/shipmentRegistrations' ? 0 : 2,
                        // padding: "0px 10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginBottom: '100px'
                    }}
                >
                    <Box>
                        <Toolbar />
                        <SubHeader
                            head
                            location={location.pathname}
                            loader={load}
                            OnLoader={() => setLoad(false)}
                            dates={[fromDate, toDate]}
                            getdates={onsetData}
                        />
                        <Outlet context={[load, setLoad, fromDate, toDate, format, mode]} />
                    </Box>
                </Box>
            </Box>
            {location.pathname != '/sea_rates' && location.pathname !== '/registrations' && location.pathname !== '/shipmentRegistrations' && location.pathname !== '/registred_Shipments' && location.pathname !== '/digitalThread' ?
                <Box className="footer" >
                    <Typography
                        sx={{ fontSize: "14px", textAlign: "left", width: "55%" }}
                    >
                        Copyright &#169; RealTrace -All Rights Reserved
                    </Typography>
                    <Box sx={{ display: 'flex', }}>
                        <a href="https://www.realvariable.com/" target="_blank" className="footer_title">
                            <Typography sx={{ fontSize: "14px", textAlign: "right", pr: 2, color: '#333' }}>
                                Powered by
                            </Typography>
                            <Typography
                                sx={{ fontSize: "14px", textAlign: "right", width: "40%", color: '#333' }}
                            >
                                <img src={require('../../asserts/new_rv_logo.png')} className="footer_img  " />
                            </Typography>
                        </a>
                    </Box>
                </Box> : ''}
        </Box>
    );
};

export const MainLayout = React.memo(Main);

import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
// Icons Imports
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./trcuk.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";

import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function WhLineItems(props: any) {
  console.log(props.data);

  const [showgrid, setShowGrid] = useState(true);
  useEffect(() => {
    console.log("TruckLine Items Screen... ");
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "COMMODITY TYPE",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
      field: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      field: "itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "PACKAGE TYPE",
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packageType",
      field: "packageDetails.type",
    },
    {
      headerName: "PACKAGE WEIGHT",
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageWeight",
      field: "packageDetails.weight",
    },

    {
      headerName: "PACKAGE LENGTH",
      headerTooltip: "LENGTH",
      tooltipField: "length",
      field: "packageDetails.length",
    },
    {
      headerName: "PACKAGE WIDTH",
      headerTooltip: "WIDTH",
      tooltipField: "width",
      field: "packageDetails.width",
    },
    {
      headerName: "PACKAGE HEIGHT",
      headerTooltip: "HEIGHT",
      tooltipField: "height",
      field: "packageDetails.height",
    },

    {
      headerName: "HAZARDOUS GOODS",
      headerTooltip: "HAZARDOUS GOODS",
      tooltipField: "hazardousGoods",
      field: "hazardousGoods",
    },
    {
      headerName: "SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      headerTooltip: "SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
  ]);
 
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);

  const gridRef = useRef<any>();

  // console.log("configgg", config);

  const url = Environment_Realtraceb_Dev.BASE_URL + "/whItemList";

  const onGridReady = useCallback((params: GridReadyEvent) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      params: {
        docReference: props.data.docReference,
        status: props.status,
      },
    };
    axios
      .get(url, config)
      .then((res: any) => {
        setRowData(res.data.primary);
        setShowGrid(true)
      })
      .catch((err: any) => {
        console.log(err);
        setShowGrid(false)
      });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
            WAREHOUSE BOOKING REFERENCE #:{" "}
            <span className="dialogTitle"> {props.data.whBookingReference}</span>

          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Paper elevation={0}>
          <Box sx={{p:3}}>
            {showgrid ? (
              <AgGridReact
                className="ag-theme-balham"
                animateRows={true}
                defaultColDef={defaultColDef}
                rowData={rowData}
                domLayout={domLayout}
                columnDefs={columnDefs}
                sideBar={sideBar}
                ref={gridRef}
                onGridReady={onGridReady}
                // onVirtualColumnsChanged={autosize}
                onFirstDataRendered={onFirstDataRendered}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            ) : (
              <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                No data found
              </Alert>
            )}
          </Box>
        </Paper>
      </div>
    </Dialog>
  );
}

export default WhLineItems;

import { Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { makeStyles, Theme } from "@material-ui/core/styles";
import React from 'react'
import moment from 'moment';
const useStyles = makeStyles((theme: Theme) => ({
    customStepIcon: {
        '& .MuiStepIcon-root.Mui-completed': {
            color: 'tomato',
        },
        '& .MuiStepIcon-root.Mui-active': {
            color: 'tomato',
        },
    },
}));
export default function IntransitExceptions(props: any) {
    console.log(props, "exceptions")
    const classes = useStyles();
    const DateFormatter = (text:any) => {
        // Regular expression to match YYYY-MM-DD formatted dates
        const dateRegex = /\d{4}-\d{2}-\d{2}/g;

        const formattedText = text.replace(dateRegex, (match:any) => {
            return moment(match).format('DD-MMM-YYYY');
        });

        return <p>{formattedText}</p>;
    };
    return (
        <div>
            <Stepper
                activeStep={props.shipmentExceptions.length }
                orientation="vertical"
                sx={{
                    display: "flex",
                    border: "2px soild red",
                    paddingLeft: "10px",
                }}
                className={classes.customStepIcon}
            >
                {props.shipmentExceptions.map((step: any, index: any) => (
                    <Step key={index} >
                        <StepLabel>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    color: "black",
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                }}
                            >
                                {step.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    color: "black",
                                    textTransform: "capitalize",
                                    mt: 1
                                }}
                            >
                                <span style={{ color: '#a7a5ad' }}>Exception Date:</span> {moment(step.createdAt).format('DD-MMM-YYYY')}

                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    color: "black",
                                    textTransform: "capitalize",
                                    mt: 1,
                                    display:'flex'
                                }}
                            >
                                <span style={{ color: '#a7a5ad' }}>Description:</span> &nbsp;<span> {
                                    DateFormatter(step.description)

                                }</span>

                            </Typography>
                        </StepLabel>

                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

import { makeStyles, Theme } from "@material-ui/core/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
import { GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import { useCallback, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useEffect } from "react";
//import Button from "../../Layout/Button";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import { DesgService } from "../../../Services/DesgService";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import axios, { Axios } from "axios";
import RejectedItemDetail from "./Rejected_lines";
import { LoadingButton } from "@mui/lab";
import swal from "sweetalert";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function AckRejected() {
  const [remarks, setRemarks] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState();
  const [showgrid, setShowGrid] = useState(true);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  useEffect(() => {}, [openDetail, open]);
  const handleMessageChange = (event: any) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
    console.log(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleData = (params: any) => {
    setData(params.data);
    setOpenDetail(true);
  };

  useEffect(() => {
    setLoad(true);
    getreturndata();
  }, [fromDate, toDate]);

  const getreturndata = () => {
    axios.get(process.env.REACT_APP_BASE_URL +"/returnOrderList", config).then((res: any) => {
      console.log("23456789876543", res);
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      } else {
        setLoad(false);
        setRowData(res.data.primary);
        console.log(res.data.primary, "$rowdata");
      }
    });
  };
  const classes = useStyles();
  const [columnDefs] = useState([
    {
      // headerName: "SELECT SHIPMENTS",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      // hide: sessionStorage.getItem("role") === "shipper" ? false : true,
    },

    {
      headerName: "RETURN NUMBER",
      field: "returnNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    { headerName: "RETURN VERSION", field: "returnVersion" },
    { headerName: "CUSTOMER ID", field: "returnDate" },
    { headerName: "CUSTOMER NAME", field: "supplierId"},
    { headerName: "CUSTOMER DATE", field: "supplierName"},
    {
      headerName: "SHIP TO ADDRESS DATE",
      field: "shipToAddress",
    },
    { headerName: "CREATED BY", field: "createdBy"},
  ]);
  const [rowData, setRowData] = useState<any>([]);
  
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    returnStatus: "open",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

 

  const onGridReady = useCallback((params: GridReadyEvent) => {
    console.log(params, "$$");
    axios.get(process.env.REACT_APP_BASE_URL +"/returnOrderList", config).then((res: any) => {
      console.log("23456789876543     151", res);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
      } else {
        setRowData(res.data.primary);
        console.log(res.data.primary, "$rowdata");
      }
    });
  }, []);

  const gridRef = useRef<any>();
  //
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };
  //return approval
  const [confirm, setConfirm] = useState(false);
  const handleselected = () => {
    let data = gridRef.current.api.getSelectedRows();
    console.log(data);
    if (data.length === 0) {
      swal("info", "Please Select Return Orders", "info");
    } else {
      setConfirm(true);
    }
  };
  const handleAccept = () => {
    let data = gridRef.current.api.getSelectedRows();

    let payload: any = {
      data: [],
    };
    // "data=[
    //   {docReference:"",returnStatus:"","rejectedReason":"" (if status rejected)}]
    data.map((data: any, index: number) => {
      let obj: any = {
        docReference: data.docReference,
        returnStatus: "accepted",
        rejectedReason: "",
      };
      payload.data.push(obj);
    });
    axios.post(process.env.REACT_APP_BASE_URL +"/updateReturnStatus", payload, { headers }).then((res) => {
      console.log("131313131313", res);
      if (res.data.status.code === "SUCCESS") {
        swal("Updated", "Return Order Accepted Successfully", "success");
      }
    });
    setConfirm(false);
  };

  const domLayout = "autoHeight";
  function handleClose(): void {
    setConfirm(false);
  }

  //
  const handlereject = () => {
    let data = gridRef.current.api.getSelectedRows();
    console.log(data);
    if (data.length === 0) {
      swal("info", "Please Select Return Orders", "info");
    } else {
      setRemarks(true);
    }
  };

  const handleRejection = () => {
    if (message.length < 3) {
      swal("info", "Please enter valid rejection message");
    } else {
      let data = gridRef.current.api.getSelectedRows();

      let payload: any = {
        data: [],
      };
      // "data=[
      //   {docReference:"",returnStatus:"","rejectedReason":"" (if status rejected)}]
      data.map((data: any, index: number) => {
        let obj: any = {
          docReference: data.docReference,
          returnStatus: "rejected",
          rejectedReason: message,
        };
        payload.data.push(obj);
      });
      axios.post(process.env.REACT_APP_BASE_URL +"/updateReturnStatus", payload, { headers }).then((res) => {
        console.log("131313131313", res);
        if (res.data.status.code === "SUCCESS") {
          swal("Updated", "Return Order Rejected Successfully", "success");
        }
      });
      setRemarks(false);
      setMessage("");
    }
    setMessage("");
  };

  const handleReclose = () => setRemarks(false);

  return (
    <div className={classes.root}>
      {showgrid ? (
        <Paper elevation={0}>
          {sessionStorage.getItem("role") === "shipper" ? (
            <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Button
                style={{
                  height: "fit-content",
                  width: "fit-content",
                  lineHeight: 0.5,
                  color: "white",
                  backgroundColor: "#28a745",
                  marginRight: "3px",
                }}
                onClick={() => handleselected()}
                className="btn btn-info"
                startIcon={<CheckCircleIcon />}
              >
                Accept
              </Button>
              <Button
                style={{
                  height: "fit-content",
                  width: "fit-content",
                  lineHeight: 0.5,
                  color: "white",
                  backgroundColor: "#95535a",
                }}
                onClick={() => handlereject()}
                className="btn btn-info"
                startIcon={<CancelIcon />}
              >
                Reject
              </Button>
            </Box>
          ) : null}
          <Box sx={{ p: 0, mt: 1 }}>
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              ref={gridRef}
              // getRowHeight={getRowHeight}
              rowSelection="multiple"
              // onVirtualColumnsChanged={autosize}
              // onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </Box>
        </Paper>
      ) : (
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          No data found
        </Alert>
      )}

      <div className="dialog">
        {openDetail ? (
          <RejectedItemDetail
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={data}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div>
        <Dialog
          open={remarks}
          onClose={() => handleReclose()}
          disableEscapeKeyDown
        >
          <DialogTitle>Return Order Rejection</DialogTitle>
          <DialogContent>
            {/* <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                placeholder="Enter Remarks"
                style={{ width: 500, border: '2px solid #7c7c7c' }}
                name='message'
                value={message}
                onChange={handleMessageChange}
              /> */}

            <TextField
              multiline={true}
              rows={4}
              minRows={10}
              autoFocus
              margin="dense"
              id="name"
              label="Enter Remarks"
              type="email"
              fullWidth
              variant="outlined"
              value={message}
              onChange={handleMessageChange}
              sx={{
                width: "500px",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => {
                setRemarks(false);
                setMessage("");
              }}
            >
              Cancel
            </Button>
            <Button onClick={(event) => handleRejection()}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={confirm}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown
        >
          <DialogTitle id="alert-dialog-title">
            Return Order Approval
          </DialogTitle>
          <DialogContent sx={{ Width: "fit-content" }}>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to accept the selected purchase orders
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={(event) => handleClose('', 'AcceptCancel')}>Cancel</Button> */}
            <LoadingButton
              loading={loader}
              variant="text"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
              }}
              onClick={(event) => handleClose()}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              loading={loader}
              variant="text"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
              }}
              onClick={(event) => handleAccept()}
            >
              Submit
            </LoadingButton>
            {/* <Button onClick={() => handleClose('', 'AcceptSubmit')} autoFocus>
                Submit
              </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default AckRejected;

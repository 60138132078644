import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
// Icons Imports
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./trcuk.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";

import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function TruckLineItems(props: any) {
  
  const [showgrid, setShowGrid] = useState(true);
  useEffect(() => {
    console.log("TruckLine Items Screen... ");
  }, []);
  console.log(props.trucknumber);
  const [columnDefs] = useState([
    {
      headerName: "CHECKIN DATE",
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "whLines.checkInDate",
    },
    {
      headerName: "WAREHOUSE ID",
      headerTooltip: "WAREHOUSE ID",
      tooltipField: "whID",
      field: "whLines.whId",
    },
    {
      headerName: "WAREHOUSE NAME",
      headerTooltip: "WAREHOUSE NAME",
      tooltipField: "whName",
      field: "whLines.whName",
    },
    {
      headerName: "WAREHOUSE BOKKING REFERENCE",
      headerTooltip: "WAREHOUSE BOKKING REFERENCE",
      tooltipField: "whID",
      field: "whLines.whBookingReference",
    },

    // {
    //   headerName: "ETD FROM WAREHOUSE",
    //   filter: true,
    //   headerTooltip: "ETD FROM WAREHOUSE",
    //   tooltipField: "etdFromWareHouse",
    //   field: "etdFromWareHouse",
    // },
    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "carrierHeaderDetails.shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "carrierHeaderDetails.shipperName",
    },
    {
      headerName: "COMMODITY TYPE",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
      field: "whLines.commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "whLines.itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      field: "whLines.itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "whLines.itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      field: "whLines.batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "whLines.batch number",
    },
    {
      headerName: "LOT NUMBER",
      field: "whLines.lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      field: "whLines.serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "PACKAGE TYPE",
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packageType",
      field: "whLines.packageDetails.type",
    },
    {
      headerName: "PACKAGE WEIGHT",
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageWeight",
      field: "whLines.packageDetails.weight",
    },

    {
      headerName: "PACKAGE LENGTH",
      headerTooltip: "LENGTH",
      tooltipField: "length",
      field: "whLines.packageDetails.length",
    },
    {
      headerName: "PACKAGE WIDTH",
      headerTooltip: "WIDTH",
      tooltipField: "width",
      field: "whLines.packageDetails.width",
    },
    {
      headerName: "PACKAGE HEIGHT",
      headerTooltip: "HEIGHT",
      tooltipField: "height",
      field: "whLines.packageDetails.height",
    },

    {
      headerName: "HAZARDOUS GOODS",
      headerTooltip: "HAZARDOUS GOODS",
      tooltipField: "hazardousGoods",
      field: "whLines.hazardousGoods",
    },
    {
      headerName: "SPECIAL INSTRUCTIONS",
      field: "whLines.packageDetails.specialInstructions",
      headerTooltip: "SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
  ]);
 
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);

  const gridRef = useRef<any>();

  // console.log("configgg", config);

  const url =
    Environment_Realtraceb_Dev.BASE_URL + "/getShipmentsByTruckNumber";

  const onGridReady = useCallback((params: GridReadyEvent) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      params: { truckNumber: props.trucknumber },
    };
    axios
      .get(url, config)
      .then((res: any) => {
        setRowData(res.data.primary);
        setShowGrid(true)
      })
      .catch((err: any) => {
        console.log(err);
        setShowGrid(false)
      });
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            TRUCK # {props.trucknumber}
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      {/* <div className={classes.root}>
        <Container maxWidth="xl" className={classes.maincontent}>
          <div className="ag-theme-balham">
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              ref={gridRef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>
        </Container>
      </div> */}
      <div className={classes.root}>
        <Paper elevation={0}>
          <Box sx={{p:3}}>
            {showgrid ? (
             <AgGridReact
             className="ag-theme-balham"
             animateRows={true}
             defaultColDef={defaultColDef}
             rowData={rowData}
             domLayout={domLayout}
             columnDefs={columnDefs}
             sideBar={sideBar}
             ref={gridRef}
             onGridReady={onGridReady}
             // onVirtualColumnsChanged={autosize}
             onFirstDataRendered={onFirstDataRendered}
           ></AgGridReact>
            ) : (
              <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                No data found
              </Alert>
            )}
          </Box>
        </Paper>
      </div>
    </Dialog>
  );
}

export default TruckLineItems;

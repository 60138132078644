import { Divider, Typography } from "@mui/material";
import "./Tooltip.css";

function TooltipCom(props: any) {
  return (
    <div>
      <ul className="liststyle">
        {props.data.map((data: any, key: any) => {
          return (
            <li key={key} className="listitem">
              {data}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TooltipCom;

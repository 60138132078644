import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from '@mui/material'
import React from 'react'
import BrFileupload from "../UploadFiles/BRupload";
import CreateBookingRequest from "./CreateBookingRequest";
import { useLocation } from "react-router-dom";

export default function BookingRequest() {
  const [value, setValue] = React.useState("upload");
  const location = useLocation();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    console.log(newValue,"newvalue")
    if(newValue === '2'){
      location.pathname = '/upload_booking_request'
      console.log(location.pathname,"path")
    }
  };
  return (
    <div>
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
             
              <Tab label="Upload Booking Request" value="upload"/>
              <Tab label="Create Booking Request" value="create" />
            </TabList>
          </Box>
         
          <TabPanel value="upload" sx={{ padding: '10px 0px' }} >
            <BrFileupload/>
          </TabPanel>
          <TabPanel value="create" sx={{ padding: '10px 0px' }}>
            <CreateBookingRequest showList={true}/>
          </TabPanel>
        </TabContext>

      </Box>
    </div>
  )
}

import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useFormik } from "formik";
import { InputLabel, Paper } from "@material-ui/core";
// import "./custom.css";

import { sideBar,defaultColDef } from '../../common/AgGridConfig';
// import DatePicker from 'material-ui';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
// import { LocalizationProvider } from "@mui/x-date-pickers";

import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";

// import { Environment_Realtraceb_Dev } from "../Enviornment/Environment_Realtraceb_Dev";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, TextField } from "@mui/material";
// import CrLineItems from "../Components/Carrier/CarrierLineItems";
import { Typography } from "@material-ui/core";
import Full from "../../../Helpers/IntransitEventsMapping";
import LegInfoVisual from "../Carrier/carrierLeginfoVisual";
// import { AnyMxRecord } from "dns";
import "./custom.css";
import swal from "sweetalert";
import { useNavigate, useOutletContext } from "react-router-dom";
import CrLineItems from "../Carrier/CarrierLineItems";
import moment from "moment";
// import "./Car_ack.css";
// import Full from "../Layout/full";
// import LegInfoVisual from "../Components/Carrier/carrierLeginfoVisual";
// import swal from "sweetalert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ExportedCustomClearence() {
  const nav = useNavigate();
  function navigation() {
    nav("/upload_documents");
  }
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [PoData, setPoData] = useState<any>([]);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();

  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setEventData(params.data)
    
    setOpenDetail(true);
  };

  // const handleData = (params: any) => {
  //   console.log("Event Screen Console....", params.data);
  //   // setPoData(params.data);
  //   getEventData(
  //     params.data.containerNumber,
  //     params.data.hscode,
  //     params.data.invoiceNumber
  //   );
  // };
  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);

    setLegOpen(true);
  };
  //UseReference Hook To Access Grid Api
  const gridRef = useRef<any>();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };

  const getEventData = (data: any, hs: any, inv: any) => {
    axios
      .post(
        "https://api.ext.realtrace.realware.tech/po/eventQuery",
        {
          containerNumber: data,
          hscode: hs,
          invoiceNumber: inv,
        },
        { headers }
      )
      .then((evedata) => {
        console.log("Event Data.....", evedata);
        setEventData(evedata);
        setOpenDetail(true);
        console.log("EVENTS  DATA.....");
      });
  };

  const [columnDefs] = useState([
    {
      headerName: "INVOICE NUMBER",
      field: "invoiceNumber",
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            sx={{ height: 20, width: "100%", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      headerName: "INVOICE Date",
      field: "invoiceDate",
      headerTooltip: "INVOICE Date",
      tooltipField: "invoiceDate",
    },
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
    },

    {
      headerName: "HBL NUMBER",
      field: "hblNumber",
      headerTooltip: "HBL NUMBER",
      tooltipField: "hblNumber",
    },
    {
      headerName: "MBL NUMBER",
      field: "mblNumber",
      headerTooltip: "MBL NUMBER",
      tooltipField: "mblNumber",
    },
    {
      headerName: "COMMODITY TYPE ",
      field: "commodityType",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },

    {
      headerName: "HS CODE",
      field: "hscode",
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
    },
    {
      headerName: "HS CODE DESCRIPTION ",
      field: "hscodeDescription",
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },

    {
      headerName: "SHIPMENT DETAILS",
      children: [
        {
          headerName: "SHIPPER ID",
          headerTooltip: "SHIPPER ID",
          tooltipField: "shipperId",
          field: "shipperId",
        },
        {
          headerName: "SHIPPER NAME",
          headerTooltip: "SHIPPER NAME",
          tooltipField: "shipperName",
          field: "shipperName",
          columnGroupShow: "closed",
        },

        {
          headerName: "SHIPMENT WEIGHT",
          headerTooltip: "SHIPMENT WEIGHT",
          tooltipField: "shipmentWeight",
          field: "shipmentWeight",
          columnGroupShow: "closed",
        },
        {
          headerName: "WEIGHT UOM",
          field: "weightUom",
          headerTooltip: "WEIGHT UOM",
          tooltipField: "weightUom",
        },
        {
          headerName: "SHIPMENT LENGTH",
          headerTooltip: "SHIPMENT LENGTH",
          tooltipField: "length",
          field: "length",
          columnGroupShow: "closed",
        },
        {
          headerName: "SHIPMENT WIDTH",
          headerTooltip: "SHIPMENT WIDTH",
          tooltipField: "width",
          field: "width",
          columnGroupShow: "closed",
        },

        {
          headerName: "SHIPMENT HEIGHT",
          headerTooltip: "SHIPMENT HEIGHT",
          tooltipField: "height",
          field: "height",
          columnGroupShow: "closed",
        },
      ],
    },

    // {
    //     headerName: "INVOICE DATE",
    //     field: "invoiceDate",
    //     filter: true,
    //     headerTooltip: "INVOICE DATE",
    //     tooltipField: "invoiceDate",
    //   },

    {
      headerName: "PICK UP DETAILS",
      children: [
        {
          headerName: "PICK UP LOCATION",
          field: "pickupLocation",
          headerTooltip: "PICK UP LOCATION",
          tooltipField: "pickupLocation",
        },

        {
          headerName: "PICKUP DATE",
          headerTooltip: "PICKUP DATE",
          tooltipField: "pickUpDate",
          field: "pickUpDate",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
        {
          headerName: "PICKUP COUNTRY",
          headerTooltip: "PICKUP COUNTRY",
          tooltipField: "pickupCountry",
          field: "pickupCountry",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
        {
          headerName: "PICKUP ZIPCODE",
          headerTooltip: "PICKUP ZIPCODE",
          tooltipField: "pickupZipCode",
          field: "pickupZipCode",
          columnGroupShow: "closed",
          cellClass: "ag-left-aligned-cell",
        },
      ],
    },

    {
      headerName: "CONSIGNEE DETAILS",
      children: [
        {
          headerName: "CONSIGNEE ID",
          headerTooltip: "CONSIGNEE ID",
          field: "consigneeId",
          tooltipField: "consigneeId",
        },
        {
          headerName: "CONSIGNEE NAME",
          headerTooltip: "CONSIGNEE NAME",
          field: "consigneeName",
          tooltipField: "consigneeName",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "DROP OFF LOCATION",
      field: "dropoffLocation",
      headerTooltip: "DROP OFF LOCATION",
      tooltipField: "dropoffLocation",
    },
    {
      headerName: "DROP OFF COUNTRY",
      field: "dropoffCountry",
      headerTooltip: "DROP OFF COUNTRY",
      tooltipField: "dropoffCountry",
    },
    {
      headerName: "DROP OFF ZIPCODE",
      field: "dropoffZipCode",
      headerTooltip: "DROP OFF ZIPCODE",
      tooltipField: "dropoffZipCode",
    },
    {
      headerName: "FORWARDER DETAILS",
      children: [
        {
          headerName: "FORWARDER ID",
          headerTooltip: "FORWARDER ID",
          tooltipField: "forwarderId",
          field: "forwarderId",
        },
        {
          headerName: "FORWARDER NAME",
          headerTooltip: "FORWARDER NAME",
          tooltipField: "forwarderName",
          field: "forwarderName",
          columnGroupShow: "closed",
        },
      ],
    },

    {
      headerName: "CARRIER ID",
      field: "carrierId",
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CARRIER NAME",
      field: "carrierName",
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",

      //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    },
    {
      headerName: "CONTAINER DETAILS",
      children: [
        {
          headerName: "CONTAINER NUMBER",
          field: "containerNumber",
          headerTooltip: "CONTAINER NUMBER",
          tooltipField: "containerNumber",
        },
        {
          headerName: "CONTAINER TYPE",
          headerTooltip: "CONTAINER TYPE",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },

        {
          headerName: "CONTAINER PICKUP DEPOT",
          headerTooltip: "CONTAINER PICKUP DEPOT",
          tooltipField: "containerType",
          field: "containerType",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER SIZE",
          headerTooltip: "CONTAINER SIZE",
          tooltipField: "containerSize",
          field: "containerSize",
          columnGroupShow: "closed",
        },
        {
          headerName: "CONTAINER TARE WEIGHT",
          headerTooltip: "CONTAINER TARE WEIGHT",
          tooltipField: "containerTareWeight",
          field: "containerTareWeight",
          columnGroupShow: "closed",
        },
      ],
    },
    {
      headerName: "MODE OF SHIPMENT",
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeOfShipment",
      field: "modeOfShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "INCO TERMS",
      headerTooltip: "INCO TERMS",
      tooltipField: "incoterms",
      field: "incoterms",
      columnGroupShow: "closed",
    },
    {
      headerName: "ETD",
      field: "etd",
      headerTooltip: "ETD",
      tooltipField: "etd",
    },
    {
      headerName: "ETA",
      field: "eta",
      headerTooltip: "ETA",
      tooltipField: "eta",
    },

    {
      headerName: "UPLOAD DOCS",
      headerTooltip: "UPLOAD DOCS",
      tooltipField: "upload docs",
      field: "tranship",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Button
            onClick={() => {
              navigation();
            }}
          >
            Upload Doc
          </Button>
        );
      },
    },
  ]);

  //
  const [date, setDate] = useState("");
  const formik = useFormik({
    initialValues: {
      truckNumber: "",
      driverName: "",
      driverNumber: "",
      gateAppointmentDate: "",
      gateAppointmentTime: "",
      gateInDate: "",
      gateInTime: "",
      mountOnTruckDate: "",
      mountOnTruckTime: "",
      gateOutDate: date,
      gateOutTime: "",
      eventGateAppointmentDate: "",
      eventGateInDate: "",
      eventMountOnTruckDate: "",
      eventGateOutDate: "",
    },
    onSubmit: (values, { resetForm }) => {
      let obj = { ...values };
      obj["eventGateAppointmentDate"] =
        values.gateAppointmentDate +
        "T" +
        values.gateAppointmentTime +
        ":00.000Z";
      obj["eventGateInDate"] =
        values.gateInDate + "T" + values.gateInTime + ":00.000Z";
      obj["eventMountOnTruckDate"] =
        values.mountOnTruckDate + "T" + values.mountOnTruckTime + ":00.000Z";
      obj["eventGateOutDate"] =
        values.gateOutDate + "T" + values.gateOutTime + ":00.000Z";
      console.log("Payload for cha custom clearence", obj);
      handlesubmit(obj);
      resetForm({
        values: {
          truckNumber: "",
          driverName: "",
          driverNumber: "",
          gateAppointmentDate: "",
          gateAppointmentTime: "",
          gateInDate: "",
          gateInTime: "",
          mountOnTruckDate: "",
          mountOnTruckTime: "",
          gateOutDate: date,
          gateOutTime: "",
          eventGateAppointmentDate: "",
          eventGateInDate: "",
          eventMountOnTruckDate: "",
          eventGateOutDate: "",
        },
      });
    },
  });
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open",
    // fbrStatus: "open,processed",
    brStatus: "open",
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      type: "cha",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    },
  };
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoad(true);
    axios.get(process.env.REACT_APP_API_URL + 'po/customsTruckList', config).then((res) => {
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);

  //gateout-shipmnets
  //eta and etd in all reports after carrier confirmation

  function closeUpdate() {
    setOpenUpdate(false);
  }

  const handlesubmit = (datas: any) => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length > 0) {
      // setShow(false);
      console.log("Selected rows...", selectedRowsData);
      let payload: any = { data: [] };
      selectedRowsData.map((data: any, index: number) => {
        let obj = {
          invoiceNumber: data.invoiceNumber,
          bookingReference: data.bookingReference,
          carrierId: data.carrierId,
          carrierName: data.carrierName,
          containerId: data.containerNumber,
          hblNumber: data.hblNumber,
          mblNumber: data.mblNumber,
          shipperId: data.shipperId,
          shipperName: data.shipperName,
          consigneeId: data.consigneeId,
          consigneeName: data.consigneeName,
          hscode: data.hscode,
          ...datas,
          carrierReference: data.carrierReference,
        };
        payload.data.push(obj);
      });
      console.log("Update data....", payload);
      // PostRequest(updateurl, payload);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };
      axios.post(process.env.REACT_APP_API_URL + 'po/updateTruckDetails' , payload, { headers }).then((sucees: any) => {
        console.log(sucees);
        axios.get(process.env.REACT_APP_API_URL + 'po/customsTruckList', config).then((res) => {
          setRowData(res.data.primary);
          console.log(res.data.primary);
        });
        // swal("Success", sucees.data.primary, "success");
        closeUpdate();
      });

      // inputRef.current.reset();

      // setRemarks("");
    }
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const handleUpdate = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length === 0) {
      swal("info", "Please Select shipments", "info");
    } else {
      setOpenUpdate(true);
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box className="ag-theme-balham">
          <AgGridReact
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            domLayout={domLayout}
            columnDefs={columnDefs}
            sideBar={sideBar}
            onGridReady={onGridReady}
            ref={gridRef}
            rowSelection="multiple"
            paginationPageSize={10}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        {openUpdate ? (
          <Dialog fullWidth open={openUpdate} onClose={closeUpdate}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="h6">Truck Details</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit} className="form">
              <Box sx={{ margin: "10px" }}>
                <Box sx={{ marginBottom: "15px" }} className="input_render">
                  <Box sx={{ marginRight: "10px" }}>
                    <InputLabel id="truck-number"> Truck Number</InputLabel>
                    <TextField
                      variant="outlined"
                      id="truck-number"
                      name="truckNumber"
                      label="Truck Number"
                      value={formik.values.truckNumber}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box>
                    {/* <Box sx={{ marginBottom: "15px" }}> */}
                    <InputLabel id="driver-name"> Driver Name</InputLabel>
                    <TextField
                      variant="outlined"
                      id="driver-name"
                      name="driverName"
                      label="Driver Name"
                      value={formik.values.driverName}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "15px" }} className="input_render">
                  <Box>
                    <InputLabel id="driver-mobilenumber">
                      Driver Mobile Number
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="driver-mobilenumber"
                      name="driverNumber"
                      label="Driver Mobile Number"
                      value={formik.values.driverNumber}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box>
                    <InputLabel id="gateAppointmentDate">
                      Gate Appointment Date
                    </InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="date"
                      // defaultValue="2017-05-24"
                      id="gateAppointmentDate"
                      name="gateAppointmentDate"
                      value={formik.values.gateAppointmentDate}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "15px" }} className="input_render">
                  <Box>
                    <InputLabel id="gateAppointmentTime">
                      Gate In Appointment Time
                    </InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="time"
                      // defaultValue="07:30"
                      id="gateAppointmentTime"
                      name="gateAppointmentTime"
                      value={formik.values.gateAppointmentTime}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box>
                    <InputLabel id="gateInDate">GateIn Date</InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="date"
                      defaultValue="07:30"
                      id="gateInDate"
                      name="gateInDate"
                      value={formik.values.gateInDate}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "15px" }} className="input_render">
                  <Box>
                    <InputLabel id="gateinTime">GateIn Time</InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="time"
                      defaultValue="07:30"
                      id="gateinTime"
                      name="gateInTime"
                      value={formik.values.gateInTime}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box>
                    <InputLabel id="mountOnTruckDate">
                      MountOn Truck Date
                    </InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="date"
                      defaultValue="07:30"
                      id="mountOnTruckDate"
                      name="mountOnTruckDate"
                      value={formik.values.mountOnTruckDate}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>

                <Box sx={{ marginBottom: "15px" }} className="input_render">
                  <Box>
                    <InputLabel id="mountOnTruckTime">
                      MountOn Truck Time
                    </InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="time"
                      id="mountOnTruckTime"
                      name="mountOnTruckTime"
                      // label="MountOn Truck Time"
                      defaultValue="07:30"
                      value={formik.values.mountOnTruckTime}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box>
                    <InputLabel id="gateOutDate">GateOut Date</InputLabel>
                    <TextField
                      sx={{ width: "221px" }}
                      variant="outlined"
                      type="date"
                      id="gateOutDate"
                      name="gateOutDate"
                      // label="GateOut Date"
                      defaultValue="07:30"
                      value={formik.values.gateOutDate}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
                {/* <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="select date"
                      inputFormat="MM/DD/YYYY"
                      // name="gateOutDate"
                      value={date}
                      onChange={(value: any) =>
                        formik.setFieldValue(date, value, true)
                      }
                      renderInput={(props: any) => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                </Box> */}
                <Box sx={{ marginBottom: "15px" }}>
                  <InputLabel id="gateOutTime">GateOut Time</InputLabel>
                  <TextField
                    sx={{ width: "221px" }}
                    variant="outlined"
                    type="time"
                    id="gateOutTime"
                    name="gateOutTime"
                    // label="GateOut Time"
                    defaultValue="07:30"
                    value={formik.values.gateOutTime}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  <Button type="submit" variant="contained" color="primary">
                    update
                  </Button>
                </Box>
              </Box>
            </form>
          </Dialog>
        ) : null}
      </Paper>
      <div className="dialog">
        {openDetail ? (
          <CrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ExportedCustomClearence;

import { ColDef, SideBarDef } from 'ag-grid-community';
import React, { useMemo } from 'react'
export const sideBar: SideBarDef = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelKey: "filters",
        labelDefault: "Filters",
        iconKey: "menu",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  
  export const defaultColDef: ColDef = {
    flex: 1,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter'
    
  };


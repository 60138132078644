import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import "./trcuk.css";

import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import WhLineItems from "./WhLineItems";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function RejectedShipments() {
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [showgrid, setShowGrid] = useState(true);
  const handlelines = (params: any) => {
    console.log(params.data);
    setData(params.data);
    setOpen(true);
  };
  useEffect(() => {
    setLoad(true);
    axios.get(process.env.REACT_APP_BASE_URL + "/whHeaderList", config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);
  const [columnDefs] = useState([
    {
      headerName: "WAREHOUSE BOKKING REFERENCE",
      headerTooltip: "WAREHOUSE BOKKING REFERENCE",
      tooltipField: "whBookingReference",
      field: "whBookingReference",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handlelines(params)}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "INVOICE NUMBER",
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
    },
    {
      headerName: "CHECKIN DATE",
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "checkInDate",
    },

    {
      headerName: "CHECKOUT DATE",
      headerTooltip: "CHECKOUT DATE",
      tooltipField: "checkOutDate",
      field: "checkOutDate",
    },

    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "carrierHeaderDetails.shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "carrierHeaderDetails.shipperName",
    },
    {
      headerName: "CONSIGNEE ID",
      headerTooltip: "CONSIGNEE ID",
      field: "carrierHeaderDetails.consigneeId",
      tooltipField: "consigneeId",
    },
    {
      headerName: "CONSIGNEE NAME",
      headerTooltip: "CONSIGNEE NAME",
      field: "carrierHeaderDetails.consigneeName",
      tooltipField: "consigneeName",
    },

    {
      headerName: "UPLOAD DOCS",
      // field: "",
      headerTooltip: "UPLOAD DOCS",
      tooltipField: "upload docs",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button>Upload Docs</Button>;
      },
    },
  ]);
 
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    whStatus: "open,processed",
    whLineStatus: "rejected",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

 
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();

  const gridRef = useRef<any>();
  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.maincontent}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Container>
      <div>
        {open ? (
          <WhLineItems
            onOpen={open}
            onClose={() => setOpen(false)}
            data={data}
            status={"rejected"}
          />
        ) : null}
      </div>
    </div>
  );
}

export default RejectedShipments;

import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { FirstDataRenderedEvent, ICellRendererParams } from "ag-grid-community";

import { DialogActions, DialogTitle, Tooltip } from "@material-ui/core";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import sizeConfigs from "../../../Config/sizeConfigs";
import colorConfigs from "../../../Config/colorConfig";
import ItemDetail from "../../../Helpers/ItemDetail";
import "../../../Styles.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    // marginTop: "0px",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    marginTop: "0px",
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function RejectedPo() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };
  const [state, setState] = useState();
  const nav = useNavigate();

  const managedoc = (params: any) => {
    console.log(params.data.purchaseOrderNumber);
    setState(params.data.purchaseOrderNumber);
    let docObject: any;
    if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber !='NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber  && params.data.invoiceNumber ==='NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber ==='NA' && params.data.invoiceNumber) {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.data.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };
  const [columnDefs] = useState([
    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      headerTooltip: "PURCHASE ORDER #",
      tooltipField: "purchaseOrderNumber",
      cellClass: "ag-left-aligned-cell",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ lineHeight: 0.5, color: "blue" }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    {
      headerName: "REJECTED REASON",
      // tooltipField: "rejectedReason",
      field: "rejectedReason",
      headerTooltip: "REJECTED REASON",
      cellRenderer: (params: ICellRendererParams) => {
        let output;
        console.log(params.value, "$value");
        if (params.value !== null) {
          if (params.value.length > 8) {
            output = params.value.substring(0, 9);
            output = output + "....";
          } else {
            output = params.value;
          }
        }

        return (
          <Tooltip title={params.value} placement="bottom-start">
            <p style={{ color: "tomato" }}>{output}</p>
          </Tooltip>
        );
      },
    },
    {
      headerName: "PURCHASE ORDER DATE",
      field: "poDate",
      headerTooltip: "PURCHASE ORDER DATE",
      tooltipField: "poDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      field: "customerId",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER ID",
      tooltipField: "customerId",
    },
    {
      headerName:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER NAME",
      field: "customerName",
      headerTooltip:
        sessionStorage.getItem("role") === "consignee"
          ? "SUPPLIER ID"
          : "CUSTOMER NAME",
      tooltipField: "customerName",
    },
    {
      headerName: "SHIP TO ADDRESS",
      field: "shipToAddress",
      headerTooltip: "SHIP TO ADDRESS",
      tooltipField: "shipToAddress",
    },
    {
      headerName: "REJECTED BY",
      field: "updatedBy",
      headerTooltip: "REJECTED BY",
      tooltipField: "updatedBy",
    },
    {
      headerName: "REJECTED DATE",
      field: "updatedDate",
      headerTooltip: "REJECTED DATE",
      tooltipField: "updatedDate",
      cellClass: "ag-left-aligned-cell",
    },
    
    {
      headerName: "VIEW DOCS",
      headerTooltip: "VIEW DOCS",
      cellRenderer: (params: ICellRendererParams) => {
        return <Button onClick={() => managedoc(params)}>View Doc</Button>;
      },
    },
  ]);

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    poStatus: "rejected",
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: options,
  };

 
  const [rowData, setRowData] = useState([]);
  const classes = useStyles();
  const domLayout = "autoHeight";
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  useEffect(() => {
    setLoad(true)
    axios.get(process.env.REACT_APP_BASE_URL + '/purchaseOrderList', config).then((res) => {
      setShowGrid(true);
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setRowData(res.data.primary);
      setLoad(false);
      console.log(res.data.primary);
    });
  }, [fromDate, toDate]);
  const gridRef = useRef<any>();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <ItemDetail
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default RejectedPo;

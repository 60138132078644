import React from 'react';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Slide, Stepper, Toolbar } from '@mui/material';
import CommonETADeviationInfo from '../CommonETADeviationInfo';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ETADeviationInfo(props: any) {
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div className="dialog">
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            ETA Deviations - Summary
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ width: "100%", typography: "body1", p: 3, overflowY: 'scroll' }}>
                    <CommonETADeviationInfo pageInfo={{ tab: 'between2days', page: 'etaDeviation', shipmentType: props.shipmentType }} />


                </Box>
            </Dialog>
        </div>
    )
}

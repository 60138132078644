import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, InputLabel, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Stepper, Step, StepLabel, StepContent, AppBar, Toolbar, IconButton, SelectChangeEvent } from '@mui/material'
import { CheckboxSelectionCallbackParams, FirstDataRenderedEvent, GridReadyEvent, GridApi, ICellRendererParams, CellClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import moment from "moment";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateCarrierBookingRequest(props: any) {
    const [selectedValue, setSelectedValue] = React.useState('accept');
    const [acceptedPoRowData, setAcceptedPoRowData] = useState<any>([]);
    const [rejectedBrRowData, setRejectedBrRowData] = useState([]);
    const [load, setLoad, fromDate, toDate, setFd, setTd] = useOutletContext<any>();
    const [packagesInfo, setPackagesInfo] = useState(false)
    const [showList, setShowList] = useState(props.showList)
    const [packagesData, setpackagesData] = useState()
    const [isDisablePackageInfo, setIsDisablePackageInfo] = useState(false)
    const [isConfirmation, setConfirmation] = useState(false)
    const [updatePackages, setUpdatePackages] = useState<any>()
    const gridRef = useRef<any>();
    const steps = ['Add Booking', 'View Booking', 'Finalize Booking'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [selectedRowData, setSelectedRowData] = useState<any>([])
    const { register, resetField, reset, setValue, getValues, formState: { errors }, control, handleSubmit, } = useForm({
        defaultValues: {
            additionalPackageInfo: {
                invoiceNo: '',
                noOfPackages: '',
                packageType: '',
                packageWeight: '',
                hzCode: '',
                length: '',
                width: '',
                height: '',
                specialInstructions: '',
                hscode: '',
                hscodeDescription: '',
                batchNumber: '',
                lotNumber: ''
            }
        },
    });
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {},
    };
    const [acceptedPoColumnDefs, setAcceptedPoColumnDefs] = useState<any>([]);
    
    const handleSelectCheckbox = (params: any, status: any) => {
        console.log(selectedValue, "selected value")
        if (status === 'accept') {
            console.log(params, "Selected params")
            setpackagesData(params.data)
            if (params.data.packageInfo != undefined) {
                setValue(`additionalPackageInfo`, params.data.packageInfo)
                setConfirmation(true)
                swal({
                    title: "You want to",
                    icon: "warning",
                    buttons: {
                        update: "Update",
                        delete: "Delete",
                        cancel: "Cancel",
                    },
                } as any)
                    .then(result => {
                        if (result === 'update') {
                            setPackagesInfo(true)
                            setIsDisablePackageInfo(false)
                        } else if (result === 'delete') {
                            let payload = {
                                'ids': [params.data._id]
                            }
                            swal({
                                title: 'You want to delete ?',
                                icon: 'error',
                                buttons: {
                                    delete: 'Delete',
                                    cancel: 'Cancel'
                                }
                            } as any).then(result => {
                                if (result === 'delete') {
                                    axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/deleteDraftPackageInfoForPO', payload, config).then(async (res: any) => {
                                        console.log("respppp", res)
                                        if (res.data.status === true) {
                                            await swal('Success', res.data.message, 'success')
                                            getAcceptedLines()
                                        }
                                    })
                                }
                            })

                        }
                    }).catch((e: any) => {
                        setPackagesInfo(false)
                    });

            } else {
                setIsDisablePackageInfo(false)
                setConfirmation(false)
                setPackagesInfo(true)
                resetField('additionalPackageInfo')
            }
        } else {
            setPackagesInfo(false)
        }

    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value, "target value")
        if (event.target.value === 'accept') {
            getAcceptedLines()
        } else {
            getRejectedLines()
        }

    };
    const getAcceptedLines = async () => {
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        setLoad(true)
        setAcceptedPoColumnDefs([
            {
                field: 'selection',
                headerName: '',
                headerCheckboxSelection: false,
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <input
                            style={{
                                lineHeight: 0.5,
                                color: "blue",
                            }}
                            type="checkbox"
                            checked={params.data.isSelect}
                            onClick={() => handleSelectCheckbox(params, 'reject')}
                            className="btn btn-info"
                        />
                    );
                },
            },
            {
                headerName: "FORWARDER BOOKING #",
                field: "purchaseOrderNumber",
                headerTooltip: "FORWARDER BOOKING #",
                tooltipField: "purchaseOrderNumber",
                cellClass: "ag-left-aligned-cell",
            },
            {
                headerName: "BOOKING DATE",
                field: "purchaseOrderDate",
                headerTooltip: "BOOKING DATE",
                tooltipField: "purchaseOrderDate",
                cellClass: "ag-left-aligned-cell",
            },
            {
                headerName: "HBL #",
                field: "customerId",
                headerTooltip: "HBL #",
                tooltipField: "customerId",
                cellClass: "ag-left-aligned-cell",
            },
            {
                headerName: "NO.OF PACKAGES",
                field: "packageInfo.noOfPackages",
                headerTooltip: "NO.OF PACKAGES",
                tooltipField: "packageInfo.noOfPackages",
                hide: false,
            },
            {
                headerName: "PACKAGE TYPE",
                field: "packageInfo.packageType",
                headerTooltip: "PACKAGE TYPE",
                tooltipField: "packageInfo.packageType",
                hide: false,
            },
            {
                headerName: "WEIGHT",
                field: "packageInfo.packageWeight",
                headerTooltip: "WEIGHT",
                tooltipField: "packageInfo.packageWeight",
                hide: false,
            },
            {
                headerName: "LENGTH",
                field: "packageInfo.packageWeight",
                headerTooltip: "LENGTH",
                tooltipField: "packageInfo.packageWeight",
                hide: false,
            },
            {
                headerName: "WIDTH",
                field: "needByDate",
                headerTooltip: "WIDTH",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "HEIGHT",
                field: "needByDate",
                headerTooltip: "HEIGHT",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "HZ GOODS",
                field: "needByDate",
                headerTooltip: "HZ GOODS",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "PICK-UP LOCATION",
                field: "needByDate",
                headerTooltip: "PICK-UP LOCATION",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "PICK-UP ZIPCODE",
                field: "needByDate",
                headerTooltip: "PICK-UP ZIPCODE",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "DROP-OFF LOCATION",
                field: "needByDate",
                headerTooltip: "DROP-OFF LOCATION",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "DROP-OFF ZIPCODE",
                field: "needByDate",
                headerTooltip: "DROP-OFF ZIPCODE",
                tooltipField: "needByDate",
                hide: false,
            },

        ])
        console.log(acceptedPoColumnDefs, "change state")
        axios.get(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/getAcceptedPOlines', config).then((res: any) => {
            setLoad(false)
            console.log(acceptedPoColumnDefs)
            let data: any = gridRef
            if (res.data.status === true) {
                res.data.data.filter((item: any) => {
                    if (item.packageInfo != undefined) {
                        return item.isSelect = true
                    }
                })
                setAcceptedPoRowData(res.data.data)
            }
        })
    }
    const getRejectedLines = () => {
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        setLoad(true)
        setAcceptedPoColumnDefs([
            {
                field: 'selection',
                headerName: '',
                headerCheckboxSelection: false,
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <input
                            style={{
                                lineHeight: 0.5,
                                color: "blue",
                            }}
                            type="checkbox"
                            checked={params.data.isSelect}
                            onClick={() => handleSelectCheckbox(params, 'reject')}
                            className="btn btn-info"
                        />
                    );
                },
            },
            {
                headerName: "FORWARDER BOOKING #",
                field: "purchaseOrderNumber",
                headerTooltip: "FORWARDER BOOKING #",
                tooltipField: "purchaseOrderNumber",
                cellClass: "ag-left-aligned-cell",
            },
            {
                headerName: "HBL #",
                field: "customerId",
                headerTooltip: "HBL #",
                tooltipField: "customerId",
                cellClass: "ag-left-aligned-cell",
            },
            {
                headerName: "NO.OF PACKAGES",
                field: "packageInfo.noOfPackages",
                headerTooltip: "NO.OF PACKAGES",
                tooltipField: "packageInfo.noOfPackages",
                hide: false,
            },
            {
                headerName: "PACKAGE TYPE",
                field: "packageInfo.packageType",
                headerTooltip: "PACKAGE TYPE",
                tooltipField: "packageInfo.packageType",
                hide: false,
            },
            {
                headerName: "WEIGHT",
                field: "packageInfo.packageWeight",
                headerTooltip: "WEIGHT",
                tooltipField: "packageInfo.packageWeight",
                hide: false,
            },
            {
                headerName: "VOLUME",
                field: "packageInfo.packageWeight",
                headerTooltip: "VOLUME",
                tooltipField: "packageInfo.packageWeight",
                hide: false,
            },
            {
                headerName: "HZ GOODS",
                field: "needByDate",
                headerTooltip: "HZ GOODS",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "SHIPPER ID",
                field: "needByDate",
                headerTooltip: "SHIPPER ID",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "PICK-UP LOCATION",
                field: "needByDate",
                headerTooltip: "PICK-UP LOCATION",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "PICK-UP ZIPCODE",
                field: "needByDate",
                headerTooltip: "PICK-UP ZIPCODE",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "CONSIGNEE ID",
                field: "needByDate",
                headerTooltip: "CONSIGNEE ID",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "DROP-OFF LOCATION",
                field: "needByDate",
                headerTooltip: "DROP-OFF LOCATION",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "DROP-OFF ZIPCODE",
                field: "needByDate",
                headerTooltip: "DROP-OFF ZIPCODE",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "REJECTED BY",
                field: "needByDate",
                headerTooltip: "REJECTED BY",
                tooltipField: "needByDate",
                hide: false,
            },
            {
                headerName: "REMARKS",
                field: "needByDate",
                headerTooltip: "REMARKS",
                tooltipField: "needByDate",
                hide: false,
            },

        ])
        axios.get(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/getRejectedBRlines', config).then((res: any) => {
            setLoad(false)
            if (res.data.status === true) {
                setRejectedBrRowData(res.data.data)
            }
        })
    }
    const createBooking = () => {
        const selectedRowData: any = acceptedPoRowData.filter((row: any) => row.isSelect).map((row: any) => row._id)
        console.log(selectedRowData, "resetrowdata")
        setSelectedRowData(selectedRowData)
        setShowList(false)
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }
    const handleDialogClose = () => {
        console.log("close")
        setPackagesInfo(false)
        const updatedRowData: any = acceptedPoRowData.map((row: any) => {
            console.log(row, "rowww")
            if (row.packageInfo === undefined) {
                return { ...row, isSelect: false };
            } else if (row.packageInfo != undefined) {
                return { ...row, isSelect: true };
            }
        });
        setAcceptedPoRowData(updatedRowData)
        console.log(updatedRowData, "close data")

    }
    const savePackagesInfo = (data: any) => {
        let data1: any = packagesData;
        console.log(data1, "dataa11")
        config.params = {
            filterOn: 'CUSTOM',
            fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
            toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        let payload = {
            "_id": data1._id,
            "uuid": data1.uuid,
            "purchaseOrderNumber": data1.purchaseOrderNumber,
            "invoiceNo": data.additionalPackageInfo.invoiceNo,
            "noOfPackages": data.additionalPackageInfo.noOfPackages,
            "packageType": data.additionalPackageInfo.packageType,
            "packageWeight": data.additionalPackageInfo.packageWeight,
            "hzCode": data.additionalPackageInfo.hzCode,
            "length": data.additionalPackageInfo.length,
            "width": data.additionalPackageInfo.width,
            "height": data.additionalPackageInfo.height,
            "specialInstructions": data.additionalPackageInfo.specialInstructions,
            "hscode": data.additionalPackageInfo.hscode,
            "hscodeDescription": data.additionalPackageInfo.hscodeDescription,
            "batchNumber": data.additionalPackageInfo.batchNumber,
            "lotNumber": data.additionalPackageInfo.lotNumber
        }
        console.log(payload, "payload")
        axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/addDraftPackageInfoForPO', payload, config).then((res: any) => {
            console.log(res, ":resp")
            setPackagesInfo(false)
            if (res.data.status === true) {
                swal('Success', res.data.message, 'success')
            }
            getAcceptedLines()
        }).catch((error) => {
            setPackagesInfo(false)
            console.log(" response error " + JSON.stringify(error));
            return error;
        });
    }
    const handleChangeHzCode = (event: SelectChangeEvent) => { }
    const resetBooking = () => {
        const resetRowData: any = acceptedPoRowData.filter((row: any) => row.isSelect).map((row: any) => row._id)
        console.log(resetRowData, "resetrowdata")
        let payload = {
            'ids': resetRowData
        }
        axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/deleteDraftPackageInfoForPO', payload, config).then(async (res: any) => {
            console.log("respppp", res)
            if (res.data.status === true) {
                await swal('Success', res.data.message, 'success')
                getAcceptedLines()
            }
        })
    }
    const handleCallback = (childData: any) => {
        setShowList(true)
        setActiveStep(0)
        getAcceptedLines()
        setPackagesInfo(true)
        setpackagesData(childData.data)
        console.log(childData, "childData")
        setValue(`additionalPackageInfo`, childData.data.packageInfo)
        setActiveStep(1)
    }
    const isStepOptional = (step: number) => {
        return step === 1;
    };
    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = (step: any) => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (step === 1) {
            getAcceptedLines();
        }
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const resetPackageInfo = () => {
        resetField('additionalPackageInfo')
    }
    const onGridReady = useCallback((params: GridReadyEvent) => {
    }, []);
    const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
        params.columnApi.autoSizeAllColumns(false);
    }, []);
    useEffect(() => {
        getAcceptedLines();
    }, []);
    return (
        <div>
            {activeStep === 0 ?
                <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChange}
                            value={selectedValue}
                        >
                            <FormControlLabel value="accept" control={<Radio />} label="Pending Bookings" />
                            <FormControlLabel value="reject" control={<Radio />} label="Rejected Bookings" />
                        </RadioGroup>
                    </FormControl>
                </Box> : ''}
            <Box sx={{ width: '100%', mt: activeStep === 0 ? 3 : 1 }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} >
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button type="submit" onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 ?
                            <Box sx={{ mt: 2 }}>
                                {selectedValue === 'accept' ?
                                    <Box>
                                        {acceptedPoRowData.length > 0 ?
                                            <AgGridReact className="ag-theme-balham"
                                                ref={gridRef}
                                                animateRows={true}
                                                rowSelection={"multiple"}
                                                defaultColDef={defaultColDef}
                                                rowData={acceptedPoRowData}
                                                domLayout={"autoHeight"}
                                                columnDefs={acceptedPoColumnDefs}
                                                sideBar={sideBar}
                                                pagination={true}
                                                paginationPageSize={10}
                                                onGridReady={onGridReady}
                                                suppressRowClickSelection={true}
                                                onFirstDataRendered={onFirstDataRendered}
                                            ></AgGridReact> :
                                            <Alert severity="info">
                                                <AlertTitle>Info</AlertTitle>
                                                No data found
                                            </Alert>
                                        }
                                    </Box>
                                    :
                                    <Box>
                                        {rejectedBrRowData.length > 0 ?
                                            <AgGridReact className="ag-theme-balham"
                                                ref={gridRef}
                                                animateRows={true}
                                                rowSelection={"multiple"}
                                                defaultColDef={defaultColDef}
                                                rowData={rejectedBrRowData}
                                                domLayout={"autoHeight"}
                                                columnDefs={acceptedPoColumnDefs}
                                                sideBar={sideBar}
                                                pagination={true}
                                                paginationPageSize={10}
                                                onGridReady={onGridReady}
                                                suppressRowClickSelection={true}
                                                onFirstDataRendered={onFirstDataRendered}
                                            ></AgGridReact> :
                                            <Alert severity="info">
                                                <AlertTitle>Info</AlertTitle>
                                                No data found
                                            </Alert>
                                        }
                                    </Box>
                                }
                            </Box> : ''
                            //  activeStep === 1 ?
                            //     <Box sx={{ mt: 2 }}>
                            //         {/* <FinalizeBookingRequest finalize='true' step='viewBooking' selectedData={selectedRowData} parentCallback={handleCallback} /> */}
                            //     </Box> :
                            //     <Box sx={{ mt: 2 }}>
                            //         {/* <FinalizeBookingRequest finalize='true' step='finalizeBooking' parentCallback={handleCallback} /> */}
                            //     </Box>
                        }
                        {acceptedPoRowData.length > 0 || rejectedBrRowData.length > 0 ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={() => handleBack(activeStep)}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {activeStep === 0 ?
                                <Box>
                                    <LoadingButton
                                        loading={false}
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                        onClick={(event) => createBooking()}
                                    >
                                        Create Booking
                                    </LoadingButton>
                                    &nbsp;&nbsp;
                                    <LoadingButton
                                        loading={false}
                                        variant="contained"
                                        type="submit"
                                        className="submitbtn"
                                        sx={{

                                            fontWeight: 500,
                                            backgroundColor: "#555a64",
                                            color: "#fff",
                                            "& .MuiButtonBase-root:hover": {
                                                backgroundColor: "#555a64",
                                                color: "#fff",
                                            },
                                        }}
                                        onClick={(event) => resetBooking()}
                                    >
                                        Reset
                                    </LoadingButton>
                                </Box> : activeStep === 1 ?
                                    <Button onClick={handleNext}>
                                        Next
                                    </Button> : ''
                            }

                        </Box>:''}
                    </React.Fragment>
                )}
            </Box>
            <Dialog open={packagesInfo} onClose={handleDialogClose} sx={{
                backgroundColor: "none",
                "& .MuiDialog-root": {},
                zIndex: 10000,
                minWidth: "lg",
            }} fullScreen TransitionComponent={Transition}>
                <form onSubmit={handleSubmit((data) => {
                    savePackagesInfo(data)
                })}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ float: 'left' }}>PACKAGE INFO</Typography>
                            <Button autoFocus color="inherit" sx={{ marginLeft: "auto" }} onClick={handleDialogClose}>
                                close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Invoice #</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.invoiceNo`, {
                                            required: "Invoice # is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Invoice #"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>HS Code</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.hscode`, {
                                            required: "Hscode is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Hscode"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>HS Code Description</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.hscodeDescription`, {
                                            required: "Hscode Description is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Hscode Description"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Batch #</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.batchNumber`, {
                                            required: "Batch # is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Batch #"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Lot #</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.lotNumber`, {
                                            required: "Lot # is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Lot #"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>No.of Packages</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.noOfPackages`, {
                                            required: "No.of Packages is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="No.of Packages"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Package Type</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.packageType`, {
                                            required: "Package Type is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Package Type"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Package Weight</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.packageWeight`, {
                                            required: "Package Weight is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Package Weight"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>HZ Goods</Typography>
                                <FormControl fullWidth size="small">
                                    {/* <InputLabel id="demo-simple-select-label">HZ Goods</InputLabel> */}
                                    {/* <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        {...register(`additionalPackageInfo.hzCode`, {
                                            required: "HZGoods is required",
                                        })}
                                        onChange={() => handleChangeHzCode}
                                        placeholder="Forwarder"
                                        sx={{ fontSize: '14px' }}
                                        label="HzCode"
                                        MenuProps={{ disableScrollLock: true }}
                                        disabled={isDisablePackageInfo}
                                    >
                                        <MenuItem value='Yes'>Yes</MenuItem>
                                        <MenuItem value='No'>No</MenuItem>
                                    </Select> */}
                                    <select
                                        className='custom_select'
                                        placeholder='HZ Goods'
                                        {...register(`additionalPackageInfo.hzCode`, {
                                            required: "HZGoods is required",
                                        })}
                                        onChange={(e: any) => handleChangeHzCode(e)}
                                    >
                                        <option value="">Select HZ Goods</option>
                                        <option value="Yes">Yes</option>
                                        <option value='No'>No</option>


                                    </select>
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Length</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.length`, {
                                            required: "Length is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Length"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Width</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.width`, {
                                            required: "Width is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Width"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Height</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.height`, {
                                            required: "Width is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Height"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={4}>
                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Special Instructions</Typography>
                                <FormControl fullWidth size="small">
                                    <TextField
                                        {...register(`additionalPackageInfo.specialInstructions`, {
                                            required: "Special Instructions is required",
                                        })}
                                        id="component-outlined"
                                        placeholder="Special Instructions"
                                        type="text"
                                        sx={{
                                            width: "100%",
                                        }}
                                        size='small'
                                        disabled={isDisablePackageInfo}
                                    />
                                    {/* <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span> */}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{
                        display: 'flex', ml: 2, textAlign: 'center',
                        margin: '0 auto', justifyContent: 'center'
                    }}>
                        <LoadingButton
                            loading={false}
                            variant="contained"
                            type="submit"
                            sx={{
                                fontWeight: 500,
                            }}

                        >
                            Save
                        </LoadingButton>
                        <Button type="submit" variant='contained' color="inherit" onClick={resetPackageInfo}>Reset</Button>

                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

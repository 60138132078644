import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
// import swal from "sweetalert";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import Input from "@mui/material/Input";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { DropzoneDialog } from "mui-file-dropzone";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import swal from "sweetalert";
import { useOutletContext } from "react-router-dom";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { ApiRequestFunction } from "../../../Services/BaseService";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function InTransitEventFileupload() {
  const [rowData, setRowData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const domLayout = "autoHeight";
  const gridRef = useRef<any>();
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const FormRef = useRef<any>();
  const [validationPoNumber, setValidationPoNumber] = useState();
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState(false);
  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceId",
      field: "invoiceId",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green" }}
              />{''}
              {params.data.preCheckValidationMessage[0]}
            </p>
          );
        } else if (params.value.length > 1) {
          return (
            <Button
              className="upload-error"
              sx={{ p: 0, fontSize: "12px" }}
              onClick={() =>
                alerts(params.value, params.data.purchaseOrderNumber)
              }
            >
              <CancelIcon
                sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
              />{" "}
              1 or more Validations
            </Button>
          );
        } else if (params.value.length === 1) {
          return (
            <Button
              startIcon={<CheckCircleIcon sx={{ color: "green" }} />}
              onClick={() =>
                alerts(params.value, params.data.purchaseOrderNumber)
              }
            ></Button>
          );
        } else {
          return <p>
            <CheckCircleIcon
              sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
            />{" "}
            NO VALIDATION
          </p>;
        }
      },
    },
    {
      headerName: "HBL #",
      field: "HBLId",
      headerTooltip: "HBL #",
      tooltipField: "HBLId",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "MBL #",
      field: "MBLId",
      headerTooltip: "MBL #",
      tooltipField: "MBLId",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "HS CODE",
      field: "hscode",
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "FORWARDER REFERENCE #",
      headerTooltip: "FORWARDER REFERENCE #",
      tooltipField: "bookingReferenceId",
      field: "bookingReferenceId",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReferenceId",
      headerTooltip: "CARRIER REFERENCE #",
      tooltipField: "carrierReferenceId",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "CARRIER ID",
      field: "carrierID",
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierID",
    },
    {
      headerName: "CARRIER",
      field: "carrierName",
      headerTooltip: "CARRIER",
      tooltipField: "carrierName",
    },
    {
      headerName: "CONTAINER #",
      field: "containerId",
      headerTooltip: "CONTAINER #",
      tooltipField: "containerId",
    },
    
    {
      headerName: "VESSEL ID",
      field: "vesselID",
      headerTooltip: "VESSEL ID",
      tooltipField: "vesselID",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "VESSEL NAME",
      field: "vesselName",
      headerTooltip: "VESSEL NAME",
      tooltipField: "vesselName",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "VOYAGE ID",
      field: "voyageId",
      headerTooltip: "VOYAGE ID",
      tooltipField: "voyageId",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },
    {
      headerName: "SHIPPER ID",
      field: "shipperID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperID",
    },
    {
      headerName: "SHIPPER",
      field: "shipperName",
      headerTooltip: "SHIPPER",
      tooltipField: "shipperName",
    },
    {
      headerName: "CONSIGNEE ID",
      field: "consigneeID",
      headerTooltip: "CONSIGNEE ID",
      tooltipField: "consigneeID",
    },
    {
      headerName: "CONSIGNEE",
      field: "consigneeName",
      headerTooltip: "CONSIGNEE",
      tooltipField: "consigneeName",
    },
    {
      headerName: "SHIPPEMENT EVENT NAME",
      field: "shipmentEventName",
      headerTooltip: "SHIPPEMENT EVENT NAME",
      tooltipField: "shipmentEventName",
    },
    {
      headerName: "ORIGIN",
      field: "origin",
      headerTooltip: "ORIGIN",
      tooltipField: "origin",
    },
    {
      headerName: "DESTINATION",
      field: "destination",
      headerTooltip: "DESTINATION",
      tooltipField: "destination",
    },
    {
      headerName: "UPLOADED BY",
      field: "uploadedBy",
      headerTooltip: "UPLOADED BY",
      tooltipField: "uploadedBy",
    },
    {
      headerName: "REMARKS",
      field: "remarks",
      headerTooltip: "REMARKS",
      tooltipField: "remarks",
    },
    // {
    //   headerName: "INVOICE DATE",
    //   field: "invoiceDate",
    //   filter: true,
    //   headerTooltip: "INVOICE DATE",
    //   tooltipField: "invoiceDate",
    // },
    // {
    //   headerName: "PURCHASE ORDER #",
    //   field: "purchaseOrderNumber",
    //   filter: "true",
    //   headerTooltip: "PURCHASE ORDER #",
    //   tooltipField: "purchaseOrderNumber",
    // },
    // {
    //   headerName: "MBL #",
    //   field: "mblNumber",
    //   filter: true,
    //   headerTooltip: "MBL #",
    //   tooltipField: "mblNumber",
    // },

    // {
    //   headerName: "COMMODITY TYPE",
    //   filter: true,
    //   headerTooltip: "COMMODITY TYPE",
    //   tooltipField: "commodityType",
    //   field: "commodityType",
    // },
    // {
    //   headerName: "ITEM NAME",
    //   field: "itemName",
    //   filter: true,
    //   headerTooltip: "PACKAGE TYPE",
    //   tooltipField: "itemName",
    // },
    // {
    //   headerName: "ITEM DESCRIPTION",
    //   field: "itemDescription",
    //   filter: true,
    //   headerTooltip: "PACKAGE TYPE",
    //   tooltipField: "itemDescription",
    // },

    // {
    //   headerName: "HS CODE",
    //   field: "hscode",
    //   filter: true,
    //   headerTooltip: "HS CODE",
    //   tooltipField: "hscode",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "HS CODE DESCRIPTION",
    //   field: "hscodeDescription",
    //   filter: true,
    //   headerTooltip: "HS CODE DESCRIPTION",
    //   tooltipField: "hscodeDescription",
    // },
    // {
    //   headerName: "SHIPMENT VOLUME",
    //   filter: true,
    //   headerTooltip: "SHIPMENT VOLUME",
    //   field: "shipmentVolume",
    //   tooltipField: "shipmentVolume",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "SHIPMENT WEIGHT",
    //   filter: true,
    //   headerTooltip: "SHIPMENT WEIGHT",
    //   field: "shipmentWeight",
    //   tooltipField: "shipmentWeight",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "UOM",
    //   field: "Uom",
    //   filter: true,
    //   headerTooltip: "UOM",
    //   tooltipField: "Uom",
    // },

    // // {
    // //   headerName: "FORWARDER BOOKING REFERENCE DATE",
    // //   field: "invoiceDate",
    // //   filter: true,
    // //   headerTooltip: "FORWARDER BOOKING REFERENCE DATE",
    // //   tooltipField: "invoiceDate",
    // //   cellClass: "ag-left-aligned-cell",
    // // },
    // {
    //   headerName: "CONTAINER NUMBER",
    //   field: "containerNumber",
    //   filter: true,
    //   headerTooltip: "CONTAINER NUMBER",
    //   tooltipField: "containerNumber",
    // },
    // {
    //   headerName: "CONTAINER TYPE",
    //   filter: true,
    //   headerTooltip: "CONTAINER TYPE",
    //   tooltipField: "containerType",
    //   field: "containerType",
    //   columnGroupShow: "closed",
    // },

    // {
    //   headerName: "EMPTY CONTAINER PICKUP DEPOT",
    //   filter: true,
    //   headerTooltip: "CONTAINER PICKUP DEPOT",
    //   tooltipField: "containerPickUpDepot",
    //   field: "containerPickUpDepot",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "CONTAINER SIZE",
    //   filter: true,
    //   headerTooltip: "CONTAINER SIZE",
    //   tooltipField: "containerSize",
    //   field: "containerSize",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "CONTAINER TARE WEIGHT",
    //   filter: true,
    //   headerTooltip: "CONTAINER TARE WEIGHT",
    //   tooltipField: "containerTareWeight",
    //   field: "containerTareWeight",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },

    // // {
    // //     headerName: "INVOICE DATE",
    // //     field: "invoiceDate",
    // //     filter: true,
    // //     headerTooltip: "INVOICE DATE",
    // //     tooltipField: "invoiceDate",
    // //   },

    // {
    //   headerName: "CARRIER REFERENCE",
    //   field: "carrierReference",
    //   filter: true,
    //   headerTooltip: "CARRIER REFERENCE",
    //   tooltipField: "carrierReference",

    //   //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    // },
    // {
    //   headerName: "CARRIER ID",
    //   field: "carrierId",
    //   filter: true,
    //   headerTooltip: "CARRIER ID",
    //   tooltipField: "carrierId",

    //   //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    // },
    // {
    //   headerName: "CARRIER NAME",
    //   field: "carrierName",
    //   filter: true,
    //   headerTooltip: "CARRIER NAME",
    //   tooltipField: "carrierName",

    //   //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    // },
    // {
    //   headerName: "SHIPPER ID",
    //   field: "shipperID",
    //   filter: true,
    //   headerTooltip: "SHIPPER ID",
    //   tooltipField: "shipperID",
    // },
    // {
    //   headerName: "SHIPPER NAME",
    //   field: "shipperName",
    //   filter: true,
    //   headerTooltip: "SHIPPER NAME",
    //   tooltipField: "shipperName",
    // },
    // {
    //   headerName: "PICK UP LOCATION",
    //   field: "pickupLocation",
    //   filter: true,
    //   headerTooltip: "PICK UP LOCATION",
    //   tooltipField: "pickupLocation",
    // },

    // {
    //   headerName: "PICKUP DATE",
    //   filter: true,
    //   headerTooltip: "PICKUP DATE",
    //   tooltipField: "pickUpDate",
    //   field: "pickUpDate",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "PICKUP COUNTRY",
    //   filter: true,
    //   headerTooltip: "PICKUP COUNTRY",
    //   tooltipField: "pickupCountry",
    //   field: "pickupCountry",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "PICKUP ZIPCODE",
    //   filter: true,
    //   headerTooltip: "PICKUP ZIPCODE",
    //   tooltipField: "pickupZipCode",
    //   field: "pickupZipCode",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "CONSIGNEE ID",
    //   filter: true,
    //   headerTooltip: "CONSIGNEE ID",
    //   field: "consigneeId",
    //   tooltipField: "consigneeId",
    // },
    // {
    //   headerName: "CONSIGNEE NAME",
    //   filter: true,
    //   headerTooltip: "CONSIGNEE NAME",
    //   field: "consigneeName",
    //   tooltipField: "consigneeName",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "DROP OFF LOCATION",
    //   field: "dropoffLocation",
    //   filter: true,
    //   headerTooltip: "DROPP OFF LOCATION",
    //   tooltipField: "dropoffLocation",
    // },
    // {
    //   headerName: "DROP OFF COUNTRY",
    //   field: "dropoffCountry",
    //   filter: true,
    //   headerTooltip: "DROP OFF COUNTRY",
    //   tooltipField: "dropoffCountry",
    // },
    // {
    //   headerName: "DROP OFF ZIPCODE",
    //   field: "dropoffZipCode",
    //   filter: true,
    //   headerTooltip: "DROP OFF ZIPCODE",
    //   tooltipField: "dropoffZipCode",
    // },

    // // {
    // //   headerName: "FORWARDER ID",
    // //   filter: true,
    // //   headerTooltip: "FORWARDER ID",
    // //   tooltipField: "forwarderId",
    // //   field: "forwarderId",
    // // },
    // // {
    // //   headerName: "FORWARDER NAME",
    // //   filter: true,
    // //   headerTooltip: "FORWARDER NAME",
    // //   tooltipField: "forwarderName",
    // //   field: "forwarderName",
    // //   columnGroupShow: "closed",
    // // },

    // {
    //   headerName: "FORWARDER BOOKING REFERENCE NUMBER",
    //   filter: true,
    //   headerTooltip: " FORWARDER BOOKING REFERENCE NUMBER",
    //   tooltipField: "bookingReferenceId",
    //   field: "bookingReferenceId",
    // },
    // {
    //   headerName: "CARRIER ID",
    //   filter: true,
    //   headerTooltip: "CARRIER ID",
    //   tooltipField: "carrierID",
    //   field: "carrierID",
    // },
    // {
    //   headerName: "CARRIER NAME",
    //   filter: true,
    //   headerTooltip: "CARRIER NAME",
    //   tooltipField: "carrierName",
    //   field: "carrierName",
    //   columnGroupShow: "closed",
    // },

    // {
    //   headerName: "CARRIER BOOKING REFERENCE NUMBER",
    //   field: "carrierReferenceId",
    //   filter: true,
    //   headerTooltip: "CARRIER BOOKING REFERENCE NUMBER",
    //   tooltipField: "carrierReferenceId",

    //   //   cellRenderer: (params: any) => <Button>{params.value}</Button>,
    // },

    // {
    //   headerName: "INCO TERMS",
    //   filter: true,
    //   headerTooltip: "INCO TERMS",
    //   tooltipField: "incoterms",
    //   field: "incoterms",
    // },

    // {
    //   headerName: "MODE OF SHIPMENT",
    //   filter: true,
    //   headerTooltip: "MODE OF SHIPMENT",
    //   tooltipField: "modeOfShipment",
    //   field: "modeOfShipment",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "ETD",
    //   filter: true,
    //   headerTooltip: "ETD",
    //   tooltipField: "etd",
    //   field: "etd",
    //   cellClass: "ag-left-aligned-cell",
    // },

    // {
    //   headerName: "ETA",
    //   filter: true,
    //   headerTooltip: "ETA",
    //   tooltipField: "eta",
    //   field: "eta",
    //   cellClass: "ag-left-aligned-cell",
    // },
  ]);
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        flex: 1,
        columnDefs: [
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationStatus",
            cellRenderer: (params: ICellRendererParams) => {
              if (params.value === true) {
                return <p className="status">TRUE</p>;
              } else {
                setDisable(false);
                return <p className="rstatus">FALSE</p>;
              }
            },
          },
          {
            headerName: "VALIDATION MESSAGE",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION MESSAGE",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              if (params.value.length > 1) {
                return (
                  <Button
                    className="upload-error"
                    sx={{ p: 0, fontSize: "12px" }}
                    onClick={() =>
                      alerts(params.value, params.data.purchaseOrderNumber)
                    }
                  >
                    <CancelIcon
                      sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                    />{" "}
                    1 or more Validations
                  </Button>
                );
              } else if (params.value.length === 1) {
                return (
                  <Button
                    onClick={() =>
                      alerts(params.value, params.data.purchaseOrderNumber)
                    }
                  >
                    {params.value[0]}
                  </Button>
                );
              } else {
                return <p>
                  <CheckCircleIcon
                    sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                  />{" "}
                  NO VALIDATION
                </p>;
              }
            },
          },
          {
            headerName: "COMMODITY TYPE",
            field: "commodityType",
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "commodityType",
          },

          {
            headerName: "HS CODE",
            field: "hscode",
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hscodeDescription",
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscodeDescription",
          },
          {
            headerName: "NUMBER OF PACKAGES",
            field: "numberofPackages",
            headerTooltip: "NUMBER OF PACKAGES",
            tooltipField: "number of packages",
          },
          {
            headerName: "PACKAGE TYPE",
            field: "type",
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packagetype",
          },
          {
            headerName: "PACKAGE WEIGHT",
            field: "weight",
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageweight",
          },
          {
            headerName: "PACKAGE LENGTH",
            field: "length",
            headerTooltip: "PACKAGE LENGTH",
            tooltipField: "packagelength",
          },
          {
            headerName: "PACKAGE WIDTH",
            field: "width",
            headerTooltip: "PACKAGE WIDTH",
            tooltipField: "packagewidth",
          },
          {
            headerName: "PACKAGE HEIGHT",
            field: "height",
            headerTooltip: "PACKAGE HEIGHT",
            tooltipField: "packageheight",
          },
          {
            headerName: "PACKAGE SPECIAL INSTRUCTIONS",
            field: "specialInstructions",
            headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
            tooltipField: "packagespecialinstructions",
          },
          {
            headerName: "HZ GOODS",
            field: "hazardousGoods",
            headerTooltip: "hazardousGoods",
            tooltipField: "HZ GOODS",
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);

        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let Excel_Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-date",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }
  useEffect(() => {
    setLoad(false)
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column: any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column: any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, format])
  // ----------------- events upload preview --------------------
  const Preview = () => {
    if (file === undefined) {
      toast.warning("Please Select a File...");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    setLoad(true)
    axios.post(Endpoints.InTransit_Events_Upload_Preview, formData, Excel_Api_Config).then((res: any) => {
      setLoad(false)
      setPreviewData(res.data);
      setRowData(res.data.data);
      res.data.data.map((disables: any) => {
        if (disables.preCheckValidationStatus === false) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      }).catch((e: any) => {
        setLoad(false);
      });
    });
    setShowFile(true);
  };
  const closeAlert = () => setAlertOpen(false);
  // --------------- events submit -------------------
  const SubmitFile = () => {
    let submitPayload: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios.post(Endpoints.InTransit_Events_Submit, submitPayload, Api_Config).then((res: any) => {
      console.log(res.data.status);
      if (res.data.status === true) {
        swal("Success", res.data.msg, "success");
      }
    })
      .catch((err: any) => {
        if (err.response.data.status === false) {
          setShowAlert(true);
          setStatus(err.response.data.status);
          setMessage(err.response.data.msg);
          swal("Error", err.response.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    FormRef.current.reset();
    setExcelName("Selcet File");
  };
  // -------------------- reset data ------------------
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    FormRef.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  // ----------------- shipment type changes ---------------------
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    setPreviewData([]);
    setRowData([]);
    setShowFile(false)
    FormRef.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  function handleAlert() {
    setShowAlert(false);
  }
  // ------------ file validation ------------------------------
  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={shipmentModeHandleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/IntransitEventsFormat.xlsx"}
            download={"IntransitEventsFormat.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={FormRef}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              color="warning"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              // detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              // masterDetail={masterDetail}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showAlert} onClose={handleAlert}>
          <Box sx={{ padding: "25px", borderRadius: "5px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {/* <img
              src={require("../asserts/sucess.png")}
              alt="sucess Image"
              width={60}
              height={60}
            /> */}
            </Box>
            <Typography sx={{ marginBottom: "15px" }}>{message}</Typography>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="success" onClick={handleAlert}>
                Ok
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Paper>
    </div>
  );
}

export default InTransitEventFileupload;

import { Box, Divider, FormControl, Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export default function DigitalThread() {
    const dummyData = [
        {
            title: 'Po Details'
        },
        {
            title: 'Invoice Details'
        },
        {
            title: 'Forwarder Details'
        },
        {
            title: 'HBL #'
        },
        {
            title: 'Carrier Details'
        },
        {
            title: 'MBL #'
        },
        {
            title: 'Container #'
        },
        {
            title: 'InTransit Events'
        },
        {
            title: 'Delivery Status'
        }
    ]
    const [isTooltipShow, setIsTooltipShow] = useState(false)
    const toolTipClick = () => {
        setIsTooltipShow(true)
    }
    return (
        <div>
            <Box>
                <Typography className='shipperView_header'>SHIPPER VIEW</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={3} md={4}>
                        <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Search By</Typography>
                        <FormControl fullWidth size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                placeholder="Partner Type"
                                sx={{ fontSize: '14px' }}
                                MenuProps={{ disableScrollLock: true }}

                            >
                                <MenuItem value='Serial'>Serial</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={3} md={4}>
                        <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Search By</Typography>
                        <FormControl fullWidth size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                placeholder="Partner Type"
                                sx={{ fontSize: '14px' }}
                                MenuProps={{ disableScrollLock: true }}

                            >
                                <MenuItem value='Serial'>Serial</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography className='shipperView_header'>INVOICE DETAILS</Typography>
                <Box className="shipper_subHeader">
                    <Box>
                        <Typography className='custome_text'>QR CODE</Typography>
                    </Box>
                    <Box>
                        <Typography className='custome_text'>SHIPPER DETAILS</Typography>
                        <Box sx={{ mr: 5 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Shipper Id
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    shipper001
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Shipper Name
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    BMW India
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Site Location
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    PDC,Mumbai
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Box>
                                <Typography className='custome_text'>PORT OF ORIGIN</Typography>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: "14px" }}>Mumbai Port</Typography>
                                    <LocationOnIcon
                                        fontSize="large"
                                        sx={{ color: "#1081e0", width: "1.5em", height: "1.5em" }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ mt: 'auto' }}>
                                <ArrowRightAltIcon sx={{ width: "2em", height: "2em" }} />
                            </Box>
                            <Box>
                                <Typography className='custome_text'>PORT OF DESTINATION</Typography>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: "14px" }}>Hamburg Port</Typography>
                                    <LocationOnIcon
                                        fontSize="large"
                                        sx={{ color: '#12be9e', width: "1.5em", height: "1.5em" }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography className='custome_text'>CONSIGNEE DETAILS</Typography>
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Consignee Id
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    consignee001
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Consignee Name
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    BMW India
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Site Location
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    PDC,Mumbai
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography className='custome_text'>CURRENT STATUS</Typography>
                        <Box>
                            <Typography sx={{ fontSize: "14px" }}>Hamburg Port (Location)</Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                                    Discharge from vessel
                                </Typography>
                                <Typography sx={{ fontSize: "14px", ml: 2 }}>
                                    09, May 2023 16:17:47
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box sx={{ mt: 4, mx: 2, display: 'flex', justifyContent: 'space-around' }}>
                {dummyData.map((item, index) => (
                    <div className='digital_steps'>
                        <div className='custom_steps'>
                            <div className='custome_step_circle'>
                            </div>
                        </div>
                        <div className='steps_border'></div>
                        <div className='steps_vertical_border'></div>
                        <div className="triangle_down">
                            <ArrowDropDownIcon fontSize='large' sx={{ color: '#676767',cursor:'pointer' }} />

                        </div>
                    </div>
                ))}
            </Box>
            <Box sx={{ mt: 5, display: 'flex', textAlign:'center',cursor:'pointer', justifyContent: 'space-between' }}>
                {dummyData.map((item: any, index) => (
                    <div>
                        <Typography sx={{ fontSize: '12px', cursor: 'pointer',mt:1 }} className='toolTip' onClick={() => toolTipClick()}>{item.title}</Typography>
                    </div>
                ))}
            </Box>
            {isTooltipShow ?
                <Box sx={{ mt: 2, visibility: isTooltipShow ? 'visible': 'hidden'  }} className="popuptext" >
                    More Info
                </Box> : ''}

        </div>
    )
}

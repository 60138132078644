import axios from "axios";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import swal from "sweetalert";
var token = sessionStorage.getItem('token');
export function PostRequest(url: any, params: any) {
  console.log(params);
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  };
  axios
    .post(url, params, { headers })
    .then((res) => {
      console.log("131313131313", res);
      swal("Success", res.data.status.messsage, "success");
      return res.data;
    })
    .catch((error) => {
      console.log(" response error " + JSON.stringify(error));
      return error;
    });
}
export const getRequest = (url: any, params: any) => {
  console.log("Requested Url", url);
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: params,
  };
  console.log(config);
  axios
    .get(url, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("response Error");
    });
};
export const ApiRequestFunction = {
  Api_Options: {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    fbrStatus: "open",
    brStatus: "open",
    fromDate: '',
    toDate: '',
  },
  Api_Config: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: {}
  },
  Excel_Api_Config: {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-date",
      Authorization: `Bearer ${token}`,
    },

  }

}
async function getToken() {
  token = await sessionStorage.getItem('token');
  return token;
}
export const getRequestHeaders = async () => {
  let token1 = await getToken();
  return {
    Api_Config: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token1}`,
      },
      params: {}
    },
  }

}


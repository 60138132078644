import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, Paper, Tab, Tabs, TextField, Typography, Alert,
    AlertTitle,
    Select,
    MenuItem
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { Endpoints } from '../../../Enviornment/Endpoints';
import { ApiRequestFunction } from '../../../Services/BaseService';
export default function PartnerOnboarding() {
    // -------------- useForm initialize -------
    const { register, resetField, reset, setValue, getValues, formState: { errors }, control, handleSubmit, } = useForm({
        defaultValues: {
            partnerInfo: {
                partnerType: '',
                partnerId: '',
                partnerName: '',
                deliverySites: 1,
                siteInfo: [
                    {
                        siteId: "",
                        siteName: "",
                        siteType: "",
                        country: "",
                        mobile: "",
                        siteEmail: "",
                        pinCode: "",
                        address: "",
                        // partnerAssociation: [{
                        //     name: '',
                        //     email: ''
                        // }]
                    },
                ],
            },
        },
    });
    //  ---------------- useForm FieldArray --------------------
    const {
        fields: parameterfields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "partnerInfo.siteInfo"
    });
    const [loader, setLoader] = useState(false);
    const [values, setValues] = React.useState('1');
    const [isPartnerAssociate, setIsPartnerAssociate] = useState(false)
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const domLayout = "autoHeight";
    const [rowData, setRowData] = useState([]);
    const [showgrid, setShowGrid] = useState(true);
    const [selectedPartners, setSelectedPartners] = useState<any>([])
    const [partnertype, setPartnerType] = useState<any>('')
    const [partnerTypeList, setPartnerTypeList] = useState<any>([])
    const [siteType, setSiteType] = useState('')
    const [status, setStatus] = useState('create')
    const [isButtonShow, setIsButtonShow] = useState(false)
    const [countrysList, setCountrysList] = useState([])
    // const [partnerAssociatesList,setPartnerAssociatesList] = useState([])
    // const [partnerAssociate, setpartnerAssociates] = useState<any>(partnerAssociatesList)
    let Api_Config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
    }

    // ---------------- Tabs Changes ----------------
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValues(newValue);
        if (newValue === '2') {
            getParnersList()
        } else {
            setStatus('create')
            reset()
        }
    };

    // const clickPartnerAssociate = (index: any) => {
    //     console.log(isPartnerAssociate, "partner associate")
    //     const config = {
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //         },
    //         params: {
    //             partnerType: 'shipper'
    //         },
    //     };
    //     axios.get('https://api.ext.realtrace.realware.tech/parties/getPartnersByOppositeType', config).then((res: any) => {
    //         console.log(res, 'resppp')
    //     })
    //     setIsPartnerAssociate(true)
    // }
    // --------------- get all partner List -------------------------
    const getParnersList = () => {
        axios.get(Endpoints.Get_Partners, Api_Config).then((res: any) => {
            if (res.data.primary.length > 0) {
                setRowData(res.data.primary)
                setShowGrid(true)
            } else {
                setShowGrid(false)
            }

        })
    }
    const handleChange1 = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    // ---------------------- add multiple sites ------------------
    const addMoreSites = (index: any) => {
        let indexValue: any = index + 1
        console.log(getValues(`partnerInfo.deliverySites`), indexValue, index)
        if (getValues(`partnerInfo.deliverySites`) > indexValue) {
            append({
                siteId: "",
                siteName: "",
                siteType: "",
                country: "",
                mobile: "",
                siteEmail: "",
                pinCode: "",
                address: "",
                // partnerAssociation: [{
                //     name: '',
                //     email: ''
                // }]

            })
        } else {
            toast.warn("Registering Sites should be Less than or equal to Number of Sites ")
        }

    }
    // --------------------- delete Sites ----------------------
    const deleteSites = (index: any) => {
        remove(index)
        console.log(parameterfields.length, "iiiiiiiiiiii length")
    }

    const handleChangePartners = (item: any) => {
        if (selectedPartners.findIndex((obj: any) => obj.id === item.id) === -1) {
            item.checked = true
            console.log("Clicked Item is ", item)
            setSelectedPartners((prev: any) => [...prev, item])
        }
        else {
            let data = selectedPartners.filter((obj: any) => obj.id !== item.id)
            item.checked = false
            setSelectedPartners(data)
        }
        console.log("item is item...", item)
    }
    // ------------------ partner edit data ---------------------
    const handleEditData = (item: any) => {
        setLoader(true)
        setValues('1')
        setStatus('update')
        console.log(item.data, parameterfields.length, typeof (item.data.partnerInfo.deliverySites), "item.data")
        if (item.data.partnerInfo.deliverySites > '1') {
            setIsButtonShow(true)
        }
        setValue(`partnerInfo`, item.data.partnerInfo)
        setValue(`partnerInfo.siteInfo`, item.data.partnerInfo.siteInfo)
        setPartnerType(item.data.partnerInfo.partnerType)
        const value = getValues()
        console.log(value, "valuessss")
        setLoader(false)

    }
    // ------------------ partner delete data -----------------
    const handleDeleteData = (item: any) => {
        let object = {
            _id: item.data.partnerInfo._id
        }
        console.log(object, item.data, "delete record")
        axios.post(Endpoints.Delete_Partners, object, Api_Config).then((res: any) => {
            if (res.data.status.code === 'SUCCESS') {
                toast.success(res.data.primary)
                getParnersList()
            } else {
                toast.error(res.data.status.message)
            }
        })
    }
    // const removeAssociatePartner = (item: any) => {
    //     console.log("you have clicked on item", item)
    //     let data = selectedPartners.filter((obj: any) => obj.id !== item.id)
    //     let patdata = partnerAssociate;
    //     let patnerData = patdata.filter((obj: any) => {
    //         if (obj.id === item.id) {
    //             obj.checked = false
    //             return obj
    //         }
    //     })
    //     console.log("Partner Data is....", patnerData);
    //     // setpartnerAssociates(patnerData)
    //     setSelectedPartners(data)
    // }
    const handleChangePartnerType = (event: React.SyntheticEvent, newValue: string) => {
        console.log(newValue, "new value")
        setPartnerType(newValue)
    };
    const handleChangeSiteType = (event: React.SyntheticEvent, newValue: string) => {
        console.log(newValue, "newValue")
        setSiteType(newValue)
    }
    const handleChangeSites = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(typeof (event.target.value))
        setValue(`partnerInfo.deliverySites`, Number(event.target.value))
        if (Number(event.target.value) > 1) {
            setIsButtonShow(true)
            if (Number(event.target.value) < parameterfields.length) {
                const newSiteCount = Number(event.target.value);
                const currentSiteCount = parameterfields.length;
                if (newSiteCount > currentSiteCount) {
                    const diff = newSiteCount - currentSiteCount;
                    for (let i = 0; i < diff; i++) {
                        append({
                            siteId: "",
                            siteName: "",
                            siteType: "",
                            country: "",
                            mobile: "",
                            siteEmail: "",
                            pinCode: "",
                            address: "",
                            // partnerAssociation: [{
                            //     name: '',
                            //     email: ''
                            // }]

                        }) // Add empty field array
                    }
                } else if (newSiteCount < currentSiteCount) {
                    const diff = currentSiteCount - newSiteCount;
                    for (let i = 0; i < diff; i++) {
                        remove(currentSiteCount - 1 - i); // Remove field arrays from the end
                    }
                }
            } else {
                // append({
                //     siteId: "",
                //     siteName: "",
                //     siteType: "",
                //     country: "",
                //     mobile: "",
                //     siteEmail: "",
                //     pinCode: "",
                //     address: "",
                //     // partnerAssociation: [{
                //     //     name: '',
                //     //     email: ''
                //     // }]

                // })
                console.log(parameterfields, getValues(`partnerInfo.deliverySites`), "para")
            }

        } else {
            setIsButtonShow(false)
        }
        console.log(typeof (event.target.value), "newValuehfjdgfjkh")
    }
    // --------------- partners creations Or updation -----------------
    const createData = (item: any) => {
        if (status === 'create') {
            setLoader(true)
            axios.post(Endpoints.Party_Insertion, item, Api_Config).then((res: any) => {
                setLoader(false)
                if (res.data.status.code === 'SUCCESS') {
                    toast.success(res.data.primary);
                    resetField('partnerInfo.siteInfo')
                    resetField('partnerInfo')
                } else if (res.data.status.code === 'error') {
                    toast.error(res.data.status.message);
                }
            })
        } else if (status === 'update') {
            setLoader(true)
            axios.post(Endpoints.Partners_Update, item, Api_Config).then((res: any) => {
                console.log(res.data, "createResp")
                setLoader(false)
                if (res.data.status.code === 'SUCCESS') {
                    toast.success(res.data.primary);
                    resetField('partnerInfo.siteInfo')
                    resetField('partnerInfo')
                } else if (res.data.status.code === 'error') {
                    toast.error(res.data.status.message);
                }
            })
        }
    }
    const handlePartnerType = (e: any) => {
        console.log(e.target.value, "partnertype")
        if (e.target.value === 'forwarder') {
            setIsButtonShow(false)
        } else {
            setIsButtonShow(true)
        }
    }
    const handleChangeCountry = (e: any) => { }
    // -------------- ag-grid ----------------
    const onGridReady = useCallback((params: GridReadyEvent) => {
        params.columnApi.autoSizeAllColumns(false);
    }, []);
    useEffect(() => {
        console.log(typeof (getValues(`partnerInfo.deliverySites`)), parameterfields, "delivery site")
        axios.get(Endpoints.GetPartner_Types_By_Login, Api_Config).then((res: any) => {
            if (res.data.status.code === 'SUCCESS') {
                setPartnerTypeList(res.data.primary)
            }
        })
        axios.get(Endpoints.Get_Countries, Api_Config).then((res: any) => {
            setCountrysList(res.data.primary)
        })
    }, [])
    return (
        <div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={values}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {status === 'create' ? <Tab label="Create Partners" value="1" /> : <Tab label="Edit Partners" value="1" />}
                            <Tab label="Partners List" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: '24px 0px' }}>
                        <form onSubmit={handleSubmit((data) => {
                            createData(data);
                        })}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Partner Type</Typography>
                                    <FormControl fullWidth size="small">
                                        {/* <InputLabel id="demo-simple-select-label">Partner Type</InputLabel> */}
                                        <select
                                            className='custom_select'
                                            placeholder='Partner Type'
                                            {...register(`partnerInfo.partnerType`, {
                                                required: "Partner Type is required",
                                            })}
                                            onChange={(e: any) => handlePartnerType(e)}
                                            disabled={status === 'update' ? true : false}
                                        >
                                            <option value="">Select Partner Type</option>
                                            {partnerTypeList.map((item: any) => (
                                                <option value={item.partnerType}>{item.partnerType}</option>
                                            )
                                            )}

                                        </select>
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerType?.message}
                                        </span>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Partner Id</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`partnerInfo.partnerId`, {
                                                required: "Partner Id is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Partner Id"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            disabled={status === 'update' ? true : false}
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerId?.message}
                                        </span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Partner Name</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`partnerInfo.partnerName`, {
                                                required: "Partner Name is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="Partner Name"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.partnerName?.message}
                                        </span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>No.of Sites</Typography>
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register(`partnerInfo.deliverySites`, {
                                                required: "Sites is required",
                                            })}
                                            id="component-outlined"
                                            placeholder="No.of Sites"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                            }}
                                            size='small'
                                            onChange={(e: any) => handleChangeSites(e)}
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.partnerInfo?.deliverySites?.message}
                                        </span>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box>
                                {parameterfields.map((filed, index: any) => {
                                    return (
                                        <Box sx={{ mt: 1 }}>
                                            <Box className="siteDetails">
                                                <Typography>Site Details {index + 1}</Typography>
                                            </Box>
                                            <Paper elevation={1} sx={{ mt: 2 }}>
                                                <Grid container spacing={2} sx={{ p: 2 }}>
                                                    <Grid item xs={12} md={8} sm={12} lg={isButtonShow ? 9 : 12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Site Id</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.siteId`, {
                                                                            required: "Site Id is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="Site Id"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'

                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.siteId?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Site Name</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.siteName`, {
                                                                            required: "Site Name is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="Site Name"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.siteName?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Site Type</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <select
                                                                        className='custom_select'
                                                                        {...register(`partnerInfo.siteInfo.${index}.siteType`, {
                                                                            required: "Site Type is required",
                                                                        })}
                                                                    >
                                                                        <option value="">Select Site Type</option>
                                                                        <option value="Plant">Plant</option>
                                                                        <option value="WH">WH</option>
                                                                        <option value="PDC">PDC</option>
                                                                        <option value="Storage Location">Storage Location</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                    {/* <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        {...register(`siteInfo.${index}.siteType`, {
                                                                            required: "Site Type is required",
                                                                        })}
                                                                        onChange={() => handleChangePartnerType}
                                                                        placeholder="Site Type"
                                                                        sx={{ fontSize: '14px' }}
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                    >

                                                                        <MenuItem value="Plant">Plant</MenuItem>
                                                                        <MenuItem value='WH'>WH</MenuItem>
                                                                        <MenuItem value='PDC'>PDC</MenuItem>
                                                                        <MenuItem value='Storage Location'>Storage Location</MenuItem>
                                                                        <MenuItem value='Other'>Other</MenuItem>
                                                                    </Select> */}

                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.siteType?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Country</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    {/* <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.country`, {
                                                                            required: "Country is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="Country"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                    /> */}
                                                                    <select
                                                                        className='custom_select'
                                                                        placeholder='Select Country'
                                                                        {...register(`partnerInfo.siteInfo.${index}.country`, {
                                                                            required: "Country is required",
                                                                        })}
                                                                        onChange={(e: any) => handleChangeCountry(e)}
                                                                    >
                                                                        <option value="">Select Country</option>
                                                                        {countrysList.map((item: any) => (
                                                                            <option value={item.countryName}>{item.countryName}</option>
                                                                        )
                                                                        )}

                                                                    </select>
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.country?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Mobile</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.mobile`, {
                                                                            required: "Mobile is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="mobile"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.mobile?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Mail</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.siteEmail`, {
                                                                            required: "Mail is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="Mail"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.siteEmail?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>PinCode / ZipCode</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.pinCode`, {
                                                                            required: "PinCode / ZipCode is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="PinCode / ZipCode"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.pinCode?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} sm={6} lg={isButtonShow ? 4 : 3}>
                                                                <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Address</Typography>
                                                                <FormControl fullWidth size="small">
                                                                    <TextField
                                                                        {...register(`partnerInfo.siteInfo.${index}.address`, {
                                                                            required: "Address is required",
                                                                        })}
                                                                        id="component-outlined"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        size='small'
                                                                        multiline={true}
                                                                        rows={4}
                                                                        minRows={10}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                                        {errors.partnerInfo?.siteInfo?.[index]?.address?.message}
                                                                    </span>
                                                                </FormControl>
                                                            </Grid>
                                                            {/* <Grid item xs={4} sx={{ m: 'auto', textAlign: 'center' }}>
                                                                <Button
                                                                    sx={{ marginRight: "10px", mt: 2 }}
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => clickPartnerAssociate(index)}

                                                                >
                                                                    Partner Associations
                                                                </Button>

                                                            </Grid> */}

                                                        </Grid>
                                                    </Grid>
                                                    {isButtonShow ?
                                                        <Grid item xs={12} md={4} sm={6} lg={3} sx={{ m: 'auto', textAlign: 'center' }}>

                                                            {(index >= parameterfields.length - 1 || (parameterfields.length == 1 && index == 0)) ?
                                                                <LoadingButton
                                                                    sx={{ marginRight: "10px" }}
                                                                    variant="contained"
                                                                    onClick={() => addMoreSites(index)}
                                                                    size='small'
                                                                    color='success'
                                                                // disabled={Number(getValues(`partnerInfo.deliverySites`)) === index + 1 ? true : false}
                                                                >

                                                                    Add
                                                                </LoadingButton>
                                                                : ''}
                                                            {index < parameterfields.length - 1 || !(parameterfields.length == 1 && index == 0) ?
                                                                <LoadingButton
                                                                    sx={{ marginRight: "10px" }}
                                                                    variant="contained"
                                                                    onClick={() => deleteSites(index)}
                                                                    size='small'
                                                                    color='warning'
                                                                >
                                                                    Delete
                                                                </LoadingButton>
                                                                : ''}
                                                        </Grid>
                                                        : ''}
                                                </Grid>
                                                {/* {
                                                    isPartnerAssociate ?
                                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')} sx={{
                                                            mt: 0, "& .MuiAccordion-root": {
                                                                margin: '0px !important'
                                                            }
                                                        }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1bh-content"
                                                                id="panel1bh-header"
                                                                sx={{
                                                                    "& .MuiAccordionSummary-content": {
                                                                        margin: '0px !important'
                                                                    },
                                                                    "& .MuiAccordionSummary-root .Mui-expanded": {
                                                                        minHeight: '10px !important'
                                                                    }
                                                                }}
                                                            >
                                                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                    Partner Details
                                                                </Typography>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={5}>
                                                                        <Box sx={{ display: 'flex' }}>
                                                                            <Typography sx={{ pr: 2, fontWeight: 'bold' }}>Registered Partners: </Typography>
                                                                            <SearchBar style={{ height: '35px !important' }} />
                                                                        </Box>
                                                                        <Box sx={{ mt: 1 }}>
                                                                            <FormGroup>
                                                                                {partnerAssociate.map((item: any, index: any) => (
                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                        <FormControlLabel control={<Checkbox checked={item.checked} onChange={() => handleChangePartners(item)} />} label={item.name} sx={{
                                                                                            '& .MuiFormControlLabel-label': {
                                                                                                fontSize: '14px !important'
                                                                                            }
                                                                                        }} />
                                                                                        <Typography sx={{ p: 2, fontSize: '14px' }}>{item.email}</Typography>
                                                                                    </Box>
                                                                                ))}
                                                                            </FormGroup>
                                                                        </Box>

                                                                    </Grid>

                                                                    <Divider orientation="vertical" flexItem>
                                                                        <IconButton sx={{ backgroundColor: '#f1f1f1' }}>
                                                                            <SyncAltOutlinedIcon />
                                                                        </IconButton>
                                                                    </Divider>

                                                                    <Grid item xs={5}>
                                                                        <Box sx={{ display: 'flex' }}>
                                                                            <Typography sx={{ pr: 2, fontWeight: 'bold' }}>Associated Partners: </Typography>
                                                                            <SearchBar style={{
                                                                                height: '35px !important',
                                                                                '.ForwardRef-root-1': {
                                                                                    heigth: '35px'
                                                                                }
                                                                            }}

                                                                            />
                                                                        </Box>
                                                                        <Box sx={{ mt: 1 }}>
                                                                            {selectedPartners.length > 0 ?
                                                                                <FormGroup>
                                                                                    {selectedPartners.map((item: any, index: any) => (
                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                            <FormControlLabel control={<Checkbox checked={true} onChange={() => removeAssociatePartner(item)} />} label={item.name} sx={{
                                                                                                '& .MuiFormControlLabel-label': {
                                                                                                    fontSize: '14px !important'
                                                                                                }
                                                                                            }} />
                                                                                            <Typography sx={{ p: 2, fontSize: '14px' }}>{item.email}</Typography>
                                                                                        </Box>
                                                                                    ))}
                                                                                </FormGroup> :
                                                                                <Alert>
                                                                                    <AlertTitle>No data found</AlertTitle>
                                                                                </Alert>}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>

                                                        : ''
                                                } */}

                                            </Paper>

                                        </Box>

                                    )
                                })}
                            </Box>
                            {status === 'create' ?
                                <LoadingButton
                                    sx={{ mt: 2, textAlign: 'center' }}
                                    loading={loader}
                                    variant="contained"
                                    type='submit'
                                    size='small'

                                >
                                    Submit
                                </LoadingButton>
                                :
                                <LoadingButton
                                    sx={{ mt: 2, textAlign: 'center' }}
                                    loading={loader}
                                    variant="contained"
                                    type='submit'
                                    size='small'

                                >
                                    Update
                                </LoadingButton>
                            }
                        </form>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: '24px 0px' }}>

                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>

                    </TabPanel>
                </TabContext>
            </Box>
            <ToastContainer />
        </div >
    )
}

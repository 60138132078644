import { Alert, AlertTitle, Box, Divider, IconButton, InputBase, Paper } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Endpoints } from '../../../Enviornment/Endpoints'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import { useOutletContext } from 'react-router-dom'
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function CarrierWiseList() {
    const [carrierList, setCarrierList] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const columns: TableColumn<any>[] = [
        {
            name: "S.NO",
            width: '100px',
            cell: (row: any, index: number) => {
                return <span>{row.serialNumber}</span>
            }
        },
        {
            name: "CARRIER NAME",
            sortable: true,
            width: mode == 'Air' ? '350px' : '250px', 
            cell: (row: any) => {
                if (row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined) {
                    return <span>{row.carrierName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "SUPPORTS TRACK BY CONTAINER",
            width: '350px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => (
                <span style={{ color: row.supportsTrackByMbl ? '#5fb25c' : '#ad2f33' }}>{row.supportsTrackByMbl ? 'YES' : 'NO'}</span>
            )
        },
        {
            name: "SUPPORTS TRACK BY MBL",
            width: '250px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => (
                <span style={{ color: row.supportsTrackByMbl ? '#5fb25c' : '#ad2f33' }}>{row.supportsTrackByMbl ? 'YES' : 'NO'}</span>
            )
        },
        {
            name: "SUPPORTS TRACK BY BOOKING #",
            width: '350px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => (
                <span style={{ color: row.supportsTrackByBookingNumber ? '#5fb25c' : '#ad2f33' }}>{row.supportsTrackByBookingNumber ? 'YES' : 'NO'}</span>
            )
        },
        

    ]
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
        console.log(event.target.value, "target value")
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    const filterData = carrierList.filter((data: any) => {
        return data.carrierName.toLowerCase().includes(searchTerm.toLowerCase())
    });
    useEffect(() => {
        if (mode === 'Ocean') {
            getCarrierList("INTERMODAL_SHIPMENT")
        } else {
            getCarrierList('AIR_SHIPMENT')
        }

    }, [mode])
    const getCarrierList = (params: any) => {
        setLoad(true)
        config.params = {
            shipmentType: params
        }
        axios.get(Endpoints.Carrier_List, config).then((res: any) => {
            setLoad(false)
            const responseDataWithSerialNumber = res.data.primary.map((data: any, index: any) => ({
                ...data,
                serialNumber: index + 1
            }));
            setCarrierList(responseDataWithSerialNumber)

        })
    }
    return (
        <div>
            {carrierList.length > 0 &&
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between', width: '100%' }}>
                    <Box></Box>
                    <Paper
                        component="form"
                        elevation={0}
                        className="custom-search"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', float: 'right', width: 400 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search By Carrier"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            size="small"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                            {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
                        </IconButton>
                    </Paper>
                </Box>}

            <Paper elevation={3} sx={{ borderRadius: 3 }}>
                {carrierList.length > 0 ? (
                    <Box sx={{ p: 0, mt: 1 }}>
                        <DataTable
                            style={{ borderRadius: 3 }}
                            columns={columns}
                            data={filterData}
                            responsive={true}
                            customStyles={customStyles}
                            theme="default"
                            pagination
                            dense
                            striped
                            highlightOnHover={true}

                        />
                    </Box>)
                    : (
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>
                    )}
            </Paper>
        </div>
    )
}

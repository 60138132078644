import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactFC from "react-fusioncharts";
import colorConfigs from "../Config/colorConfig";
import sizeConfigs from "../Config/sizeConfigs";
import TabData from "./Routes/appRoutes";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Chart from "react-apexcharts";
import { statements } from "@babel/template";
import { bottom } from "@popperjs/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import DashboardInTransit from "../Helpers/DashboardInTransit";
import ItemDetail from "../Helpers/ItemDetail";
import Full from "../Helpers/IntransitEventsMapping";
import DashboardBookingLifeCycle from "../Helpers/DashboardBookingLifeCycle";
import DashboardDeviation from "../Helpers/DashboardDeviation";
import { getRequest, PostRequest } from "../Services/BaseService";
import { DesgService } from "../Services/DesgService";
import DashboardCountInfo from "../Helpers/DashboardCountInfo";
import { SubHeader } from "./common/SubHeader";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import ETADeviationInfo from "../Helpers/ETADeviations/ETADeviationInfo";
import '../Styles.css'

// type Props = {};

function Dashboard() {
  const [inTransitOpenDialog, setIntransitOpenDialog] = useState(false);
  const [bookingOpenDialog, setBookingOpenDialog] = useState(false);
  const [deviationOpenDialog, setDeviationOpenDialog] = useState(false);
  const [dashboardCountInfoDialog, setDashboardCountInfoDialog] = useState(false);
  const [dashboardDataCount, setDashboardCounts] = useState<any>("");
  const [preCarriage, setPreCarriage] = useState<any>("");
  const [exportClearance, setExportClearance] = useState<any>("");
  const [exportClearancePending, setExportClearancePending] = useState<any>('')
  const [onVessel, setOnVessel] = useState<any>("");
  const [importClearnace, setImportClearance] = useState<any>("");
  const [importClearnacePending, setImportClearancePending] = useState<any>("");
  const [onCarriage, setOncarriage] = useState<any>("");
  const [deviated, setDeviated] = useState<any>("");
  const [delivered, setDelivered] = useState<any>("");
  const [inTransitShipments, setInTransitShipments] = useState<any>([]);
  const shipmentLifeCycle = ["Booking Requests", "Accepted By Forwarder", "Confirmed By Carrier"];
  const [shipmentEtaDeviation, setShipmentEtaDeviation] = useState<any>([]);
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  const [etaDeviationDialog, setEtaDeviationDialog] = useState<any>(false)
  const [deliveredShipmentDeviation, setDeliveredShipmentDeviation] = useState<any>([]);
  const [showIntransit, setShowIntransit] = useState(false);
  const [shipmentMoreInfo, setShipmentMoreInfo] = useState<any>();
  const [onFlightCount, setOnFlightCount] = useState<any>('')
  const [seriesData, setSeriesData] = useState<any>([]);
  const [showBookingLifeCycle, setShowBookingBokkingLifeCycle] = useState(false)
  const desgService = new DesgService();
  let dashboard: any;
  let state: any = {};
  const chartRef = useRef(null);
  // const [mode] = useState(sessionStorage.getItem('shipmentType'))
  useEffect(() => {
    setLoad(true);

    let options = {
      filterOn: format ? format : "MTD",
      startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
      endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
      shipmentType: mode
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    };
    console.log("................", options);
    setSeriesData([])
    desgService.getDashboardInfo(headers, options).then((res: any) => {
      setLoad(false);
      dashboard = res.data.data;
      setDashboardCounts(dashboard.ordersCount.pendingPickup);
      setOnFlightCount(dashboard.onFlightCount)
      setPreCarriage(dashboard.preCarriageCount);
      setExportClearance(dashboard.exportclearanceCompletedCount);
      setExportClearancePending(dashboard.exportclearancePendingCount);
      setOnVessel(dashboard.onVesselCount);
      setImportClearance(dashboard.importclearanceCompletedCount);
      setImportClearancePending(dashboard.importclearancePendingCount)
      setOncarriage(dashboard.onCarraigeCount);
      setDeviated(dashboard.etaDeviationCount);
      setDelivered(dashboard.deliveredCount);
      setInTransitShipments(
        [
          mode === 'Ocean' ? dashboard.inTransitEventShipment.arrivingToday : dashboard.inTransitEventShipment.arrivingNext2Hours,
          mode === 'Ocean' ? dashboard.inTransitEventShipment.arrivingNextDay : dashboard.inTransitEventShipment.arrivingNext2To12Hours,
          mode === 'Ocean' ? dashboard.inTransitEventShipment.arrivingNext7Days : dashboard.inTransitEventShipment.arrivingNext12To24Hours,
          mode === 'Ocean' ? dashboard.inTransitEventShipment.greaterThan7Days : dashboard.inTransitEventShipment.arrivingGreaterThan24Hours
        ]
      );
      // setShipmentLifeCycle(
      //   [

      //     dashboard.pieChart.totalPendingBookingRequestsCount,
      //     dashboard.pieChart.forwaderAcceptedCount,
      //     dashboard.pieChart.carrierConfirmedCount,

      //   ]
      // );
      setSeriesData([
        res.data.data.pieChart.totalPendingBookingRequestsCount,
        res.data.data.pieChart.forwaderAcceptedCount,
        res.data.data.pieChart.carrierConfirmedCount,
        // dashboard.pieChart

      ])
      setShipmentEtaDeviation(dashboard.inTransitEventShipmentDeviation);
      setDeliveredShipmentDeviation([
        {
          name: "ETA Deviation",
          data: [
            mode === 'Ocean' ? dashboard.deliveredShipmentDeviation.deliveredBetween2days : dashboard.deliveredShipmentDeviation.deliveredOn2Hours,
            mode === 'Ocean' ? dashboard.deliveredShipmentDeviation.deliveredBetweenWeek : dashboard.deliveredShipmentDeviation.deliveredBetween2To12Hours,
            mode === 'Ocean' ? dashboard.deliveredShipmentDeviation.deliveredBetweenMonth : dashboard.deliveredShipmentDeviation.deliveredBetween12To24Hours,
            mode === 'Ocean' ? dashboard.deliveredShipmentDeviation.deliveredGreaterThanMonth : dashboard.deliveredShipmentDeviation.deliveredGreater24Hours,
          ],
        },
      ]);
      setTimeout(() => {

        setSeriesData([
          res.data.data.pieChart.totalPendingBookingRequestsCount,
          res.data.data.pieChart.forwaderAcceptedCount,
          res.data.data.pieChart.carrierConfirmedCount,
          // dashboard.pieChart

        ]); // Update the state with the fetched data
      }, 1000);
      intransitCount();
      bookingLifeCycleCount()
      console.log(seriesData, dashboard.pieChart, "shipment lifecycle")
    })
      .catch((e: any) => {
        setLoad(false);
      });
  }, [fromDate, toDate, format, mode,]);
  const intransitCount = () => {
    console.log(inTransitShipments, "111intransit count");
    setShowIntransit(true);
  };
  const shipmentOrdersMoreInfo = (data: any) => {
    setShipmentMoreInfo(data);
    setDashboardCountInfoDialog(true);
  };
  const bookingLifeCycleCount = () => {
    setShowBookingBokkingLifeCycle(true)
  }
  useEffect(() => {
    // Update the chart when seriesData changes
    // updateChart();
  }, [seriesData]);
  // const updateChart = () => {
  //   if (chartRef.current) {
  //     // Access the chart instance using chartRef.current
  //     const chartInstance = chartRef.current;

  //     // Update the chart with the new data
  //     chartInstance.updateSeries([{ data: seriesData }]);
  //   }
  // };
  return (
    <Box sx={{ overflowX: "hidden", mt: 2, mb: 5 }}>
      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={8} sm={8}>
              <Grid
                item
                justifyContent="center"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card">
                    <Box className="card-body">
                      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={9}>
                          <Typography className="dashboard-title">Pending Pickups</Typography>
                          <Typography
                            variant="h3"
                            className="dashboard_info"
                            sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                          >
                            {dashboardDataCount}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ alignSelf: "center", position: 'relative' }}>
                          <small className="custom-intransit-info"></small>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Orders.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card" sx={{ borderRadius: '4px 8px 0px 0px' }}>
                    <Box className="card-body">
                      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={9}>
                          <Typography className="dashboard-title">Pre-Carriage</Typography>
                          <Typography
                            variant="h3"
                            className="dashboard_info"
                            sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                          >
                            {preCarriage}
                          </Typography>
                        </Grid>

                        <Grid item xs={3} sx={{ alignSelf: "center" }}>
                          <small className="custom-intransit-info" style={{ backgroundColor: '#edae49' }}>In-Transit</small>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">

                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Pre-Carriage.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card" sx={{ borderRadius: '4px 8px 0px 0px' }}>
                    <Box className="card-body">
                      {mode === 'Ocean' ?
                        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                          <Grid item xs={9}>
                            <Typography className="dashboard-title">On Vessel</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {onVessel}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sx={{ alignSelf: "center" }}>
                            <small className="custom-intransit-info" style={{ backgroundColor: '#edae49' }}>In-Transit</small>
                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                              <img
                                src={require("../asserts/Col-RealTrace-SVG-Icons/On Vessel.png")}
                                className="ti ti-users font-24 align-self-center text-muted"
                              />
                            </Box>
                          </Grid>
                        </Grid> :
                        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                          <Grid item xs={9}>
                            <Typography className="dashboard-title">On Flight</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {onFlightCount}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sx={{ alignSelf: "center" }}>
                            <small className="custom-intransit-info" style={{ backgroundColor: '#edae49' }}>In-Transit</small>
                            <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                              <img
                                src={require("../asserts/Col-RealTrace-SVG-Icons/Air1.png")}
                                className="ti ti-users font-24 align-self-center text-muted"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      }
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card" sx={{ borderRadius: '4px 8px 0px 0px' }}>
                    <Box className="card-body">
                      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={9}>
                          <Typography className="dashboard-title">WH & On Carriage</Typography>
                          <Typography
                            variant="h3"
                            className="dashboard_info"
                            sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                          >
                            {onCarriage}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ alignSelf: "center" }}>
                          <small className="custom-intransit-info" style={{ backgroundColor: '#edae49' }}>In-Transit</small>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/On Carriage.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card">
                    <Box className="card-body">
                      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={9}>
                          <Typography className="dashboard-title">Deviated</Typography>
                          <Typography
                            variant="h3"
                            className="dashboard_info"
                            sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                          >
                            {deviated}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ alignSelf: "center" }}>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Deviate-Shipment.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                  <Paper elevation={0} className="card">
                    <Box className="card-body">
                      <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={9}>
                          <Typography className="dashboard-title">Delivered</Typography>
                          <Typography
                            variant="h3"
                            className="dashboard_info"
                            sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                          >
                            {delivered}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ alignSelf: "center" }}>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle mx-auto">
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Delivered.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} xs={4} sm={4}>
              <Grid item className="mainGrid">
                <Paper elevation={0} className="card" sx={{ borderRadius: '4px 8px 0px 0px' }}>
                  <Box className="card-body">
                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                      <Grid item xs={12}>
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontWeight: 'bold' }}>Export Clearance</Typography>
                          <small className="custom-intransit-info" style={{ backgroundColor: '#edae49' }}>In-Transit</small>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle">
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Export-declaration.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>

                        <Grid spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1.5 }}>
                          <Grid item >
                            <Typography className="dashboard-title">Pending</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {exportClearancePending}
                            </Typography>

                          </Grid>
                          <Divider variant="middle" orientation="vertical" flexItem sx={{ margin: '10px' }} />
                          <Grid item>
                            <Typography className="dashboard-title">Cleared</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {exportClearance}
                            </Typography>

                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Box>
                  <Divider variant="middle" />
                  
                  <Box className="card-body" sx={{position:'relative'}}>
                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                      <Grid item xs={12}>
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontWeight: 'bold' }}>Import Clearance</Typography>
                          <small className="custom-intransit-info" style={{ backgroundColor: '#edae49', position: 'absolute', marginRight: '0px', borderRadius: '0px 0px 0px 10px' }}>In-Transit</small>
                          <Box className="iconContent thumb-md bg-light-alt rounded-circle" sx={{marginTop:'7px'}}>
                            <img
                              src={require("../asserts/Col-RealTrace-SVG-Icons/Import-Declaration.png")}
                              className="ti ti-users font-24 align-self-center text-muted"
                            />
                          </Box>
                        </Grid>
                        <Grid spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'10px' }}>
                          <Grid item >
                            <Typography className="dashboard-title">Pending</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {importClearnacePending}
                            </Typography>
                          </Grid>
                          <Divider variant="middle" orientation="vertical" flexItem sx={{ margin: '10px' }} />
                          <Grid item >
                            <Typography className="dashboard-title">Cleared</Typography>
                            <Typography
                              variant="h3"
                              className="dashboard_info"
                              sx={{ my: 1, fontSize: 24, fontWeight: 700 }}
                            >
                              {importClearnace}
                            </Typography>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Box>
                </Paper>

              </Grid>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={4}>
              <Paper elevation={0} className="card">
                <Box className="card-header">
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography variant="h4" className="card-title" sx={{ fontWeight: 'bold' }}>
                        Booking Life Cycle
                      </Typography>

                    </Grid>
                    <Grid>
                      <Typography
                        variant="h4"
                        className="card-title"
                        sx={{ color: "#7081b9", fontSize: "12px" }}
                      >
                        <Tooltip title="Available In Pro Version" placement="bottom" componentsProps={{ tooltip: { sx: { bgcolor: '#06BF06' } } }}>
                          <RemoveRedEyeIcon
                            sx={{ cursor: "not-allowed" }}
                          // onClick={() => setBookingOpenDialog(true)}
                          />
                        </Tooltip>

                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="card-body">
                  {showBookingLifeCycle ?
                    <Box>
                      {/* <Chart
                        type="pie"
                        width={250}
                        height={330}
                        series={seriesData}
                        options={{
                          // colors: ["#2a76f4", "#94baf9", "#6098f5"],
                          colors: ['#64B5F6', '#EDAE49', '#1976D2',],
                          legend: {
                            show: true,
                            position: bottom,
                            horizontalAlign: "left",
                            fontSize: "12px",
                            fontFamily: "Roboto",
                            fontWeight: 700,
                            labels: {
                              colors: "rgb(112 129 185)",
                            },

                          },

                          dataLabels: {
                            enabled: true,
                            formatter: function (val, opts) {
                              return shipmentLifeCycle[opts.seriesIndex] + ': ' + seriesData[opts.seriesIndex];
                            },
                          },
                          labels: [
                            "Booking Requests",
                            "Accepted By Forwarder",
                            "Confirmed By Carrier",
                          ],
                        }}
                      /> */}
                      <Chart
                        type="pie"
                        ref={chartRef}
                        width={250}
                        height={330}
                        series={seriesData}
                        options={{
                          colors: ['#64B5F6', '#EDAE49', '#1976D2'],
                          legend: {
                            show: true,
                            position: "bottom",
                            horizontalAlign: "left",
                            fontSize: "12px",
                            fontFamily: "Roboto",
                            fontWeight: 700,
                            labels: {
                              colors: "rgb(112 129 185)",
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            // formatter: function (val, opts) {
                            //   console.log('seriesData:', seriesData);
                            //   console.log('opts.seriesIndex:', opts.seriesIndex);
                            //   return seriesData[opts.seriesIndex];
                            // },
                            formatter: function (val, opts) {
                              console.log('seriesData:', seriesData); // Debug to check the data
                              return seriesData[opts.seriesIndex];
                            },
                          },
                          labels: shipmentLifeCycle,
                        }}
                      />
                    </Box> :
                    <Box sx={{
                      width: '250px',
                      height: '300px'
                    }}>
                      <Alert severity="info" >
                        <AlertTitle>Info</AlertTitle>
                        No data found
                      </Alert>
                    </Box>
                  }
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={8}>
              <Paper elevation={0} className="card">
                <Box className="card-header">
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography variant="h4" className="card-title" sx={{ fontWeight: 'bold' }}>
                        Delivered Shipments - Deviation
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="h4"
                        className="card-title"
                        sx={{ color: "#7081b9", fontSize: "12px" }}
                      >
                        <RemoveRedEyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setDeviationOpenDialog(true)}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="card-body">
                  {deliveredShipmentDeviation.length > 0 ?
                    <Box>
                      <Chart
                        type="bar"
                        width={580}
                        height={280}
                        series={deliveredShipmentDeviation}
                        options={{
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: "25%",
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                          },
                          xaxis: {
                            categories: mode === 'Ocean' ? [
                              "< 2 D",
                              "> 2 D to < 1 W",
                              "> 1 W to < 1 M",
                              "> 1 M",
                            ] : [
                              "<= 2 Hrs",
                              ">2 Hrs to <= 12 Hrs",
                              ">12 Hrs to <= 24 Hrs",
                              ">24 Hours",
                            ],
                            title: {
                              text: "Time",
                            },
                          },
                          yaxis: {

                            title: {
                              text: "Shipments",
                            },
                          },
                          fill: {
                            opacity: 1,
                          },
                          grid: {
                            xaxis: {
                              lines: {
                                show: false,
                              },
                            },
                          },
                        }}
                      />
                    </Box> : <Box sx={{
                      width: '250px',
                      height: '300px'
                    }}>
                      <Alert severity="info" >
                        <AlertTitle>Info</AlertTitle>
                        No data found
                      </Alert>
                    </Box>}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3}>
          <Paper elevation={0} className="card">
            <Box className="card-header">
              <Grid
                item
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography variant="h4" className="card-title" sx={{ fontWeight: 'bold' }}>
                    In-Transit Shipments
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant="h4"
                    className="card-title"
                    sx={{ color: "#7081b9", fontSize: "12px" }}
                  >
                    <RemoveRedEyeIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIntransitOpenDialog(true)}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className="card-body" sx={{ padding: '1.5rem 1rem' }}>
              {showIntransit ? (
                <Box>
                  <Chart
                    type="donut"
                    width={260}
                    height={500}
                    className="donut"
                    series={inTransitShipments}
                    options={{
                      noData: { text: "Empty Data" },
                      labels: mode === 'Ocean' ? [
                        "Arriving Today",
                        "Next Day",
                        "Next 7 Days", ">7 Days"
                      ] : [
                        "Next 2 Hrs",
                        ">2 Hrs to <=12 Hrs",
                        ">12 Hrs to <=24 Hrs", ">24 Hrs"
                      ],
                      // colors: ["#2a76f4", "#94baf9", "#6098f5","#1976d2"],
                      colors: ['#EDAE49', '#EF6C00', '#1976D2', '#64B5F6'],
                      legend: {
                        show: true,
                        position: bottom,
                        fontSize: "12px",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                        labels: {
                          colors: "rgb(112 129 185)",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          donut: {
                            size: "80%",
                            labels: {
                              show: true,
                              total: {
                                show: true,
                                label: "In-Transit Shipments",
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </Box>
              ) : (
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  No data found
                </Alert>
              )}
              <Box sx={{ pt: 6, textAlign: "left" }}>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Grid>
                    <Typography sx={{ fontWeight: 'bold' }}>ETA Deviations</Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="h4"
                      className="card-title"
                      sx={{ color: "#7081b9", fontSize: "12px" }}
                    >
                      <RemoveRedEyeIcon sx={{ cursor: 'pointer' }}
                        onClick={() => setEtaDeviationDialog(true)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <table className="etaDeviations">
                    <tr>
                      <th>Criteria</th>
                      <th style={{ width: "100px" }}># Shipments</th>
                      <th>(%)</th>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[0],
                          }}
                        >
                          &#60;{mode === 'Ocean' ? '2 days' : '2 Hrs'}
                        </span>
                      </td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.between2days : shipmentEtaDeviation.between2Hours}</td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.between2daysPercentage : shipmentEtaDeviation.between2HoursPercentage}</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[1],
                          }}
                        >
                          &#62;{mode === 'Ocean' ? '2 days' : '2 Hrs'}  to &#x2264;{mode === 'Ocean' ? '1 Week' : '12 Hrs'}
                        </span>
                      </td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.betweenWeek : shipmentEtaDeviation.between2To12Hours}</td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.betweenWeekPercentage : shipmentEtaDeviation.between2To12HoursPercentage}</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[2],
                          }}
                        >
                          &#62;{mode === 'Ocean' ? '1 Week' : '12 Hrs'} to &#x2264;{mode === 'Ocean' ? '1 Month' : '24 Hrs'}
                        </span>
                      </td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.betweenMonth : shipmentEtaDeviation.between12To24Hours}</td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.betweenMonthPercentage : shipmentEtaDeviation.between12To24HoursPercentage}</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[3],
                          }}
                        >
                          &#62;{mode === 'Ocean' ? '1 Month' : '24 Hrs'}
                        </span>
                      </td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.greaterThanMonth : shipmentEtaDeviation.greaterThan24Hours}</td>
                      <td>{mode === 'Ocean' ? shipmentEtaDeviation.greaterThanMonthPercentage : shipmentEtaDeviation.greaterThan24HoursPercentage}</td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="eta-text-bg"
                          style={{
                            background: colorConfigs.etaDeviations.color[4],
                          }}
                        >
                          No Update
                        </span>
                      </td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="eta-text-bg">Totals</span>
                      </td>
                      <td>{shipmentEtaDeviation.totalEtaDeviationCount}</td>
                      <td>{shipmentEtaDeviation.TotalEtaPercentage}</td>
                    </tr>
                  </table>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <div className="dialog">
        {inTransitOpenDialog ? (
          <DashboardInTransit
            onOpen={inTransitOpenDialog}
            onClose={() => setIntransitOpenDialog(false)}
            status={"open"}
            shipmentType={mode}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {bookingOpenDialog ? (
          <DashboardBookingLifeCycle
            onOpen={bookingOpenDialog}
            onClose={() => setBookingOpenDialog(false)}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {deviationOpenDialog ? (
          <DashboardDeviation
            onOpen={deviationOpenDialog}
            shipmentType={mode}
            onClose={() => setDeviationOpenDialog(false)}
            status={"open"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {dashboardCountInfoDialog ? (
          <DashboardCountInfo
            onOpen={dashboardCountInfoDialog}
            onClose={() => setDashboardCountInfoDialog(false)}
            status={"open"}
            data={shipmentMoreInfo}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {etaDeviationDialog ? (
          <ETADeviationInfo
            onOpen={etaDeviationDialog}
            shipmentType={mode}
            onClose={() => setEtaDeviationDialog(false)}
            status={"open"}

            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </Box>
  );
}
export default Dashboard;

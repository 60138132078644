import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import Input from "@mui/material/Input";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import swal from "sweetalert";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { DropzoneDialog } from "mui-file-dropzone";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  TextField,
  Grid,
  Paper,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useOutletContext } from "react-router-dom";
import { ApiRequestFunction } from "../../../Services/BaseService";
import { Endpoints } from "../../../Enviornment/Endpoints";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function CrFileupload() {
  const [rowData, setRowData] = useState<any>([]);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const [validationPoNumber, setValidationPoNumber] = useState();
  const [loader, setLoader, fromDate, toDate, format] = useOutletContext<any>();
  const Formref = useRef<any>();
  const gridRef = useRef<any>();
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "VALIDATION MESSAGE",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION MESSAGE",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} />{" "}
              {params.data.preCheckValidationMessage[0]}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                />{" "}
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            return <p>
              <CheckCircleIcon
                sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
              />{" "}
              NO VALIDATION
            </p>;
          }
        }
      },
    },
    {
      headerName: "CARRIER ID",
      field: "carrierId",
      headerTooltip: "CARRIER ID",
      tooltipField: "carrierId",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CARRIER REFERENCE #",
      field: "carrierReference",
      headerTooltip: "CARRIER REFERENCE #",
      tooltipField: "carrierReference",
    },
    {
      headerName: "CARRIER",
      field: "carrierName",
      headerTooltip: "CARRIER NAME",
      tooltipField: "carrierName",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "FORWARDER REFERENCE #",
      headerTooltip: "FORWARDER REFERENCE #",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },
    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },
    {
      headerName: "PICKUP COUNTRY",
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE MOBILE",
      headerTooltip: "CONSIGNEE MOBILE",
      field: "consingneeContactNumber",
      tooltipField: "consingneeContactNumber",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE EMAIL",
      headerTooltip: "CONSIGNEE EMAIL",
      field: "consigneeEamilAddress",
      tooltipField: "consigneeEamilAddress",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF LOCATION",
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIP CODE",
      headerTooltip: "DROPOFF ZIP CODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "INCOTERMS",
      headerTooltip: "INCOTERMS",
      tooltipField: "incoTerms",
      field: "incoTerms",
    },
    {
      headerName: "READY BY TIME",
      headerTooltip: "READY BY TIME",
      tooltipField: "readyBytime",
      field: "readyBytime",
      cellClass: "ag-left-aligned-cell",
    },
  ]);
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let Excel_Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-date",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        columnDefs: [
          sessionStorage.getItem('shipmentType') !='Air' ?
          {
            headerName: "HBL #",
            field: "hblNumber",
            headerTooltip: "HBL #",
            tooltipField: "hblNumber",
            
          }:
          {
            headerName: "HAWB #",
            field: "hawbNumber",
            headerTooltip: "HAWB #",
            tooltipField: "hawbNumber",
          },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION STATUS",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params,sessionStorage.getItem('shipmentType'), "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    />{" "}{params.data.preCheckValidationMessage[0]}
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      />{" "}
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  return <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    />{" "}
                    NO VALIDATION
                  </p>;
                }
              }
            },
          },
          {
            headerName: "COMMODITY TYPE",
            field: "commodityType",
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "commodityType",
          },
          {
            headerName: "HS CODE",
            field: "hscode",
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hscodeDescription",
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscodeDescription",
          },
          {
            headerName: "NUMBER OF PACKAGES",
            field: "numberofPackages",
            headerTooltip: "NUMBER OF PACKAGES",
            tooltipField: "number of packages",
          },
          {
            headerName: "PACKAGE TYPE",
            field: "type",
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packagetype",
          },
          {
            headerName: "PACKAGE WEIGHT",
            field: "weight",
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageweight",
          },
          {
            headerName: "PACKAGE LENGTH",
            field: "length",
            headerTooltip: "PACKAGE LENGTH",
            tooltipField: "packagelength",
          },
          {
            headerName: "PACKAGE WIDTH",
            field: "width",
            headerTooltip: "PACKAGE WIDTH",
            tooltipField: "packagewidth",
          },
          {
            headerName: "PACKAGE HEIGHT",
            field: "height",
            headerTooltip: "PACKAGE HEIGHT",
            tooltipField: "packageheight",
          },
          {
            headerName: "PACKAGE SPECIAL INSTRUCTIONS",
            field: "specialInstructions",
            headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
            tooltipField: "packagespecialinstructions",
          },
          {
            headerName: "HZ GOODS",
            field: "hazardousGoods",
            headerTooltip: "hazardousGoods",
            tooltipField: "HZ GOODS",
          },
          
        ],

        defaultColDef: {
          sortable: true,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);
        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
    };
  }, []);
  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }
  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  // ---------------- upload preview -------------------------
  const Preview = () => {
    if (file === undefined) {
      toast.warning("Please Select a File...");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    setLoader(true)
    axios.post(Endpoints.Carrier_Booking_Request_Preview, formData, Excel_Api_Config).then((res: any) => {
      setLoader(false)
      setPreviewData(res.data);
      setRowData(res.data.data);
      res.data.data.map((disables: any) => {
        if (disables.preCheckValidationStatus === false) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      });
    }).catch((e: any) => {
      setLoader(false);
    });
    setShowFile(true);
  };
  
  const closeAlert = () => setAlertOpen(false);
  // ----------------- upload file submit -----------------
  const SubmitFile = () => {
    let submitPayload: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios.post(Endpoints.Carrier_Booking_Request_Submit, submitPayload, Api_Config).then((res: any) => {
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Error", err.res.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };
  //-------------------- upload file reset -------------------
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
  };
  // ----------------------- shipment type changes ----------------
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType',event.target.value)
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
 
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  
  useEffect(() => {
    setLoader(false)
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column:any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column:any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, format])
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
      <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={shipmentModeHandleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + selectedValue === 'Ocean' ? "/carrierBooking.xlsx" : '/carrierAirBookingRequest.xlsx'}
            download={selectedValue === 'Ocean' ? "carrierBooking.xlsx" : 'carrierAirBookingRequest.xlsx'}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box>
            <Box className="ag-theme-balham" sx={{ mt: 2 }}>
              <AgGridReact
                animateRows={true}
                defaultColDef={defaultColDef}
                detailCellRendererParams={detailCellRendererParams}
                rowData={rowData}
                domLayout={domLayout}
                columnDefs={columnDefs}
                sideBar={sideBar}
                pagination={true}
                masterDetail={masterDetail}
                paginationPageSize={10}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
              ></AgGridReact>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}

        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

export default CrFileupload;

import { GoogleMap, MarkerF, PolylineF } from '@react-google-maps/api'
import React, { useEffect } from 'react'
const containerStyle = {
    width: "68vw",
    height: "78vh",
};
export default function SeaRatesMap(props: any) {
    const locationDataa: any = [];
    const options = {
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: "#000000",
        fillOpacity: 0.35,
        clickable: true,
        draggable: false,
        editable: false,
        visible: true,
      };
    let icon: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
    let iconbetween: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffa500";
    useEffect(() => {
        console.log(props.data, "props datata")
    }, [])
    let obj = {
        name: props.data.origin.name,
        location: {
            lat: parseFloat(props.data.origin.lat),
            lng: parseFloat(props.data.origin.lng),
        },
    };
    locationDataa.push(obj);
    let obj1 = {
        name: props.data.destination.name,
        location: {
            lat: parseFloat(props.data.destination.lat),
            lng: parseFloat(props.data.destination.lng),
        },
    };
    locationDataa.push(obj1);
    const paths = locationDataa.map((item: any) => item.location);

    return (
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={locationDataa[0].location}
                zoom={2}

            >
                <MarkerF
                    title={locationDataa[0].name}
                    position={locationDataa[0].location}
                    options={options}
                    animation={2}
                    visible={true}
                    icon={icon}
                />
                 <MarkerF
                    title={locationDataa[1].name}
                    position={locationDataa[1].location}
                    options={options}
                    animation={2}
                    visible={true}
                    icon={iconbetween}
                />
                 <PolylineF path={paths} options={{ path: paths, strokeOpacity: 1 }} />

            </GoogleMap>
        </div>
    )
}

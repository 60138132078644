import {
    Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, FormControl, SelectChangeEvent, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Alert, AlertTitle, IconButton, List, ListItemButton, Divider, InputBase,
} from '@mui/material';
import Chart from "react-apexcharts";
import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../Styles.css'
import { AgGridReact } from 'ag-grid-react';
import { FirstDataRenderedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Endpoints } from '../Enviornment/Endpoints';
import { ApiRequestFunction } from '../Services/BaseService';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { bottom } from "@popperjs/core";
import ReactECharts from 'echarts-for-react';
import Loaders from '../components/common/Loaders';
import { useOutletContext } from 'react-router-dom';
import SearchBar from 'material-ui-search-bar';
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
declare var $: any;
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function CommonDeliveredShipmentsInfo(props: any) {
    const [rowData, setRowData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('');
    const domLayout = "autoHeight";
    const gridRef = useRef<any>();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [searched, setSearched] = useState<string>("");
    const [countrys, setCountrys] = useState<any>([])
    const [dropOff, setDropOff] = useState<any>([])
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const [rows, setRows] = useState([]);
    const columns: TableColumn<any>[] = [
        {
            name: "CONSIGNEE",
            selector: row => row.consigneeName !== '' ? row.consigneeName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '250px',
        },
        {
            name: "TOTAL SHIPMENTS",
            selector: row => row.totalDeviatedDeliveredShipments !== '' ? row.totalDeviatedDeliveredShipments : 0,
            width: '200px'
        },
        {
            name: "<2 DAYS",
            omit: props.shipmentType == 'Air' ? true : false,
            selector: row => row.firstFilter !== '' ? row.firstFilter : 0,
        },
        {
            name: "<=2 Hrs",
            omit: props.shipmentType == 'Ocean' ? true : false,
            selector: row => row.firstFilter !== '' ? row.firstFilter : 0,
        },
        {
            name: "<2 DAYS TO <1 WEEK",
            omit: props.shipmentType == 'Air' ? true : false,
            selector: row => row.secondFilter !== '' ? row.secondFilter : 0,
            width: '200px',

        },
        {
            name: ">2 Hrs TO <=12 Hrs",
            omit: props.shipmentType == 'Ocean' ? true : false,
            selector: row => row.secondFilter !== '' ? row.secondFilter : 0,
            width: '200px',

        },
        {
            name: ">1 WEEK TO <1 MONTH",
            omit: props.shipmentType == 'Air' ? true : false,
            selector: row => row.thirdFilter !== '' ? row.thirdFilter : 0,
            width: '200px',
        },
        {
            name: ">12 Hrs TO <=24 Hrs",
            omit: props.shipmentType == 'Ocean' ? true : false,
            selector: row => row.thirdFilter !== '' ? row.thirdFilter : 0,
            width: '200px',
        },

        {
            name: ">1 MONTH",
            width: '180px',
            omit: props.shipmentType == 'Air' ? true : false,
            selector: row => row.fourthFilter !== '' ? row.fourthFilter : 0,
        },
        {
            name: ">24 Hrs",
            width: '180px',
            omit: props.shipmentType == 'Ocean' ? true : false,
            selector: row => row.fourthFilter !== '' ? row.fourthFilter : 0,
        },
        {
            name: "DEVIATED SHIPMENTS (%)",
            selector: row => row.deviatedPercentage !== '' ? row.deviatedPercentage : 0,
            wrap: false,
            width: '250px',
        },

    ]
    // let option = {
    //     title: {
    //         text: 'Pick Up',
    //         left: 'center'
    //     },
    //     tooltip: {
    //         trigger: 'item'
    //     },
    //     legend: {
    //         bottom: 0
    //     },
    //     series: [
    //         {
    //             type: 'pie',
    //             radius: '50%',
    //             data: dropOff,
    //             emphasis: {
    //                 itemStyle: {
    //                     shadowBlur: 10,
    //                     shadowOffsetX: 0,
    //                     shadowColor: 'rgba(0, 0, 0, 0.5)'
    //                 }
    //             }
    //         }
    //     ]
    // };
    let option1 = {
        title: {
            text: 'Drop Off',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                data: dropOff,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    let config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
    }
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    const filterData = countrys.filter((data:any) => {
        const country = data._id.country;
        if (country === null) {
          // Include data with null country value
          return true;
        }
      
        return country.toLowerCase().includes(searchTerm.toLowerCase());
      });
    useEffect(() => {
        countryWiseInsights()
    }, [])
    const countryWiseInsights = () => {
        let totalCountrysInfo: any = []
        let totalCountrysCount: any = 0;
        let totalResp1: any = []
        setLoad(true)
        let payload = {
            shipmentType: props.shipmentType,
            filterDetails: {
                filterOn: format ? format : "MTD",
                startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
            }
        }
        config.params = payload
        axios.get(Endpoints.Delivered_Deviation_Shipments_CountryWise_Insights, config).then((res: any) => {
            setLoad(false)
            if (res.data.data.length > 0) {
                console.log(selectedIndex, "selected index")
                if (selectedIndex === 0) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        totalCountrysCount += res.data.data[i].countryTotalDeviatedDeliveredShipments || 0
                        for (let j = 0; j < res.data.data[i].list.length; j++) {
                            totalCountrysInfo.push(res.data.data[i].list[j])
                        }
                    }
                    let resp = {
                        countryTotalDeviatedDeliveredShipments: totalCountrysCount,
                        list: totalCountrysInfo,
                        _id: {
                            country: 'All Countries'
                        }
                    }
                    res.data.data.unshift(resp)
                    totalResp1 = res.data.data
                    setRowData(totalResp1[selectedIndex].list)
                    setCountrys(totalResp1)
                    getCountryWiseRealiablility(resp)
                }


            }

        })

    }
    const getCountryWiseRealiablility = (country: any) => {
        console.log(country, "selected country")
        let graphPayload = {
            countryCode: country._id.country === 'All Countries' ? 'all' : country._id.country,
            shipmentType: props.shipmentType,
            filterDetails: {
                filterOn: format ? format : "MTD",
                startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
            }
        }
        config.params = graphPayload
        axios.get(Endpoints.Delivered_Deviation_Shipments_DropOff, config).then((res: any) => {
            setLoad(false)
            let dropOffData: any = []
            if (res.data.data.length > 0) {
                for (let i = 0; i < res.data.data.length; i++) {
                    dropOffData.push({ name: res.data.data[i]._id.carrierName, value: res.data.data[i].dropOffCount })
                }
                setDropOff(dropOffData)
            }

        })
    }
    const countryWiseInsightsByFilter = (event: any) => {
        console.log(event, "target value")
        setRowData(event.list)
    }
    const handleListItemClick = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
        name: any
    ) => {
        setSelectedIndex(index);
        countryWiseInsightsByFilter(name)
        getCountryWiseRealiablility(name)
    };
    const requestSearch = (searchedVal: string) => {
        console.log(searchedVal, "searched value")
        const filteredRows = rows.filter((row: any) => {
            console.log(row, "row names")
            return row.countryName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };
    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    return (
        <div>
            {rowData.length > 0 ?
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        {/* <Box>
                            <Typography>{sessionStorage.getItem('role') != 'consignee' ? "Destination Country" : "Origin Country"}</Typography>
                            <SearchBar
                                value={searched}
                                placeholder="Search By Country"
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                                className='searchBar'

                            />
                        </Box> */}
                        <Paper
                            component="form"
                            elevation={0}
                            className="custom-search"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search By Country"
                                inputProps={{ 'aria-label': 'search google maps' }}
                                size="small"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                                {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
                            </IconButton>
                        </Paper>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 2 }}>
                            {filterData.map((item: any, index: any) => (
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItemButton
                                        selected={selectedIndex === index}
                                        onClick={(event: any) => handleListItemClick(event, index, item)}
                                    >
                                        <ListItemText primary={item._id.country} />({item.countryTotalDeviatedDeliveredShipments})

                                    </ListItemButton>

                                </List>
                            ))}
                        </Box>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={9}>
                        <div>
                            {load ? <Loaders /> :
                                <DataTable
                                    style={{ borderRadius: 3 }}
                                    columns={columns}
                                    data={rowData}
                                    responsive={true}
                                    customStyles={customStyles}
                                    theme="default"
                                    pagination
                                    dense
                                    striped
                                    highlightOnHover={true}

                                />

                            }
                        </div>
                        <Typography variant='h6' sx={{ fontSize: '1rem', paddingTop: 2, paddingLeft: 3 }}>RELIABILITY TRENDS</Typography>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            {/* <Grid item xs={6}>
                                <ReactECharts option={option} />
                            </Grid> */}
                            <Grid item xs={12}>
                                <ReactECharts option={option1} />
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid> : <Alert severity="info" >
                    <AlertTitle>Info</AlertTitle>
                    No data found
                </Alert>}
        </div>
    )
}

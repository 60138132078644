import { Box, Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Paper } from "@mui/material";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { Alert, AlertTitle } from "@mui/lab";
import { useOutletContext } from "react-router-dom";
import Loaders from "../components/common/Loaders";
import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import DataTable, { TableColumn } from "react-data-table-component";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7'
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },

};
function IntransitFileViewer(props: any) {
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);
  const [showgrid, setShowGrid] = useState(true);
  const gridRef = useRef<any>();
  const [load, setLoad] = useOutletContext<any>();
  const docref = props.data != undefined ? props.data.split("-")[0] === "INV" ? "INVOICE" : props.data.split("-")[0] : '';
  const gconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      docAgainst: 'INV',
      documentReference: props.data,
    },
  };
  const columns: TableColumn<any>[] = [
    {
      name: "DOCUMENT ID",
      sortable: true,
      width:'220px',
      selector: row => row.documentAgainstId.name !== '' ? row.documentAgainstId.name : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    },
    {
      name: "DOCUMENT TYPE",
      sortable: true,
      selector: row => row.docExtension !== '' ? row.docExtension : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },
    {
      name: "FILE NAME",
      sortable: true,
      selector: row => row.originalName !== '' ? row.originalName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },
    {
      name: "REFERENCE #",
      sortable: true,
      selector: row => row.documentReference !== '' ? row.documentReference : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    },
    {
      name: "UPLOADED BY",
      sortable: true,
      selector: row => row.uploadedBy !== '' ? row.uploadedBy : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px',
    },
    {
      name: "VIEW DOC",
      sortable: true,
       width: '200px',
      cell: (row) => (
        <>
          <a href={row.docPath} target="_blank">
            <IconButton
              sx={{ padding: "0", margin: "0" }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <VisibilitySharpIcon />
            </IconButton>
          </a>
        </>
      ),
    },
  ]
  useEffect(() => {
    console.log("Intrnasit File Viewer Screen .....", props);
    getDocsFile()

  }, []);
  const getDocsFile = () => {
    setLoad(true)
    axios.get(process.env.REACT_APP_PREPROCESSOR_API + "api/v1/documents/getDocs", gconfig).then((res) => {
      setLoad(false)
      if (res.data.data.length === 0) {
        setShowGrid(false);
      } else {
        setRowData(res.data.data);
      }
    }).catch((err: any) => {
      setShowGrid(false);
      setLoad(false)
    });
  }
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        {load ? <Loaders /> : ''}
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <DataTable
              style={{ borderRadius: 3 }}
              columns={columns}
              data={rowData}
              responsive={true}
              customStyles={customStyles}
              theme="default"
              pagination
              dense
              striped
              highlightOnHover={true}
            />
          ) : (
            <Alert severity="info" >
              <AlertTitle>Info</AlertTitle>
              No documents found 
            </Alert>
          )}
        </Box>
      </Paper>
    </div>
  );
}

export default IntransitFileViewer;

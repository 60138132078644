import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

export default function IntransitShipmentInfo(props: any) {
    const [expanded, setExpanded] = React.useState<string | false>('panel0');
    console.log(props, "props")
    const [ATA] = useState(props.shipmentInfo?.route?.destinationDetails?.ata || "")
    const [ETA] = useState(props.shipmentInfo?.route?.destinationDetails?.eta || "")
    const [changeInETA] = useState(props.changeInETA)
    let hour = 0, min = 0, day = 0;
    props.delay.days.map((d: any, ind: any) => day = + d)
    props.delay.hours.map((d: any, ind: any) => hour += d)
    props.delay.minutes.map((d: any, ind: any) => min += d)
    let delay = "On Time"
    const [delayIndicator, setDelayIndicator] = useState(false);
    const [datesStatus, setDatesStatus] = useState(false)
    const [subStatus1, setSubStatus1] = useState<any>()
    const [subStatus2, setSubStatus2] = useState<any>()
    function dayhours(date1: any, date2: any) {


        const milliseconds1 = date1.getTime();
        const milliseconds2 = date2.getTime();

        // Calculate the difference in milliseconds.
        const differenceInMilliseconds = milliseconds2 - milliseconds1;

        // Convert the difference in milliseconds to days, hours, and minutes.
        const days = Math.abs(Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)));
        const hours = Math.abs(Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = Math.abs(Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)));
        console.log("Dates are ...", days, hours, minutes)
        if (days > 0 && days === 1) {
            return days + " Day " + hours + " Hours " + minutes + " Minutes."

        } else if (days > 1) {
            return days + " Days " + hours + " Hours " + minutes + " Minutes."
        }

        return hours + " Hours " + minutes + " Minutes."
    }
    const delaycaluclator = () => {
        // const _ata = props.shipmentInfo?.route?.destinationDetails?.ata || '';
        // const _eta = props.shipmentInfo?.route?.destinationDetails?.eta || ""
        // if (_ata == '' && _eta == '') {
        //     setDatesStatus(false)
        //     return 'UNKOWN'
        // }
        // console.log(_ata)
        // const moment1: any = moment(ETA)
        // const moment2: any = moment(ATA)
        // const diff = moment2.diff(moment1);
        // const duration = moment.duration(diff);
        // const days = duration.days();
        // const hours = duration.hours();
        // const minutes = duration.minutes();
        // console.log(hours, moment1, moment2, duration, "hours")
        // let condition = days < 0 || hours < 0 || minutes < 0;
        // if (condition) {
        //     if (!delayIndicator) {
        //         setDelayIndicator(true)
        //     }
        //     if (days == 0) {
        //         setDatesStatus(true)
        //         return `Arrived early by ${Math.abs(hours)} hrs and ${Math.abs(minutes)} mins`
        //     } else {
        //         return `Arrived early by ${days} days, ${Math.abs(hours)} hrs and ${Math.abs(minutes)} mins`
        //     }

        // } else {
        //     return `${days} days ${hours} hrs ${minutes} minutes`;
        // }

        const subStatus1 = props.shipmentInfo?.shipmentDetails?.subStatus1
        const subStatus2 = props.shipmentInfo?.shipmentDetails?.subStatus2
        setSubStatus1(subStatus1 || '');
        setSubStatus2(subStatus2 || '')
        setDelayIndicator(true)

    }
    useEffect(() => {
        delaycaluclator();
    }, [])
    function formatDate(input: string) {
        // Validate the date string
        if (!input || typeof input !== 'string' || input.trim() === '') {
            return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
        }

        // Regex pattern to validate the format "YYYY-MM-DD HH:MM:SS AM/PM"
        const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} (AM|PM)$/;

        if (!pattern.test(input)) {
            return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
        }

        // Use moment's string parsing capabilities with a format declaration
        let date = moment(input, 'YYYY-MM-DD hh:mm:ss A');

        if (!date.isValid()) {
            return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
        }

        // Return the formatted date
        return date.format('DD-MMM-YYYY hh:mm:ss A');
    }
    if (min > 60) {
        var num = min;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        hours = hours + rhours
        min = rminutes
    }
    console.log("Days are  ", day, hour, min)
    const TotalDealy: any = day + " Day " + hour + " Hours " + min + " Minutes"
    const co2EmissionsValue = props.shipmentInfo?.shipmentDetails?.emissions?.co2e?.value;
    const co2EmissionsUnit = props.shipmentInfo?.shipmentDetails?.emissions?.co2e?.unit;
    console.log(co2EmissionsValue, "co2")
    const formattedValue = isNaN(co2EmissionsValue) || co2EmissionsValue === null ? 'NA' : parseFloat(co2EmissionsValue).toFixed(2);
    const cargoEsInfo: any = [
        {
            title: 'Shipment Info',
            shipmentInfo: [
                { header: '# Of Packages', field: props.shipmentInfo?.shipmentDetails?.totalNumberOfPackages || 'NA' },
                { header: 'Package Type', field: props.shipmentInfo?.shipmentDetails?.packageType || 'NA' },
                { header: 'Shipment Weight', field: props.shipmentInfo?.shipmentDetails?.totalWeight ? parseFloat(props.shipmentInfo?.shipmentDetails?.totalWeight).toFixed(2) : 'NA'  },
                { header: 'Weight UOM', field: props.shipmentInfo?.shipmentDetails?.totalWeightUom?.toUpperCase() === 'KGS' ? "KG" : props.shipmentInfo?.shipmentDetails?.totalWeightUom?.toUpperCase() || 'NA' },
                { header: 'Pickup Country', field: props.shipmentInfo != undefined && 'NA' },
                { header: 'Pickup Zipcode', field: props.shipmentInfo != undefined && 'NA' },
                { header: 'Drop Off Country', field: props.shipmentInfo != undefined && 'NA'  },
                { header: 'Drop Off Zipcode', field: props.shipmentInfo != undefined && 'NA' },
                props.shipmentType === 'Air' ? { header: 'Flight #', field: props.shipmentInfo?.shipmentDetails?.cuurentFlightNumber || 'NA' } : { header: 'Drop Off location', field: props.shipmentInfo != undefined &&'NA' },
                { header: 'Co2 Emissions', field: `${formattedValue} ${formattedValue != 'NA' ? co2EmissionsUnit.toUpperCase() : '' }` },

            ]
        },
        {
            title: 'Port Info',
            originInfo: [
                { header: 'Port Of Origin', field: props.shipmentInfo?.route?.originDetails?.location || 'NA' },
                { header: 'Port Of Origin Code', field: props.shipmentInfo?.route?.originDetails?.locationCode || 'NA' },
                { header: 'Departure Terminal', field: 'NA' },
                { header: 'Port Of Origin ATD', field: props.shipmentInfo?.route?.originDetails?.atd ? formatDate(props.shipmentInfo.route.originDetails.atd) : 'NA' },
                { header: 'Port Of Discharge', field: props.shipmentInfo?.route?.destinationDetails?.location || 'NA' },
                { header: 'Port Of Discharge Code', field: props.shipmentInfo?.route?.destinationDetails?.locationCode || 'NA' },
                { header: 'Port Of Discharge ETA', field: props.shipmentInfo?.route?.destinationDetails?.eta ? formatDate(props.shipmentInfo.route.destinationDetails.eta) : 'NA'  },
                { header: 'Port Of Discharge ATA', field: props.shipmentInfo?.route?.destinationDetails?.ata ? formatDate(props.shipmentInfo.route.destinationDetails.ata) : 'NA' },
            ]
        },

        {
            title: 'Container Info',
            containerInfo: [
                { header: 'Container #', field: props.shipmentInfo?.shipmentDetails?.containerNumber || 'NA' },
                { header: 'Container Seal #', field: props.shipmentInfo?.shipmentDetails?.containerSealNumber || 'NA' },
                { header: 'Container Type', field:  props.shipmentInfo?.shipmentDetails?.containerType || 'NA' },
                { header: 'Container Size', field: props.shipmentInfo?.shipmentDetails?.containerSize || 'NA' },
                { header: 'Container Tare Weight', field:  'NA' },
                { header: 'Container Gross Weight', field: 'NA' },
                { header: 'Container Net Weight', field:  'NA' },
                { header: 'Empty Container Pickup Depot', field: 'NA' },
                { header: 'Empty Container Pickup Date', field: 'NA' },
                { header: 'Empty Container Pickup Time', field: 'NA' },
            ]
        },
        {
            title: 'Package Info',
            PackageInfo: [
            ]
        },

    ]
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <Box sx={{ m: 2, mb: 4 }}>
                {/* <Grid container spacing={3} sx={{ display: 'flex', width: '100%' }}>
                    <Grid item xs={6}>
                        {originInfo.map((item: any, index: any) => (
                            <Box>
                                <Typography className='shipmentInfoTitle'sx={{paddingBottom:'15px',marginBottom:'15px',borderBottom:'2px solid #ccc'}}>{item.title}</Typography>
                                <Box sx={{ mt: 1, }}>
                                    {item.originInfo.map((item1: any, index: any) => (
                                        <Box className="shipmentPortInfo" sx={{ mr: 4 ,pt:'8px'}}>
                                            <Typography sx={{ color: "#333", fontSize: "15px" }}>{item1.header}</Typography>
                                            <Typography sx={{ fontSize: "15px" }}>{item1.field}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {dischargeInfo.map((item: any, index: any) => (
                            <Box  >
                                <Typography className='shipmentInfoTitle' sx={{paddingBottom:'15px',marginBottom:'15px',borderBottom:'2px solid #ccc'}}>{item.title}</Typography>
                                <Box sx={{ mt: 1 }}>
                                    {item.dischargeInfo.map((item1: any, index: any) => (
                                        <Box className="shipmentPortInfo" sx={{ mr: 4 ,pt:'8px'}}>
                                            <Typography sx={{ color: "#333", fontSize: "15px" }}>{item1.header}</Typography>
                                            <Typography sx={{ fontSize: "15px" }}>{item1.field}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )
                        )}
                    </Grid>
                </Grid> */}
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        {cargoEsInfo.map((item: any, index: any) => (
                            <Accordion sx={{ mt: 2 }} className={props.shipmentType === 'Air' && item.title === 'Container Info' ? 'shipmentInfoHide' : 'shipmentInfoTitle'} expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    // disabled={item.title === 'Package Info' ? true : false}
                                    sx={{
                                        "& .MuiAccordionSummary-content": {
                                            margin: '0px !important',
                                            justifyContent: 'space-between',
                                            position: 'relative'


                                        },
                                        '&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
                                            borderBottom: '2px solid #ccc', // Change the border color to red
                                            color: '#1976d2'
                                        },
                                        "& .MuiAccordionSummary-root .Mui-expanded": {
                                            minHeight: '10px !important',

                                        }, m: 0
                                    }}

                                >


                                    {item.title === 'Package Info' ? <Badge badgeContent={'Genius'} color="primary" sx={{
                                        "& .MuiBadge-badge": {
                                            float: 'right',
                                            marginTop: '10px',
                                            marginRight: '-25px',
                                            backgroundColor: 'secondary',
                                            position: 'absolute'


                                        }
                                    }}>
                                        <Typography className='shipmentInfoTitle'>{item.title}</Typography>&nbsp;&nbsp;&nbsp;
                                    </Badge>
                                        : item.title === 'Shipment Info' ?
                                            <Typography className='shipmentInfoTitle' >
                                                {item.title}<span style={{
                                                    marginLeft: '10px',
                                                    fontSize: '12px',
                                                    backgroundColor: delayIndicator ? "green" :'',
                                                    padding: '7px',
                                                    borderRadius: '20px',
                                                    color: 'white'
                                                }}>
                                                    {delayIndicator ? `${subStatus2} - ${subStatus1}` : ''}
                                                </span>
                                            </Typography>

                                            :

                                            <Typography className='shipmentInfoTitle'>{item.title}</Typography>}
                                </AccordionSummary>
                                {item.title === 'Shipment Info' ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid item xs={6} sx={{ mb: 3 }}>
                                            {item.shipmentInfo.map((item1: any, index1: any) => (
                                                index1 < 5 &&
                                                <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">
                                                    <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header}</Typography>
                                                    <Typography sx={{ fontSize: "14px" }}>{item1.field}</Typography>
                                                </AccordionDetails>))
                                            }
                                        </Grid>
                                        <Grid item xs={6} sx={{ mb: 3 }}>
                                            {item.shipmentInfo.map((item1: any, index1: any) => (
                                                index1 > 4 &&
                                                <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">
                                                    <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header} {item1.header === 'Co2 Emissions' ? <img src={require('../../../asserts/leaf.png')} width={20}></img> : ''}</Typography>
                                                    <Typography sx={{ fontSize: "14px" }} className={item1.header === 'Co2 Emissions' ? 'co2Emissions' : ''}>{item1.field}</Typography>
                                                </AccordionDetails>))
                                            }
                                        </Grid>
                                    </Box>
                                    : item.title === 'Container Info' ?
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                {item.containerInfo.map((item1: any, index1: any) => (
                                                    index1 < 5 &&
                                                    <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">

                                                        <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header}</Typography>
                                                        <Typography sx={{ fontSize: "14px" }}>{item1.field}</Typography>

                                                    </AccordionDetails>))
                                                }
                                            </Grid>
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                {item.containerInfo.map((item1: any, index1: any) => (
                                                    index1 > 4 &&
                                                    <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">
                                                        <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header}</Typography>
                                                        <Typography sx={{ fontSize: "14px" }}>{item1.field}</Typography>
                                                    </AccordionDetails>))
                                                }
                                            </Grid>

                                        </Box> : item.title === 'Port Info' ? <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                {item.originInfo.map((item1: any, index1: any) => (
                                                    index1 < 4 &&
                                                    <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">

                                                        <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header}</Typography>
                                                        <Typography sx={{ fontSize: "14px" }}>{item1.field}</Typography>

                                                    </AccordionDetails>))
                                                }
                                            </Grid>
                                            <Grid item xs={6} sx={{ mb: 3 }}>
                                                {item.originInfo.map((item1: any, index1: any) => (
                                                    index1 > 3 &&
                                                    <AccordionDetails sx={{ mr: 4, pb: '0px' }} className="shipmentPortInfo">
                                                        <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>{item1.header}</Typography>
                                                        <Typography sx={{ fontSize: "14px" }}>{item1.field}</Typography>
                                                    </AccordionDetails>))
                                                }
                                            </Grid>
                                        </Box> : ''}
                            </Accordion>
                        ))
                        }
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

import React, { useEffect, useMemo, useState } from 'react'
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { Alert, AlertTitle, Box } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { Endpoints } from '../Enviornment/Endpoints';
import { ApiRequestFunction } from '../Services/BaseService';
export default function Echarts(props: any) {
    const [shipmentInfo, setShipmentInfo] = useState(false)
    const [chartResp, setChartResp] = useState([])
    const [chartInfo, setChartInfo] = useState(false)
    const [data, setData] = useState([])
    const [yAxisData, setYAxisData] = useState([])
    const [avgMin, setAvgMin] = useState([])
    let Api_Config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
    }
    const yAxisNames = ['Shipments', 'Avg Response Time In Hrs']
    const monthOnMonth = {
        months: ['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March'],
        bookingRequest: {
            shipments: [5400, 3880, 3449, 3018, 3880, 3449, 3880, 4311, 3449, 4742, 4311, 4742],
            avgResp: [6.32, 5.54, 6.42, 7.36, 6.12, 6.31, 6.42, 5.42, 6.21, 6.43, 6.52, 6.12]
        },
        acceptedRequest: {
            shipments: [4900, 3654, 3223, 2792, 3654, 3223, 3654, 4086, 3223, 4517, 4086],
            avgResp: [6.32, 5.54, 6.42, 7.36, 6.12, 6.31, 6.42, 5.42, 6.21, 6.43, 6.52, 6.12]
        },
        confirmedRequest: {
            shipments: [4700, 3634, 3203, 2702, 3634, 3203, 3634, 4066, 3203, 4497, 4064, 4990],
            avgResp: [6.32, 5.54, 6.42, 7.36, 6.12, 6.31, 6.42, 5.42, 6.21, 6.43, 6.52, 6.12]
        }
    }
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        color: ['#004F92', '#e19907'],
        legend: {
            bottom: 0,
            data: yAxisNames
        },
        xAxis: [
            {
                minInterval:1,
                type: 'category',
                data: data,
                axisPointer: {
                    type: 'shadow'
                },
            },

        ],
        yAxis: [
            {
                type: 'value',
                name: yAxisNames[0],
                min: 0,
                // max: 100,
                interval: 1000,
                axisLabel: {
                    formatter: '{value}'
                },
                minInterval:1,
            },
            props.page != 'BookingLifeCycle' ?
                {
                    minInterval:1,
                    type: 'value',
                    name: yAxisNames[1],
                    min: 0,
                    interval: 2,
                    axisLabel: {
                        formatter: '{value}'
                    }
                } : {
                    type: 'value',
                    minInterval:1,
                    name: yAxisNames[1],

                }
        ],
        series: [
            {
                name: yAxisNames[0],
                type: 'bar',
                barWidth:30,
                tooltip: {
                    valueFormatter: function (value: any) {
                        return (value as number);
                    }
                },
                data: yAxisData
            },
            {
                name: yAxisNames[1],
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value: any) {
                        return (value as number);
                    }
                },
                data: avgMin
            }
        ],
    }
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const onEvents = {
        click: onChartClick,
    };
    function onChartClick(params: any) {
        console.log(params, "$params")
        shipmentsMoreInfoList(params)

    }
    const shipmentsMoreInfoList = (parmas: any) => {
        setShipmentInfo(true);
        chartResp.filter((item: any) => {
            if (parmas.name === item.carrierName) {
                props.parentCallback({ status: true, carrier: item.shipmentsData, carrierName: parmas.name })
            }
        })

    }
    useEffect(() => {
        console.log(props, "propsssss")
        monthOnmonthTrend(props.page)
    }, [])
    const monthOnmonthTrend = (status: any) => {
        if (props.page != 'carrierBookings') {
            axios.get(Endpoints.Accepted_Booking_Request_MOM_Trend, Api_Config).then((res1: any) => {
                let carrierInfo: any = []
                let percentageInfo: any = []
                let avgMindata: any = []
                console.log(res1.data.data, "resppppp")
                if (res1.data.data.length > 0) {
                    let resp = [...res1.data.data].reverse()
                    setChartResp(res1.data.data)
                    for (let i = 0; i < resp.length; i++) {
                        carrierInfo.push(resp[i].monthName)
                        percentageInfo.push(resp[i].acceptedBookingsCount)
                        avgMindata.push(resp[i].avgBookingRespTime)
                    }
                    setData(carrierInfo)
                    setChartInfo(true)
                } else {
                    setChartInfo(false)
                }

                setYAxisData(percentageInfo)
                setAvgMin(avgMindata)
                // setChartInfo(true)


            })
        } else {
            axios.get(Endpoints.Carrier_Booking_Request_MOM_Trend, Api_Config).then((res1: any) => {
                let carrierInfo: any = []
                let percentageInfo: any = []
                let avgMindata: any = []
                console.log(res1.data.data, "resppppp")
                if (res1.data.data.length > 0) {
                    let resp = [...res1.data.data].reverse()
                    setChartResp(res1.data.data)
                    for (let i = 0; i < resp.length; i++) {
                        carrierInfo.push(resp[i].monthName)
                        percentageInfo.push(resp[i].acceptedBookingsCount)
                        avgMindata.push(resp[i].avgBookingRespTime)
                    }
                    setData(carrierInfo)
                    setChartInfo(true)
                } else {
                    setChartInfo(false)
                }

                setYAxisData(percentageInfo)
                setAvgMin(avgMindata)
                // setChartInfo(true)


            })
        }

    }
    const deliveredShipmentByCarrierAnalytics = (status: any) => {
        config.params = status
        axios.get(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/lifeCycleMonthTrend', config).then((res1: any) => {
            let carrierInfo: any = []
            let percentageInfo: any = []
            if (res1.data.data.length > 0) {
                setChartResp(res1.data.data)
                for (let i = 0; i < res1.data.data.length; i++) {
                    carrierInfo.push(res1.data.data[i].carrierName)
                    percentageInfo.push(res1.data.data[i].percentage)
                }
                setData(carrierInfo)
                setChartInfo(true)
            } else {
                setChartInfo(false)
            }

            setYAxisData(percentageInfo)
            // setChartInfo(true)


        })
    }
    const etaDeviationShipmentByCarrierData = (status: any) => {
        config.params = status
        axios.get(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/lifeCycleMonthTrend', config).then((res1: any) => {
            let carrierInfo: any = []
            let percentageInfo: any = []
            if (res1.data.data.length > 0) {
                console.log("hiii")
                setChartResp(res1.data.data)
                for (let i = 0; i < res1.data.data.length; i++) {
                    carrierInfo.push(res1.data.data[i].carrierName)
                    percentageInfo.push(res1.data.data[i].percentage)
                }
                setData(carrierInfo)
                setChartInfo(true)
            } else {
                setChartInfo(false)
            }

            setYAxisData(percentageInfo)
            // setChartInfo(true)


        })
    }
    return (
        <div>
            {chartInfo ?
                <Box >
                    <ReactECharts option={options}
                        onEvents={onEvents} />
                </Box>
                : (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        No data found
                    </Alert>
                )}

        </div>
    )
}

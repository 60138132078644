import {
    Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, FormControl, SelectChangeEvent, InputLabel, MenuItem, OutlinedInput, Checkbox, ListItemText, Alert, AlertTitle, IconButton, List, ListItemButton, ListItemIcon, Divider, InputBase, Select, 
    Tooltip,
} from '@mui/material';
import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../Styles.css'
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { FirstDataRenderedEvent, GridReadyEvent } from 'ag-grid-community';
import { useOutletContext } from 'react-router-dom';
import Loaders from '../components/common/Loaders';
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';

declare var $: any;
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function CommonInTransitInfo(props: any) {
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm1, setSearchTerm1] = useState('');
    const [shipmentsCount, setShipmentsCount] = useState<any>([])
    const gridRef = useRef<any>();
    const domLayout = "autoHeight";
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [destinationCountries, setDestinationCountries] = useState<any>([])
    // const [load, setLoad] = useOutletContext<any>();
    const [selectedItems, setSelectedItems] = useState<any>([])
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    // const filterData = rowData.filter((data: any, index: any) =>
    //     Object.values(data).some((value: any) =>
    //         value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //     )
    // );
    const filterData = rowData.filter((data: any) => {
        return Object.values(data).some((value) => {
            if (value === null || value === undefined || value === '') {
                // Include null and undefined values
                return false;
            }
            return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
    });
    console.log(filterData, "filter")
    const columns: TableColumn<any>[] = [
        {
            name: "INVOICE #",
            selector: row => row.invoiceNumber !== '' ? row.invoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },
        {
            name: "HBL #",
            selector: row => row.hblNumber !== '' ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: "HAWB #",
            sortable: true,
            selector: row => row.hawbNumber !== '' ? row.hawbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            width: '200px',
        },
        {
            name: "MBL #",
            selector: row => row.mblNumber !== '' ? row.mblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '150px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: "MAWB #",
            sortable: true,
            selector: row => row.awbNumber !== '' ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            width: '200px',
 
        },
        {
            name: "CONTAINER #",
            selector: row => row.containerNumber !== '' ? <Tooltip title={row.containerNumber} arrow><span>{row.containerNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '250px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        // {
        //     name: "FLIGHT #",
        //     selector: row => row.flightNumber !== '' ? row.flightNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
        //     width: '150px',
        //     omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
        // },
        {
            name: "CARRIER",
            selector: row => row.carrierName !== '' ? <Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },

        {
            name: "SHIPPER",
            selector: row => row.shipperName !== '' && row.shipperName !== null && row.shipperName != undefined ? <Tooltip title={row.shipperName} arrow><span>{row.shipperName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '220px'
          },
          {
            name: "CONSIGNEE",
            selector: row => row.consingneeName !== '' && row.consingneeName !== null && row.consingneeName != undefined ? <Tooltip title={row.consingneeName} arrow><span>{row.consingneeName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,  
            width: '220px'
          },
        {
            name: "ORIGIN",
            selector: (row) => (
                <div key={row.origin}>
                  {row.origin !== '' ? (
                    <Tooltip title={row.origin} arrow>
                      <span>{row.origin}</span>
                    </Tooltip>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>- NA -</span>
                  )}
                </div>
              ),
            width: '300px',
        },
        {
            name: "DESTINATION",
            selector: row => row.destination !== '' && row.destination !== null && row.destination != undefined ? <Tooltip title={row.destination} arrow><span>{row.destination}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            // wrap: false,
            width: '300px',
        }

    ]
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        item: any
    ) => {
        setSelectedIndex(index);
        console.log(item, "itemmm")
        setRowData(item.shipmentList)

    };
    useEffect(() => {
        etaDeviationAnalyticsEventsData('all')

    }, [])
    const etaDeviationAnalyticsEventsData = (country: any) => {
        let status: any;
        if (props.pageInfo.tab === "arrivingToday" || props.pageInfo.tab === "Next 2 Hrs") {
            status = {
                dataCode: 0,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }

            }

        } else if (props.pageInfo.tab === "arrivingNextDay" || props.pageInfo.tab === ">2 Hrs to <=12 Hrs") {
            status = {
                dataCode: 1,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        } else if (props.pageInfo.tab === "arrivingNext7Days" || props.pageInfo.tab === ">12 Hrs to <=24 Hrs") {
            status = {
                dataCode: 7,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        } else {
            status = {
                dataCode: 8,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        }
        config.params = status
        setLoad(true)
        let destinationList: any = []
        axios.get(Endpoints.Intransit_Shipments_Insights, config).then((res: any) => {
            setLoad(false)
            if (res.data.data.result.length > 0) {
                setShipmentsCount(res.data.data.result)
                setRowData(res.data.data.result[selectedIndex].shipmentList)
                if (country === 'all') {
                    setDestinationCountries(res.data.data.countries)
                }

            }

        })
    }
    const handleChangeDestination = (e: any) => {
        setSelectedItems(e)
        const selectedOptionLabels = e.map((option: any) => option.label);
        console.log(selectedOptionLabels, selectedItems, "selected labels")
        const stringResult = selectedOptionLabels.join(',');

        etaDeviationAnalyticsEventsData(stringResult.length > 0 ? stringResult : 'all')

    }
    return (
        <div >
            {
                rowData.length > 0 ?
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <MultiSelect
                            options={destinationCountries.map((label: any) => ({ label, value: label }))}
                            value={selectedItems}
                            onChange={handleChangeDestination}
                            labelledBy="Select By Country"
                            className='multiSelect'
                        />
                        <Paper
                            component="form"
                            elevation={0}
                            className="custom-search"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, float: 'right' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={props.pageInfo.shipmentType === 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : "Search By HAWB/MAWB/Invoice"}
                                inputProps={{ 'aria-label': 'search google maps' }}
                                size="small"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                                {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
                            </IconButton>
                        </Paper>
                    </div> : ''}
            {
                rowData.length > 0 && !load ?
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
                                {shipmentsCount.map((item: any, index: any) => (
                                    <List component="nav" aria-label="main mailbox folders">
                                        <ListItemButton
                                            selected={selectedIndex === index}
                                            onClick={(event: any) => handleListItemClick(event, index, item)}
                                        >
                                            <ListItemText primary={item._id.eventName} />({item.count})

                                        </ListItemButton>

                                    </List>
                                ))}
                            </Box>
                            <Divider orientation="vertical" flexItem />
                        </Grid>
                        <Grid item xs={9}>
                            <Box sx={{ mt: 2 }}>
                                {load ? <Loaders /> :
                                    <DataTable
                                        style={{ borderRadius: 3 }}
                                        columns={columns}
                                        data={filterData}
                                        responsive={true}
                                        customStyles={customStyles}
                                        theme="default"
                                        pagination
                                        dense
                                        striped
                                        highlightOnHover={true}


                                    />
                                }
                            </Box>

                        </Grid>
                    </Grid> : load && rowData.length === 0 ? <Loaders /> :
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>}

        </div>
    )
}

import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import BarEcharts from './BarEcharts'
import { AgGridReact } from 'ag-grid-react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import { Endpoints } from '../Enviornment/Endpoints';
import { ApiRequestFunction } from '../Services/BaseService';
export default function (props: any) {
    console.log("Tab Name", props.pageInfo.tab)
    let BookingWidgetList: any = [
        {
            title: 'Last 12 Months',
            totalBookings: '',
            pendingBookingsTitle: 'Pending Bookings',
            pendingBookingsCount: '',
            acceptedBookingsTitle: 'Accepted Bookings',
            acceptedBookingsCount: '',
            avgBookingRespTimeTitle: 'Avg Bookings Response time',
            avgBookingRespTimeCount: '',
            bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
            bookingResponseIn3HRCount: ''

        },
        {
            title: 'Last 6 Months',
            totalBookings: '',
            pendingBookingsTitle: 'Pending Bookings',
            pendingBookingsCount: '',
            acceptedBookingsTitle: 'Accepted Bookings',
            acceptedBookingsCount: '',
            avgBookingRespTimeTitle: 'Avg Bookings Response time',
            avgBookingRespTimeCount: '',
            bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
            bookingResponseIn3HRCount: ''
        },
        {
            title: 'Last 3 Months',
            totalBookings: '',
            pendingBookingsTitle: 'Pending Bookings',
            pendingBookingsCount: '',
            acceptedBookingsTitle: 'Accepted Bookings',
            acceptedBookingsCount: '',
            avgBookingRespTimeTitle: 'Avg Bookings Response time',
            avgBookingRespTimeCount: '',
            bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
            bookingResponseIn3HRCount: ''
        },
        {
            title: 'Current Month',
            totalBookings: '',
            pendingBookingsTitle: 'Pending Bookings',
            pendingBookingsCount: '',
            acceptedBookingsTitle: 'Accepted Bookings',
            acceptedBookingsCount: '',
            avgBookingRespTimeTitle: 'Avg Bookings Response time',
            avgBookingRespTimeCount: '',
            bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
            bookingResponseIn3HRCount: ''
        }
    ]
    const [childData, setChildData] = useState(false)
    const [widgetsList, setWidgetList] = useState(BookingWidgetList)
    const handleCallback = (childData: any) => {
        console.log(childData, "chield data")
        setChildData(childData)
    }
    let Api_Config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
      }
    useEffect(() => {
        bookingRequestWidgets()
    }, [])
    const bookingRequestWidgets = () => {
        let bookingResp: any;
        if (props.pageInfo.tab === 'forwarderBookings') {
            axios.get(Endpoints.Booking_Request_Insights, Api_Config).then((res: any) => {
                bookingResp = res.data.data[0]
                bookingsData(bookingResp)

            })
        } else if (props.pageInfo.tab === 'carrierBookings') {
            axios.get(Endpoints.Carrier_Booking_Request_Insights, Api_Config).then((res: any) => {
                bookingResp = res.data.data[0]
                bookingsData(bookingResp)
            })
        }
    }
    const bookingsData = (bookingResp: any) => {
        if (bookingResp) {
            let BookingWidgetList: any = [
                {
                    title: 'Last 12 Months',
                    totalBookings: bookingResp.yearData[0]?.totalBookingCount,
                    pendingBookingsTitle: 'Pending Bookings',
                    pendingBookingsCount: bookingResp.yearData[0].pendingBookingsCount,
                    acceptedBookingsTitle: 'Accepted Bookings',
                    acceptedBookingsCount: bookingResp.yearData[0].acceptedBookingsCount,
                    avgBookingRespTimeTitle: 'Avg Bookings Response time',
                    avgBookingRespTimeCount: bookingResp.yearData[0].avgBookingRespTime.toFixed(2),
                    bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
                    bookingResponseIn3HRCount: bookingResp.yearData[0].bookingRespLessThan3hrs

                },
                {
                    title: 'Last 6 Months',
                    totalBookings: bookingResp.halfYearData[0]?.totalBookingCount,
                    pendingBookingsTitle: 'Pending Bookings',
                    pendingBookingsCount: bookingResp.halfYearData[0].pendingBookingsCount,
                    acceptedBookingsTitle: 'Accepted Bookings',
                    acceptedBookingsCount: bookingResp.halfYearData[0].acceptedBookingsCount,
                    avgBookingRespTimeTitle: 'Avg Bookings Response time',
                    avgBookingRespTimeCount: bookingResp.halfYearData[0].avgBookingRespTime.toFixed(2),
                    bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
                    bookingResponseIn3HRCount: bookingResp.halfYearData[0].bookingRespLessThan3hrs
                },
                {
                    title: 'Last 3 Months',
                    totalBookings: bookingResp.quarterYearData[0]?.totalBookingCount,
                    pendingBookingsTitle: 'Pending Bookings',
                    pendingBookingsCount: bookingResp.quarterYearData[0].pendingBookingsCount,
                    acceptedBookingsTitle: 'Accepted Bookings',
                    acceptedBookingsCount: bookingResp.quarterYearData[0].acceptedBookingsCount,
                    avgBookingRespTimeTitle: 'Avg Bookings Response time',
                    avgBookingRespTimeCount: bookingResp.quarterYearData[0].avgBookingRespTime.toFixed(2),
                    bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
                    bookingResponseIn3HRCount: bookingResp.quarterYearData[0].bookingRespLessThan3hrs
                },
                {
                    title: 'Current Month',
                    totalBookings: bookingResp.currentMonthData[0]?.totalBookingCount,
                    pendingBookingsTitle: 'Pending Bookings',
                    pendingBookingsCount: bookingResp.currentMonthData[0].pendingBookingsCount,
                    acceptedBookingsTitle: 'Accepted Bookings',
                    acceptedBookingsCount: bookingResp.currentMonthData[0].acceptedBookingsCount,
                    avgBookingRespTimeTitle: 'Avg Bookings Response time',
                    avgBookingRespTimeCount: bookingResp.currentMonthData[0].avgBookingRespTime.toFixed(2),
                    bookingResponseIn3HRTitle: 'Booking Response In <3 HR',
                    bookingResponseIn3HRCount: bookingResp.currentMonthData[0].bookingRespLessThan3hrs
                }
            ]
            setWidgetList(BookingWidgetList)
        }
    }
    return (
        <div>
            <Grid container spacing={2} sx={{ display: 'flex' }}>
                {widgetsList.map((item: any, index: any) =>
                    <Grid item md={4} lg={3} sm={6} xs={12} className="mainGrid">
                        <Paper elevation={0} className="card">
                            <Box className="card-body">
                                <Grid item xs={9}>
                                    <Typography variant='h5' sx={{ fontSize: '1rem' }}>{item.title}</Typography>
                                    <Typography variant='h6' sx={{ fontSize: '30px' }}>
                                        {item.totalBookings ? item.totalBookings : 0} <span style={{ fontSize: '12px' }}>Bookings</span>
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>{item.pendingBookingsTitle}: &nbsp;</Typography>
                                        <Typography>{item.pendingBookingsCount ? item.pendingBookingsCount : 0}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>{item.acceptedBookingsTitle}: &nbsp;</Typography>
                                        <Typography>{item.acceptedBookingsCount ? item.acceptedBookingsCount : 0}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>{item.avgBookingRespTimeTitle}: &nbsp;</Typography>
                                        <Typography>{item.avgBookingRespTimeCount ? item.avgBookingRespTimeCount + 'HRS' : 'NA'}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px', fontStyle: 'italic', color: 'blue' }}>{item.bookingResponseIn3HRTitle}: &nbsp;</Typography>
                                        <Typography>{item.bookingResponseIn3HRCount ? item.bookingResponseIn3HRCount : 0}</Typography>
                                    </Box>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <Grid>
                <BarEcharts data={props.pageInfo.tab === 'forwarderBookings' ? 'totalBookings' : 'avgTime'} parentCallback={handleCallback} pageInfo={props.pageInfo.tab} />
            </Grid>
            <a href="#" id="roll_back" className="animate">
                <KeyboardArrowUpIcon />
            </a>

        </div>
    )
}

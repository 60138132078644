import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  ICellRendererParams,
  FirstDataRenderedEvent,
} from "ag-grid-community";

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle, Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup } from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import { Typography } from "@material-ui/core";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import "../../../Styles.css";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import React from "react";
import { Endpoints } from "../../../Enviornment/Endpoints";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function RejectedByCarrier() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };

  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{ color: "blue", lineHeight: 0.5 }}
            onClick={() => handleData(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },

    {
      headerName: "REJECTED REASON",
      headerTooltip: "REJECTED REASON",
      tooltipField: "rejectedReason",
      field: "rejectedReason",
    },
    // {
    //   headerName: "INVOICE DATE",
    //   field: "invoiceDate",
    //   filter: true,
    //   headerTooltip: "INVOICE DATE",
    //   tooltipField: "invoiceDate",
    //   columnGroupShow: "closed",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "PURCHASE ORDER #",
    //   field: "purchaseOrderNumber",
    //   filter: "true",
    //   headerTooltip: "PURCHASE ORDER #",
    //   tooltipField: "purchaseOrderNumber",
    // },
    {
      headerName: "FORWARDER REFERENCE #",
      headerTooltip: "FORWARDER REFERENCE #",
      tooltipField: "bookingReference",
      field: "bookingReference",
    },
    {
      headerName: "HBL #",
      field: "hbl",
      headerTooltip: "HBL #",
      tooltipField: "hbl",
      hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
    },
    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
    },

    {
      headerName: "PICKUP COUNTRY",
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      headerTooltip: "CONSIGNEE ID",
      field: "consigneeId",
      tooltipField: "consigneeId",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE",
      headerTooltip: "CONSIGNEE",
      field: "consigneeName",
      tooltipField: "consigneeName",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE CONTACT #",
      headerTooltip: "CONSIGNEE CONTACT #",
      field: "consingneeContactNumber",
      tooltipField: "consingneeContactNumber",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },
    {
      headerName: "CONSIGNEE EMAIL",
      headerTooltip: "CONSIGNEE EMAIL",
      field: "consigneeEamilAddress",
      tooltipField: "consigneeEamilAddress",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'consignee' ? false : true
    },

    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "shipperId",
      hide: sessionStorage.getItem('role') != 'shipper' ? false : true
    },
    {
      headerName: "SHIPPER",
      headerTooltip: "SHIPPER",
      tooltipField: "shipperName",
      field: "shipperName",
      columnGroupShow: "closed",
      hide: sessionStorage.getItem('role') != 'shipper' ? false : true
    },
    {
      headerName: "SHIPPER DATE",
      headerTooltip: "SHIPPER DATE",
      tooltipField: "requestedDateOfDelivery",
      field: "requestedDateOfDelivery",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
      hide: sessionStorage.getItem('role') != 'shipper' ? false : true
    },
    // {
    //   headerName: "SHIPPER VOLUME",
    //   filter: true,
    //   headerTooltip: "SHIPPER VOLUME",
    //   tooltipField: "shipperVolume",
    //   field: "shipperVolume",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "SHIPPER WEIGHT",
    //   filter: true,
    //   headerTooltip: "SHIPPER WEIGHT",
    //   tooltipField: "shipperWeight",
    //   field: "shipperWeight",
    //   columnGroupShow: "closed",
    // },
    {
      headerName: "MODE OF SHIPMENT",
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
      columnGroupShow: "closed",
    },

    {
      headerName: "DROPOFF LOCATION",
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIP CODE",
      headerTooltip: "DROPOFF ZIP CODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "MODE OF SHIPMENT",
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "CARRIER CREATE DATE",
      headerTooltip: "CARRIER CREATE DATE",
      tooltipField: "crCreatedDate",
      field: "crCreatedDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "BOOKING REQUEST UPDATE DATE",
      headerTooltip: "BOOKING REQUEST UPDATE DATE",
      tooltipField: "brPickupDate",
      field: "brPickupDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "REJECTED BY",
      headerTooltip: "REJECTED BY",
      tooltipField: "modifiedBy",
      field: "modifiedBy",
    },
    {
      headerName: "REJECTED DATE",
      headerTooltip: "REJECTED DATE",
      tooltipField: "crModifiedDate",
      field: "crModifiedDate",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "INCO TERMS",
      headerTooltip: "INCO TERMS",
      tooltipField: "incoTerms",
      field: "incoTerms",
    },

    // {
    //   headerName: "STATUS ",
    //   filter: true,
    //   headerTooltip: "ACTIVE STATUS",
    //   tooltipField: "activeStatus",
    //   field: "activeStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     let val = params.value.charAt(0).toUpperCase();
    //     let ans = val + params.value.slice(1).toLowerCase();
    //     return (
    //       <Box className="Active">
    //         <Typography>{ans}</Typography>
    //         <span className="active"></span>
    //       </Box>
    //     );
    //   },
    // },
  ]);
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let requestPayload = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    cbrStatus: "open,processed",
    brStatus: "rejected",
    shipmentType: selectedValue,
    type: sessionStorage.getItem('role'),
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
  };
  useEffect(() => {
    setLoad(true);
    setShowGrid(true);
    getRejectedByCarrierInfo()
  }, [fromDate, toDate]);
  const getRejectedByCarrierInfo =() =>{
    Api_Config.params = requestPayload
    axios.get(Endpoints.Pending_Carrier_Booking_HeaderList, Api_Config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
    });
  }

  const onGridReady = useCallback((params: GridReadyEvent) => { 
    sessionStorage.setItem('shipmentType', selectedValue)
  }, []);

  const gridRef = useRef<any>();

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSelectedValue(event.target.value);
    // if (event.target.value === 'Ocean') {
    //   let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
    //     if (ele.userProvidedColDef.hide != undefined) {
    //       ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
    //     }
    //     return ele.userProvidedColDef
    //   });
    //   gridRef.current.api.setColumnDefs(a)
    //   autosize()
    // } else {
    //   let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
    //     if (ele.userProvidedColDef.hide != undefined) {
    //       ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide

    //     }
    //     return ele.userProvidedColDef
    //   });
    //   gridRef.current.api.setColumnDefs(a)
    //   autosize()
    // }
    requestPayload.shipmentType = event.target.value
    setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType', event.target.value)
    getRejectedByCarrierInfo()

  };
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <CrLineItems
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={PoData}
              status={"rejected"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default RejectedByCarrier;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Alert, AlertTitle, Badge, Box, Chip, Divider, Grid, Link, List, ListItem, ListItemText, Step, StepContent, Stepper } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import { TransitionProps } from "@mui/material/transitions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyMapComponent from "./Maps";
import Charts from "./Charts";
import IntransitFileViewer from "./IntransitFileViewer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useState } from "react";
import '../Styles.css'
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";
import { display } from "@mui/system";
import { useOutletContext } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import IntransitShipmentInfo from "../components/pages/Intransit/IntransitShipmentInfo";
import Loaders from "../components/common/Loaders";
import { Endpoints } from "../Enviornment/Endpoints";
import swal from "sweetalert";
import IntransitExceptions from "./IntransitExceptions";
import moment from "moment";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function IntransitEventsMapping(props: any) {
  console.log("Props data is", props.data)
  const [ETA, setEta] = useState(props.data.eta)
  const [changeInETA, setChangeInEta] = useState(props.data.changeInEta)
  let locations: { name: any; location: { lat: any; lng: any } }[] = [];
  console.log("Full screen Data 123456789", props.line);
  const [value, setValue] = useState("1");
  const [sort, setSort] = useState(false);
  const [mapData, setMapData] = useState<any>(props.line.legInfo != undefined ? props.line.legInfo : '');
  const [shipmentEventData, setShipmentEventData] = useState<any>([]);
  const [load, setLoad] = useOutletContext<any>();
  const [shipmentLegs, setShipmentLegs] = useState<any>()
  const [cargoEsData, setCargoEsData] = useState<any>()
  const [activeStep, setActiveStep] = React.useState(shipmentEventData.length);
  const [eventsTrackingMap, setEventsTrackingMap] = useState()
  const [latestEvents, setLatestEvents] = useState<any>([])
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }

  const [Delay, setDelay] = useState("");
  const delay = {
    days: [0],
    minutes: [0],
    hours: [0],
  }

  const caluclateDate = (index: any) => {
    console.log(latestEvents, "index")
    let time: any = 0;
    let date1:any, date2:any, date3:any, date4, date;

    if (index < latestEvents.length) {
      if (latestEvents[index].actualTime !== null && latestEvents[index].actualTime !== '') {
        if (latestEvents[index + 1].actualTime !== null && latestEvents[index + 1].actualTime !== '') {
          date3 = formatDate(latestEvents[index].actualTime)+'';
          date4 = formatDate(latestEvents[index + 1].actualTime)+''
          console.log(typeof(date3),date3,"date3")
          date1 = new Date(date3);
          date2 = new Date(date4)
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          console.log(date,date1,date2, "dateeeeeeeeeeeee")
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Minutes are 93,.........................",Math.abs(Math.round(date * 100) / 100) * 100)
            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
  
              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if(hours == 0 ){
                return `${minutes} Minutes`;
              }
  
              return `${hours} Hours ${minutes} Minutes`;
              
            }
          const hours = Math.floor(date);
          const totalMinutes = Math.round((date - hours) * 60);
          const minutes = totalMinutes % 60;

          const hoursText = hours > 0 ? `${hours} Hour${hours > 1 ? 's' : ''}` : '';
          const minutesText = minutes > 0 ? `${minutes} Minute${minutes > 1 ? 's' : ''}` : '';

          if (hoursText && minutesText) {
            return `${hoursText} ${minutesText}`;
          } else if (hoursText) {
            return hoursText;
          } else if (minutesText) {
            return minutesText;
          } else {
            return "Less than a minute";
          }
        }
          if (date <= 0) date = -1 * date
          if (Math.round(date) == 1) return "1 Day"
          return Math.round(date) + " Days"
        }
        else if (latestEvents[index + 1].estimateTime !== null && latestEvents[index + 1].estimateTime !== '') {
          
          date3 = formatDate(latestEvents[index].actualTime)+'';
          date4 = formatDate(latestEvents[index + 1].estimateTime)+'';
          date1 = new Date(date3);
          date2 = new Date(date4);
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date)
            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
  
              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if(hours == 0 ){
                return `${minutes} Minutes`;
              }
  
              return `${hours} Hours ${minutes} Minutes`;
              
            }
          const hours = Math.floor(date);
          const totalMinutes = Math.round((date - hours) * 60);
          const minutes = totalMinutes % 60;

          const hoursText = hours > 0 ? `${hours} Hour${hours > 1 ? 's' : ''}` : '';
          const minutesText = minutes > 0 ? `${minutes} Minute${minutes > 1 ? 's' : ''}` : '';

          if (hoursText && minutesText) {
            return `${hoursText} ${minutesText}`;
          } else if (hoursText) {
            return hoursText;
          } else if (minutesText) {
            return minutesText;
          } else {
            return "Less than a minute";
          }
        }
          if (date < 0) date = -1 * date
          delay.days.push(Math.round(date))
          if (Math.round(date) == 1) return "1 Day"

          return Math.round(date) + " Days"
        }
        else {
          time = latestEvents[index].actualTime
          return time
        }
      }
      else if (latestEvents[index].estimateTime !== null && latestEvents[index].estimateTime !== '') {
        console.log(latestEvents[index].estimateTime, "index")
        if (latestEvents[index + 1].estimateTime !== null) {
          // date3 = formatDate(latestEvents[index].actualTime)+'';
          date3 = formatDate(latestEvents[index].estimateTime)+'';
          date4 = formatDate(latestEvents[index + 1].estimateTime)+'';
          date1 = new Date(date3);
          date2 = new Date(date4)
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          console.log(date, "dateeeeeeeeeeeee")
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date)
            // if (date < 1) return (Math.abs(Math.round(date * 100) / 100) * 100).toFixed(0) + ' Minutes'
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
  
              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if(hours == 0 ){
                return `${minutes} Minutes`;
              }
  
              return `${hours} Hours ${minutes} Minutes`;
              
            }
          const hours = Math.floor(date);
          const totalMinutes = Math.round((date - hours) * 60);
          const minutes = totalMinutes % 60;

          const hoursText = hours > 0 ? `${hours} Hour${hours > 1 ? 's' : ''}` : '';
          const minutesText = minutes > 0 ? `${minutes} Minute${minutes > 1 ? 's' : ''}` : '';

          if (hoursText && minutesText) {
            return `${hoursText} ${minutesText}`;
          } else if (hoursText) {
            return hoursText;
          } else if (minutesText) {
            return minutesText;
          } else {
            return "Less than a minute";
          }
        }
          if (date < 0) date = -1 * date
          delay.days.push(Math.round(date))
          if (Math.round(date) == 1) return "1 Day"

          return Math.round(date) + " Days"
        }
        else if (latestEvents[index + 1].actualTime !== null && latestEvents[index + 1].actualTime !== '') {
          date3 = formatDate(latestEvents[index].estimateTime)+'';
          date4 = formatDate(latestEvents[index + 1].actualTime)+'';
          date1 = new Date(date3)
          date2 = new Date(date4)
          date = Math.abs((date2 - date1) / (1000 * 3600 * 24));
          if (date <= 1) {
            date = Math.abs((date2 - date1) / (1000 * 3600));
            console.log("Hours Are...", date)
            if (date < 1) {
              const totalMinutes = Math.round(date * 60);
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
  
              if (hours === 1 && minutes === 1) return "1 Hour 1 Minute";
              if (hours === 1) return `1 Hour ${minutes} Minutes`;
              if (minutes === 1) return `${hours} Hours 1 Minute`;

              if(hours == 0 ){
                return `${minutes} Minutes`;
              }
  
              return `${hours} Hours ${minutes} Minutes`;
              
            }
          const hours = Math.floor(date);
          const totalMinutes = Math.round((date - hours) * 60);
          const minutes = totalMinutes % 60;

          const hoursText = hours > 0 ? `${hours} Hour${hours > 1 ? 's' : ''}` : '';
          const minutesText = minutes > 0 ? `${minutes} Minute${minutes > 1 ? 's' : ''}` : '';

          if (hoursText && minutesText) {
            return `${hoursText} ${minutesText}`;
          } else if (hoursText) {
            return hoursText;
          } else if (minutesText) {
            return minutesText;
          } else {
            return "Less than a minute";
          }
        }
          if (date < 0) date = -1 * date
          if (Math.round(date) == 1) return "1 Day"
          return Math.round(date) + " Days"
        }
        else {
          time = formatDate(latestEvents[index].estimateTime)
          console.log(time, "time")
          return time
        }
      }
    }
    return (
      <span>{time}</span>
    )


  }


  // ------------- events ----------------
  
 
  const handleReset = () => {
    setActiveStep(0);
  };
  React.useEffect(() => {
    getEventData(
      props.line.containerNumber,
      props.line.hscode,
      props.line.invoiceNumber,
      props.line.awbNumber,
      props.line.shipmentReference
    );
  }, []);

  function formatDate(input: string) {
    // Validate the date string
    if (!input || typeof input !== 'string' || input.trim() === '') {
      return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
    }

    // Regex pattern to validate the format "YYYY-MM-DD HH:MM:SS AM/PM"
    const pattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} (AM|PM)$/;

    if (!pattern.test(input)) {
      return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
    }

    // Use moment's string parsing capabilities with a format declaration
    let date = moment(input, 'YYYY-MM-DD hh:mm:ss A');

    if (!date.isValid()) {
      return <span style={{ fontStyle: 'italic' }}>-NA-</span>;
    }

    // Return the formatted date
    return date.format('DD-MMM-YYYY hh:mm:ss A');
  }


  let dateString = '2023-10-17 09:00:00 AM';
  console.log(formatDate(dateString));

  const getEventData = (data: any, hs: any, inv: any, awb: any, shipmentRef: any) => {
    setLoad(true)
    axios.post(Endpoints.Intransit_Events, {
      containerNumber: data,
      hscode: hs,
      invoiceNumber: inv,
      shipmentType: props.line.modeOfShipment,
      awbNumber: awb,
      shipmentReference: shipmentRef
    }, Api_Config).then((evedata) => {
      setLoad(false)
      try {
        if (evedata.data.primary.events.length > 0) {
          setLatestEvents(evedata.data.primary.events.reverse());
          setCargoEsData(evedata.data.primary)
          // if (evedata.data.primary.cargoEsData.length > 0) {
          //   if (evedata.data.primary.cargoEsData[0].shipmentEvents != undefined && evedata.data.primary.cargoEsData[0].shipmentEvents.length > 0) {
          //     setShipmentLegs(evedata.data.primary.cargoEsData[0].shipmentLegs);
          //     setCargoEsData(evedata.data.primary.cargoEsData)
          //     setEventsTrackingMap(evedata.data.primary.sharingUrl)
          //     console.log(evedata.data.primary.sharingUrl, "tracking ")
          //   }
          //   else {
          //     console.log(evedata.data.primary.events, "event data")
          //     setShipmentLegs(evedata.data.primary.latestEvents)

          //   }
          // }
          // else {
          //   setShipmentLegs(evedata.data.primary.latestEvents)
          // }
        }
      } catch (e: any) {
        if (evedata.data.status.code === 'FAILURE') {
          swal('Warning', 'Internal Server Error', 'warning')
        }
      }
    });
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const reverse = () => {

    setLatestEvents(latestEvents.reverse());
    setSort(sort ? false : true);


  };
  // console.log(locations);

  return (
    <div className="dialog">
      <Dialog
        fullScreen
        open={props.onOpen}
        onClose={props.onClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: "flex", width: "70%", alignItems: "center" }}>
              <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                {/* for HBL */}
                {props.shipmentType === 'Ocean' && props.line.hblNumber != "" && 'HBL # : '}
                {props.shipmentType === 'Air' && props.line.hawbNumber != "" && props.line.hawbNumber != null && 'HAWB # : '}

                {props.shipmentType === 'Ocean' && props.line.hblNumber && <span className="dialogTitle">{props.line.hblNumber} </span>}
                {props.shipmentType === 'Air' && props.line.hawbNumber && <span className="dialogTitle">{props.line.hawbNumber} </span>}
                {props.shipmentType === 'Ocean' && props.line.hblNumber && <span >&nbsp; &nbsp;</span>}
                {props.shipmentType === 'Air' && props.line.hawbNumber && <span >&nbsp; &nbsp; </span>}

                {/* for MBL */}

                {props.shipmentType === 'Ocean' && props.line.mblNumber !== "" && 'MBL # : '}
                {props.shipmentType === 'Air' && props.line.awbNumber && 'MAWB # : '}
                {/* {props.shipmentType === 'Ocean' &&  'MBL # : ' : 'MAWB # : '} */}
                {props.shipmentType === 'Ocean' && props.line.mblNumber && <span className="dialogTitle blueColor">{props.line.mblNumber}</span>}
                {props.shipmentType === 'Air' && props.line.awbNumber && <span className="dialogTitle blueColor">{props.line.awbNumber}</span>}

                {/* for Container */}
                &nbsp;&nbsp;
                {props.shipmentType === 'Ocean' && props.line.containerNumber !== "" && 'Container # : '}
                {props.shipmentType === 'Ocean' && props.line.containerNumber && <span className="dialogTitle greyColor">{props.line.containerNumber}</span>}
                &nbsp;&nbsp;
              </Typography>
              {cargoEsData != undefined ?
                props.shipmentType === 'Ocean' ?
                  <Box
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {cargoEsData.route.originDetails.location != '' ?
                        <LocationOnIcon fontSize="medium" sx={{ color: "#f4c919" }} /> : ''}
                      <p style={{ fontSize: "14px" }}>{cargoEsData.route.originDetails.location}</p>
                    </Box>
                    &nbsp;
                    {cargoEsData.route.destinationDetails.location ?
                      <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                        <span>
                          <ArrowRightAltIcon fontSize="large" />
                        </span>
                      </Box> : ''}
                    {cargoEsData.route.destinationDetails.location ?
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <LocationOnIcon sx={{ color: "red" }} fontSize="medium" />
                        <p style={{ fontSize: "14px" }}>
                          {cargoEsData.route.destinationDetails.location}
                        </p>
                      </Box> : ''}
                  </Box> : <Box
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {cargoEsData.route.originDetails.locationCode != '' ?
                        <LocationOnIcon fontSize="medium" sx={{ color: "#f4c919" }} /> : ''}
                      <p style={{ fontSize: "14px" }}>{cargoEsData.route.originDetails.locationCode}</p>
                    </Box>
                    &nbsp;
                    {cargoEsData.route.destinationDetails.locationCode ?
                      <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                        <span>
                          <ArrowRightAltIcon fontSize="large" />
                        </span>
                      </Box> : ''}
                    {cargoEsData.route.destinationDetails.locationCode ?
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <LocationOnIcon sx={{ color: "red" }} fontSize="medium" />
                        <p style={{ fontSize: "14px" }}>
                          {cargoEsData.route.destinationDetails.locationCode}
                        </p>
                      </Box> : ''}
                  </Box>
                : ''}
            </Box>
            <Button
              autoFocus
              color="inherit"
              onClick={props.onClose}
              sx={{ marginLeft: "auto" }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar >
        <Grid spacing={2} container>
          <Grid item xs={3} className="scrollbar-container">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <Typography
                  sx={{

                    textAlign: "left",
                    fontSize: "18px",
                    marginLeft: "20px",
                  }}
                >
                  EVENTS
                </Typography>
                {load ? <Loaders /> : ''}
                <IconButton>
                  {sort ? (
                    <ArrowUpwardIcon onClick={() => reverse()} />
                  ) : (
                    <ArrowDownwardIcon onClick={() => reverse()} />
                  )}
                </IconButton>
              </Box>
              {latestEvents.length > 0 ? (
                <div>
                  <Stepper
                    activeStep={latestEvents.length }
                    orientation="vertical"
                    sx={{
                      display: "flex",
                      border: "2px soild red",
                      paddingLeft: "10px",
                    }}
                  >
                    {latestEvents.map((step: any, index: any) => (
                      <Step key={index} sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: (step.actualTime !=null && step.actualTime== '') ?'#FFBF00':'green', 
                        },
                        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {  color: (step.actualTime !=null && step.actualTime== '') ?'#FFBF00 !important':'green !important'},
                      }}>
                        <StepLabel>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {step.eventName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            <span style={{ color: '#a7a5ad' }}>Location:</span> {step.location}&nbsp;<span style={{ fontSize: '12px', color: '#007ba8' }}>({step.locationType})</span>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            <span style={{ color: '#a7a5ad' }}>Location Code:</span> {step.locationCode}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "capitalize",

                            }}
                          >
                            <span style={{ color: '#a7a5ad' }}>estimate Time:</span> {formatDate(step.estimateTime)}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: "bold",
                              textTransform: "capitalize",

                            }}
                          >
                            <span style={{ color: '#a7a5ad' }}>Actual Time:</span> {formatDate(step.actualTime)}
                          </Typography>
                        </StepLabel>
                        {/* <StepContent>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "300px",
                              textTransform: "capitalize",
                            }}
                          >
                            {step.description}
                          </Typography>
                        </StepContent> */}
                        {latestEvents.length - 1 != index ?
                          <Step>
                            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>Duration:</span>&nbsp;&nbsp;
                            <span className="duration">
                              {
                                caluclateDate(index)

                              }

                            </span>

                          </Step> : ''}
                      </Step>
                    ))}
                  </Stepper>

                </div>


              ) : latestEvents.length === 0 && load ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography>Fetching Events</Typography>
                </Box>
              ) :
                <Typography sx={{ textAlign: 'center' }}>No Events</Typography>}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Shipment Info" value="1" />
                  <Tab label="Exceptions" value="2" />
                  <Badge badgeContent={cargoEsData != undefined && cargoEsData.shipmentExceptions.length > 0 ? cargoEsData.shipmentExceptions.length : 0} color="primary" sx={{
                    "& .MuiBadge-badge": {
                      marginTop: '18px',
                      backgroundColor: 'tomato'
                    }
                  }}>

                  </Badge>
                  <Tab label="Shipment Documents" value="3" sx={{ marginLeft: '12px' }} />
                  <Tab label="Route Maps" value="4" disabled sx={{ marginRight: '8px' }} />
                  <Chip label="Pro" size="small" color="secondary" className="custom-chip" sx={{
                    "& .MuiChip-label": {
                      paddingLeft: '5px !important',
                      paddingRight: '5px !important'
                    },
                    marginTop: '15px',
                    marginLeft: '-22px',
                    marginRight: '20px'

                  }} />

                </TabList>
              </Box>
              <TabPanel value="4">
                {/* {shipmentLegs != undefined && cargoEsData != undefined ?
                  <MyMapComponent
                    locations={shipmentLegs}
                    sx={{ height: "fit-content" }}
                    tracking={eventsTrackingMap}
                  /> : cargoEsData === undefined && latestEvents.length > 0 ? <MyMapComponent
                    map={latestEvents}
                    sx={{ height: "fit-content" }}

                  />

                    : shipmentLegs === undefined && load ? <Loaders /> : "The shipment routes haven't been identified yet"} */}
              </TabPanel>
              <TabPanel value="1" sx={{ p: 0, mt: 2 }} >
                {latestEvents.length > 0 && !load ?
                  <IntransitShipmentInfo shipmentInfo={cargoEsData} delay={delay} changeInETA={changeInETA} shipmentType={props.line.modeOfShipment}/> : load ? <Loaders /> :
                    <Alert severity="info">
                      <AlertTitle>Info</AlertTitle>
                      The shipment info haven't been identified yet.
                    </Alert>

                }
              </TabPanel>
              <TabPanel value="3" sx={{ p: 0 }}>
                <IntransitFileViewer data={props.line.invoiceNumber} />
              </TabPanel>
              <TabPanel value='2' sx={{ p: 0, mt: 2 }}>
                {cargoEsData != undefined && cargoEsData.shipmentExceptions != undefined && cargoEsData.shipmentExceptions.length > 0 ?
                  <IntransitExceptions shipmentExceptions={cargoEsData.shipmentExceptions}></IntransitExceptions> : <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>

                    No exceptions for the Shipment.
                  </Alert>}
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Dialog >
    </div >
  );
}
export default IntransitEventsMapping;

import React, { useState } from 'react'
import { Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import OtpInput from 'react-otp-input';

export default function OtpVerification() {
    const [otp, setOtp] = useState('');
    const handleOnChange = (data: any) => {
        console.log('data', data)
    }
    return (
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            <Box>
                {/* <img
                    src={require("../asserts/RT.png")}
                    className='header_logo'

                /> */}
            </Box>
            <Paper sx={{ m: 2, display: 'flex' }} elevation={0} >
                <Grid container spacing={2} sx={{ p: 5 }}>
                    <Grid item xs={6} sx={{ m: 'auto' }}>
                        <img
                            src={require("../asserts/OTP_IMAGE.png")}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ m: 'auto' }}>
                        <Box sx={{ backgroundColor: '#e9f0f7', borderRadius: '10px', p: 3 }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography>Enter Your OTP Code</Typography>
                            </Box>
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Typography>We have sent a verification code to your mobile number</Typography>
                                <Box className="otpInput-header">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderSeparator={<span>&nbsp;&nbsp; </span>}

                                        renderInput={(props) => <input {...props} className='otpInput' />}
                                    />
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', mt: 4,textAlign:'center' }}>
                                <Button variant='contained' type='submit' >Submit</Button>&nbsp;&nbsp;
                                <Button variant='contained' color="inherit" >Reset</Button>
                            </Box>
                            </Box>

                        </Box>

                    </Grid>
                </Grid>
            </Paper>
            <Box sx={{ display: 'flex', textAlign: 'center', color: '#fff' }}>
                <a href="https://www.realvariable.com/" target="_blank" className="changePassword_footer_title">
                    <Typography>
                        Powered by
                    </Typography>
                    <Typography

                    >
                        <img src={require('../asserts/rv.png')} className="footer_img  " />
                    </Typography>
                </a>
            </Box>
        </Box>
    )
}

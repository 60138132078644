// React Imports
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import * as React from "react";
import swal from "sweetalert";
import axios from "axios";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { DialogActions, DialogTitle } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Divider,
  Grid,
  FormControl
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { ApiRequestFunction, PostRequest } from "../../../Services/BaseService";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../../Styles.css";
import { useLocation, useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import path from "path";
import { Bars } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { Endpoints } from "../../../Enviornment/Endpoints";
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import moment from "moment";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    padding: "15px",

    // fontWeight: "400",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function CrLineItems(props: any) {
 
  const {
    register,
    resetField,
    formState: { errors },
    control,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      mblCode: "",
      containerCode: "",
      transhipmentMode: "",
      eta: '',
      etd: '',
      portOfOrigin: ''
    }
  });
  const { register: register2, resetField: resetFiled2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({
    defaultValues: {
      awbNumber: "",
      transhipmentMode: "",
      eta: '',
      etd: '',
      portOfOrigin: '',
      flightNo:''
    }
  });
  const classes = useStyles();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState([]);
  const [containercode, setcontainercode] = useState("");
  const [hscode, setHscode] = useState("");
  const [mblcode, setMblCode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [cr, setCr] = useState("");
  const [link, setLink] = useState("");
  const qrimage = process.env.REACT_APP_API_URL + "qrImage/";
  const [QrOpen, SetQrOpen] = useState(false);
  const [hblOpen, setHblOpen] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [carrierObj, setCarrierObj] = useState<any>([]);
  const location = useLocation();
  const [name, setName] = useState();
  const [containerError, setContainerError] = useState<boolean>(false);
  const [fromDate, setFromDate] = React.useState<Dayjs | any>(null);
  const [mblError, setMblError] = useState<boolean>(false);
  const [load, setLoad] = useOutletContext<any>();
  const [countrysList, setCountrysList] = useState([])
  const [columnDefs,setColumnDefs] = useState<any>([]);
  // let currentDate = new Date().toISOString().split('T')[0];
  const [etdCurrentDate,setetdCurrentDate] = useState<any>(new Date().toISOString().split('T')[0])
  const [etaCurrentDate,setetaCurrentDate] = useState<any>(new Date().toISOString().split('T')[0])
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let payload = {
    docReference: props.data.docReference,
    brStatus: props.status,
  };
  let payload_intrans = {
    docReference: props.data.docReference,
    brStatus: props.status,
    itemName: props.data.itemName,
    hscode: props.data.hscode,
  };
  let options =
    location.pathname === "/intransit_shipments" ||
      location.pathname === "/confirmed_by_carrier"
      ? payload_intrans
      : payload;
  const getLoadColumns = () =>{
    setColumnDefs([
      {
  
        headerCheckboxSelection: true,
        checkboxSelection: true,
        hide:
          props.status === "open" && sessionStorage.getItem("role") === "forwarder"
            ? false
            : true,
      },
      sessionStorage.getItem('shipmentType') != 'Air' ?
        {
          headerName: "HBL #",
          field: "hblNumber",
          headerTooltip: "HBL #",
          tooltipField: "hblNumber",
        } : {
          headerName: "HAWB #",
          field: "hawbNumber",
          headerTooltip: "HAWB #",
          tooltipField: "hawbNumber",
        },
        sessionStorage.getItem('shipmentType') != 'Air' ?
      {
        headerName: "MBL #",
        field: "mblNumber",
        headerTooltip: "MBL #",
        tooltipField: "mblNumber",
        hide: location.pathname == "/pending_carrier_confirmation" || location.pathname == "/rejected_by_carrier" ? true : false,
      }:{
        headerName: "MAWB #",
        field: "awbNumber",
        headerTooltip: "MAWB #",
        tooltipField: "awbNumber",
        hide: location.pathname == "/pending_carrier_confirmation" || location.pathname == "/rejected_by_carrier" ? true : false,
      },
      {
        headerName: "REJECTED REASON",
        field: "rejectedReason",
        hide: props.status === "rejected" ? false : true,
        headerTooltip: "REJECTED REASON",
        tooltipField: "rejectedReason",
        cellRenderer: (params: ICellRendererParams) => {
          let output;
          if (params.value.length > 8) {
            output = params.value.substring(0, 9);
            output = output + "....";
          } else {
            output = params.value;
          }
          return (
            <Tooltip title={params.value} placement="bottom-start">
              <Button sx={{ color: "tomato" }}>{output}</Button>
            </Tooltip>
          );
        },
      },
      {
        headerName: "INVOICE NUMBER",
        headerTooltip: "INVOICE NUMBER",
        tooltipField: "invoiceNumber",
        field: "invoiceNumber",
        hide: true,
      },
      {
        headerName: "COMMODITY TYPE",
        headerTooltip: "COMMODITY TYPE",
        tooltipField: "commodityType",
        field: "commodityType",
      },
      {
        headerName: "ITEM NAME",
        field: "itemName",
        headerTooltip: "PACKAGE TYPE",
        tooltipField: "itemName",
        hide: sessionStorage.getItem("role") == "cha" ? true : false,
      },
      {
        headerName: "ITEM DESCRIPTION",
        field: "itemDescription",
        headerTooltip: "PACKAGE TYPE",
        tooltipField: "itemDescription",
        hide: sessionStorage.getItem("role") == "cha" ? true : false,
      },
  
      {
        headerName: "HS CODE",
        headerTooltip: "HS CODE",
        tooltipField: "hscode",
        field: "hscode",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "HS CODE DESCRIPTION",
        field: "hscodeDescription",
        headerTooltip: "HS CODE DESCRIPTION",
        tooltipField: "hscodeDescription",
      },
      {
        headerName: "NUMBER OF PACKAGES",
        field: "packageDetails.numberofPackages",
        headerTooltip: "NUMBER OF PACKAGES",
        tooltipField: "number of packages",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PACKAGE TYPE",
        field: "packageDetails.type",
        headerTooltip: "PACKAGE TYPE",
        tooltipField: "packagetype",
      },
      {
        headerName: "PACKAGE WEIGHT",
        field: "packageDetails.weight",
        headerTooltip: "PACKAGE WEIGHT",
        tooltipField: "packageweight",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PACKAGE LENGTH",
        field: "packageDetails.length",
        headerTooltip: "PACKAGE LENGTH",
        tooltipField: "packagelength",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PACKAGE WIDTH",
        field: "packageDetails.width",
        headerTooltip: "PACKAGE WIDTH",
        tooltipField: "packagewidth",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PACKAGE HEIGHT",
        field: "packageDetails.height",
        headerTooltip: "PACKAGE HEIGHT",
        tooltipField: "packageheight",
        cellClass: "ag-left-aligned-cell",
      },
  
      {
        headerName: "PACKAGE SPECIAL INSTRUCTIONS",
        field: "packageDetails.specialInstructions",
        headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
        tooltipField: "packagespecialinstructions",
      },
      {
        headerName: "CONTAINER NUMBER",
        field: "containerNumber",
        headerTooltip: "CONTAINER NUMBER",
        tooltipField: "containerNumber",
        hide: sessionStorage.getItem("role") == "cha" ? false : true,
      },
      {
        headerName: "CONTAINER TYPE",
        headerTooltip: "CONTAINER TYPE",
        tooltipField: "containerType",
        field: "containerType",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem("role") == "cha" ? false : true,
      },
      {
        headerName: "CONTAINER SIZE",
        headerTooltip: "CONTAINER SIZE",
        tooltipField: "containerSize",
        field: "containerSize",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
        hide: sessionStorage.getItem("role") == "cha" ? false : true,
      },
      {
        headerName: "CONTAINER TARE WEIGHT",
        headerTooltip: "CONTAINER TARE WEIGHT",
        tooltipField: "containerTareWeight",
        field: "containerTareWeight",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
        hide: sessionStorage.getItem("role") == "cha" ? false : true,
      },
      {
        headerName: "HAZARDOUS GOODS",
        headerTooltip: "HAZARDOUS GOODS",
        tooltipField: "hazardousGoods",
        field: "hazardousGoods",
      },
      {
        headerName: "ETD",
        hide: props.status === "accepted" ? false : true,
        field: "etd",
        headerTooltip: "ETD",
        tooltipField: "etd",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "ETA",
        hide: props.status === "accepted" ? false : true,
        field: "eta",
        headerTooltip: "ETA",
        tooltipField: "eta",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "SHIPMENT LINE STATUS",
        field: "lineShipStatus",
        headerTooltip: "SHIPMENT LINE STATUS",
        tooltipField: "lineShipStatus",
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value) {
            return params.value
          } else {
            return 'NA'
          }
        },
      },
  
      // {
      //   headerName: "DISPATCH STATUS",
      //   field: "lineDelStatus",
      //   headerTooltip: "DISPATCH STATUS",
      //   tooltipField: "lineDelStatus",
      //   cellRenderer: (params: ICellRendererParams) => {
      //     if (params.value === "Delay") {
      //       return <ReportProblemIcon sx={{ color: "red" }} />;
      //     } else {
      //       return <p>{params.value}</p>;
      //     }
      //   },
      // },
  
    ])
  }
  const handleChangeETD = (e:any) =>{
    console.log(e.target.value,"target")
    if(e.target.value){
     setetaCurrentDate(e.target.value)
    }
  }
  const handleChangeReamark = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };
  const handleChangecontainerNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcontainercode(event.target.value);
  };
  const handlefd = (newValue: Dayjs | any,) => {
    let data = newValue.$d;
    var responseDate = moment(data).format("YYYY-MM-DD");
    console.log(data, "data", responseDate)
    setFromDate(newValue);
    setValue(`eta`, responseDate)
  }
  const handletd = (e:any) => {
    if(e.target.value){
      setetaCurrentDate(e.target.value)
      setFromDate(e.target.value);
    }
    
  }
  const handleChangeCountry = (e: any) => { }
  const handleAccept = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length <= 0) {
      alert("Please Select Shipments");
    } else {
      setHblOpen(true); setStatus("accepted");
      setHblOpen(true);
    }
    axios.get(Endpoints.Get_Countries, Api_Config).then((res: any) => {
      setCountrysList(res.data.primary)
    })
  };
  //Function to get Remarks on pressing Accept in lines
  const handleReject = () => {
    setStatus("rejected");
    setRemarkOpen(true);
  };
  //FUNCTION TO Call shipments
  function getCarrierLines() {
    setLoad(true)
    Api_Config.params = options
    axios.get(Endpoints.Pending_Carrier_Item_List, Api_Config).then((res) => {
      setLoad(false)
      if (res.data.primary.length === 0) {
        props.onClose()
      } else {
        setRowData(res.data.primary);
      }
    });
  }
  useEffect(() => {
    // console.log(props.data,currentDate,"props data")
    
    sessionStorage.setItem('shipmentType',props.data.modeOfShipment)
    getLoadColumns()
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column: any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column: any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }

  }, []);
  //-------------------- accepted by carrier bookings -----------------
  const acceptHandleSubmit = (data1: any) => {
    if (data1.mblCode && data1.containerCode) {
      let data = gridRef.current.api.getSelectedRows();
      let submitList: any = [];
      let additionalPackageInfo: any = [];
      let updateShipment: any = {};
      let carrierUpdated: any = {};
      setHblOpen(false);
      data.map((item: any, index: number) => {
        carrierUpdated = {
          hscode: item.hscode,
          docReference: props.data.docReference,
          mblNumber: data1.mblCode,
          containerNumber: data1.containerCode,
          brStatus: "accepted",
          tranship: data1.transhipmentMode,
          eta: data1.eta,
          etd: data1.etd,
          portOfOrigin: data1.portOfOrigin,
          shipmentType: props.data.modeOfShipment
        };
        submitList.push(carrierUpdated);
      });
      let finalAcceptPayload = {
        data: submitList,
      };
      setLoad(true)
      data.map((item: any, index: any) => {
        updateShipment = {
          containerNumber: data1.containerCode,
          carrierName: item.carrierName,
          mblNumber: data1.mblCode,
          shipmentMode: props.data.modeOfShipment=="Ocean"?"INTERMODAL_SHIPMENT":'AIR_SHIPMENT'
        };
        additionalPackageInfo.push(updateShipment);
      })
      let shipmentPayload = {
        additionalInfo: additionalPackageInfo
      }
      console.log(finalAcceptPayload, "final payload")
      axios.post(Endpoints.Create_Shipment_Registration, shipmentPayload, Api_Config).then((res: any) => {
        setLoad(true)
        if (res.data.status.code === 'SUCCESS') {
          carrierUpdated.shipmentReference = res.data.primary.shipmentReference
          axios.post(Endpoints.Update_Carrier_Request_Status, finalAcceptPayload, Api_Config).then(async (res) => {
            setLoad(false)
            if (res.data.status.code === 'SUCCESS') {
              setLoad(false)
              toast.success(res.data.status.message);
              getCarrierLines();
              resetField('mblCode')
              resetField('containerCode')
              resetField('transhipmentMode')
            }
          }).catch((error) => {
            setLoad(false)
            console.log(" response error " + JSON.stringify(error));
            return error;
          });
        }
      })
      setMblCode("");
      setcontainercode("");
    } else if (data1.mblCode == "" && data1.containerCode == "") {
      setMblError(true);
      setContainerError(true);
    } else if (data1.mblCode == "") {
      setMblError(true);
    } else {
      setContainerError(true);
    }
  };
  // -------------- air accepted by carrier bookings -----------------
  const acceptHandleSubmit2 = (data1:any) =>{
    if (data1.awbNumber) {
      let data = gridRef.current.api.getSelectedRows();
      let submitList: any = [];
      let additionalPackageInfo: any = [];
      let updateShipment: any = {};
      let carrierUpdated: any = {};
      setHblOpen(false);
      data.map((item: any, index: number) => {
        carrierUpdated = {
          hscode: item.hscode,
          docReference: props.data.docReference,
          awbNumber: data1.awbNumber,
          brStatus: "accepted",
          tranship: data1.transhipmentMode,
          eta: data1.eta,
          etd: data1.etd,
          portOfOrigin: data1.portOfOrigin,
          shipmentType: props.data.modeOfShipment
          
        };
        submitList.push(carrierUpdated);
      });
      let finalAcceptPayload = {
        data: submitList,
      };
      setLoad(true)
      data.map((item: any, index: any) => {
        updateShipment = {
          awbNumber: data1.awbNumber,
          carrierName: item.carrierName,
          shipmentMode: props.data.modeOfShipment=="Ocean"?"INTERMODAL_SHIPMENT":'AIR_SHIPMENT'
        };
        additionalPackageInfo.push(updateShipment);
      })
      let shipmentPayload = {
        additionalInfo: additionalPackageInfo
      }
      console.log(finalAcceptPayload, shipmentPayload,"final payload")
      axios.post(Endpoints.Create_Shipment_Registration, shipmentPayload, Api_Config).then((res: any) => {
        setLoad(true)
        if (res.data.status.code === 'SUCCESS') {
          carrierUpdated.shipmentReference = res.data.primary.shipmentReference
          axios.post(Endpoints.Update_Carrier_Request_Status, finalAcceptPayload, Api_Config).then(async (res) => {
            setLoad(false)
            if (res.data.status.code === 'SUCCESS') {
              setLoad(false)
              toast.success(res.data.status.message);
              getCarrierLines();
              resetField('mblCode')
              resetField('containerCode')
              resetField('transhipmentMode')
            }
          }).catch((error) => {
            setLoad(false)
            console.log(" response error " + JSON.stringify(error));
            return error;
          });
        }
      })
      setMblCode("");
      setcontainercode("");
    } else if (data1.mblCode == "" && data1.containerCode == "") {
      setMblError(true);
      setContainerError(true);
    } else if (data1.mblCode == "") {
      setMblError(true);
    } else {
      setContainerError(true);
    }
  }
  //------------------- rejected by carrier bookings ------------------------
  const rejectHandleSubmit = () => {
    let data = gridRef.current.api.getSelectedRows();
    let submitList: any = []
    setHblOpen(false);
    data.map((item: any, index: number) => {
      let obj = {
        hscode: item.hscode,
        docReference: props.data.docReference,
        rejectedReason: remarks,
        brStatus: "rejected",
      };
      submitList.push(obj);
    });
    let finalPayload = {
      data: submitList,
    };
    setRemarkOpen(false);
    axios.post(Endpoints.Update_Carrier_Request_Status, finalPayload, Api_Config).then((res) => {
      setLoad(false)
      if (res.data.status.code === 'SUCCESS') {
        toast.success(res.data.status.message);
        getCarrierLines();
      }
    })
      .catch((error) => {
        setLoad(false)
        console.log(" response error " + JSON.stringify(error));
        return error;
      });
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    getCarrierLines();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
            INVOICE REF. #{" "}
            <span className="dialogTitle">{props.data.invoiceNumber}</span>
            &nbsp; &nbsp; CARRIER REF. #{" "}
            <span className="dialogTitle blueColor">
              {props.data.carrierReference}
            </span>
          </Typography>

          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "15px",
            justifyContent: "space-between",
            backgroundColor: "#f5f4f7",
          }}
        >
          <Box sx={{ height: "fit-content", width: "20%" }}>
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              INVOICE DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Number
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Date
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceDate ? props.data.invoiceDate : "12/02/2000"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Consignee ID
                </Typography>
                <Typography
                  // className="dialogTitle greyColor"
                  sx={{ fontSize: "14px" }}
                >

                  {props.data.consigneeId}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              CARRIER DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Carrier ID
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.carrierId}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Carrier Name
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.carrierName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Mode Of Shipment
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                >
                   {props.data.modeOfShipment}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box className="pointerIcon">
                <div className="countryText">{props.data.pickupCountry ? props.data.pickupCountry : 'HAMBURG'}</div>
                <span className="countryType">(Origin)</span>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  margin: "5px 0px",
                  color: "#555",
                }}
              >
              </Box>
              <Box className="pointerIcon">
                <div className="countryText destinationText">
                  {props.data.dropoffCountry ? props.data.dropoffCountry : "HOUSTOUN"}
                </div>
                <span className="countryType">(Destination)</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingTop: "20px",
            height: "fit-content",
          }}
        >
          <div className={classes.root}>
            <div className="ag-theme-balham">
              <Box className="align" sx={{ mb: 2 }}>
                <Typography variant="h6">SHIPMENTS</Typography>

                <div>
                  {props.status === "open" &&
                    sessionStorage.getItem("role") === "carrier" || sessionStorage.getItem("role") === "forwarder" && location.pathname != '/confirmed_by_carrier' && location.pathname != '/intransit_shipments' && location.pathname != '/rejected_by_carrier' && location.pathname != '/delivered_report' && rowData.length > 0 ? (
                    <div className="actionBtn">
                      {load ? <Bars
                        height="30"
                        width="30"
                        color="#2a76f4"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      /> : ''}
                      <Button
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                          lineHeight: 0.5,
                          color: "white",
                          backgroundColor: "#28a745",
                          marginRight: "3px",
                        }}
                        onClick={() => handleAccept()}
                        className="btn btn-info"
                        startIcon={<CheckCircleIcon />}
                      >
                        Accept
                      </Button>
                      <Button
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                          lineHeight: 0.5,
                          color: "white",
                          backgroundColor: "#95535a",
                        }}
                        onClick={() => handleReject()}
                        className="btn btn-info"
                        startIcon={<CancelIcon />}
                      >
                        Reject
                      </Button>
                    </div>
                  ) : null}
                </div>
              </Box>
              {
                rowData ?
                  <AgGridReact
                    ref={gridRef}
                    animateRows={true}
                    rowSelection={"multiple"}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    domLayout={"autoHeight"}
                    columnDefs={columnDefs}
                    sideBar={sideBar}
                    pagination={true}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                  ></AgGridReact> :
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    No data found
                  </Alert>}
            </div>
          </div>
        </Box >
      </Box >

      {/*MBL OPEN DIALOGUE */}
      < Dialog
        open={hblOpen}
        onClose={() => SetQrOpen(!hblOpen)
        }
        maxWidth='xl'
        sx={{
          "& .MuiDialog-root": {
            "& .MuiPaper": {
              height: '100vh'
            }
          },
          zIndex: 10000,
          padding: 20,

        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "15px ",
            paddingBottom: "0px",
          }}
        >
          {sessionStorage.getItem('shipmentType') !== 'Air' ?
            <Typography variant="h6">
              MBL Number For Carrier ID #: {props.data.carrierReference}
            </Typography> : <Typography variant="h6">
              MAWB Number For Carrier ID #: {props.data.carrierReference}
            </Typography>}
          <Button onClick={props.onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Divider />
        {sessionStorage.getItem('shipmentType') === 'Ocean' ?
          <form
            onSubmit={handleSubmit((formdata: any) => {
              acceptHandleSubmit(formdata)
              console.log(formdata, "form data")
            })}
          >
            <Box
              sx={{
                marginBottom: "5px",
                backgroundColor: "none",
                padding: "15px",
                paddingTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                }}
              >
                <Box className="align1" maxWidth='md'>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="mblCode"
                        size='small'
                        label="Enter MBL"
                        fullWidth
                        variant="outlined"
                        {...register(`mblCode`, {
                          required: "Mbl code is required",
                        })}
                        required
                        onChange={handleChangecontainerNumber}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.mblCode?.type === 'required' && <p role="alert">Mbl Code is required</p>}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="name"
                        size="small"
                        label="Enter Container"
                        fullWidth
                        variant="outlined"
                        {...register(`containerCode`, {
                          required: "Container code is required",
                        })}
                        required
                        onChange={handleChangecontainerNumber}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.containerCode?.message}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>ETD</Typography>
                      <Box>
                      <TextField
                          fullWidth
                          size="small"
                          sx={{ width: "100%" }} 
                          type="date"
                          variant="outlined"
                          inputProps={{ min: etdCurrentDate }}
                          {...register(`etd`, {
                            required: "ETD is required",
                          })}
                          onChange={(e: any) => handleChangeETD(e)}

                        />
                      </Box>
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.etd?.message}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>ETA</Typography>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          sx={{ width: "100%" }}
                          type="date"
                          variant="outlined"
                          inputProps={{ min: etaCurrentDate }}
                          {...register(`eta`, {
                            required: "ETA is required",
                          })}

                        />
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors.eta?.message}
                        </span>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>Port Of Origin</Typography>
                      <FormControl fullWidth size="small">

                        <select
                          className='custom_select'
                          placeholder='Select Country'
                          {...register(`portOfOrigin`, {
                            required: "Country is required",
                          })}
                          onChange={(e: any) => handleChangeCountry(e)}
                        >
                          <option value="">Select Country</option>
                          {countrysList.map((item: any) => (
                            <option value={item.countryName}>{item.countryName}</option>
                          )
                          )}

                        </select>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors.portOfOrigin?.message}
                        </span>
                      </FormControl>
                    </Grid>


                    <Grid item xs={6} sx={{ display: 'flex', mt: 3 }}>
                      <Box sx={{ mr: 3 }}>
                        <Typography>Transhipment Mode * :</Typography>
                      </Box>
                      <Box>
                        <input
                          {...register('transhipmentMode', { required: true })}
                          type="radio"
                          name="transhipmentMode"
                          value="Yes"
                          className="form-check-input"
                          id="yes"
                        /> &nbsp;
                        <label htmlFor="chooseCb" className="form-check-label">
                          YES
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          {...register('transhipmentMode', { required: true })}
                          type="radio"
                          name="transhipmentMode"
                          value="No"
                          className="form-check-input"
                          id="No"
                        /> &nbsp;
                        <label htmlFor="chooseCb" className="form-check-label">
                          NO
                        </label>
                      </Box>
                    </Grid>

                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.transhipmentMode?.message}
                    </span>

                  </Grid>
                </Box>
              </Box>


              {/* <p>{errors.legsinfo?.root?.message}</p> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  paddingRight: "8px",
                }}
              >
                <Button type="submit" color={"primary"} variant={"contained"}>
                  SUBMIT
                </Button>
                <Button

                  onClick={() => {
                    setHblOpen(false);
                    setcontainercode("");
                    setMblCode("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form> :
          <form
            onSubmit={handleSubmit2((formdata: any) => {
              acceptHandleSubmit2(formdata)
              console.log(formdata, "form data")
            })}
          >
            <Box
              sx={{
                marginBottom: "5px",
                backgroundColor: "none",
                padding: "15px",
                paddingTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                }}
              >
                <Box className="align1" maxWidth='md'>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="mblCode"
                        size='small'
                        label="Enter MAWB"
                        fullWidth
                        variant="outlined"
                        {...register2(`awbNumber`, {
                          required: "MAWB code is required",
                        })}
                        required
                        onChange={handleChangecontainerNumber}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors2.awbNumber?.type === 'required' && <p role="alert">Mawb Code is required</p>}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="name"
                        size="small"
                        label="Enter Flight#"
                        fullWidth
                        variant="outlined"
                        {...register2(`flightNo`, {
                          required: "Flight code is required",
                        })}
                        required
                        onChange={handleChangecontainerNumber}
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors2.flightNo?.message}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>ETD</Typography>
                      <Box>
                        <TextField
                          size="small"
                          variant="outlined"
                          type="date"
                          id="gateOutDate"
                          fullWidth
                          inputProps={{ min: etdCurrentDate }}

                          {...register2(`etd`, {
                            required: "Container code is required",
                          })}
                          onChange={(e:any)=>handletd(e)}
                        />
                      </Box>
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors2.etd?.message}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>ETA</Typography>
                      <Box>
                        <TextField
                          variant="outlined"
                          type="date"
                          id="gateOutDate"
                          fullWidth
                          size="small"
                          inputProps={{ min: etaCurrentDate }}
                          {...register2(`eta`, {
                            required: "ETA is required",
                          })}
                          onChange={handlefd}
                        />
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors2.eta?.message}
                        </span>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>Port Of Origin</Typography>
                      <FormControl fullWidth size="small">

                        <select
                          className='custom_select'
                          placeholder='Select Country'
                          {...register2(`portOfOrigin`, {
                            required: "Country is required",
                          })}
                          onChange={(e: any) => handleChangeCountry(e)}
                        >
                          <option value="">Select Country</option>
                          {countrysList.map((item: any) => (
                            <option value={item.countryName}>{item.countryName}</option>
                          )
                          )}

                        </select>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors2.portOfOrigin?.message}
                        </span>
                      </FormControl>
                    </Grid>


                    <Grid item xs={6} sx={{ display: 'flex', mt: 3 }}>
                      <Box sx={{ mr: 3 }}>
                        <Typography>Transhipment Mode * :</Typography>
                      </Box>
                      <Box>
                        <input
                          {...register2('transhipmentMode', { required: true })}
                          type="radio"
                          name="transhipmentMode"
                          value="Yes"
                          className="form-check-input"
                          id="yes"
                        /> &nbsp;
                        <label htmlFor="chooseCb" className="form-check-label">
                          YES
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          {...register2('transhipmentMode', { required: true })}
                          type="radio"
                          name="transhipmentMode"
                          value="No"
                          className="form-check-input"
                          id="No"
                        /> &nbsp;
                        <label htmlFor="chooseCb" className="form-check-label">
                          NO
                        </label>
                      </Box>
                    </Grid>

                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors2.transhipmentMode?.message}
                    </span>

                  </Grid>
                </Box>
              </Box>


              {/* <p>{errors.legsinfo?.root?.message}</p> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  paddingRight: "8px",
                }}
              >
                <Button type="submit" color={"primary"} variant={"contained"}>
                  SUBMIT
                </Button>
                <Button

                  onClick={() => {
                    setHblOpen(false);
                    setcontainercode("");
                    setMblCode("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>}
      </Dialog >

      <Dialog
        open={remarkOpen}
        onClose={() => SetQrOpen(!remarkOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          width: "100%",
        }}
      >
        <DialogTitle>
          Remarks For Carrier ID #{props.data.carrierReference}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "500px" }}>
            Please Enter Remarks
          </DialogContentText>

          <TextField
            multiline={true}
            rows={4}
            minRows={10}
            autoFocus
            margin="dense"
            id="name"
            label="Enter Remarks"
            type="email"
            fullWidth
            variant="outlined"
            value={remarks}
            onChange={handleChangeReamark}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={rejectHandleSubmit}>Submit</Button>
          <Button
            onClick={() => {
              setRemarkOpen(false);
              setRemarks("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer style={{ zIndex: 10000 }} />
    </Dialog >
  );
}

export default CrLineItems;

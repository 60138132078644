import { Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '../Enviornment/Endpoints';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import {useLocation} from 'react-router-dom';

export default function PasswordverifyMsg() {
    const location = useLocation()
    const navigate = useNavigate();
    const [showSucess, setSucess] = useState(false)
    const [email, setEmail] = useState('')
    return (
        <div>
            <Box className="changePassword" sx={{ flexGrow: 1, overflow: "auto" }}>
                <Box sx={{ float: 'right' }}>
                    <img
                        src={require("../asserts/end_to_end.png")}
                        className='header_logo'
                    />
                </Box>
                <Paper sx={{ margin: '5% 15%', display: 'flex' }} >
                    <Grid container spacing={2} sx={{ p: 5 }}>
                        <Grid item xs={12} md={4} sm={12} lg={6} sx={{ m: 'auto' }}>
                            <Box sx={{ textAlign: 'center' }}>
                               <img src={require('../asserts/verify.png')} style={{ width: '50%' }} />
                            </Box>
                        </Grid>
                      
                            <Grid item xs={12} md={8} sm={12} lg={12}>
                                <Box >
                                    {/* <Typography sx={{textAlign:'center'}}>We Have sent an email to your registered <span style={{fontWeight:'bold'}}> {email} </span></Typography> */}
                                    <Typography sx={{textAlign:'center'}}>Verification mail has been sent to your <span style={{fontWeight:'Bold'}}>{location.state.email}</span> mail</Typography>
                                    <Typography sx={{textAlign:'center',marginTop:'10px'}}><a  style={{cursor:'pointer',fontWeight:'bold',color:'blue'}} onClick={() => navigate('/login')}>Back To Login</a></Typography>
                                </Box>
                            </Grid>
                    </Grid>
                </Paper>
                <Box sx={{ display: 'flex', textAlign: 'center', color: '#fff' }}>
                    <a href="https://www.realvariable.com/" target="_blank" className="changePassword_footer_title">
                        <Typography>
                            Powered by
                        </Typography>
                        <Typography

                        >
                            <img src={require('../asserts/rv.png')} className="footer_img  " />
                        </Typography>
                    </a>
                </Box>
            </Box>

        </div>
    )
}

import React, { useState, useEffect, useContext } from "react";
// import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Box } from "@mui/system";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import ReCAPTCHA from 'react-google-recaptcha';
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Field, Form, Formik, useFormik } from "formik";
import "../Layout/Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import { DesgService } from "../Services/DesgService";
import { Endpoints } from "../Enviornment/Endpoints";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import LoadingButton from "@mui/lab/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { getCountries, getStates } from 'country-state-picker';
import { MenuProps } from "react-select";
import SignupOrganization from "../Layout/SignupOrganization";

const signupvalidation = Yup.object().shape({

    organisationName: Yup.string()
        .required('Organisation name is required.')
        .min(3, 'Organisation name should be at least 3 characters.')
        .max(100, 'Organisation name should be at most 100 characters.'),
    operations: Yup.array()
        .of(Yup.string())
        .min(1, 'Operations type is required.'),
    // .min(3, "Operations Type Should Greater Than 3 Characters"),
    countries: Yup.string()
        .required("Country is required.")
        .min(3, "Country should greater than 3 characters."),
    email: Yup.string().email("Please enter a valid email address.").required("Email is required."),
    mobileNumber: Yup.string().required("Mobile number is required.").min(10, 'Mobile number should be  10 digits.')
        .max(10, 'Mobile number should be 10 digits.'),
    // promocode: Yup.string()
    //     .notRequired()
    //     .matches(/^[a-zA-Z0-9]*$/, 'Promo code should be alphanumeric.')
    //     .length(8, 'Promo code should be exactly 8 characters.')
    //     .test('asyncValidation', 'Invalid promo code', async function (value) {
    //         if (!value || value.length !== 8) {
    //             return true; // Skip API call if not 8 characters
    //         }

    //         try {
    //             const result = await validatePromoCodeApiCall(value);

    //             if (result === 'Valid') {
    //                 return this.createError({
    //                     message: 'Valid'
    //                 }) // Validation passed
    //             } else {
    //                 return this.createError({
    //                     message: 'Promo code is not valid.',
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('API call error:', error);
    //             return this.createError({
    //                 message: 'Error validating promo code.',
    //             });
    //         }
    //     }),


    // promocode: Yup.string()
    //     .required('Promo code is required')
    //     .test('isAlphanumeric', 'Promo code should be alphanumeric.', (value) => {
    //         if (value === undefined) {
    //             return true; // Treat undefined as valid to avoid the type error
    //         }
    //         return /^[a-zA-Z0-9]*$/.test(value);
    //     })
    //     .test('noWhiteSpaces', 'Promo code should not contain white spaces.', (value) => !value?.includes(' '))
    //     .test('isEightCharacters', 'Promo code should be 8 characters.', (value) => {
    //         if (value === undefined) {
    //             return true; // Treat undefined as valid to avoid the type error
    //         }
    //         return value.length === 8;
    //     })
    //     .test('isValid', 'Invalid promo code', async function (value) {
    //         if (value === undefined || value.length !== 8) {
    //             return false; // Show error message if undefined or not 8 characters
    //         }
    //         return (await validatePromoCodeApiCall(value)) === 'Valid';
    //     }),
    // promocode: Yup.string()
    //     .required('Promo code is required')
    //     .matches(/^[a-zA-Z0-9]*$/, 'Promo code should be alphanumeric.')
    //     .test('noWhiteSpaces', 'Promo code should not contain white spaces.', (value) => !value?.includes(' '))
    //     .test('isEightCharacters', 'Promo code should be 8 characters.', (value) => value?.length === 8)
    //     .test('isValid', 'Invalid promo code', async function (value) {
    //         if (value?.length === 8) {
    //             return (await validatePromoCodeApiCall(value)) === 'Valid';
    //         }
    //         return true; // Skip API call if not 8 characters
    //     }),

});

function SignUp(props: any) {
    const { register, handleSubmit, reset, setValue, control, watch, trigger, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            firstName: '',
            lastName: '',
            // location: '',
            // place: '',
            state: '',
            gmail: '',
            Mobile: '',
            countries: '',
            promocode: '',
            _operations: [],
            recaptcha: ''
        },
    })
    // const { register: register2, control: control2, resetField: resetFiled2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({
    //     mode: "onChange",
    //     defaultValues: {
    //         organisationName: '',
    //         countries: '',
    //         email: '',
    //         mobileNumber: '',
    //         promocode: '',
    //         operations: []
    //     },
    // });
    const [iscaptchamessage, setCaptchaMessage] = useState<any>(false)
    const [ischeckChaptcha, setIsCheckChaptcha] = useState(false)
    const [isApiCallSuccess, setIsApiCallSuccess] = useState(false);
    const promoCodeValue = watch('promocode');
    const [promoCodeResp, setPromoCodeResp] = useState<any>()
    const operationsList = ['Shipper', 'Forwarder', 'Consignee']
    const operationsList1 = ['Shipper', 'Forwarder', 'Consignee']
    const [_operatonTypes] = useState(['Shipper', 'Consignee', 'Forwarder'])
    const [width] = useState('49%')
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [selectedValue, setSelectedValue] = useState("Organisation")
    const [operations, setOperations] = useState('');
    const [countries, setcountries] = useState('');
    const [country, setCountry] = useState<any>([])
    const [count, setCount] = useState<any>([])
    const [orgState, setOrgState] = useState<any>([])
    const [indState, setIndState] = useState<any>([])
    const [phcode, setPhoneCode] = useState<any>('')
    const [operations1, setOperations1] = useState('');
    const [countries1, setcountries1] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [checkboxEnabled, setCheckboxEnabled] = useState<boolean>(false);
    const recaptchaValue = watch('recaptcha');
    const iconAdornment = isSelected
        ? {
            startAdornment: (
                <InputAdornment position="start">
                    {phcode}
                </InputAdornment>
            )
        }
        : {};

    const [phoneCode, setPhoneCodes] = useState<any | null>()
    const [isSelected2, setIsSelected2] = useState(false);
    const navigate = useNavigate();
    const desgService = new DesgService();
    const [desg, setDesg] = useState<any>([]);

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",

    }
    const validatePromoCodeApiCall = async (promoCode: any) => {
        try {
            const response = await fetch(`${Endpoints.Validate_PromoCode}?promocode=${promoCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseData = await response.json();
            if (responseData.primary) {
                setIsApiCallSuccess(true)
                setPromoCodeResp('Valid')
                return 'Valid'; // Validation passed
            } else {
                setIsApiCallSuccess(false);
                setPromoCodeResp('Invalid Promo Code')
                return 'Invalid Promo Code';
            }

        } catch (error) {
            console.error('API call error:', error);
            setIsApiCallSuccess(false);
            setPromoCodeResp('Error validating promo code.')
            return 'Error validating promo code.';
        }
    };
    const data = () => {
        const data = getCountries();
        setCount(data)
    }
    useEffect(() => {
        if (promoCodeValue && promoCodeValue.length === 8) {
            // Call your API here
            validatePromoCodeApiCall(promoCodeValue);
        } else {
            // Reset the success status if the length is not 8
            setPromoCodeResp('')
            setIsApiCallSuccess(false);
        }
    }, [promoCodeValue]);
    const signuphere = (data: any) => {
        if (ischeckChaptcha) {
            console.log("payload....", data)
            setLoader(true)
            axios.post(Endpoints.Signup, data, { headers }).then((res: any) => {
                console.log(data)
                console.log(res.data.status.code);
                if (res.data.status.code === "error") {
                    swal('Error', res.data.status.message, 'error');
                    setLoader(false)
                    setLoader2(false)
                }
                else {
                    // swal('success', "Verification mail has been sent to your registered mail.", 'success')
                    setLoader(false)
                    setLoader2(false)
                    navigate('/registered', { state: { email: data.email } })
                    reset({
                        firstName: '',
                        lastName: '',
                        gmail: '',
                        Mobile: '',
                        countries: '',
                        _operations: []
                    })
                }


            }).catch((err) => {
                console.log(err);
                setLoader(false)
                setLoader2(false)
            })
        } else {
            setCaptchaMessage(true)
        }



    }
    useEffect(() => {
        data()
    }, [selectedValue]);
    //for Organisation
    const getvalue = (value: any) => {
        const index = count.findIndex((item: any) => item.name === value);
        let phoneCode = count[index].dial_code;
        setPhoneCode(phoneCode)
    }
    //for individuals
    const getvalues = (value: any) => {
        console.log("Value is...", value.dial_code);
        // const index = count.findIndex((item: any) => item.name === value);
        // let phoneCode = count[index].dial_code;
        // console.log("PhoneCode", phoneCode)
        setPhoneCodes(value.dial_code)
    }

    const iconAdornment2 = isSelected2
        ? {
            startAdornment: (
                <InputAdornment position="start">
                    {phoneCode}
                </InputAdornment>
            )
        }
        : {};
    const handleChange = (event: SelectChangeEvent) => {
        console.log(event.target.value);
        setSelectedValue(event.target.value);
    };
    const onSubmit = (data: any) => {
       
        let country: any;
        if (data.countries.name != undefined) {
            country = data.countries.name
        } else {
            country = data.countries
        }
        let p = {
            userName: data.firstName + data.lastName,
            userType: selectedValue == "Organisation" ? "Org" : 'Self',
            operationsType: data._operations,
            country: country,
            pincode: '',
            address: '',
            email: data.gmail,
            mobileNumber: data.Mobile,
            promocode: data.promocode
        }
        signuphere(p)
        setcountries1('')
        setOperations1('')

    }

    let Code = (value: any) => {
        console.log("value is...", value)
        let ans = Object.keys(country).find(key => country[key] === value);
        console.log(ans)
    }

    const isAlpha = (value: any) => {
        // Regular expression to allow only alphabetic characters


        if (/\s/.test(value)) {
            return 'Spaces are not allowed.';
        }
        return /^[A-Za-z]+$/.test(value);
    };
    const onChange = (data: any) => {
        // Handle form submission, e.g., send data to the server
        setIsCheckChaptcha(true)
        setCaptchaMessage(false)
        console.log(data);
    };

    return (
        <Box className="app appbg" sx={{ flexGrow: 1, overflow: "auto" }}>
            <Grid container className="app2">
                <Grid item xs={6}>

                    <Grid item xs={8}>
                        <Paper
                            elevation={0}
                            sx={{
                                background: "transparent",
                                display: "flex",
                                paddingTop: 2,
                                paddingLeft: 5,
                                mx: 3
                            }}
                        >
                            <img
                                src={require("../asserts/end_to_end.png")}
                                className="images1"
                                alt="real variable logo"
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            elevation={0}
                            sx={{
                                background: "transparent",
                                width: "100%",
                                height: "450px",

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={require("../asserts/SupplyChainImage.png")}
                                className="images"
                                alt="heroNode"
                            />

                        </Paper>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Box sx={{

                        backgroundColor: 'whitesmoke',
                        height: '100%',
                        width: '100%',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                        <Typography sx={{ textAlign: 'center', fontSize: 24, fontWeight: 600, textTransform: 'capitalize', }}>Registration</Typography>
                        <Box sx={{ height: '400px', margin: '10px', width: '100%', padding: '10px' }}>
                            <Typography sx={{ fontSize: '16px', fontfamily: 'Roboto', fontWeight: '500', textTransform: 'capitalize' }}>User Type</Typography>
                            <Box sx={{ marginBottom: 2 }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={handleChange}
                                        value={selectedValue}
                                    >
                                        <FormControlLabel value="Organisation" control={<Radio />} label="Organisation" />
                                        <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {selectedValue === "Organisation" ?
                                <SignupOrganization />
                                :
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                rules={{
                                                    required: 'First name is required.',
                                                    minLength: {
                                                        value: 3,
                                                        message: 'First name should be greater than 3 characters.',
                                                    },
                                                    validate: {
                                                        isAlpha: (value) => isAlpha(value) || 'Please enter alphabets only.',
                                                    },
                                                    maxLength: {
                                                        value: 25,
                                                        message: 'First name should be at most 25 characters.',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        size="small"
                                                        id="component-outlined"
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        type="text"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.firstName && errors.firstName.message}
                                            </span>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width }}>
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                rules={{
                                                    required: 'Last name is required.',
                                                    minLength: {
                                                        value: 3,
                                                        message: 'Last name should be greater than 3 characters.',
                                                    },
                                                    validate: {
                                                        isAlpha: (value) =>
                                                            isAlpha(value) || 'Please enter alphabets only.',
                                                    },
                                                    maxLength: {
                                                        value: 25,
                                                        message: 'Last name should be at most 25 characters.',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        size="small"
                                                        id="component-outlined"
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        type="text"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.lastName && errors.lastName.message}
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                            {/* <Controller
                                name="_operations"
                                control={control}
                                defaultValue={[]}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        {...field}
                                        id="component-outlined"
                                        size='small'
                                        label="Operations Type"
                                        placeholder="Select"
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected:any) => (
                                                selected.join(', ')
                                            ),
                                        }}
                                    >
                                        {['Shipper', 'Consignee', 'Forwarder'].map((value:any) => (
                                            <MenuItem key={value} value={value}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={field.value.includes(value)} />}
                                                    label={value}
                                                />
                                            </MenuItem>
                
                // {['Shipper', 'Consignee', 'Forwarder'].map((value) => (
                //     <MenuItem key={value} value={value}>
                //         <Checkbox checked={values.operations.includes(value)} />
                //         {value}
                //     </MenuItem>
                // ))}
                
                
                                        ))}
                                    </TextField>
                                )}
                                rules={{
                                    validate: (value) => value.length > 0 || 'Operations Type is Required'
                                }}
                            /> */}
                                            <Controller
                                                name="_operations"
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }: { field: { onChange: any; onBlur: any; value: string[] } }) => (
                                                    <TextField
                                                        select
                                                        {...field}
                                                        id="component-outlined"
                                                        size='small'
                                                        label="Operations Type"
                                                        placeholder="Select"
                                                        SelectProps={{
                                                            multiple: true,
                                                            renderValue: (selected1: any) => (selected1 as string[]).join(', ')
                                                        }}
                                                    >
                                                        {operationsList1.map((value: any) => (

                                                            <MenuItem key={value} value={value}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={field.value.includes(value)} />}
                                                                    label={value}
                                                                />
                                                            </MenuItem>
                                                        ))}
                                                        {/* {['Shipper', 'Consignee', 'Forwarder'].map((value: any) => (
                                                <MenuItem key={value} value={value}>
                                                    <Checkbox checked={field.value.includes(value)} />
                                                    {value}
                                                </MenuItem>
                                            ))} */}
                                                    </TextField>
                                                )}
                                                rules={{
                                                    validate: (value) => value.length > 0 || 'Operations type is required.'
                                                }}
                                            />

                                            {errors._operations && <span style={{ color: 'red', fontSize: '12px' }}>{errors._operations.message}</span>}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginTop: '3px' }}>
                                            {/* <Controller
                                name="countries"
                                control={control} 
                                render={({ field }) => (
                                    <TextField
                                        select
                                        {...field}
                                        id="component-outlined"
                                        size='small'
                                        label="Country"
                                        placeholder="Select"
                                        onChange={(event) => {
                                            field.onChange(event);
                                            getvalues(event.target.value);
                                        }}
                                    >
                                        {count.map((value: any, index: any) => (
                                            <MenuItem key={index} value={value.name}>
                                                {value.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                rules={{
                                    required: 'Country is required.'
                                }}
                            />
                
                
                
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {errors?.countries && "Country is required."}
                            </span> */}
                                            {/* <FormHelperText sx={{ color: 'red' }}>{errors.countries}</FormHelperText> */}
                                            <Controller
                                                name={'countries'}
                                                control={control}
                                                rules={{
                                                    required: 'Country is required.'
                                                }}
                                                defaultValue='' // Set the default value as needed
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        disablePortal
                                                        id={`combo-box-demo`}
                                                        options={count}
                                                        getOptionLabel={(option: any) => (option ? option.name : "")}
                                                        value={field.value}
                                                        onChange={(_, newValue) => { field.onChange(newValue); getvalues(newValue) }}
                                                        isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                                                        // getOptionSelected={(option: any, value: any) => option.name === value.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Country"
                                                                fullWidth
                                                                size="small"
                                                                variant="outlined"
                                                                onChange={(newValue) => console.log(newValue.target.value)}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />

                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {errors?.countries && "Country is required."}
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                            <TextField
                                                size="small"
                                                {...register('gmail', {
                                                    required: 'Email is required.',

                                                    pattern: {
                                                        value:
                                                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: "Please enter a valid email address.",
                                                    },

                                                })}
                                                id="component-outlined"
                                                label="Email"
                                                placeholder="Email"


                                            />
                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {errors.gmail && errors.gmail.message}
                                            </span>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                            <TextField
                                                size="small"
                                                {...register('Mobile', {
                                                    required: 'Mobile number required.',
                                                    pattern: {
                                                        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                        message: 'Enter a valid phone number.'
                                                    },
                                                    // valueAsNumber: true,

                                                })}
                                                id="component-outlined"
                                                label="Mobile Number"
                                                placeholder="Mobile Number"
                                                // type="number"
                                                InputProps={
                                                    iconAdornment2
                                                }
                                                onFocus={e => setIsSelected2(true)}
                                                onBlur={e => setIsSelected2(false)}

                                            />
                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {errors.Mobile && errors.Mobile.message}
                                            </span>
                                        </Box>




                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                                            <Controller
                                                name="promocode"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <>
                                                        <TextField
                                                            size="small"
                                                            {...field}
                                                            id="component-outlined"
                                                            label="Promo Code"
                                                            placeholder="Promo Code"
                                                            error={Boolean(errors.promocode)}
                                                            helperText={errors.promocode?.message || ''}
                                                            InputProps={{
                                                                inputProps: {
                                                                    maxLength: 8,
                                                                },
                                                            }}
                                                        />
                                                        <span style={{ color: isApiCallSuccess ? 'green' : '#d32f2f', fontSize: '12px', fontWeight: 'bold' }}>{promoCodeResp}</span>
                                                    </>
                                                )}
                                                rules={{

                                                    validate: (value) =>
                                                        value.length === 8 || 'Promo code must be exactly 8 characters.',
                                                }}
                                            />

                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <ReCAPTCHA sitekey="6LfUgFgpAAAAAKRDNWqQ-gHuJcxyUgdXSmQqE9z2" onChange={onChange} />
                                            {!ischeckChaptcha && iscaptchamessage && <span style={{ color: '#d32f2f', fontSize: '12px', fontWeight: 'bold' }}>Please Validate Captcha</span>}
                                        </Box>

                                    </Box>

                                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: width, marginBottom: 2 }}>
                            <TextField
                                size="small"
                                {...register('Mobile', {
                                    required: 'Mobile Number Required',
                                    pattern: {
                                        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                        message: 'Enter A Valid Phone Number'
                                    },
                                    // valueAsNumber: true,
                
                                })}
                                id="component-outlined"
                                label="Mobile Number"
                                placeholder="Mobile Number"
                                // type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{phoneCode}</InputAdornment>,
                                }}
                
                            />
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.Mobile && errors.Mobile.message}
                            </span>
                        </Box> */}
                                    {/* </Box> */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                        <LoadingButton loading={loader2} type="submit" variant="contained">Submit</LoadingButton>
                                        <Typography sx={{ textAlign: 'center', fontSize: '12px', }}>
                                            Already have an account? <a style={{ color: '#6161e1', cursor: 'pointer' }} onClick={() => navigate('/login')}>LogIn</a>
                                        </Typography>
                                    </Box>

                                </form>
                            }
                        </Box>


                    </Box>
                </Grid>
            </Grid>


            <ToastContainer />
        </Box>
    );
}

export default SignUp;

import {
    Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, FormControl, SelectChangeEvent, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Alert, AlertTitle, IconButton, List, ListItemButton, Divider, InputBase,
} from '@mui/material';

import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import '../Styles.css'
import SearchBar from "material-ui-search-bar";

import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import React from 'react';
import { FirstDataRenderedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import axios from 'axios';
import Loaders from '../components/common/Loaders';
import { Endpoints } from '../Enviornment/Endpoints';
import ReactECharts from 'echarts-for-react';
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
declare var $: any;
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function CommonETADeviationInfo(props: any) {
    const [rowData, setRowData] = useState<any>([])
    const domLayout = "autoHeight";
    const gridRef = useRef<any>();
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [searched, setSearched] = useState<string>("");
    const [countrys, setCountrys] = useState<any>([])
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [eventName, setEventname] = useState([])
    const [lessThan2days, setLessThan2Days] = useState([])
    const [twoDaystoWeek, setTwoDaystoWeek] = useState([])
    const [oneWeekToMonth, setOneWeekToMonth] = useState([])
    const [lessThanOneMonth, setLessThanOneMonth] = useState([])
    const [noDeviation, setNodeviation] = useState([])
    const [firstFilter, setFirstFilter] = useState([])
    const [secondFilter, setSecondFilter] = useState([])
    const [thirdFilter, setThirdFilter] = useState([])
    const [fourthFilter, setFourthFilter] = useState([])
    const [fifthFilter, setFifthFilter] = useState([])

    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "10%",
            },
        },
        legend: {
            orient: 'horizontal',
            bottom: 0
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            y: 20,
            y2: 20,
            borderColor: 'transparent',
        },
        yAxis: {
            minInterval: 1,
            type: 'value',
        },
        xAxis: {
            type: 'category',
            data: eventName,
            // data: ['v', 'b']
        },
        series: [
            {
                name: props.pageInfo.shipmentType === 'Ocean' ? '<=2days' : '<2 Hrs',
                type: 'bar',
                stack: 'total',
                data: props.pageInfo.shipmentType === 'Ocean' ? lessThan2days : firstFilter,
                barWidth: 30,
            },
            {
                name: props.pageInfo.shipmentType === 'Ocean' ? '>2 days to <=1 Week' : '>2 Hrs to <=12 Hrs ',
                type: 'bar',
                stack: 'total',
                data: props.pageInfo.shipmentType === 'Ocean' ? twoDaystoWeek : secondFilter,
                barWidth: 30,
            },

            {
                name: props.pageInfo.shipmentType === 'Ocean' ? '>1 Week to <=1 Month' : '>12 Hrs to <=24 Hrs',
                type: 'bar',
                stack: 'total',
                data: props.pageInfo.shipmentType === 'Ocean' ? oneWeekToMonth : thirdFilter,
                barWidth: 30,
            },
            {
                name: props.pageInfo.shipmentType === 'Ocean' ? '>1 Month' : '>24 Hrs',
                type: 'bar',
                stack: 'total  ',
                data: props.pageInfo.shipmentType === 'Ocean' ? lessThanOneMonth : fourthFilter,
                barWidth: 30,
            },
            {
                name: 'No Update',
                type: 'bar',
                stack: 'total',
                data: props.pageInfo.shipmentType === 'Ocean' ? noDeviation : fifthFilter,
                barWidth: 30,
            },
        ]
    };
    // const [columnDefs] = useState([
    //     {
    //         headerName: "SITE",
    //         field: "consigneeName",
    //         headerTooltip: "SITE",
    //         tooltipField: "consigneeName",
    //         cellRenderer: (params: ICellRendererParams) => {
    //             return (
    //                 <a
    //                     style={{ color: "black", lineHeight: 0.5, fontWeight: 'bold' }}
    //                     className="btn btn-info"
    //                 >
    //                     {params.value}
    //                 </a>
    //             );
    //         },
    //     },

    //     {
    //         headerName: 'DEVIATED SHIPMENTS',
    //         children: [
    //             {
    //                 headerName: "TOTAL",
    //                 field: 'totalDeviatedDeliveredShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "totalDeviatedDeliveredShipments",
    //             },
    //             {
    //                 headerName: "<=2 DAYS",
    //                 field: 'twoDaysDeviatedShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "twoDaysDeviatedShipments",
    //             },
    //             {
    //                 headerName: ">2 DAYS TO <=1 WEEK",
    //                 field: 'weekDeviatedDeliveredShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "weekDeviatedDeliveredShipments",
    //             },
    //             {
    //                 headerName: ">1 WEEK TO <=1 MONTH",
    //                 field: 'monthDeviatedDeliveredShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "monthDeviatedDeliveredShipments",
    //             },
    //             {
    //                 headerName: ">1 MONTH",
    //                 field: 'greaterThanMonthdeviatedDeliveredShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "greaterThanMonthdeviatedDeliveredShipments",
    //             },
    //             {
    //                 headerName: "NO UPDATE",
    //                 field: 'greaterThanMonthdeviatedDeliveredShipments',
    //                 filter: 'agNumberColumnFilter',
    //                 tooltipField: "greaterThanMonthdeviatedDeliveredShipments",
    //             },
    //         ],
    //     },
    //     {
    //         headerName: "No Deviation (%)",
    //         field: "deviatedPercentage",
    //         headerTooltip: "No Deviation (%)",
    //         tooltipField: "deviatedPercentage",
    //     },
    // ]);
    const columns: TableColumn<any>[] = [
        {
            name: "CONSIGNEE",
            selector: row => row.consigneeName !== '' ? row.consigneeName : 0,
            width: '300px',
        },
        {
            name: "TOTAL SHIPMENTS",
            selector: row => row.totalDeviatedDeliveredShipments !== '' ? row.totalDeviatedDeliveredShipments : 0,
            width: '170px'
        },
        {
            name: "<=2 DAYS",
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
            selector: row => row?.firstFilter ||  0,
        },

        {
            name: "<=2 Hrs",
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            selector: row => row?.firstFilter ||  0,
        },
        {
            name: ">2 DAYS TO <=1 WEEK",
            selector: row => row?.secondFilter || 0,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: ">2 Hrs TO <=12 Hrs",
            selector: row => row?.secondFilter || 0,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
        },
        {
            name: ">1 WEEK TO <=1 MONTH",
            selector: row => row?.thirdFilter || 0,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: ">12 Hrs TO <=24 Hrs",
            selector: row => row?.thirdFilter || 0,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
        },

        {
            name: ">1 MONTH",
            width: '180px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
            selector: row => row?.fourthFilter || 0,
        },
        {
            name: ">24 Hrs",
            width: '180px',
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            selector: row => row?.fourthFilter || 0,
        },
        {
            name: "NO UPDATE",
            width: '180px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
            selector: row => row?.fifthFilter || 0,
        },
        {
            name: "NO UPDATE",
            width: '180px',
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            selector: row => row?.fifthFilter || 0,
        },
        {
            name: "DEVIATED SHIPMENTS (%)",
            selector: row => row?.deviatedPercentage || 0,
            wrap: false,
            width: '250px',
        },

    ]
    let config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
    }
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    const filterData = countrys.filter((data: any) =>
        data._id.country.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        countryWiseInsights()
        GraphData()
    }, [])


    const GraphData = () => {
        config.params = {
            dataCode: 0,
            shipmentType: props.pageInfo.shipmentType,
            filterDetails: {
                filterOn: format ? format : "MTD",
                startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
            }
        }
        setLoad(true)
        axios.get(Endpoints.ETA_Deviation_Shipments_EventsWise_Insights, config).then((res: any) => {
            setLoad(false)
            let EventNames: any = []
            let twoDaysDeviatedShipments: any = []
            let weekDeviatedDeliveredShipments: any = []
            let monthDeviatedDeliveredShipments: any = []
            let greaterThanMonthdeviatedDeliveredShipments: any = []
            let noDeviatedDeliveredShipments: any = []
            let filter1: any = [];
            let filter2: any = [];
            let filter3: any = [];
            let filter4: any = [];
            let filter5: any = [];
            res.data.data.map((item: any) => {
                EventNames.push(item._id.eventMappingName);
                if (props.pageInfo.shipmentType === 'Ocean') {
                    twoDaysDeviatedShipments.push(item.firstFilter);
                    weekDeviatedDeliveredShipments.push(item.secondFilter);
                    monthDeviatedDeliveredShipments.push(item.thirdFilter);
                    greaterThanMonthdeviatedDeliveredShipments.push(item.fourthFilter)
                    noDeviatedDeliveredShipments.push(item.fifthFilter)
                } else {
                    filter1.push(item.firstFilter);
                    filter2.push(item.secondFilter);
                    filter3.push(item.thirdFilter);
                    filter4.push(item.fourthFilter);
                    filter5.push(item.fifthFilter)
                }

            })

            setEventname(EventNames);
            if (props.pageInfo.shipmentType === 'Ocean') {
                setLessThan2Days(twoDaysDeviatedShipments);
                setTwoDaystoWeek(weekDeviatedDeliveredShipments);
                setOneWeekToMonth(monthDeviatedDeliveredShipments);
                setLessThanOneMonth(greaterThanMonthdeviatedDeliveredShipments)
                setNodeviation(noDeviatedDeliveredShipments);
            }else{
                setFirstFilter(filter1);
                setSecondFilter(filter2);
                setThirdFilter(filter3);
                setFourthFilter(filter4);
                setFifthFilter(filter5);
            }

        }).catch((err: any) => {
            console.log(err)
        })
    }
    const countryWiseInsights = () => {
        setLoad(true)
        let totalCountrysInfo: any = []
        let totalCountrysCount: any = 0;
        let totalResp1: any = []
        config.params = {
            dataCode: 0,
            shipmentType: props.pageInfo.shipmentType,
            filterDetails: {
                filterOn: format ? format : "MTD",
                startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
            }
        }
        axios.get(Endpoints.ETA_Deviation_Shipments_CountryWise_Insights, config).then((res: any) => {
            setLoad(false)
            if (res.data.data.length > 0) {
                if (selectedIndex === 0) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        totalCountrysCount += res.data.data[i].countryTotalDeviatedShipments || 0
                        for (let j = 0; j < res.data.data[i].list.length; j++) {
                            totalCountrysInfo.push(res.data.data[i].list[j])
                        }
                    }
                    let resp = {
                        countryTotalDeviatedShipments: totalCountrysCount,
                        list: totalCountrysInfo,
                        _id: {
                            country: 'All Destination Codes'
                        }
                    }
                    res.data.data.unshift(resp)
                    totalResp1 = res.data.data
                    setRowData(totalResp1[selectedIndex].list)
                    setCountrys(totalResp1)


                }

            }

        })
    }
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        name:any
    ) => {
        // setSelectedIndex(index);
        // countryWiseInsights()
        setSelectedIndex(index);
        countryWiseInsightsByFilter(name)
        // getCountryWiseRealiablility(name)
    };
    const requestSearch = (searchedVal: string) => {
        console.log(searchedVal, "searched value")
        const filteredRows = rows.filter((row: any) => {
            console.log(row, "row names")
            return row.countryName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };
    const countryWiseInsightsByFilter = (event: any) => {
        console.log(event, "target value")
        setRowData(event.list)
    }
    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    return (
        <div ref={gridRef}>
            {rowData.length > 0 ?
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Paper
                            component="form"
                            elevation={0}
                            className="custom-search"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search By Destination Codes"
                                inputProps={{ 'aria-label': 'search google maps' }}
                                size="small"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                                {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
                            </IconButton>
                        </Paper>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 2 }}>
                            {filterData.map((item: any, index: any) => (
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItemButton
                                        selected={selectedIndex === index}
                                        onClick={(event) => handleListItemClick(event, index, item)}
                                    >
                                        <ListItemText primary={item._id.country} />({item.countryTotalDeviatedShipments})

                                    </ListItemButton>

                                </List>
                            ))}
                        </Box>
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={9}>
                        {load ? <Loaders /> :
                            <DataTable
                                style={{ borderRadius: 3 }}
                                columns={columns}
                                data={rowData}
                                responsive={true}
                                customStyles={customStyles}
                                theme="default"
                                pagination
                                dense
                                striped
                                highlightOnHover={true}

                            />
                        }
                         <Paper>
                            <Typography variant='h6' sx={{ fontSize: '1rem', paddingTop: 2, paddingLeft: 3 }}>EVENT WISE DEVIATED SHIPMENTS (All Destinations)</Typography>
                            <ReactECharts option={option} />
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9}>

                        <Paper>
                            <Typography variant='h6' sx={{ fontSize: '1rem', paddingTop: 2, paddingLeft: 3 }}>EVENT WISE DEVIATED SHIPMENTS</Typography>
                            <ReactECharts option={option} />
                        </Paper>
                    </Grid> */}
                </Grid> : <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    No data found
                </Alert>}
        </div>
    )
}

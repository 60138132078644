import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import { access } from "fs";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import FastForwardIcon from "@mui/icons-material/FastForward";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
const ForwrderTabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    // children: [],
    access: true,
    route: "/",
  },
  {
    id: 1,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        title: "FORWARDER",
        icon: <FastForwardIcon />,
      },

      {
        cid: 1,
        childnav: "Upload Forwarder Confirmation",
        route: "/upload_forwarder_confirmation",
        access: false,
        disable:true,
      },
      {
        cid: 2,
        childnav: "Pending Forwarder Confirmation",
        route: "/pending_forwarder_confirmation",
        access: false,
        disable:true,
      },
      {
        cid: 3,
        childnav: "Confirmed By Forwarder",
        route: "/confirmed_by_forwarder",
        access: false,
        disable:true,
      },
      {
        cid: 4,
        childnav: "Rejected By Forwarder",
        route: "/rejected_by_forwarder",
        access: false,
        disable:true,
      },
      {
        cid: 5,
        title: "CARRIER",
        icon: <DirectionsBoatFilledOutlinedIcon />,
      },
      {
        cid: 6,
        childnav: "Carrier Booking Request",
        route: "/carrier_booking_request",
        access: true,
        disable:true,
      },
      {
        cid: 6,
        childnav: "Carrier Confirmation Upload",
        route: "/carrier_confirmation_upload",
        access: true,
        disable:true,
      },

      {
        cid: 7,
        childnav: "Pending Carrier Confirmation",
        route: "/pending_carrier_confirmation",
        access: true,
        disable:true,
      },
      // {
      //   cid: 5,
      //   childnav: "Add Legs",
      //   route: "/add_legs",
      //   access: true,
      // },
      {
        cid: 8,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
        disable:true,
      },
      {
        cid: 9,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "InTransit",
    icon: <TransferWithinAStationSharpIcon />,
    // route: "/intransit_shipments",
    children: [
      {
        cid: 0,
        title: "INTRANSIT",
        icon: <TransferWithinAStationSharpIcon />,
      },
      {
        cid: 1,
        childnav: "InTransit Events Upload",
        route: "/intransit_events_upload",
        access: true,
        disable:true,
      },
      {
        cid: 2,
        childnav: "InTransit Shipments",
        route: "/intransit_shipments",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 3,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: true,
  },

  {
    id: 4,
    parentnav: "Upload Doc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    route: "/upload_documents",
    // children: [
    //   {
    //     cid: 0,
    //     title: "UPLOAD DOCUMENTS",
    //     icon: <UploadFileOutlinedIcon />,
    //   },
    //   {
    //     id: 1,
    //     childnav: "Upload Documents",
    //     route: "/upload_documents",
    //     access: true,
    //   },
    //   // {
    //   //   id: 2,
    //   //   childnav: "Manage Documents",
    //   //   route: "/manage_documents",
    //   //   access: true,
    //   // },
    // ],
  },
  {
    id: 5,
    parentnav: "Registrations",
    icon: <HowToRegOutlinedIcon />,
    children: [
      {
        cid: 0,
        title: "REGISTRATIONS",
        icon: <HowToRegOutlinedIcon />,
      },

      {
        cid: 1,
        childnav: "Partner Onboarding",
        route: "/registrations",
        access: true,
        disable:true
      },
      {
        cid: 2,
        childnav: "Shipment Registrations",
        route: "/shipmentRegistrations",
        access: true,
      },
      process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 3,
        childnav: "Registred Shipments",
        route: "/registred_Shipments",
        access: false,
      }:""
    ],
    access: true,
  },
];
export default ForwrderTabData;

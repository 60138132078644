import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, AlertTitle, Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import "./trcuk.css";
import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { useOutletContext } from "react-router-dom";
import BrLineItems from "../Forwarder/BrLineItems";
import moment from "moment";
import { Box } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function SpaceUtilizatoinReport() {
  const rowData = [
    {
        "_id": "644fbd06989c879a4dd524ab",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch001",
        "lotNumber": "BATCH001",
        "serialNumber": "LOT001",
        "hscode": "29242910",
        "hscodeDescription": "Acetanilides and derivatives, acetaminophen (paracetamol)",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "12345-6789-01",
        "itemDescription": "Acetaminophen",
        "packageId": "INV-21-04-A001_29242910_12345-6789-01_Acetaminophen",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 5.5,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "03:15",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c87f80bd524ac",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch002",
        "lotNumber": "BATCH002",
        "serialNumber": "LOT002",
        "hscode": "29411000",
        "hscodeDescription": "Antibiotics, amoxicillin",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "98765-4321-10",
        "itemDescription": "Amoxicillin",
        "packageId": "INV-21-04-A001_29411000_98765-4321-10_Amoxicillin",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 12.8,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "03:15",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c87335ad524ad",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch003",
        "lotNumber": "BATCH003",
        "serialNumber": "LOT003",
        "hscode": "29333921",
        "hscodeDescription": "Heterocyclic compounds, sertraline",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "24680-1357-09",
        "itemDescription": "Sertraline",
        "packageId": "INV-21-04-A001_29333921_24680-1357-09_Sertraline",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 0.4,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "03:15",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c8713c5d524ae",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch004",
        "lotNumber": "BATCH004",
        "serialNumber": "LOT004",
        "hscode": "29339990",
        "hscodeDescription": "Heterocyclic compounds, diphenhydramine",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "36987-5412-02",
        "itemDescription": "Diphenhydramine",
        "packageId": "INV-21-04-A001_29339990_36987-5412-02_Diphenhydramine",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 10.5,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "03:15",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c877e89d524af",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch005",
        "lotNumber": "BATCH005",
        "serialNumber": "LOT005",
        "hscode": "29333929",
        "hscodeDescription": "Heterocyclic compounds, aripiprazole",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "75310-0200-10",
        "itemDescription": "Aripiprazole",
        "packageId": "INV-21-04-A001_29333929_75310-0200-10_Aripiprazole",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 5.5,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "03:15",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c879fd4d524b1",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch007",
        "lotNumber": "BATCH007",
        "serialNumber": "LOT007",
        "hscode": "29333921",
        "hscodeDescription": "Heterocyclic compounds, sertraline",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "24680-1357-09",
        "itemDescription": "Levonorgestrel",
        "packageId": "INV-21-04-A001_29333921_24680-1357-09_Levonorgestrel",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 0.4,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "02:31",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c873914d524b2",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch008",
        "lotNumber": "BATCH008",
        "serialNumber": "LOT008",
        "hscode": "29333921",
        "hscodeDescription": "Heterocyclic compounds, sertraline",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "24680-1357-09",
        "itemDescription": "Infliximab",
        "packageId": "INV-21-04-A001_29333921_24680-1357-09_Infliximab",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 10.5,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "02:31",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c87ed32d524b3",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch009",
        "lotNumber": "BATCH009",
        "serialNumber": "LOT009",
        "hscode": "29333921",
        "hscodeDescription": "Heterocyclic compounds, sertraline",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "24680-1357-09",
        "itemDescription": "Albuterol",
        "packageId": "INV-21-04-A001_29333921_24680-1357-09_Albuterol",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 5.5,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "02:31",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    },
    {
        "_id": "644fbd06989c8753cad524b4",
        "docReference": "WH-21-04-A001_INV-21-04-A001_FBK-21-04-A001",
        "invoiceNumber": "INV-21-04-A001",
        "bookingReference": "FBK-21-04-A001",
        "whBookingReference": "WH-21-04-A001",
        "checkInDate": "2023-04-18",
        "checkOutDate": "2023-04-28",
        "commodityType": "Generic Drugs",
        "batchNumber": "Batch010",
        "lotNumber": "BATCH010",
        "serialNumber": "LOT010",
        "hscode": "29339990",
        "hscodeDescription": "Heterocyclic compounds, diphenhydramine",
        "whId": "wh-001",
        "whName": "TPZ Ware House Pvt Ltd",
        "itemName": "36987-5412-02",
        "itemDescription": "Diazepam",
        "packageId": "INV-21-04-A001_29339990_36987-5412-02_Diazepam",
        "packageDetails": {
            "numberofPackages": 1,
            "type": "BOX",
            "weight": 12.8,
            "length": "20FT",
            "height": "20FT",
            "width": "40FT",
            "specialInstructions": "Not Applicable"
        },
        "hazardousGoods": "Not Applicable",
        "status": "accepted",
        "whCreationDate": "2023-05-01T00:00:00.000Z",
        "createdBy": "consignee001",
        "createdByName": "consignee 001",
        "createdDate": "2023-05-01T18:52:14.000Z",
        "updatedBy": "wh-001",
        "updatedByName": "wh 001",
        "updatedDate": "2023-05-01T19:00:10.000Z",
        "gateInDate": "2023-05-01",
        "gateInTime": "02:31",
        "whTruckStatus": "gateOut",
        "gateOutDate": "2023-05-02",
        "gateOutTime": "06:30",
        "noOfDays": 1
    }
]
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  useEffect(() => {
    setLoad(false);
    setShowGrid(true);
    // axios.get(url, config).then((res) => {
    //   if (res.data.primary.length === 0) {
    //     setShowGrid(false);
    //     setLoad(false);
    //   }
    //   setLoad(false);
    //   setRowData(res.data.primary);
    //   console.log(res.data.primary);
    //   // params.columnApi.autoSizeAllColumns(false);
    // });
  }, [fromDate, toDate]);
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const handleData = (params: any) => {
    console.log("8888888888888888888", params);
    setPoData(params.data);
    setOpenDetail(true);
  };
  const [columnDefs] = useState([
    // {
    //   headerName: "SELECT ",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   // hide: sessionStorage.getItem("role") === "shipper" ? false : true,
    // },
    {
      headerName: "INVOICE NUMBER",
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      // cellRenderer: (params: ICellRendererParams) => {
      //   return (
      //     <a
      //       style={{ color: "blue", lineHeight: 0.5 }}
      //       onClick={() => handleData(params)}
      //       className="btn btn-info"
      //     >
      //       {params.value}
      //     </a>
      //   );
      // },
    },
    {
      headerName: "CHECK IN DATE",
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "checkInDate",
    },
    {
      headerName: "CHECK OUT DATE",
      headerTooltip: "CHECK OUT DATE",
      tooltipField: "checkOutDate",
      field: "checkOutDate",
    },
    {
      headerName: "GATE IN DATE",
      headerTooltip: "GATEIN DATE",
      tooltipField: "gateInDate",
      field: "gateInDate",
    },
    {
      headerName: "GATE IN TIME",
      headerTooltip: "GATEIN TIME",
      tooltipField: "gateInTime",
      field: "gateInTime",
    },
    {
      headerName: "GATE OUT DATE",
      headerTooltip: "GATE OUT DATE",
      tooltipField: "gateOutDate",
      field: "gateOutDate",
    },
    {
      headerName: "GATEOUT TIME",
      headerTooltip: "GATEOUT TIME",
      tooltipField: "gateOutTime",
      field: "gateOutTime",
    },
    {
      headerName: "NUMBER OF DAYS",
      headerTooltip: "NUMBER OF DAYS",
      tooltipField: "noOfDays",
      field: "noOfDays",
    },

    {
      headerName: "WAREHOUSE ID",
      headerTooltip: "WAREHOUSE ID",
      tooltipField: "whID",
      field: "whId",
    },
    {
      headerName: "WAREHOUSE NAME",
      headerTooltip: "WAREHOUSE NAME",
      tooltipField: "whName",
      field: "whName",
    },
    {
      headerName: "WAREHOUSE BOKKING REFERENCE",
      headerTooltip: "WAREHOUSE BOKKING REFERENCE",
      tooltipField: "whID",
      field: "whBookingReference",
    },

    // {
    //   headerName: "ETD FROM WAREHOUSE",
    //   filter: true,
    //   headerTooltip: "ETD FROM WAREHOUSE",
    //   tooltipField: "etdFromWareHouse",
    //   field: "etdFromWareHouse",
    // },
    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      // field: "carrierHeaderDetails.shipperId",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <p
            style={{  lineHeight: 0.5 }}
           
            className="btn btn-info"
          >
           {"GSKSITE01"}
          </p>
        );
      },
    },
    {
      headerName: "SHIPPER NAME",
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "carrierHeaderDetails.shipperName",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <p
            style={{  lineHeight: 0.5 }}
           
            className="btn btn-info"
          >
           {"GSK"}
          </p>
        );
      },
    },
    {
      headerName: "COMMODITY TYPE",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
      field: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      field: "itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "PACKAGE TYPE",
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packageType",
      field: "packageDetails.type",
    },
    {
      headerName: "PACKAGE WEIGHT",
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageWeight",
      field: "packageDetails.weight",
    },

    {
      headerName: "PACKAGE LENGTH",
      headerTooltip: "LENGTH",
      tooltipField: "length",
      field: "packageDetails.length",
    },
    {
      headerName: "PACKAGE WIDTH",
      headerTooltip: "WIDTH",
      tooltipField: "width",
      field: "packageDetails.width",
    },
    {
      headerName: "PACKAGE HEIGHT",
      headerTooltip: "HEIGHT",
      tooltipField: "height",
      field: "packageDetails.height",
    },

    {
      headerName: "HAZARDOUS GOODS",
      headerTooltip: "HAZARDOUS GOODS",
      tooltipField: "hazardousGoods",
      field: "hazardousGoods",
    },
    {
      headerName: "SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      headerTooltip: "SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
  ]);

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    fbrStatus: "open,processed",
    brStatus: "accepted",
  };

  
  const classes = useStyles();
  const domLayout = "autoHeight";
  // const [rowData, setRowData] = useState();
  const [role, setRole] = useState<any>(sessionStorage.getItem("role"));

  const gridRef = useRef<any>();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      whTruckStatus: "gateOut",
      type: role,
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    },
  };

  const url = Environment_Realtraceb_Dev.BASE_URL + "/spaceUtilizationReport";

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.maincontent}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Container>
      <div className="dialog">
        {openDetail ? (
          <BrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SpaceUtilizatoinReport;

import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FirstDataRenderedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ToastContainer, toast } from 'react-toastify';
import ShipmentRegistrationEvents from './ShipmentRegistrationEvents';
import { Alert, AlertTitle, Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { sideBar,defaultColDef } from '../../common/AgGridConfig';
export default function RegistredShipments() {
    const [rowData, setRowData] = useState([])
    const domLayout = "autoHeight";
    const [openDetail, setOpenDetail] = useState(false);
    const [shipmentEvents, setShipmentEvents] = useState([])
    const [load, setLoad] = useOutletContext<any>();
    // ----------------- api headers config -------------------
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const [columnDefs] = useState([
        {
            headerName: "SHIPMENT REF #",
            field: "shipmentReference",
            headerTooltip: "SHIPMENT REF #",
            tooltipField: "shipmentReference",
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <a
                        style={{
                            lineHeight: 0.5,
                            color: "blue",
                        }}
                        onClick={() => handleShipmentRef(params)}
                        className="btn btn-info"
                    >
                        {params.value}
                    </a>
                );
            },
        },
        {
            headerName: "SHIPMENT MODE",
            field: "shipmentMode",
            headerTooltip: "SHIPMENT MODE",
            tooltipField: "shipmentMode",

        },
        {
            headerName: "CONTAINER #",
            field: "containerNumber",
            headerTooltip: "CONTAINER #",
            tooltipField: "containerNumber",

        },
        {
            headerName: "CARRIER",
            field: "carrierName",
            headerTooltip: "CARRIER",
            tooltipField: "carrierName",

        },
        {
            headerName: "MBL #",
            field: "mblNumber",
            headerTooltip: "MBL #",
            tooltipField: "mblNumber",

        },
        {
            headerName: "BOOKING #",
            field: "bookingNumber",
            headerTooltip: "BOOKING #",
            tooltipField: "bookingNumber",

        },
        {
            headerName: "HBL #",
            field: "hblNumber",
            headerTooltip: "HBL #",
            tooltipField: "hblNumber",
        },
        {
            headerName: "INCO TERMS",
            field: "incoTerms",
            headerTooltip: "INCO TERMS",
            tooltipField: "incoTerms",
        },
        {
            headerName: "FORWARDER",
            field: "forwarderId",
            headerTooltip: "FORWARDER",
            tooltipField: "forwarderId",
        },
        {
            headerName: "SHIPPER",
            field: "shipperId",
            headerTooltip: "SHIPPER",
            tooltipField: "shipperId",
        },
        {
            headerName: "CONSIGNEE",
            field: "consigneeId",
            headerTooltip: "CONSIGNEE",
            tooltipField: "consigneeId",
        },
        {
            headerName: "SHIPMENT ORIGIN",
            field: "shipmentOrigin",
            headerTooltip: "SHIPMENT ORIGIN",
            tooltipField: "shipmentOrigin",
        },
        {
            headerName: "SHIPMENT DESTINATION",
            field: "shipmentDestination",
            headerTooltip: "SHIPMENT DESTINATION",
            tooltipField: "shipmentDestination",
        },
        {
            headerName: "ACTUAL ETA",
            field: "actualETA",
            headerTooltip: "ACTUAL ETA",
            tooltipField: "actualETA",
        },
        {
            headerName: "TOTAL PACK",
            field: "totalPack",
            headerTooltip: "TOTAL PACK",
            tooltipField: "totalPack",
        },
        {
            headerName: "PACK UOM",
            field: "packUOM",
            headerTooltip: "PACK UOM",
            tooltipField: "packUOM",
        },
        {
            headerName: "TOTAL WEIGHT",
            field: "totalWeight",
            headerTooltip: "TOTAL WEIGHT",
            tooltipField: "totalWeight",
        },
        {
            headerName: "WEIGHT UOM",
            field: "weightUOM",
            headerTooltip: "WEIGHT UOM",
            tooltipField: "weightUOM",
        },
        {
            headerName: "TOTAL VOLUME",
            field: "totalVolume",
            headerTooltip: "TOTAL VOLUME",
            tooltipField: "totalVolume",
        },
        {
            headerName: "VOLUME UOM",
            field: "volumeUOM",
            headerTooltip: "VOLUME UOM",
            tooltipField: "volumeUOM",
        },

    ])
    const handleShipmentRef = (item: any) => {
        console.log(item, "item")
        axios.get(process.env.REACT_APP_API_URL +'cargoEs/fetchShipments/' + item.data.shipmentReference, config).then((res: any) => {
            console.log(res.data, "respppp")
            if (res.data.primary.length > 0) {
                if (res.data.primary[0].shipmentEvents) {
                    setOpenDetail(true)
                    setShipmentEvents(res.data.primary)
                } else {
                    toast.warning('No Events Found')
                }

            } else {
                toast.warning("No Events Found")
            }

        })
    }
    const onGridReady = useCallback((params: GridReadyEvent) => {
        params.columnApi.autoSizeAllColumns(false);
    }, []);
    useEffect(() => {
        setLoad(true)
        axios.get(process.env.REACT_APP_API_URL +'cargoEs/fetchShipmentRegistrations', config).then((res: any) => {
            setLoad(false)
            setRowData(res.data.primary)
        })
    }, [])
    const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
        params.columnApi.autoSizeAllColumns(false);
    }, []);
    return (
        <div>
            {rowData.length > 0 ?
                <Box sx={{ mt: 3 }}>
                    <AgGridReact
                        className="ag-theme-balham"
                        animateRows={true}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        domLayout={domLayout}
                        columnDefs={columnDefs}
                        sideBar={sideBar}
                        onGridReady={onGridReady}
                        paginationPageSize={10}
                        masterDetail={true}
                        pagination={true}
                        onFirstDataRendered={onFirstDataRendered}
                    ></AgGridReact>
                </Box> :
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    No data found
                </Alert>
            }
            <div className="dialog">
                {openDetail ? (
                    <ShipmentRegistrationEvents
                        onOpen={openDetail}
                        onClose={() => setOpenDetail(false)}
                        status={"open"}
                        events={shipmentEvents}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>
            <ToastContainer />
        </div>
    )
}

import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import swal from "sweetalert";
import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import Full from "../../../Helpers/IntransitEventsMapping";
import "../../../Styles.css";
import moment from "moment";
import React from "react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import IntransitEventsMapping from "../../../Helpers/IntransitEventsMapping";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { ApiRequestFunction } from "../../../Services/BaseService";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function IntransitEventDisplay() {
  const [columnDefs, setColumnDefs] = useState<any>([])
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  const [showgrid, setShowGrid] = useState(false);
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef<any>();
  let gridColumnApi: any;
  let gridApi: any;
  const [load, setLoad, fromDate, toDate, setFd, setTd] =
    useOutletContext<any>();
  const nav = useNavigate();
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let requestPayload: any = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    type: role,
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    shipmentType: selectedValue
  }
  useEffect(() => {
    sessionStorage.setItem('shipmentType',selectedValue);
    getLoadColumns()
    getConfirmedCarrierInfo()
  }, [fromDate, toDate]);
  const getLoadColumns = () => {
    setColumnDefs([
      {
        headerName: "INVOICE #",
        field: "invoiceNumber",
        headerTooltip: "INVOICE #",
        tooltipField: "invoiceNumber",
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <a
              style={{
                lineHeight: 0.5,
                color: "blue",
              }}
              onClick={() => handleLine(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          );
        },
      },
      {
        headerName: "TRANSHIPMENT",
        field: "tranship",
        headerTooltip: "TRANSHIPMENT",
        tooltipField: "tranship",
      },
      {
        headerName: "LEG INFORMATION",
        field: "legInfo",
        headerTooltip: "LEG INFORMATION",
        tooltipField: "leginformatin",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <a
              style={{
                lineHeight: 0.5,
                color: "blue",
              }}
              onClick={() => handleLegData(params)}
              className="btn btn-info"
            >
              LegInfo
            </a>
          );
        },
      },

      {
        headerName: "HBL #",
        field: "hblNumber",
        headerTooltip: "HBL #",
        tooltipField: "hblNumber",
        hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
      },
      {
        headerName: "HAWB #",
        field: "hawbNumber",
        headerTooltip: "HAWB #",
        tooltipField: "hawbNumber",
        hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? true : false
      },

      {
        headerName: "MBL #",
        field: "mblNumber",
        headerTooltip: "MBL #",
        tooltipField: "mblNumber",
        hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? false : true
      },
      {
        headerName: "MAWB #",
        field: "awbNumber",
        headerTooltip: "MAWB #",
        tooltipField: "awbNumber",
        hide: sessionStorage.getItem('shipmentType') === 'Ocean' ? true : false
      },
      {
        headerName: "SHIPMENT WEIGHT",
        headerTooltip: "SHIPMENT WEIGHT",
        field: "shipmentWeight",
        tooltipField: "shipmentWeight",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "WEIGHT UOM",
        field: "weightUom",
        headerTooltip: "UOM",
        tooltipField: "weightUom",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      sessionStorage.getItem('shipmentType') === 'Ocean' ?
        {
          headerName: "CONTAINER #",
          field: "containerNumber",
          headerTooltip: "CONTAINER #",
          tooltipField: "containerNumber",
        } : {
          headerName: "SHIPMENT #",
          field: "containerNumber",
          headerTooltip: "SHIPMENT #",
          tooltipField: "containerNumber",
        },
      {
        headerName: "CONTAINER TYPE",
        field: "containerType",
        headerTooltip: "CONTAINER TYPE",
        tooltipField: "containerType",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "CONTAINER SIZE",
        field: "containerSize",
        headerTooltip: "CONTAINER SIZE",
        tooltipField: "containerSize",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "CONTAINER TARE WEIGHT",
        field: "payloadCapacity",
        headerTooltip: "CONTAINER TARE WEIGHT",
        tooltipField: "payloadCapacity",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "CONTAINER GROSS WEIGHT",
        field: "containerGrossWeight",
        headerTooltip: "CONTAINER GROSS WEIGHT",
        tooltipField: "containerGrossWeight",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "CONTAINER NET WEIGHT",
        field: "containerNetWeight",
        headerTooltip: "CONTAINER NET WEIGHT",
        tooltipField: "containerNetWeight",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "EMPTY CONTAINER PICKUP DEPOT",
        field: "containerPickUpDepot",
        headerTooltip: "EMPTY CONTAINER PICKUP DEPOT",
        tooltipField: "containerPickUpDepot",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },

      {
        headerName: "EMPTY CONTAINER PICKUP DATE",
        field: "containerPickUpDepot",
        headerTooltip: "EMPTY CONTAINER PICKUP DATE",
        tooltipField: "containerPickUpDepot",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },

      {
        headerName: "EMPTY CONTAINER PICKUP TIME",
        field: "containerPickUpDepot",
        headerTooltip: "EMPTY CONTAINER PICKUP TIME",
        tooltipField: "containerPickUpDepot",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "FORWARDER ID",
        headerTooltip: "FORWARDER ID",
        tooltipField: "forwarderId",
        field: "forwarderId",
        hide: sessionStorage.getItem('role') != 'forwarder' ? false : true
      },
      {
        headerName: "FORWARDER",
        headerTooltip: "FORWARDER",
        tooltipField: "forwarderName",
        field: "forwarderName",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'forwarder' ? false : true
      },

      {
        headerName: "FORWARDER REFERENCE #",
        headerTooltip: " FORWARDER REFERENCE #",
        tooltipField: "bookingReference",
        field: "bookingReference",
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value) {
            return params.value
          } else {
            return 'NA'
          }
        },
      },
      {
        headerName: "CARRIER REFERENCE #",
        field: "carrierReference",
        headerTooltip: "CARRIER REFERENCE #",
        tooltipField: "carrierReference",

      },
      {
        headerName: "CARRIER ID",
        field: "carrierId",
        headerTooltip: "CARRIER ID",
        tooltipField: "carrierId",
        hide: sessionStorage.getItem('role') != 'carrier' ? false : true
      },
      {
        headerName: "CARRIER",
        field: "carrierName",
        headerTooltip: "CARRIER",
        tooltipField: "carrierName",
        hide: sessionStorage.getItem('role') != 'carrier' ? false : true

      },
      {
        headerName: "SHIPPER ID",
        field: "shipperId",
        headerTooltip: "SHIPPER ID",
        tooltipField: "shipperId",
        hide: sessionStorage.getItem('role') != 'shipper' ? false : true
      },
      {
        headerName: "SHIPPER",
        field: "shipperName",
        headerTooltip: "SHIPPER",
        tooltipField: "shipperName",
        hide: sessionStorage.getItem('role') != 'shipper' ? false : true
      },
      {
        headerName: "PICK UP LOCATION",
        field: "pickupLocation",
        headerTooltip: "PICK UP LOCATION",
        tooltipField: "pickupLocation",
      },

      {
        headerName: "PICKUP DATE",
        headerTooltip: "PICKUP DATE",
        tooltipField: "pickUpDate",
        field: "pickUpDate",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PICKUP COUNTRY",
        headerTooltip: "PICKUP COUNTRY",
        tooltipField: "pickupCountry",
        field: "pickupCountry",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PICKUP ZIPCODE",
        headerTooltip: "PICKUP ZIPCODE",
        tooltipField: "pickupZipCode",
        field: "pickupZipCode",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "CONSIGNEE ID",
        headerTooltip: "CONSIGNEE ID",
        field: "consigneeId",
        tooltipField: "consigneeId",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "CONSIGNEE",
        headerTooltip: "CONSIGNEE",
        field: "consigneeName",
        tooltipField: "consigneeName",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "DROP OFF LOCATION",
        field: "dropoffLocation",
        headerTooltip: "DROPP OFF LOCATION",
        tooltipField: "dropoffLocation",
      },
      {
        headerName: "DROP OFF COUNTRY",
        field: "dropoffCountry",
        headerTooltip: "DROP OFF COUNTRY",
        tooltipField: "dropoffCountry",
      },
      {
        headerName: "DROP OFF ZIPCODE",
        field: "dropoffZipCode",
        headerTooltip: "DROP OFF ZIPCODE",
        tooltipField: "dropoffZipCode",
      },

      {
        headerName: "PORT OF ORIGIN",
        tooltipField: "portOfOrigin",
        field: "portOfOrigin",
        headerTooltip: "PORT OF ORIGIN",
      },
      {
        headerName: "VESSEL ID",
        tooltipField: "vesselId",
        field: "vesselId",
        headerTooltip: "VESSEL ID",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "VESSEL NAME",
        tooltipField: "vesselName",
        field: "vesselName",
        headerTooltip: "VESSEL NAME",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "VOYAGE #",
        tooltipField: "voyage",
        field: "voyage",
        headerTooltip: "VOYAGE #",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "DEPARTURE TERMINAL",
        tooltipField: "departureTerminal",
        field: "departureTerminal",
        headerTooltip: "DEPARTURE TERMINAL",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "VGA CUT OFF TIME",
        tooltipField: "vgaCutOffTime",
        field: "vgaCutOffTime",
        headerTooltip: "VGA CUT OFF TIME",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },

      {
        headerName: "VESSEL DEPARTURE TIME",
        tooltipField: "vesselDepartureTime",
        field: "vesselDepartureTime",
        headerTooltip: "VESSEL DEPARTURE TIME",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "OCEAN TRANSIT TIME",
        headerTooltip: "OCEAN TRANSIT TIME",
        tooltipField: "oceanTransitTime",
        field: "oceanTransitTime",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },

      {
        headerName: "PORT OF  DISCHARGE",
        tooltipField: "portOfDischarge",
        field: "portOfDischarge",
        headerTooltip: "PORT OF  DISCHARGE",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },

      {
        headerName: "DISCHARGE TERMINAL",
        tooltipField: "dischargeTerminal",
        field: "dischargeTerminal",
        headerTooltip: "DISCHARGE TERMINAL",
        hide: process.env.REACT_APP_ENV != 'multitenancy' ? false : true,
      },
      {
        headerName: "ETD",
        headerTooltip: "ETD",
        tooltipField: "etd",
        field: "etd",
        cellClass: "ag-left-aligned-cell",
      },

      {
        headerName: "ETA",
        headerTooltip: "ETA",
        tooltipField: "eta",
        field: "eta",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "INCO TERMS",
        headerTooltip: "INCO TERMS",
        tooltipField: "incoTerms",
        field: "incoTerms",
      },

      {
        headerName: "MODE OF SHIPMENT",
        headerTooltip: "MODE OF SHIPMENT",
        tooltipField: "modeOfShipment",
        field: "modeOfShipment",
        columnGroupShow: "closed",
      },
      {
        headerName: "VIEW DOCS",
        headerTooltip: "VIEW DOCS",
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                color: "blue",
              }}
              className="btn btn-info"
              onClick={() => managedoc(params)}
            >
              View Doc
            </a>
          );
        },
      },
    ]);
  }
  const managedoc = (params: any) => {
    let docObject: any;
    if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber != 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber && params.data.invoiceNumber === 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber === 'NA' && params.data.invoiceNumber) {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.data.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };
  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);
    setLineData(params.data);
    if (params.data.legInfo === undefined) {
      swal("info", "No Leg Information", "info");
    } else {
      setLegOpen(true);
    }
  };
  const handlemap = () => {
    setNodata(false);
  };
  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
  };
  const getConfirmedCarrierInfo = () => {
    Api_Config.params = requestPayload
    setLoad(true)
    axios.get(Endpoints.Intransit_Header_List, Api_Config).then((res) => {
      setLoad(false);
      if (res.data.status.code === 'FAILURE') {
        swal('Warning', res.data.status.message, 'warning')
      } else {
        setShowGrid(true);
        if (res.data.primary.length === 0) {
          setShowGrid(false);
          setLoad(false);
        }
        setRowData(res.data.primary);
        setLoad(false);
        console.log(res.data.primary);
      }

    });
  }
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
    sessionStorage.setItem('shipmentType', selectedValue)
    gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    gridColumnApi = params.columnApi;
    gridApi.sizeColumnsToFit();

  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    requestPayload.shipmentType = event.target.value
    setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType', event.target.value)
    setRowData([])
    getLoadColumns()
    getConfirmedCarrierInfo()
  };
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ p: 0, mt: 1 }}>
          {rowData.length > 0 ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <IntransitEventsMapping
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default IntransitEventDisplay;

import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { ApiRequestFunction, getRequest } from "../../../Services/BaseService";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import swal from "sweetalert";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
  VirtualColumnsChangedEvent,
} from "ag-grid-community";
import BrLineItems from "./BrLineItems";
import { Alert, AlertTitle, Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tab, Typography } from "@mui/material";
import "../../../Styles.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";
import React from "react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { Endpoints } from "../../../Enviornment/Endpoints";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function PendingBookingRequest() {
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [loader, setLoader, fromDate, toDate] = useOutletContext<any>();
  const [rowData, setRowData] = useState([]);
  const classes = useStyles();
  const domLayout = "autoHeight";
  const nav = useNavigate();
  const gridRef = useRef<any>();
  const [selectedValue, setSelectedValue] = React.useState('Ocean');
  let gridColumnApi: any;
  let gridApi: any;
  const [columnDefs] = useState([
      {
        headerName: "INVOICE #",
        headerTooltip: "INVOICE #",
        tooltipField: "invoiceNumber",
        field: "invoiceNumber",
        cellClass: "ag-left-aligned-cell",
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <a
              style={{ color: "blue", lineHeight: 0.5 }}
              onClick={() => handleData(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          );
        },
        suppressAutoSize: false
      },
      {
        headerName: "INVOICE DATE",
        field: "brInvoiceDate",
        hide: true,
        headerTooltip: "INVOICE DATE",
        tooltipField: "brInvoiceDate",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
        suppressAutoSize: false
      },
      {
        headerName: "PURCHASE ORDER #",
        field: "purchaseOrderNumber",
        headerTooltip: "PURCHASE ORDER #",
        cellClass: "ag-left-aligned-cell",
        tooltipField: "purchaseOrderNumber",
        suppressAutoSize: false
      },
      {
        headerName: "FORWARDER ID",
        headerTooltip: "FORWARDER ID",
        tooltipField: "forwarderID",
        field: "forwarderID",
        suppressAutoSize: false,
        hide: sessionStorage.getItem('role') != 'forwarder' ? false : true
      },
      {
        headerName: "FORWARDER",
        headerTooltip: "FORWARDER",
        tooltipField: "forwarderName",
        field: "forwarderName",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'forwarder' ? false : true
      },
      {
        headerName: "FORWARDER REFERENCE #",
        headerTooltip: "FORWARDER REFERENCE #",
        tooltipField: "forwarderBookingReference",
        field: "forwarderBookingReference",
        columnGroupShow: "closed",
      },
      {
        headerName: "PICK UP LOCATION",
        field: "pickupLocation",
        headerTooltip: "PICK UP LOCATION",
        tooltipField: "pickupLocation",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PICKUP COUNTRY",
        headerTooltip: "PICKUP COUNTRY",
        tooltipField: "pickupLocation",
        field: "pickupCountry",
        cellClass: "ag-left-aligned-cell",
        columnGroupShow: "closed",
      },
      {
        headerName: "PICKUP DATE",
        headerTooltip: "PICKUP DATE",
        tooltipField: "brPickupDate",
        field: "brPickupDate",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "PICKUP ZIP CODE",
        headerTooltip: "PICKUP ZIP CODE",
        tooltipField: "brPickupDate",
        field: "pickupZipCode",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "CONSIGNEE ID",
        headerTooltip: "CONSIGNEE ID",
        tooltipField: "consigneeID",
        field: "consigneeID",
        cellClass: "ag-left-aligned-cell",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "CONSIGNEE",
        headerTooltip: "CONSIGNEE",
        tooltipField: "consigneeName",
        field: "consigneeName",
        cellClass: "ag-left-aligned-cell",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "CONSIGNEE EMAIL ADDRESS",
        headerTooltip: "CONSIGNEE EMAIL ADDRESS",
        tooltipField: "consigneeEamilAddress",
        field: "consigneeEamilAddress",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "CONSIGNEE CONTACT #",
        headerTooltip: "CONSIGNEE CONTACT #",
        tooltipField: "consingneeContactNumber",
        field: "consingneeContactNumber",
        cellClass: "ag-left-aligned-cell",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'consignee' ? false : true
      },
      {
        headerName: "DROPOFF LOCATION",
        headerTooltip: "DROPOFF LOCATION",
        tooltipField: "dropoffLocation",
        field: "dropoffLocation",
      },
      {
        headerName: "DROPOFF COUNTRY",
        headerTooltip: "DROPOFF COUNTRTY",
        tooltipField: "dropoffCountry",
        field: "dropoffCountry",
        columnGroupShow: "closed",
      },
      {
        headerName: "DROPOFF ZIPCODE",
        headerTooltip: "DROPOFF ZIPCODE",
        tooltipField: "dropoffZipCode",
        field: "dropoffZipCode",
        columnGroupShow: "closed",
        cellClass: "ag-left-aligned-cell",
      },
      {
        headerName: "CHA ID",
        headerTooltip: "CHA ID",
        tooltipField: "chaDetails.chaID",
        field: "chaDetails.chaID",
      },
      {
        headerName: "CHA",
        headerTooltip: "CHA",
        tooltipField: "chaDetails.chaName",
        field: "chaDetails.chaName",
        columnGroupShow: "closed",
      },
      {
        headerName: "SHIPPER ID",
        headerTooltip: "SHIPPER ID",
        tooltipField: "createdBy",
        field: "createdBy",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'shipper' ? false : true
      },
      {
        headerName: "SHIPPER",
        headerTooltip: "SHIPPER",
        tooltipField: "createdByName",
        field: "createdByName",
        columnGroupShow: "closed",
        hide: sessionStorage.getItem('role') != 'shipper' ? false : true
      },
      {
        headerName: "FBR STATUS",
        headerTooltip: "FBR STATUS",
        tooltipField: "fbrStatus",
        field: "fbrStatus",
      },
      {
        headerName: "MODE OF SHIPMENT ",
        headerTooltip: "MODE OF SHIPMENT",
        tooltipField: "modeofShipment",
        field: "modeofShipment",
      },
      {
        headerName: "INCO TERMS",
        field: "incoTerms",
        headerTooltip: "incoTerms",
        tooltipField: "INCO TERMS",
      },
      {
        headerName: "CREATED BY",
        headerTooltip: "CREATED BY",
        tooltipField: "createsby",
        field: "createdBy",
      },
      {
        headerName: "VIEW DOCS",
        headerTooltip: "VIEW DOCS",
        flex: 2,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                color: "blue",
              }}
              className="btn btn-info"
              onClick={() => managedoc(params)}
            >
              View Doc
            </a>
          );
        },
      },
    ]

  );
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    fbrStatus: "open",
    brStatus: "open",
    type: sessionStorage.getItem('role'),
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    shipmentType: selectedValue
  };
  useEffect(() => {
    sessionStorage.setItem('shipmentType', selectedValue)

    getPendingForwarderInfo();
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column: any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column: any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, selectedValue]);
  const handleData = (params: any) => {
    setPoData(params.data);
    setOpenDetail(true);
  };
  const managedoc = (params: any) => {
    let docObject: any;
    if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber != 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber && params.data.invoiceNumber === 'NA') {
      docObject = {
        docAgainst: 'PO',
        documentReference: params.data.purchaseOrderNumber
      }
    } else if (params.data.purchaseOrderNumber === 'NA' && params.data.invoiceNumber) {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.data.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };
  const getPendingForwarderInfo = () => {
    setLoader(true);
    Api_Config.params = options
    axios.get(Endpoints.Pending_Forwarder_Booking_HeaderList, Api_Config).then((res) => {
      if (res.data.status.code === 'FAILURE') {
        setLoader(false);
        swal("warning", res.data.sttus.message);
      } else {
        setRowData(res.data.primary);
        setLoader(false);
      }

    }).catch((error: any) => {
      if (error.code === 'ECONNABORTED') {
        console.log('Request timed out');
        setRowData([])
        setLoader(false)
      } else {
        console.log(error.message);
        setRowData([])
        setLoader(false)
      }
    });
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    gridColumnApi = params.columnApi;
    gridApi.sizeColumnsToFit();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    // params.columnApi.autoSizeAllColumns(false);
    const colIds = params.columnApi
      .getAllDisplayedColumns()
      .map(col => col.getColId());

    params.columnApi.autoSizeColumns(colIds);
  }, []);
  const closeDialog = () => {
    setOpenDetail(false)

    getPendingForwarderInfo()
  }
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType', event.target.value)
    setRowData([])

    getPendingForwarderInfo()

  };
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Box sx={{ width: '100%', typography: 'body1', ml: 1 }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={shipmentModeHandleChange}
              value={selectedValue}
            >
              <FormControlLabel value="Ocean" control={<Radio />} label="Ocean" />
              <FormControlLabel value="Air" control={<Radio />} label="Air" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ p: 0, }}>
          {rowData.length > 0 ? (
            <AgGridReact
              className="ag-theme-balham"
              ref={gridRef}
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onVirtualColumnsChanged={autosize}
              // suppressColumnVirtualisation={true}
              // suppressRowVirtualisation={true}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <div className="dialog">
          {openDetail ? (
            <BrLineItems
              onOpen={openDetail}
              onClose={closeDialog}
              data={PoData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}

export default PendingBookingRequest;

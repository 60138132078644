import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useState, useMemo, useRef, useEffect } from "react";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";

import { toast, ToastContainer } from "react-toastify";

import { ICellRendererParams } from "ag-grid-community";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useOutletContext } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function ReturnFileUpload() {
  const FormRef = useRef<any>();
  const [rowData, setRowData] = useState<any>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<Boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [validationData, setValidationData] = useState<any>([]);
  const [validationPoNumber, setValidationPoNumber] = useState();
  const [load, setLoad, fromDate, toDate, format] = useOutletContext<any>();
  const masterDetail = true;
  const [columnDefs] = useState([
    {
      headerName: "RETURN NUMBER",
      field: "returnNumber",
      headerTooltip: "RETURN NUMBER",
      tooltipField: "returnNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationStatus",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationStatus",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === true) {
          return <p className="status">TRUE</p>;
        } else {
          setDisable(false);
          return <p className="rstatus">FALSE</p>;
        }
      },
    },
    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.value.length > 1) {
          return (
            <Button
              className="upload-error"
              sx={{ p: 0, fontSize: "12px" }}
              onClick={() =>
                alerts(params.value, params.data.purchaseOrderNumber)
              }
            >
              <CancelIcon
                sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
              />{" "}
              1 or more Validations
            </Button>
          );
        } else if (params.value.length === 1) {
          return (
            <Button
              onClick={() =>
                alerts(params.value, params.data.purchaseOrderNumber)
              }
            >
              {params.value[0]}
            </Button>
          );
        } else {
          return <p>
            <CheckCircleIcon
              sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
            />
            NO VALIDATION
          </p>;
        }
      },
    },
    {
      headerName: "RETURN ORDER DATE",
      field: "returnDate",
      headerTooltip: "RETURN ORDER DATE",
      tooltipField: "returnDate",
    },
    {
      headerName: "RETURN VERSION NUMBER",
      field: "returnVersionNumber",
      headerTooltip: "RETURN VERSION NUMBER",
      tooltipField: "returnVersionNumber",
    },
    {
      headerName: "SUPPLIER ID",
      field: "supplierId",
      headerTooltip: "SUPPLIER ID",
      tooltipField: "supplierId",
    },
    {
      headerName: "SUPPLIER NAME",
      field: "supplierName",
      headerTooltip: "SUPPLIER NAME",
      tooltipField: "supplierName",
    },
    {
      headerName: "SHIP TO ADDRESS",
      field: "shipToAddress",
      headerTooltip: "SHIP TO ADDRESS",
      tooltipField: "shipToAddress",
    },

    {
      headerName: "WITH INVOICE",
      field: "withInvoice",
      headerTooltip: "WITH INVOICE",
      tooltipField: "withInvoice",
    },
    {
      headerName: "INVOICE  NUMBER",
      field: "invoiceNumber",
      headerTooltip: "INVOICE  NUMBER",
      tooltipField: "invoiceNumber",
    },
    {
      headerName: "INVOICE DATE",
      field: "InvoiceDate",
      headerTooltip: "INVOICE DATE",
      tooltipField: "InvoiceDate",
    },
  ]);
  function alerts(params: any, poNum: any) {
    setValidationPoNumber(poNum);
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);

  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        columnDefs: [
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationStatus",
            cellRenderer: (params: ICellRendererParams) => {
              if (params.value === true) {
                return <p className="status">TRUE</p>;
              } else {
                setDisable(false);
                return <p className="rstatus">FALSE</p>;
              }
            },
          },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION STATUS",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              if (params.value.length > 0) {
                return (
                  <Button
                    onClick={() =>
                      alerts(
                        params.value,
                        params.data.preCheckValidationMessage
                      )
                    }
                  >
                    {params.value[0]}
                  </Button>
                );
              } else {
                return <p>NO VALIDATION</p>;
              }
            },
          },
          { headerName: "LINE NUMBER", field: "lineNumber" },

          { headerName: "CATEGORY", field: "category" },
          { headerName: "PART ID", field: "partId" },
          { headerName: "PART TYPE", field: "partType" },
          { headerName: "PART DESCRIPTION", field: "partDescription" },

          { headerName: "QUANTITY", field: "QTY" },
          { headerName: "PRIMARY UOM", field: "primaryUOM" },
          { headerName: "SECONDARY UOM", field: "secondaryUOM" },
          { headerName: "CANCEL STATUS", field: "cancelStatus" },
          {
            headerName: "SHIPPED BY SELF",
            field: "shippedBySelf",
            headerTooltip: "SHIPPED BY SELF",
            tooltipField: "shippedBySelf",
          },
        ],

        defaultColDef: {
          sortable: true,
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        console.log(params.data.lines);
        params.successCallback(params.data.lines);
      },
    };
  }, []);

  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      console.log(e.target.files[0], "files");
      if (split[1] !== "xlsx") {
        console.log(split);
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setExcelName(e.target.files[0].name);
    }
  }
  const handlePreview = () => {
    console.log(file, "$file");
    if (file === undefined) {
      toast.warning("Please Select a File...");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    setLoad(true)
    axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/ReturnOrderUpload', formData, config).then((res: any) => {
      console.log(res);
      setLoad(true)
      setPreviewData(res.data);
      setRowData(res.data.data);
      res.data.data.map((disables: any) => {
        if (disables.preCheckValidationStatus === false) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      });
    }).catch((e: any) => {
      setLoad(false);
    });
    setShowFile(true);
    console.log(disable);
  };

  const SubmitFile = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    let obj: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    console.log(obj);
    axios.post(process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/returnOrderUploadSubmit', obj, config).then((res: any) => {
      console.log(res);
      // setPreviewData(res);
      // setRowData(res.data.data);
    });
    setRowData([]);
    setShowFile(false);
    FormRef.current.reset();
    setExcelName("Selcet File");
  };
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    FormRef.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  useEffect(() => {
    setLoad(false)
  }, [fromDate, toDate, format])
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/ReturnOrderUpload.xlsx"}
            download={"ReturnOrderUpload.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton>{" "}
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={FormRef}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                marginLeft: "inherit",
              }}
              onClick={(event) => handlePreview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              color="warning"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={10}
              // onGridReady={onGridReady}
              // onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {/* <Button variant="contained" type="submit" onClick={SubmitFile}>
                Submit
              </Button> */}
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

export default ReturnFileUpload;

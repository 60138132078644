import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import { access } from "fs";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import FastForwardIcon from "@mui/icons-material/FastForward";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
const CarrierTabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    // children: [],
    access: true,
    route: "/",
  },
  {
    id: 1,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        title: "CARRIER",
        icon: <DirectionsBoatFilledOutlinedIcon />,
      },
      {
        cid: 1,
        childnav: "Carrier Confirmation Upload",
        route: "/carrier_confirmation_upload",
        access: true,
        disable:true,
      },
      {
        cid: 2,
        childnav: "Pending Carrier Confirmation",
        route: "/pending_carrier_confirmation",
        access: true,
        disable:true,
      },
      {
        cid: 5,
        childnav: "Add Legs",
        route: "/add_legs",
        access: true,
        disable:true,
      },
      {
        cid: 3,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
        disable:true,
      },
      {
        cid: 4,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "InTransit ",
    icon: <TransferWithinAStationSharpIcon />,
    children: [
      {
        cid: 0,
        title: "INTRANSIT",
        icon: <TransferWithinAStationSharpIcon />,
      },
      {
        cid: 1,
        childnav: "InTransit Events Upload",
        route: "/intransit_events_upload",
        access: true,
      },
      {
        cid: 2,
        childnav: "InTransit Shipments",
        route: "/intransit_shipments",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 3,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: true,
  },
  {
    id: 4,
    parentnav: "UploadDoc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    children: [
      {
        cid: 0,
        title: "UPLOAD DOCUMENTS",
        icon: <UploadFileOutlinedIcon />,
      },
      {
        id: 1,
        childnav: "Upload Documents",
        route: "/upload_documents",
        access: true,
      },
      // {
      //   id: 2,
      //   childnav: "Manage Documents",
      //   route: "/manage_documents",
      //   access: true,
      // },
    ],
  },
];

export default CarrierTabData;

import { AppBar, Box, Button, Dialog, IconButton, Slide, Step, StepContent, StepLabel, Stepper, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TabContext } from '@mui/lab';
import { GoogleMap, MarkerF, PolylineF, useJsApiLoader } from '@react-google-maps/api';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ShipmentRegistrationEvents(props: any) {
    console.log(props, "props")
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = useState(false);
    const [shipmentEventData, setShipmentEventData] = useState<any>([])
    let icon: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
    let iconbetween: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ffa500";
    let iconorigin: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|f4c919";
    const locationDataa: any = [];
    if (props.events.length > 0) {
        if ((props.events[0].shipmentLegs.portToPort.loadingPortCoordinates.latitude != null) && (props.events[0].shipmentLegs.portToPort.loadingPortCoordinates.latitude != undefined)) {
            let obj = {
                name: props.events[0].shipmentLegs.portToPort.loadingPort,
                location: {
                    lat: parseFloat(props.events[0].shipmentLegs.portToPort.loadingPortCoordinates.latitude),
                    lng: parseFloat(props.events[0].shipmentLegs.portToPort.loadingPortCoordinates.latitude),
                },
            };
            locationDataa.push(obj);
        }
        if ((props.events[0].shipmentLegs.portToPort.latestLocation.latitude != null) && (props.events[0].shipmentLegs.portToPort.latestLocation.latitude != undefined)) {
            let obj1 = {
                location: {
                    lat: parseFloat(props.events[0].shipmentLegs.portToPort.latestLocation.latitude),
                    lng: parseFloat(props.events[0].shipmentLegs.portToPort.latestLocation.latitude),
                }
            }
            locationDataa.push(obj1)
        }
        if ((props.events[0].shipmentLegs.portToPort.dischargePortCoordinates.latitude != null) && (props.events[0].shipmentLegs.portToPort.dischargePortCoordinates.latitude != undefined)) {
            let obj2 = {
                name: props.events[0].shipmentLegs.portToPort.dischargePort,
                location: {
                    lat: parseFloat(props.events[0].shipmentLegs.portToPort.dischargePortCoordinates.latitude),
                    lng: parseFloat(props.events[0].shipmentLegs.portToPort.dischargePortCoordinates.longitude),
                },
            };
            locationDataa.push(obj2);
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
    });
    const containerStyle = {
        width: "80vw",
        height: "80vh",
    };
    const options = {
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: "#000000",
        fillOpacity: 0.35,
        clickable: true,
        draggable: false,
        editable: false,
        visible: true,
    };
    const paths = locationDataa.map((item: any) => item.location);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const reverse = () => {
        setShipmentEventData(shipmentEventData.reverse());
        setSort(sort ? false : true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        console.log(props.events[0], "props")
        if (props.events.length > 0) {
            setShipmentEventData(props.events[0].shipmentEvents)
        }

    }, [])
    return (
        <div>
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        {/* <Box sx={{ display: "flex", width: "80%", alignItems: "center" }}>
                            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                                SHIPMENT REF # :
                                <span className="dialogTitle">{props.events[0].referenceNumber}</span>{" "}
                            </Typography>&nbsp;

                            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                                CONTAINER # :
                                <span className="dialogTitle blueColor">{props.events[0].containerNumber}</span>{" "}
                            </Typography>&nbsp;
                            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                                CARRIER :
                                <span className="dialogTitle greyColor">{props.events[0].carrierScac}</span>{" "}
                            </Typography>


                        </Box> */}
                        <Box sx={{ display: "flex", width: "70%", alignItems: "center" }}>
                            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
                                HBL # :
                                {props.events[0].hblNumber ? <span className="dialogTitle">
                                    {props.events[0].hblNumber}</span> : ''}
                                &nbsp;&nbsp; MBL # :{" "}
                                {props.events[0].mblNumber ? <span className="dialogTitle blueColor">
                                    {props.events[0].mblNumber}
                                </span>:''}
                                &nbsp;&nbsp;
                                CONTAINER # : 
                                <span className="dialogTitle blueColor">{props.events[0].containerNumber}</span>{" "}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",

                                    alignItems: "center",
                                    flexDirection: "row",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <LocationOnIcon fontSize="medium" sx={{ color: "#f4c919" }} />
                                    <p style={{ fontSize: "14px" }}>{props.events[0].shipmentLegs.portToPort.loadingPort}</p>
                                </Box>
                                &nbsp;
                                <Box sx={{ marginBottom: "18px", marginRight: "15px" }}>
                                    <span>
                                        <ArrowRightAltIcon fontSize="large" />
                                    </span>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <LocationOnIcon sx={{ color: "red" }} fontSize="medium" />
                                    <p style={{ fontSize: "14px" }}>
                                        {props.events[0].shipmentLegs.portToPort.dischargePort}

                                    </p>
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={props.onClose}
                            sx={{ marginLeft: "auto" }}
                        >
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden",
                        margin: "3px",
                    }}
                >
                    <Box
                        sx={{
                            margin: "5px",
                            width: "30%",
                            height: "inherit",
                            overflowY: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <Typography
                                sx={{

                                    textAlign: "left",
                                    fontSize: "18px",
                                    marginLeft: "20px",
                                }}
                            >
                                EVENTS
                            </Typography>

                            <IconButton>
                                {sort ? (
                                    <ArrowUpwardIcon onClick={() => reverse()} />
                                ) : (
                                    <ArrowDownwardIcon onClick={() => reverse()} />
                                )}
                            </IconButton>
                        </Box>

                        {shipmentEventData.length > 0 ? (
                            <Stepper
                                activeStep={shipmentEventData.length - 1}
                                orientation="vertical"
                                sx={{
                                    display: "flex",
                                    border: "2px soild red",
                                    paddingLeft: "10px",
                                }}
                            >
                                {shipmentEventData.map((step: any, index: any) => (
                                    <Step key={index}>
                                        <StepLabel>
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {step.name}
                                                {/* <br></br>
                        {step.date}
                        <br></br>
                        {step.location} */}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    color: "black",

                                                }}
                                            >
                                                {step.actualTime}
                                            </Typography>

                                        </StepLabel>
                                        <StepContent>
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    fontWeight: "300px",
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {step.description}
                                            </Typography>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "100%",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>No Events</Typography>
                            </Box>
                        )}

                    </Box>

                    <Box sx={{ width: "100%", typography: "body1" }}>
                        {isLoaded ? (
                            <GoogleMap
                                //onLoad={handleOnLoad}
                                mapContainerStyle={containerStyle}
                                center={locationDataa[0].location}
                                zoom={2}

                            >
                                {locationDataa
                                    .slice(1, locationDataa.length - 1)
                                    .map((item: any, index: any) => {
                                        return (
                                            <MarkerF
                                                // title={item.name}
                                                position={item.location}
                                                visible={true}
                                                icon={iconbetween}
                                                options={options}
                                                animation={2}
                                            />
                                        );
                                    })}

                                <MarkerF
                                    title={locationDataa[0].name}
                                    position={locationDataa[0].location}
                                    options={options}
                                    animation={2}
                                    visible={true}
                                    icon={iconorigin}
                                />
                                <MarkerF
                                    title={locationDataa[locationDataa.length - 1].name}
                                    position={locationDataa[locationDataa.length - 1].location}
                                    options={options}
                                    animation={2}
                                    visible={true}
                                    icon={icon}
                                />
                                <PolylineF path={paths} options={{ path: paths, strokeOpacity: 1 }} />
                            </GoogleMap>
                        ) : null}
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

import { Box, Button, Card, CardActions, CardContent, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import swal from "sweetalert";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from 'react-toastify';
import { Endpoints } from '../Enviornment/Endpoints';
import { ApiRequestFunction } from '../Services/BaseService';
export default function ChangePassword() {
    const { register, resetField, reset, setValue, formState: { errors }, control, handleSubmit, watch } = useForm({
        defaultValues: {
            email: '',
            newPassword: '',
            confirmPassword: ''
        },
    });
    const nav = useNavigate();
    const location = useLocation()
    const [token, setToken] = useState(queryString.parse(location.search).token)
    const [email, SetEmail] = useState(queryString.parse(location.search).email)
    const [userName, setUserName] = useState(queryString.parse(location.search).userName)
    const [changePasswordShow, setChangePasswordShow] = useState(false)
    const [showConfirmPwd, setShowConfirmPwd] = useState(false)
    const [respMessage, setRespMessage] = useState('')
    const [showpwd, SetShowpwd] = useState(false);
    const [passwordUpdate, setPasswordUpdate] = useState(false)
    const handlepwd = () => SetShowpwd(!showpwd);
    const handleNewpwd = () => setShowConfirmPwd(!showConfirmPwd)
    useEffect(() => {
        if (!changePasswordShow) {
            const payloadObject = {
                token: token,
                email: email,
                userName: userName
            };
            ApiRequestFunction.Api_Config.params = payloadObject
            axios.get(Endpoints.Partner_Verification, ApiRequestFunction.Api_Config).then((res: any) => {
                if (res.data.status.code === 'SUCCESS') {
                    setPasswordUpdate(false)
                    setChangePasswordShow(true)

                } else {
                    setChangePasswordShow(false)
                    setRespMessage(res.data.status.message)
                    setPasswordUpdate(false)
                }
            })
        }

    })
    const resetForm = () => {
        reset()
    }

    const createPassword = (item: any) => {
        let obj = {
            email: email,
            password: item.newPassword,
            userName: userName
        }
        axios.post(Endpoints.Set_New_Password, obj).then((res: any) => {
            if (res.data.status.code === 'SUCCESS') {
                reset()
                setPasswordUpdate(true)
                setRespMessage(res.data.primary)
            } else {
                toast.warning(res.data.status.message)
                setPasswordUpdate(false)
            }
        })
    }
    const PasswordStrengthValidator = (password: any) => {
        // Minimum length 8, maximum length 20
        const lengthRegex = /^.{8,20}$/;
        // At least one special character
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
        // At least one uppercase letter
        const uppercaseRegex = /[A-Z]/;
        return (
            lengthRegex.test(password) &&
            specialCharRegex.test(password) &&
            uppercaseRegex.test(password)
        );
    };

    return (
        <div>
            {!passwordUpdate ?
                changePasswordShow ?
                    <Box className="changePassword" sx={{ flexGrow: 1, overflow: "auto" }}>
                        <Box sx={{ float: 'right' }}>
                            <img
                                src={require("../asserts/end_to_end.png")}
                                className='header_logo'

                            />
                        </Box>
                        <Paper sx={{ margin: '5% 15%', display: 'flex' }} >
                            <Grid container spacing={2} sx={{ p: 5 }}>
                                <Grid item xs={12} md={6} sm={12} lg={6} sx={{ m: 'auto' }}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img src={require('../asserts/reset-password (1).png')} style={{ width: '70%' }} />

                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} sm={12} lg={6}>

                                    <Box className="header" sx={{ mb: 3 }}>
                                        <Typography variant='h6'>Please Set Your Password</Typography>
                                    </Box>
                                    <form onSubmit={handleSubmit((data) => {
                                        createPassword(data)
                                    })}>
                                        <Box>
                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Email/User Name</Typography>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    {...register('email')}
                                                    id="component-outlined"
                                                    placeholder="Email"
                                                    type="text"
                                                    sx={{
                                                        width: "100%",
                                                        marginBottom: 2,
                                                    }}
                                                    size='small'
                                                    fullWidth
                                                    value={email}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '12px', pb: 0.5, fontWeight: 'bold' }}>Password</Typography>
                                            <FormControl fullWidth size="small" sx={{ pb: '0px' }}>
                                                <TextField
                                                    {...register('newPassword', {
                                                        required: "Password is required",
                                                        minLength: {
                                                            value: 8,
                                                            message: "Password should be atleast 8 characters.",
                                                        },
                                                        maxLength: {
                                                            value: 13,
                                                            message: 'Password should be less than 13 characters.'
                                                        },
                                                        validate: {
                                                            hasUpperCase: (value: any) => value && /\w*[A-Z]\w*/.test(value) || "Password should contain a capital letter.",
                                                            hasSpecialCharacter: (value) => value && /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/i.test(value) || "Password should contain a special character.",
                                                            // watching: (value) =>  value === watch('confirmPassword') || 'Passwords do not match.',

                                                        }

                                                    })}
                                                    size='small'
                                                    id="component-outlined"
                                                    placeholder="Password"
                                                    type={showpwd ? "text" : "password"}
                                                    sx={{
                                                        width: "100%",
                                                        marginBottom: 2,
                                                    }}

                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password Visibility"
                                                                    onClick={handlepwd}
                                                                >
                                                                    {showpwd ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.newPassword?.message}
                                                </span>
                                            </FormControl>

                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Confirm Password</Typography>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    {...register('confirmPassword', {

                                                        required: 'Confirm password is required.',

                                                        validate: (value) => value === watch('newPassword') || 'Passwords do not match.',

                                                    })}
                                                    id="component-outlined"
                                                    placeholder="Confirm Password"
                                                    size='small'
                                                    type={showConfirmPwd ? "text" : "password"}
                                                    sx={{
                                                        width: "100%",
                                                        marginBottom: 2,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password Visibility"
                                                                    onClick={handleNewpwd}

                                                                >
                                                                    {showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.confirmPassword?.message}
                                                </span>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
                                            <Button variant='contained' type='submit' className='submit' fullWidth>Submit</Button>&nbsp;&nbsp;
                                            <Button variant='contained' color="inherit" className='reset' fullWidth onClick={resetForm}>Reset</Button>
                                        </Box>
                                    </form>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Box sx={{ display: 'flex', textAlign: 'center', color: '#fff' }}>
                            <a href="https://www.realvariable.com/" target="_blank" className="changePassword_footer_title">
                                <Typography>
                                    Powered by
                                </Typography>
                                <Typography

                                >
                                    <img src={require('../asserts/rv.png')} className="footer_img  " />
                                </Typography>
                            </a>
                        </Box>


                    </Box> : <Box className="changePassword" sx={{ flexGrow: 1, overflow: "auto" }}>
                        <Box sx={{ float: 'right' }}>
                            <img
                                src={require("../asserts/end_to_end.png")}
                                className='header_logo'

                            />
                        </Box>
                        <Paper sx={{ margin: '10% 30%', display: 'flex' }}>
                            <Typography variant='h4' className='dummyPage'>{respMessage}</Typography>
                            <Box sx={{ maxWidth: 400, minHeight: 200, mx: 'auto', my: 5 }}>
                                <Box sx={{ width: '100%', textAlign: 'center' }}>
                                    <InfoOutlinedIcon fontSize='large' color='warning' sx={{ width: '100px !important', height: '100px !important' }} />
                                    <Typography>{respMessage}</Typography>
                                    <Typography sx={{ mt: 2 }}><a href='/'>Please contact to administrator</a></Typography>

                                </Box>

                            </Box>
                        </Paper>

                    </Box> : <Box className="changePassword" sx={{ flexGrow: 1, overflow: "auto" }}>
                    <Box sx={{ float: 'right' }}>
                        <img
                            src={require("../asserts/end_to_end.png")}
                            className='header_logo'

                        />
                    </Box>
                    <Paper sx={{ textAlign: 'center',margin:'10% 30%' }}>
                        <Typography variant='h4' className='dummyPage'>{respMessage}</Typography>
                        <Box sx={{ maxWidth: 400, minHeight: 200, mx: 'auto',}}>
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <CheckCircleOutlinedIcon fontSize='large' color='success' sx={{ width: '100px !important', height: '100px !important' }} />
                                <Typography>{respMessage}</Typography>
                                <Typography sx={{ mt: 2 }}><a href='/'>Please visit login page</a></Typography>

                            </Box>

                        </Box>

                    </Paper>

                </Box>}
        </div>
    )
}

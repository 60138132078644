import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import CloseIcon from "@mui/icons-material/Close";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import Full from "../../../Helpers/IntransitEventsMapping";
import "../../../Styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import swal from "sweetalert";
import TooltipCom from "../../../Helpers/ToolTipComponent";
import moment from "moment";
import IntransitEventsMapping from "../../../Helpers/IntransitEventsMapping";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { ApiRequestFunction } from "../../../Services/BaseService";
import { ToastContainer, toast } from "react-toastify";
import DataTable, { TableColumn } from "react-data-table-component";
import SearchIcon from '@mui/icons-material/Search';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7'
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {

    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },

};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function IntransitForDelivery(props: any) {
  const nav = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  const gridRef = useRef<any>();
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);
  const [ponumber, setPoNumber] = useState<any>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const handleClose = () => setUpdate(false);
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>('');

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const handleClear = () => {
    setSearchTerm('')
  }
  const filterData = rowData.filter((data: any) => {
    return Object.values(data).some((value) => {
      if (value === null || value === undefined || value === '') {
        // Include null and undefined values
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  console.log("Filtered DATA..................", filterData)


  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }

  const columns: TableColumn<any>[] = [
    {
      name: "INVOICE #",
      sortable: true,
      selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined ? row.invoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "MBL #",
      selector: row => row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber != undefined ? row.mblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "MAWB #",
      selector: row => row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber != undefined ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Ocean' ? true : false,
    },
    {
      name: "HBL #",
      selector: row => row.hblNumber !== '' && row.hblNumber !== null && row.hblNumber != undefined ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "HAWB #",
      selector: row => row.hawbNumber !== '' && row.hawbNumber !== null && row.hawbNumber != undefined ? row.hawbNumber  : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Ocean' ? true : false,
    },
    {
      name: "BOOKING  #",
      selector: row => row.carrierReference !== '' && row.carrierReference !== null && row.carrierReference != undefined ? row.carrierReference : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "CONTAINER #",
      selector: row => row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber != undefined ? row.containerNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "FLIGHT #",
      selector: row => row.flightNumber !== '' && row.flightNumber !== null && row.flightNumber != undefined ? row.flightNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
     
      width: '200px',
      omit: mode == 'Ocean' ? true : false,
    },
    {
      name: "CARRIER #",
      selector: row => row.carrierName !== '' && row.carrierName !== null && row.carrierName != undefined ? <Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "SHIPPER #",
      selector: row => row.shipperName !== '' && row.shipperName !== null && row.shipperName != undefined ? <Tooltip title={row.shipperName} arrow><span>{row.shipperName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "CONSIGNEE #",
      selector: row => row.consigneeName !== '' && row.consigneeName !== null && row.consigneeName != undefined ? 
      <Tooltip title={row.consigneeName} arrow><span>{row.consigneeName}</span></Tooltip> 
      : <span style={{ fontStyle: 'italic' }}>- NA -</span>,  
      width: '200px'
    },
    {
      name: "ETA ",
      selector: row => row.eta !== '' && row.eta !== null && row.eta != undefined ? row.eta : <span style={{ fontStyle: 'italic' }}>- NA -</span>,  
     
      width: '200px'
    },
    {
      name: "VIEW DOCS ",

      width: '200px',
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => managedoc(row)}
      >
        View Doc
      </a>
    },
    {
      name: 'ACTION',
      cell: (row: any) => <Button size="small" variant="contained" sx={{ backgroundColor: '#5fb25c' }} onClick={() => handleSubmitConfirmReceipt(row)}> Confirm Receipt</Button>,
      width: '200px'
    }

  ]
  const handlemap = () => {
    setNodata(false);
  };
  const handleLine = (params: any) => {
    setLineData(params);
    setOpenLineItems(true);
  };
  const managedoc = (params: any) => {
    console.log(params, "params")
    let docObject: any;
    if (params.invoiceNumber != 'NA') {
      docObject = {
        docAgainst: 'INV',
        documentReference: params.invoiceNumber
      }
    }
    nav("/manage_documents", { state: docObject });
  };

  // ---------------- confirm goods receipt list -------------------
  const getTransitList = () => {
    sessionStorage.setItem('deliveryStatus', 'pending')
    let requestPayload = {
      type: role,
      status: "gateOut",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
      shipmentType: mode
    }
    Api_Config.params = requestPayload
    setLoad(true)
    axios.get(Endpoints.Intransit_Delivery_List, Api_Config).then((res) => {
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      }else{
        setShowGrid(true)
        setLoad(false);
        setRowData(res.data.primary);
      }
     
    }).catch((Err)=>{
      setLoad(false)
      setShowGrid(false)
    })
  };
  // ----------------- confirm submit ----------------------
  const handleSubmitConfirmReceipt = (params: any) => {
    let payload: any = []
    let obj = {
      shipperName:params.shipperName,
      consigneeName:params.consigneeName,
      invoiceNumber: params.invoiceNumber,
     shipmentType:mode
    };
    payload.push(obj);
    const data = {
      payload: payload,
    };

    console.log("data is data",data)
    Api_Config.params = {}
    axios.post(Endpoints.update_Shipment_Status_To_Delivery, data, Api_Config).then((res: any) => {
      console.log(res, "final resp")
      toast.success(res.data.primary)
      getTransitList()
    })
  }
  const handleUpdate = () => {
    let selectedData = gridRef.current.api.getSelectedRows();
    console.log("update.....");
    let payload: any = [];
    selectedData.map((key: any, index: number) => {
      let obj = {
        carrierReference: key.carrierReference,
        invoiceNumber: key.invoiceNumber,
        hscode: key.hscode,
        hscodeDescription: key.hscodeDescription,
      };
      payload.push(obj);
    });
    const data = {
      payload: payload,
    };
    axios.post(process.env.REACT_APP_API_URL + "po/", data, Api_Config).then((res: any) => {
      console.log("intransit Delivery Update...", res);
      swal("success", res.data.primary, "success");
      getTransitList();
    });

    setUpdate(false);
  };
  useEffect(() => {
    console.log(mode, "modeeeeeeeeee")
    getTransitList();
  }, [fromDate, toDate, mode]);
  return (
    <div className={classes.root}>
      {rowData.length > 0 && showgrid &&
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: -3.05 }}>
          <Paper
            component="form"
            elevation={0}
            className="custom-search"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, }}
              placeholder={mode == 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : "Search By HAWB/MAWB/Invoice"}
              inputProps={{ 'aria-label': 'search google maps' }}
              size="small"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
              {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
            </IconButton>

          </Paper>
        </Box>
      }
      <Paper elevation={0}>
        {rowData.length > 0 && showgrid ? (
          <div>
            <Box sx={{ p: 0, mt: 1 }}>
              <DataTable
                style={{ borderRadius: 3 }}
                columns={columns}
                data={filterData}
                responsive={true}
                customStyles={customStyles}
                theme="default"

                pagination
                dense
                striped
                highlightOnHover={true}
                onRowClicked={(data) => handleLine(data)}

              />
            </Box>
          </div>
        ) : (
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            No data found
          </Alert>
        )}
        
      </Paper>

      <div>
        <Dialog
          open={update}
          onClose={() => handleClose()}
          disableEscapeKeyDown
        >
          <DialogTitle>
            {ponumber.length > 1 ? (
              <Tooltip title={<TooltipCom data={ponumber} placement="left" />}>
                <p>Shipments Delivered: 1 or More Shipments Delivered</p>
              </Tooltip>
            ) : (
              <Typography>Shipments Delivery: {ponumber[0]}</Typography>
            )}
          </DialogTitle>
          <DialogContent>
            Are You Sure You Want To Confirm Goods Receipt ?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdate()}>Confirm</Button>
            <Button onClick={() => handleClose()}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer style={{ zIndex: 10000 }} />
    </div>
  );
}

export default IntransitForDelivery;

import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Popover,
  Grid,
  Alert,
  AlertTitle,
  FormControl,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { makeStyles, Theme } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import TextField from "@mui/material/TextField";
import "./Br_line.css";
import "../../../Styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { ApiRequestFunction, getRequest, PostRequest } from "../../../Services/BaseService";
import { render } from "@testing-library/react";
import { DialogActions, DialogTitle, Tooltip } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useOutletContext } from "react-router-dom";
import swal from "sweetalert";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { Endpoints } from "../../../Enviornment/Endpoints";
import Loaders from "../../common/Loaders";
import { ToastContainer, toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BrLineItems = (props: any) => {
  const { register, resetField, reset, setValue, formState: { errors }, control, handleSubmit, } = useForm({
    defaultValues: {
      [props.data.modeofShipment ? 'hbl' : 'hawb']: '',
    },
  });
  const { register: register2, resetField: resetFiled2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({
    defaultValues: {
      remarks: ''
    },
  });
  const domLayout = "autoHeight";
  const location = useLocation();
  const [showgrid, setShowGrid] = useState(true);
  const gridRef = useRef<any>();
  const [link, setLink] = useState("");
  const [loader, setLoader] = useOutletContext<any>();
  const [br, setBr] = useState("");
  const [hblcode, setHblCode] = useState("");
  const [rowData, setRowData] = useState([]);
  const [serial, setSerial] = useState("");
  const [qr, setQr] = useState("");
  const [QrOpen, SetQrOpen] = useState(false);
  const [hblOpen, setHblOpen] = useState(false);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [sno, setSno] = useState("");
  const [inv, setInv] = useState("");
  const [lot, setLot] = useState("");
  const [poNumber, setPoNumber] = useState<any>([]);
  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      hide:
        props.status === "open" &&
          sessionStorage.getItem("role") === "forwarder"
          ? false
          : true,
    },
    {
      headerName: "QR CODE",
      field: "qrImage",
      headerTooltip: "QR SCANNER",
      tooltipField: "qrscanner",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Box>
            <a onClick={() => showQr(params)}>
              <img
                src={require("../../../asserts/Qr_Scanner.jpg")}
                alt="QR SCANNER"
                width="20"
              />
            </a>
          </Box>
        );
      },
    },
    {
      headerName: "REJECTED REASON",
      hide: props.status === "rejected" ? false : true,
      tooltipField: "rejectedReason",
      field: "rejectedReason",
      headerTooltip: "REJECTED REASON",
      cellRenderer: (params: ICellRendererParams) => {
        let output;
        if (params.value.length > 8) {
          output = params.value.substring(0, 9);
          output = output + "....";
        } else {
          output = params.value;
        }
        return (
          <Tooltip title={params.value} placement="bottom-start">
            <Button style={{ color: "tomato" }}>{output}</Button>
          </Tooltip>
        );
      },
    },
    props.data.modeofShipment != 'Air' ?
      {
        headerName: "HBL #",
        field: "hblNumber",
        headerTooltip: "HBL #",
        tooltipField: "hblNumber",
        hide:
          location.pathname === "/rejected_by_forwarder" ||
            location.pathname === "/pending_forwarder_confirmation"
            ? true
            : false,
      } :
      {
        headerName: "HAWB #",
        field: "hawbNumber",
        headerTooltip: "HAWB #",
        tooltipField: "hawbNumber",
        hide:
          location.pathname === "/rejected_by_forwarder" ||
            location.pathname === "/pending_forwarder_confirmation"
            ? true
            : false,
      },
    {
      headerName: "COMMODITY TYPE",
      field: "commodityType",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      field: "itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "HS CODE",
      field: "hscode",
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
    },
    {
      headerName: "NUMBER OF PACKAGES",
      field: "packageDetails.numberofPackages",
      headerTooltip: "NUMBER OF PACKAGES",
      tooltipField: "number of packages",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE TYPE",
      field: "packageDetails.type",
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packagetype",
    },
    {
      headerName: "PACKAGE WEIGHT",
      field: "packageDetails.weight",
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageweight",
    },
    {
      headerName: "PACKAGE LENGTH",
      field: "packageDetails.length",
      headerTooltip: "PACKAGE LENGTH",
      tooltipField: "packagelength",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE WIDTH",
      field: "packageDetails.width",
      headerTooltip: "PACKAGE WIDTH",
      tooltipField: "packagewidth",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE HEIGHT",
      field: "packageDetails.height",
      headerTooltip: "PACKAGE HEIGHT",
      tooltipField: "packageheight",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PACKAGE SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
    {
      headerName: "HAZARDOUS GOODS",
      field: "hazardousGoods",
      headerTooltip: "hazardousGoods",
      tooltipField: "HAZARDOUS GOODS",
    },
    {
      headerName: "SHIPMENT LINE STATUS",
      field: "lineShipStatus",
      headerTooltip: "SHIPMENT LINE STATUS",
      tooltipField: "lineShipStatus",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value) {
          return params.value
        } else {
          return 'NA'
        }
      },
    },

    {
      headerName: "REQUESTED DATE OF DELIVERY",
      field: "brRequestedDateOfDelivery",
      headerTooltip: "brRequestedDateOfDelivery",
      tooltipField: "REQUEST DATE OF DELIVERY",
      cellClass: "ag-left-aligned-cell",
    },


  ]);
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  const handleAccept = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length <= 0) {
      alert("Please Select Shipments");
    } else {
      setHblOpen(true);
    }
  };
  const handleReject = () => {
    let selectedRowsData = gridRef.current.api.getSelectedRows();
    if (selectedRowsData.length === 0) {
      swal("info", "Please Select shipments", "info");
    } else {
      let pos: any = [];
      selectedRowsData.map((ele: any, index: number) => {
        pos.push(ele.hscode);
      });
      setPoNumber(pos);
      setRemarkOpen(true);
    }
  };
  const getQrLink = () => {
    let link = props.data.qrImage.split("/");
    let qr = Endpoints.Qr_Image + link[3];
    setLink(qr);
  };
  const showQr = (params: any) => {
    console.log(params);
    let link = params.value.split("/");
    let qr = Endpoints.Qr_Image + link[3];
    setBr(params.data.batchNumber);
    setSerial(params.data.serialNumber);
    setQr(qr);
    SetQrOpen(true);
    setSno(params.data.serialNumber);
    setInv(params.data.invoiceNumber);
    setLot(params.data.lotNumber);
  };
  const acceptHandleSubmit = (payload: any) => {
    let data = gridRef.current.api.getSelectedRows();
    let submitList: any = []
    data.map((item: any, index: number) => {
      let acceptPayload = {
        hscode: item.hscode,
        itemDescription: item.itemDescription,
        docReference: props.data.docReference,
        [props.data.modeofShipment != 'Air' ? 'hblNumber' : 'hawbNumber']: payload.hbl != '' ? payload.hbl : payload.hawb,
        brStatus: "accepted",
        shipmentType: props.data.modeofShipment
      };
      submitList.push(acceptPayload);
    });
    let finalAcceptPayload = {
      data: submitList,
    };
    setLoader(true)
    axios.post(Endpoints.Update_Booking_Request_Status, finalAcceptPayload, Api_Config).then(async (res) => {
      setHblOpen(false);
      setLoader(false)
      if (res.data.status.code === 'SUCCESS') {
        toast.success(res.data.status.message);
        resetField('hbl');
        resetField('hawb');
        await getBrLines()
      }
    }).catch((error) => {
      setLoader(false)
      console.log(" response error " + JSON.stringify(error));
      return error;
    });
    resetField('hbl')
  };
  const rejectHandleSubmit = (payload: any) => {
    let data = gridRef.current.api.getSelectedRows();
    let rejectList: any = []
    setHblOpen(false);
    data.map((item: any, index: number) => {
      let obj = {
        hscode: item.hscode,
        itemDescription: item.itemDescription,
        docReference: props.data.docReference,
        rejectedReason: payload.remarks,
        brStatus: "rejected",
        shipmentType: props.data.modeofShipment
      };
      rejectList.push(obj);
    });
    let finalRejectSubmit = {
      data: rejectList,
    };
    setRemarkOpen(false);
    setLoader(true)
    axios.post(Endpoints.Update_Booking_Request_Status, finalRejectSubmit, Api_Config).then(async (res) => {
      setLoader(false)
      if (res.data.status.code === 'SUCCESS') {
        toast.success(res.data.status.message);
        setHblCode("");
        getBrLines();
      }
    }).catch((error) => {
      setLoader(false)
      console.log(" response error " + JSON.stringify(error));
      return error;
    });
    setHblCode("");
  };
  const getBrLines = () => {
    setLoader(true)
    let brLinesPayload = {
      docReference: props.data.docReference,
      brStatus: props.status == "accepted" ? "accepted,cleared" : props.status,
      shipmentType: props.data.modeofShipment
    };
    Api_Config.params = brLinesPayload
    axios.get(Endpoints.Pending_Forwarder_Booking_ItemList, Api_Config).then((res) => {
      setLoader(false)
      if (res.data.primary.length === 0) {
        props.onClose()
      } else {
        setRowData(res.data.primary);
      }
    }).catch((error) => {
      setLoader(false)
    })
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    getBrLines();
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  useEffect(() => {
    getQrLink();
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column: any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column: any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, []);
  function autosize() {
    let allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().forEach(function (column: any) {
      allColumnIds.push(column.colId);
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  }

  return (
    <Dialog
      fullScreen
      open={props.onOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-root": {},
        zIndex: 10000,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem' }} variant="h6" component="div">
            INVOICE # :{" "}
            <span className="dialogTitle">{props.data.invoiceNumber}</span>{" "}
            &nbsp;&nbsp; BOOKING REF # :{" "}
            <span className="dialogTitle blueColor">
              {props.data.forwarderBookingReference}
            </span>
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            justifyContent: "space-between",
            backgroundColor: "#f5f4f7",
          }}
        >
          <Box sx={{ height: "fit-content", width: "20%" }}>
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              INVOICE DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Number
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.invoiceNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Invoice Date
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.brInvoiceDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Consignee ID
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                >
                  {props.data.consigneeID}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              FORWARDER DETAILS
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Forwarder ID
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {props.data.forwarderID}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Forwarder Name
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {" "}
                  {props.data.forwarderName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#a7a5ad", fontSize: "15px" }}>
                  Mode Of Shipment
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                >
                  {props.data.modeofShipment}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box className="pointerIcon">
                <div className="countryText">{props.data.pickupCountry}</div>
                <span className="countryType">(Origin)</span>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  margin: "5px 0px",
                  color: "#555",
                }}
              >
              </Box>
              <Box className="pointerIcon">
                <div className="countryText destinationText">
                  {props.data.dropoffCountry}
                </div>
                <span className="countryType">(Destination)</span>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "fit-content",
              width: "20%",
            }}
          >
            <Typography sx={{ textAlign: "left", paddingBottom: "5px" }}>
              SCAN QR
            </Typography>
            <img src={link} alt="scann qr " width={100} height={"auto"} />
          </Box>
        </Box>
        <Grid
          xs={12}
          sx={{
            px: 2,
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <Typography variant="h6">SHIPMENTS</Typography>&nbsp;&nbsp;<span>{loader ? <Loaders /> : ''}</span>
          </Grid>
          <Grid item xs={6}>
            {props.status === "open" &&
              sessionStorage.getItem("role") === "forwarder" && showgrid ? (
              <div className="actionBtn">
                {loader ? <Loaders /> : ''}&nbsp;&nbsp;
                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#28a745",
                    marginRight: "3px",
                  }}
                  onClick={() => handleAccept()}
                  className="btn btn-info"
                  startIcon={<CheckCircleIcon />}
                >
                  Accept
                </Button>
                <Button
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    lineHeight: 0.5,
                    color: "white",
                    backgroundColor: "#95535a",
                  }}
                  onClick={() => handleReject()}
                  className="btn btn-info"
                  startIcon={<CancelIcon />}
                >
                  Reject
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Box sx={{ p: 2 }}>
          {rowData ? (
            <AgGridReact
              className="ag-theme-balham"
              ref={gridRef}
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
              onVirtualColumnsChanged={autosize}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Box>

      <Dialog
        open={QrOpen}
        onClose={() => SetQrOpen(!QrOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
        }}
      >
        <Box sx={{ padding: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Scan QR Code
            </Typography>

            <CloseIcon
              onClick={() => SetQrOpen(!QrOpen)}
              sx={{ color: "blue", cursor: "pointer" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={qr} alt="scann qr " width={270} height={250} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Batch # :</span>
            <span> {br}</span>
            &nbsp; &nbsp; <span style={{ fontWeight: "bold" }}>Serial # :</span>
            <span>{serial}</span>
          </Box>
        </Box>
      </Dialog>
      {/* HBL OPEN DIALOGUE */}
      <Dialog
        open={hblOpen}
        onClose={() => SetQrOpen(!hblOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          padding: 20,
        }}
      >
        <form onSubmit={handleSubmit((data: any) => {
          acceptHandleSubmit(data)
        })}>
          <DialogTitle>
            {props.data.modeofShipment != 'Air' ? 'HBL' : 'HAWB'}  Number For Booking Reference #{" "}
            {props.data.forwarderBookingReference}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ width: "500px" }}>
              Please Enter {props.data.modeofShipment != 'Air' ? 'HBL' : 'HAWB'} Number
            </DialogContentText>
            <FormControl fullWidth size="small">
              <TextField
                {...register(props.data.modeofShipment != 'Air' ? `hbl` : `hawb`, {
                  required: props.data.modeofShipment != 'Air' ? 'HBL # is required' : 'HAWB # is required',
                })}
                id="component-outlined"
                placeholder={props.data.modeofShipment != 'Air' ? 'HBL #' : 'HAWB #'}
                type="text"
                sx={{
                  width: "100%",
                }}
                size='small'
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {props.data.modeofShipment != 'Air' ?
                  errors.hbl?.message : errors.hawb?.message}
              </span>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loader}
              variant="text"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
              }}

            >
              Submit
            </LoadingButton>
            <Button
              onClick={() => {
                setHblOpen(false);
                resetField("hbl");
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* REAMRKS DIALOGUE */}

      <Dialog
        open={remarkOpen}
        onClose={() => SetQrOpen(!remarkOpen)}
        sx={{
          "& .MuiDialog-root": {},
          zIndex: 10000,
          width: "100%",
        }}
      >
        <form onSubmit={handleSubmit2((data: any) => {
          rejectHandleSubmit(data)
        })}>
          <DialogTitle>
            Remarks For Booking Reference # {props.data.forwarderBookingReference}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ width: "500px" }}>
              Please Enter Remarks
            </DialogContentText>
            <FormControl fullWidth size="small">
              <TextField
                {...register2(`remarks`, {
                  required: "Remarks is required",
                })}
                id="component-outlined"
                placeholder="Remarks"
                type="text"
                multiline={true}
                rows={4}
                minRows={10}
                sx={{
                  width: "100%",
                }}
                size='small'
              />
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors2.remarks?.message}
              </span>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loader}
              variant="text"
              type="submit"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
              }}

            >
              Submit
            </LoadingButton>
            <Button
              onClick={() => {
                setRemarkOpen(false);
                resetFiled2("remarks");
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ToastContainer style={{ zIndex: 10000 }} />
    </Dialog>
  );
};

export default BrLineItems;

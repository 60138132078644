import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import WorkspacesSharpIcon from "@mui/icons-material/WorkspacesSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
const WHTabs = [
  // {
  //   id: 0,
  //   parentnav: "Dashboard",
  //   icon: <DashboardSharpIcon />,
  //   // children: [],
  //   access: true,
  //   route: "/",
  // },
  {
    id: 1,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        route: "/wareHouse_confirmation_upload",
        childnav: "WareHouse Confirmation Upload",
        access: "true",
        disable:true
      },
      {
        cid: 1,
        route: "/pending_requests",
        childnav: "Pending Requests",
        access: "true",
        disable:true
      },
      {
        cid: 2,
        route: "/confirmed_shipments",
        childnav: "Confirmed Shipments",
        access: "true",
        disable:true
      },
      {
        cid: 3,
        route: "/rejected_shipments",
        childnav: "Rejected Shipments",
        access: "true",
        disable:true
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "In-Ward ",
    icon: <ArrowForwardSharpIcon />,
    access: true,

    children: [
      {
        cid: 1,
        route: "/inward_truckdetails",
        childnav: "InWard TruckDetails",
        access: "true",
      },
      {
        cid: 2,
        route: "/shipments_at_warehouse",
        childnav: "Shipments At WareHouse",
        access: "true",
      },
    ],
  },
  {
    id: 3,
    parentnav: "OutWard ",
    icon: <ArrowBackSharpIcon />,
    access: true,

    children: [
      {
        cid: 1,
        route: "/outward_shipments",
        childnav: "OutWard Shipments",
        access: "true",
      },
    ],
  },
  {
    id: 4,
    parentnav: "Space Utilization",
    icon: <WorkspacesSharpIcon />,
    children: [
      {
        cid: 1,
        route: "/space_utilization_report",
        childnav: "Space Utilization Report",
        access: "true",
      },
    ],
    access: true,
  },
  {
    id: 5,
    parentnav: "UploadDoc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    children: [
      {
        id: 1,
        childnav: "Upload Documents",
        route: "/upload_documents",
        access: true,
      },
    ],
  },
];
export default WHTabs;

//update Truck Details
//Shipments Moved Out

import { Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Endpoints } from '../Enviornment/Endpoints';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

export default function ForgetPassword() {
    const [showSucess, setSucess] = useState(false)
    const [successmsg, setSuccessMsg] = useState('')
    const [load, setLoad] = useState<boolean>(true);
    const [email, setEmail] = useState('')
    const { register, resetField, reset, setValue, formState: { errors }, control, handleSubmit, } = useForm({
        defaultValues: {
            email: '',
        },
    });
    const navigate = useNavigate();
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const createPassword = (item: any) => {
        setEmail(item.email)
        config.params = {
            email: item.email,
        }
        setLoad(true)
        axios.get(Endpoints.Verify_Email, config).then((res: any) => {
            setLoad(false)
            console.log(res.data)
            try {
                if (res.data.status.code === 'SUCCESS') {
                    // toast.success(res.data.status.message)
                    setSucess(true)
                    setSuccessMsg(res.data.status.message)

                }else{
                    toast.error(res.data.status.message)
                    setSucess(false)
                }
            } catch (e: any) {
                toast.error('Internal Server Error')
            }
        })
    }

    return (
        <div>
            <Box className="changePassword" sx={{ flexGrow: 1, overflow: "auto" }}>
                <Box sx={{ float: 'right' }}>
                    <img
                        src={require("../asserts/end_to_end.png")}
                        className='header_logo'

                    />
                </Box>
                <Paper sx={{ margin: '5% 15%', display: 'flex' }} >
                    <Grid container spacing={2} sx={{ p: 5 }}>
                        <Grid item xs={12} md={4} sm={12} lg={6} sx={{ m: 'auto' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                {showSucess == false ?
                                    <img src={require('../asserts/reset-password (1).png')} style={{ width: '50%' }} />
                                    : <img src={require('../asserts/verify.png')} style={{ width: '50%' }} />}

                            </Box>
                        </Grid>
                        {showSucess != true && <Grid item xs={12} md={6} sm={12} lg={5}>

                            <Box className="header" sx={{ mb: 3 }}>
                                <Typography variant='h6' sx={{ fontSize: '16px !important' }}>Forgot Password</Typography>
                                <small style={{ textAlign: 'center' }}>Please enter registered Email</small>
                            </Box>
                            <form onSubmit={handleSubmit((data) => {
                                createPassword(data)
                            })}>
                                <Box>
                                    <Typography sx={{ fontSize: '12px', pb: 1, fontWeight: 'bold' }}>Email</Typography>

                                    <FormControl fullWidth size="small">
                                        <TextField
                                            {...register('email', {
                                                required: "Email is required",
                                                pattern: {
                                                    value:
                                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Please enter a valid email address.",
                                                },
                                            })}
                                            id="component-outlined"
                                            placeholder="Email"
                                            type="text"
                                            sx={{
                                                width: "100%",
                                                marginBottom: 2,
                                            }}
                                            size='small'
                                            fullWidth
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {errors.email?.message}
                                        </span>
                                    </FormControl>
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
                                    <LoadingButton variant='contained' type='submit' className='submit' loading={showSucess} fullWidth>Submit</LoadingButton>&nbsp;&nbsp;

                                </Box>
                                {/* <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}> Login ? </a>
                                    </Typography> */}
                            </form>
                        </Grid>}
                        {
                            showSucess &&
                            <Grid item xs={12} md={8} sm={12} lg={12}>
                                <Box >
                                    <Typography sx={{ textAlign: 'center' }}>We have sent an email to <span style={{ fontWeight: 'bold' }}> {email} </span></Typography>
                                    <Typography sx={{ textAlign: 'center' }}> verify your email and reset your password</Typography>
                                    <Typography sx={{ textAlign: 'center', marginTop: '10px' }}><a style={{ cursor: 'pointer', fontWeight: 'bold',color:'blue' }} onClick={() => navigate('/login')}>Back To Login</a></Typography>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Paper>
                <Box sx={{ display: 'flex', textAlign: 'center', color: '#fff' }}>
                    <a href="https://www.realvariable.com/" target="_blank" className="changePassword_footer_title">
                        <Typography>
                            Powered by
                        </Typography>
                        <Typography

                        >
                            <img src={require('../asserts/rv.png')} className="footer_img  " />
                        </Typography>
                    </a>
                </Box>
            </Box>
            <ToastContainer/>
        </div>
    )
}

// export const environment = {
//   production: true,
//   keycloakConfig: {
//     clientId: "rw_viewer",
//     realm: "RR_DLR_1",
//     url: "https://auth.dizitwin.com/auth",
//   },
//   apiBaseUrl: "https://api.generic.rr-dlr-1.uat.dizitwin.com",
//   currencyUrl: "https://api.ui.rr-dlr-1.uat.dizitwin.com",
//   extApiBaseUrl: "https://realreco.ext.rr-dlr-1.uat.dizitwin.com",
//   qrBaseUrl: "https://qrcode.ext.rr-dlr-1.uat.dizitwin.com",
//   chatbaseurl: "http://192.168.0.153:9999",
//   applicationId: "dad707c1-bae2-4daa-8143-5b33f2ccc397",
//   viewerRole: "RW_VIEWER",
//   environmentName: "",
//   pdfBaseUrl: "https://write-api.pds.rr-dlr-1.uat.dizitwin.com",

//   logo: "../../../../assets/images/initialSetupimages/MicrosoftTeams-image (2).png",
//   logo1: "../../../../assets/images/initialSetupimages/mayora-logo-2.png",
// };


export const Environment_Realtraceb_Dev = {
  URL: 'https://auth.realtrace.realware.app/auth',
  REALM: 'RW_OEM_1',
  CLIENTID: 'rw_viewer',
  BASE_URL:
    // 'https://api.generic.rr-dlr-1-test.uat.dizitwin.com/features/REAL_RECO',
    'https://api.generic.realtrace.realware.tech/features/REALTRACEABILITY',
  BASE_ROLE: 'https://realreco.ext.rr-dlr-1-test.uat.dizitwin.com',

  BASE_URL_IMAGE:
    'https://api.generic.rr-dlr-1-test.uat.dizitwin.com/features/file-upload/REAL_RECO',

  GET_BASE_URL_IMAGE:
    'https://api.generic.rr-dlr-1-test.uat.dizitwin.com/doc_uploads/',

  DEFAULTIMAGE:
    //'121387f3-ae5b-41fa-ab03-4ae06dc1f8a0_default.png'; //dev
    'c3e7f05c-8023-4a45-933c-4a5001f26153_default.png', //TEST
  GET_REFRESH_TOKEN:
    // 'https://auth.dizitwin.com/auth/realms/RR_DLR_1/protocol/openid-connect/token',
    'https://auth.realtrace.realware.app/auth/realms/RW_OEM_1/protocol/openid-connect/token',
  logout_url:
    'https://auth.dizitwin.com/auth/realms/RR_DLR_1_TEST/protocol/openid-connect/logout',

  GET_LOGOUT:
    'https://auth.realware.app/auth/realms/RR_DLR_1_TEST/protocol/openid-connect/logout?redirect_uri=com.salesmanapp://oauthresponse/',

  keycloak_logout:
    'https://auth.dizitwin.com/auth/realms/RR_DLR_1_TEST/protocol/openid-connect/logout',
  GET_LOGOUT_ALL:
    'https://auth.dizitwin.com/auth/admin/realms/RR_DLR_1/logout-all',

  logout:
    'https://auth.dizitwin.com/auth/realms/RR_DLR_1_TEST/protocol/openid-connect/logout?redirect_uri=com.salesmanapp://oauthresponse/',

  // get_logout:
  //   'https://auth.dizitwin.com/auth/realms/RR_DLR_1_TEST/protocol/openid-connect/logout?client_id=rw_rr_mobile_app&redirect_uri=com.salesmanapp://oauthresponse/',
};

import React, { useEffect, useState } from 'react'

import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { Alert, AlertTitle, Box, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Echarts from './BarWithLinesEcharts';
import { Endpoints } from '../Enviornment/Endpoints';
import { ApiRequestFunction } from '../Services/BaseService';
import { AnyAaaaRecord } from 'dns';
export default function BarEcharts(props: any) {
    console.log("3457898765457890-097654578909876", props)
    const [shipmentInfo, setShipmentInfo] = useState(false)
    const [carrierInfo, setCarrierInfo] = useState<any>()
    const [carrierPercentage, setCarrierPercentage] = useState<any>()
    const [bookingRespTime, setBookingRespTime] = useState([])
    const [bookingRespHrs, setBookingRespHrs] = useState([])
    const [bookingRespPercentage, setBookingRespPercentage] = useState([])
    const [bookingRespIn3Hrs, setBookingRespIn3Hrs] = useState()
    const [bookingInfo, setBookingInfo] = useState<any>()
    const [alignment, setAlignment] = React.useState('currentMonth');
    const [forwarderNames, setForwarderNames] = useState([]);
    const [carrierNames, setCarrierNames] = useState([]);
    const [totalBookings, setTotalBookings] = useState([]);
    const [totalPendingBookings, setTotalPendingBookings] = useState([])
    const [totalAcceptedBookings, setTotalAcceptedBookings] = useState([])
    const [totalRejecetedBookings, setTotalRejectedBookings] = useState([])
    const [totalAcceptedBookingsIn3Hrs, setTotalAcceptedBookingsIn3Hrs] = useState([])
    const [selectedMonth,setSelectedMonth]=useState(0)
    const [totalAcceptedBookingsInMoreThan3Hrs, setTotalAcceptedBookingsInMoreThan3Hrs] = useState([])
    let respo = {

        "status": true,

        "data": [

            {

                "_id": {

                    "forwarderID": "fwd-003",

                    "forwarderName": "forwader 003"

                },

                "pendingBookingsCount": 1,

                "acceptedBookingsCount": 25,

                "rejectedBookingsCount": 1,

                "bookingRespLessThan3hrs": 27,

                "bookingRespGraterThan3hrs": 0

            },

            {

                "_id": {

                    "forwarderID": "paper001-s01",

                    "forwarderName": "paper001-s01"

                },

                "pendingBookingsCount": 230,

                "acceptedBookingsCount": 256,

                "rejectedBookingsCount": 20,

                "bookingRespLessThan3hrs": 506,

                "bookingRespGraterThan3hrs": 0

            },

            {

                "_id": {

                    "forwarderID": "paper001-s01",

                    "forwarderName": "forwader 001"

                },

                "pendingBookingsCount": 13,

                "acceptedBookingsCount": 7,

                "rejectedBookingsCount": 0,

                "bookingRespLessThan3hrs": 20,

                "bookingRespGraterThan3hrs": 0

            },

            {

                "_id": {

                    "forwarderID": "fwd-002",

                    "forwarderName": "kuehne nagel"

                },

                "pendingBookingsCount": 10,

                "acceptedBookingsCount": 55,

                "rejectedBookingsCount": 15,

                "bookingRespLessThan3hrs": 67,

                "bookingRespGraterThan3hrs": 13

            },

            {

                "_id": {

                    "forwarderID": "fwd-002",

                    "forwarderName": "forwader 001"

                },

                "pendingBookingsCount": 8,

                "acceptedBookingsCount": 28,

                "rejectedBookingsCount": 5,

                "bookingRespLessThan3hrs": 36,

                "bookingRespGraterThan3hrs": 5

            },

            {

                "_id": {

                    "forwarderID": "fwd-001",

                    "forwarderName": "forwader 001"

                },

                "pendingBookingsCount": 2,

                "acceptedBookingsCount": 24,

                "rejectedBookingsCount": 3,

                "bookingRespLessThan3hrs": 27,

                "bookingRespGraterThan3hrs": 2

            },

            {

                "_id": {

                    "forwarderID": "mf-forwarder001",

                    "forwarderName": "mf-forwarder 001"

                },

                "pendingBookingsCount": 87,

                "acceptedBookingsCount": 27,

                "rejectedBookingsCount": 2,

                "bookingRespLessThan3hrs": 116,

                "bookingRespGraterThan3hrs": 0

            },

            {

                "_id": {

                    "forwarderID": "fwd-001",

                    "forwarderName": "DHL"

                },

                "pendingBookingsCount": 42,

                "acceptedBookingsCount": 25,

                "rejectedBookingsCount": 1,

                "bookingRespLessThan3hrs": 57,

                "bookingRespGraterThan3hrs": 11

            },

            {

                "_id": {

                    "forwarderID": "mf-forwarder001",

                    "forwarderName": "mf-forwarder001"

                },

                "pendingBookingsCount": 364,

                "acceptedBookingsCount": 41,

                "rejectedBookingsCount": 1,

                "bookingRespLessThan3hrs": 396,

                "bookingRespGraterThan3hrs": 10

            },

            {

                "_id": {

                    "forwarderID": "fwd-002",

                    "forwarderName": "forwader 002"

                },

                "pendingBookingsCount": 535,

                "acceptedBookingsCount": 141,

                "rejectedBookingsCount": 46,

                "bookingRespLessThan3hrs": 638,

                "bookingRespGraterThan3hrs": 84

            }

        ]

    }
    let Api_Config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
      }
    const onEvents = {
        click: onChartClick,
    };
    const ChartData = {
        fwd: {
            name: ['fwd-001', 'fwd-002',
                'fwd-003',
                'fwd-004',
                'fwd-005']
        },
        carrier: {
            name: ['CRR-001', 'CRR-002', 'CRR-003', 'CRR-004', 'CRR-005']
        },
        brData: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        },
        acceptedByfwd: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        },
        ConfirmedByCarrier: {
            totalShipments: [1342,
                1002,
                1066,
                949,
                1041,
            ],
            pendingShipments: [97,
                106,
                95,
                99,
                103,
            ]
        }

    }
    const handleChange = (
        event: any,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        let obj = {
            status: props.pageInfo,
            dateFilterOn: newAlignment
        }
        let value=event.target.value;
        bookingRequests(obj,value)
        console.log("334444",typeof event.target,event.target.value)

        if(event.target.value === "last12Months"){
            setSelectedMonth(12)
        }else if(event.target.value === "last6Months"){
            setSelectedMonth(6)
        }
        else if(event.target.value === "last3Months"){
            setSelectedMonth(3)
        }
        else{
            setSelectedMonth(0)   
        }

    };
    function onChartClick(params: any) {
        shipmentsMoreInfoList()
    }
    const shipmentsMoreInfoList = () => {
        setShipmentInfo(true);
        props.parentCallback(true)
    }
    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "2%",
            },
        },
        legend: {
            orient: 'horizontal',
            bottom: 0
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            y: 20,
            y2: 20,
            borderColor: 'transparent',
        },
        yAxis: {
            minInterval: 1,
            type: 'value',
        },
        xAxis: {
            type: 'category',
            data: forwarderNames,
        },
        series: [
            {
                name: 'Pending',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: totalPendingBookings,
            },
            {
                name: 'Accepted',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: totalAcceptedBookings,
            },
            {
                name: 'Rejected',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: totalRejecetedBookings,
            },
        ]
    };
    let option1 = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "2%",
            },
        },
        legend: {
            orient: 'horizontal',
            bottom: 0
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            y: 20,
            y2: 20,
            borderColor: 'transparent',
        },
        yAxis: {
            minInterval: 1,
            type: 'value',
        },
        xAxis: {
            type: 'category',
            data: forwarderNames,
        },
        series: [
            {
                name: 'Response In <3 Hrs',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: totalAcceptedBookingsIn3Hrs,
            },
            {
                name: 'Response In >3 Hrs',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: totalAcceptedBookingsInMoreThan3Hrs,
            }
        ]
    };
    useEffect(() => {
        let barChartPayload = {
            dateCode: 0
        }
        let event :any ;
        bookingRequests(barChartPayload,event='currentMonth')

    }, [])
    const bookingRequests = (barChartPayload: any,value:any) => {
        let selectedMonth:any;
        if(value === "last12Months"){
            selectedMonth=12
        }else if(value === "last6Months"){
            selectedMonth=6
        }
        else if(value === "last3Months"){
            selectedMonth=3
        }
       
        if(value === "currentMonth"){
            selectedMonth=0
        }

        let barChartPayloa = {
            dateCode: selectedMonth ? selectedMonth : 0
        }

        Api_Config.params = barChartPayloa
        if (props.pageInfo != 'carrierBookings') {

            axios.get(Endpoints.Booking_Request_BarChart, Api_Config).then((res) => {
                console.log(res.data.data, "widgets1");
                let forwarderNames: any = []
                let pendingCounts: any = []
                let acceptedCounts: any = []
                let rejectedCounts: any = []
                let acceptedIn3HrsCounts: any = [];
                let acceptedInMoreThan3HrsCounts: any = []
                res.data.data.filter((item: any) => {
                    forwarderNames.push(item._id.forwarderID)
                    pendingCounts.push(item.pendingBookingsCount)
                    acceptedCounts.push(item.acceptedBookingsCount)
                    rejectedCounts.push(item.rejectedBookingsCount)
                    acceptedIn3HrsCounts.push(item.bookingRespLessThan3hrs)
                    acceptedInMoreThan3HrsCounts.push(item.bookingRespGraterThan3hrs)
                    setForwarderNames(forwarderNames)
                    setTotalPendingBookings(pendingCounts)
                    setTotalAcceptedBookings(acceptedCounts)
                    setTotalRejectedBookings(rejectedCounts)
                    setTotalAcceptedBookingsIn3Hrs(acceptedIn3HrsCounts)
                    setTotalAcceptedBookingsInMoreThan3Hrs(acceptedInMoreThan3HrsCounts)

                })
            });
        } else {
            axios.get(Endpoints.Carrier_Booking_Request_Bar_Chart, Api_Config).then((res) => {
                console.log(res.data.data, "widgets1");
                let forwarderNames: any = []
                let pendingCounts: any = []
                let acceptedCounts: any = []
                let rejectedCounts: any = []
                let acceptedIn3HrsCounts: any = [];
                let acceptedInMoreThan3HrsCounts: any = []
                res.data.data.filter((item: any) => {
                    forwarderNames.push(item._id.carrierId)
                    pendingCounts.push(item.pendingBookingsCount)
                    acceptedCounts.push(item.acceptedBookingsCount)
                    rejectedCounts.push(item.rejectedBookingsCount)
                    acceptedIn3HrsCounts.push(item.bookingRespLessThan3hrs)
                    acceptedInMoreThan3HrsCounts.push(item.bookingRespGraterThan3hrs)
                    setForwarderNames(forwarderNames)
                    setTotalPendingBookings(pendingCounts)
                    setTotalAcceptedBookings(acceptedCounts)
                    setTotalRejectedBookings(rejectedCounts)
                    setTotalAcceptedBookingsIn3Hrs(acceptedIn3HrsCounts)
                    setTotalAcceptedBookingsInMoreThan3Hrs(acceptedInMoreThan3HrsCounts)

                })
            });
        }

    }
    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Box>
                    {sessionStorage.getItem('role') === 'shipper' || sessionStorage.getItem('role') === 'consignee' || sessionStorage.getItem('role') === 'carrier' ?
                        <Typography variant='h6' sx={{ fontSize: '1rem' }}>TOP 5 BOOKING REQUESTS TO FORWARDER</Typography> :
                        <Typography variant='h6' sx={{ fontSize: '1rem' }}>TOP 5 CARRIER RESPONSES</Typography>}
                </Box>
                <Box component="form" sx={{ display: 'flex' }} >
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value="last12Months" sx={{ padding: '5px 11px' }}>Last 12 Months</ToggleButton>
                        <ToggleButton value="last6Months" sx={{ padding: '5px 11px' }}>Last 6 Months</ToggleButton>
                        <ToggleButton value="last3Months" sx={{ padding: '5px 11px' }}>Last 3 Months</ToggleButton>
                        <ToggleButton value="currentMonth" sx={{ padding: '5px 11px' }}>Current Month</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', mt: 0 }}>
                <Grid item md={6} lg={6} sm={6}>
                    <Paper sx={{ p: 2 }}>
                        {props.pageInfo != 'carrierBookings' ?
                            <Typography sx={{ textAlign: 'center' }}>Forwarder Booking Request</Typography> : <Typography sx={{ textAlign: 'center' }}>Carrier Booking Request</Typography>}
                        {forwarderNames.length > 0 ?
                            <ReactECharts option={option} />
                            : <Alert severity="info" sx={{ mt: 1 }}>
                                <AlertTitle>Info</AlertTitle>
                                No data found
                            </Alert>}

                    </Paper>
                </Grid>
                <Grid item md={6} lg={6} sm={6}>
                    <Paper sx={{ p: 2 }}>
                        {/* {props.pageInfo != 'carrierBookings' ? */}
                            <Typography sx={{ textAlign: 'center' }}>Booking Request Response Time</Typography> 
                            {/* : <Typography sx={{ textAlign: 'center' }}>Booking Request Response Time</Typography>} */}
                        
                        {forwarderNames.length > 0 ?
                            <ReactECharts option={option1} />
                            : <Alert severity="info" sx={{ mt: 1 }}>
                                <AlertTitle>Info</AlertTitle>
                                No data found
                            </Alert>}
                    </Paper>
                </Grid>
            </Grid>
            {/* {props.pageInfo === 'bookingRequests' ? */}
            <Grid sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontSize: '1rem' }}>ACCEPTED SHIPMENTS (Vs) AVG. RESPONSE TIME</Typography>
                    <Paper>
                        {alignment != undefined ?
                            <Echarts data={'months'} page={props.pageInfo} pageInfo={props.pageInfo.tab} /> : ''}
                    </Paper>

                </Grid>
            </Grid>
            {/* : ''} */}
        </div>
    )
}

import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import WarehouseSharpIcon from "@mui/icons-material/WarehouseSharp";
import LuggageSharpIcon from "@mui/icons-material/LuggageSharp";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import FastForwardIcon from "@mui/icons-material/FastForward";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp"; 
import WorkspacesSharpIcon from "@mui/icons-material/WorkspacesSharp";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
const ConsigneeTabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    access: true,
    disable:false,
    route: "/",
    
  },
  {
    id: 1,
    parentnav: "Registrations",
    icon: <HowToRegOutlinedIcon />,
    disable:false,
    children: [
      {
        cid: 0,
        title: "REGISTRATIONS",
        icon: <HowToRegOutlinedIcon />,
      },
      // process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 1,
        childnav: "Partner Onboarding",
        route: "/registrations",
        access: true,
        disable:true,
      },
      // : '',
      {
        cid: 2,
        childnav: "Register To Track",
        route: "/shipmentRegistrations",
        access: true,
      },
      {
        cid: 3,
        childnav: "Supported Carrier List",
        route: "/supported_carrier_list",
        access: true,
      },
      process.env.REACT_APP_ENV != 'multitenancy' && process.env.REACT_APP_ENV != 'prod'?
      {
        cid: 3,
        childnav: "Registred Shipments",
        route: "/registred_Shipments",
        access: false,
        disable:true,
      }:''
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "InTransit ",
    icon: <TransferWithinAStationSharpIcon />,
    route: "/intransit_shipments",
    disable:false,
    access: true,
  },
  {
    id: 3,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: true,
    disable:false,
  },
  {
    id: 4,
    parentnav: "Upload Doc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    route: "/upload_documents",
    disable:false,
  },
  {
    id: 5,
    parentnav: "PO's",
    icon: <StickyNote2OutlinedIcon />,
    disable:true,
    children: [
      {
        cid: 0,
        title: "PURCHASE ORDER",
        icon: <StickyNote2OutlinedIcon />,
      },
      {
        cid: 1,
        childnav: "Upload Purchase Order",
        route: "/upload_purchase_order",
        access: true,
        disable:true,
      },
      {
        cid: 2,
        childnav: "Pending For Confirmation",
        route: "/pending_for_approval",
        access: true,
        disable:true,
      },

      {
        cid: 3,
        childnav: "Accepted Purchase Order ",
        route: "/accepted_purchase_order",
        access: true,
        disable:true,
      },
      {
        cid: 4,
        childnav: "Rejected Purchase Order",
        route: "/rejected_purchase_order",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  {
    id: 6,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    disable:true,
    children: [
      {
        cid: 0,
        title: "FORWARDER",
        icon: <FastForwardIcon />,
      },
      {
        cid: 1,
        childnav: "Pending Forwarder Confirmation",
        route: "/pending_forwarder_confirmation",
        access: true,
        disable:true,
      },
      {
        cid: 2,
        childnav: "Confirmed By Forwarder",
        route: "/confirmed_by_forwarder",
        access: true,
        disable:true,
      },

      {
        cid: 3,
        childnav: "Rejected By Forwarder",
        route: "/rejected_by_forwarder",
        access: true,
        disable:true,
      },
      {
        cid: 0,
        title: "CARRIER",
        icon: <DirectionsBoatFilledOutlinedIcon />,
      },
      {
        cid: 4,
        childnav: "Pending Carrier Confirmation",
        route: "/carrier_confirmation",
        access: true,
        disable:true,
      },
      {
        cid: 5,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
        disable:true,
      },
      {
        cid: 6,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
        disable:true,
      },
      // {
      //   cid: 7,
      //   childnav: "Booking Request Upload",
      //   access: true,
      // },
    ],
    access: true,
  },
  

  {
    id: 7,
    parentnav: "WareHouse",
    icon: <WarehouseSharpIcon />,
    access:true,
    disable:true,
    children: [
      {
        cid: 0,
        title: "WAREHOUSE",
        icon: <WarehouseSharpIcon />,
      },
      {
        cid: 1,
        route: "/wareHouse_request_upload",
        childnav: "WareHouse Request Upload",
        access: true,
        disable:true,
      },

      {
        cid: 4,
        route: "/pending_requests",
        childnav: "Pending Requests",
        access: true,
        disable:true,
      },
      {
        cid: 2,
        route: "/confirmed_shipments",
        childnav: "Confirmed Shipments",
        access: true,
        disable:true,
      },
      {
        cid: 5,
        route: "/rejected_shipments",
        childnav: "Rejected Shipments",
        access: true,
        disable:true,
      },

      {
        cid: 6,
        route: "/inward_truckdetails",
        childnav: "InWard TruckDetails",
        access: true,
        disable:true,
      },
      {
        cid: 7,
        route: "/shipments_at_warehouse",
        childnav: "Shipments At WareHouse",
        access: true,
        disable:true,
      },

      {
        cid: 8,
        route: "/outward_shipments",
        childnav: "OutWard Shipments",
        access: true,
        disable:true,
      },

      {
        cid: 1,
        route: "/space_utilization_report",
        childnav: "Space Utilization Report",
        access: true,
        disable:true,
      },
    ],
  },

  {
    id: 8,
    parentnav: "Returns",
    icon: <LuggageSharpIcon />,
    disable:true,
    children: [
      {
        cid: 0,
        title: "RETURNS",
        icon: <LuggageSharpIcon />,
      },
      {
        cid: 1,
        childnav: "Upload Return Order",
        route: "/upload_return_order",
        access: true,
        disable:true,
      },
      {
        cid: 1,
        childnav: "Return Orders For Acknowledgement",
        route: "/return_orders_for_acknowledgement",
        access: true,
        disable:true,
      },
      {
        cid: 3,
        childnav: "Accepted Return Orders ",
        route: "/confirmed_return_orders",
        access: true,
        disable:true,
      },
      {
        cid: 4,
        childnav: "Rejected Return Order ",
        route: "/rejected_return_orders",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  
  
];
export default ConsigneeTabData;

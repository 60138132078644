import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import Full from "../../../Helpers/IntransitEventsMapping";
import "../../../Styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import swal from "sweetalert";
import React from "react";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import IntransitEventsMapping from "../../../Helpers/IntransitEventsMapping";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { ApiRequestFunction } from "../../../Services/BaseService";
import moment from "moment";
import IntransitForDelivery from "./IntransitForDelivery";
import DataTable, { TableColumn } from "react-data-table-component";
import SearchIcon from '@mui/icons-material/Search';
const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7'
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },

};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
}));
function DeliveredInTransitShipments(props: any) {
  const nav = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  const gridRef = useRef<any>();
  const [value, setValue] = React.useState("delivered");
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState<any>([]);
  const [deliveredData, setDeliveredData] = useState<any>()
  const [searchTerm, setSearchTerm] = useState<any>('');

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const handleClear = () => {
    setSearchTerm('')
  }
  // const filterData = rowData.filter((data: any) =>
  //   Object.values(data).some((value: any) =>
  //     value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );
  const filterData = rowData.filter((data:any) => {
    return Object.values(data).some((value) => {
      if (value === null || value === undefined || value === '') {
        // Include null and undefined values
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  console.log(filterData, "filter data")
  const [columnDefs] = useState([

    {
      headerName: "INVOICE #",
      field: "invoiceNumber",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            onClick={() => handleLine(params)}
            className="btn btn-info"
          >
            {params.value}
          </a>
        );
      },
    },
    // sessionStorage.getItem('shipmentType') === 'Ocean' ?
    {
      headerName: "HBL #",
      field: "hblNumber",
      headerTooltip: "HBL #",
      // tooltipField: "hblNumber",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Tooltip title={"Click Here To View Events"} placement="bottom-start">
            <a
              style={{
                height: 20,
                width: "100%",
                lineHeight: 0.5,
                textAlign: "left",
                color: "blue",
              }}
              onClick={() => handleData(params)}
              className="btn btn-info"
            >
              {params.value}
            </a>
          </Tooltip>
        );
      },
    },
    // :{
    //   headerName: "HAWB #",
    //   field: "hawbNumber",
    //   headerTooltip: "HAWB #",
    //   // tooltipField: "hblNumber",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     return (
    //       <Tooltip title={"Click Here To View Events"} placement="bottom-start">
    //         <a
    //           style={{
    //             height: 20,
    //             width: "100%",
    //             lineHeight: 0.5,
    //             textAlign: "left",
    //             color: "blue",
    //           }}
    //           onClick={() => handleData(params)}
    //           className="btn btn-info"
    //         >
    //           {params.value}
    //         </a>
    //       </Tooltip>
    //     );
    //   },
    // },
    // sessionStorage.getItem('shipmentType') === 'Ocean' ?
    {
      headerName: "MBL #",
      field: "mblNumber",
      headerTooltip: "MBL #",
    },
    // :{
    //   headerName: "MAWB #",
    //   field: "awbNumber",
    //   headerTooltip: "MAWB #",
    // },
    {
      headerName: "SHIPPER ID",
      field: "shipperId",
      headerTooltip: "SHIPPER ID",
    },
    {
      headerName: "SHIPPER",
      field: "shipperName",
      headerTooltip: "SHIPPER",
    },
    {
      headerName: "FORWARDER ID",
      field: "forwarderId",
      headerTooltip: "FORWARDER ID",
    },
    {
      headerName: "FORWARDER",
      field: "forwarderName",
      headerTooltip: "FORWARDER",
    },
    {
      headerName: "FORWARDER BOOKING #",
      field: "bookingReference",
      headerTooltip: "FORWARDER BOOKING #",
    },
    {
      headerName: "CARRIER ID",
      field: "carrierId",
      headerTooltip: "CARRIER ID",
    },
    {
      headerName: "CARRIER",
      field: "carrierName",
      headerTooltip: "CARRIER",
    },
    {
      headerName: "CARRIER BOOKING #",
      field: "carrierReference",
      headerTooltip: "CARRIER BOOKING #",
    },
    // {
    //   headerName: "LEG INFORMATION",
    //   field: "legInfo",
    //   headerTooltip: "LEG INFORMATION",
    //   tooltipField: "leginformatin",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     return (
    //       <a
    //         style={{
    //           height: 20,
    //           width: "100%",
    //           lineHeight: 0.5,
    //           color: "blue",
    //         }}
    //         onClick={() => handleLegData(params)}
    //         className="btn btn-info"
    //       >
    //         LegInfo
    //       </a>
    //     );
    //   },
    // },
    {
      headerName: "CONTAINER #",
      field: "containerNumber",
      headerTooltip: "CONTAINER #",
      tooltipField: "containerNumber",
    },
    {
      headerName: "COMMODITY TYPE",
      field: "commodityType",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
    },
    {
      headerName: "HS CODE",
      field: "hscode",
      headerTooltip: "HS CODE",
      tooltipField: "hscode",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "HS CODE DESCRIPTION",
      field: "hscodeDescription",
      headerTooltip: "HS CODE DESCRIPTION",
      tooltipField: "hscodeDescription",
      cellClass: "ag-left-aligned-cell",
    },
    // {
    //   headerName: "QUANTITY",
    //   field: "hscode",
    //   headerTooltip: "QUANTITY",
    //   tooltipField: "hscode",
    //   cellClass: "ag-left-aligned-cell",
    // },
    // {
    //   headerName: "UOM",
    //   field: "weightUom",
    //   headerTooltip: "UOM",
    //   tooltipField: "weightUom",
    // },
    {
      headerName: "MODE OF SHIPMENT",
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeOfShipment",
      field: "modeOfShipment",
      columnGroupShow: "closed",
    },
    {
      headerName: "ETA",
      headerTooltip: "ETA",
      tooltipField: "eta",
      field: "eta",
      cellClass: "ag-left-aligned-cell",
    },
    // {
    //   headerName: "UPLOAD DOCS",
    //   headerTooltip: "UPLOAD DOCS",
    //   tooltipField: "upload docs",
    //   field: "tranship",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     return (
    //       <Button onClick={() => nav("/uploadDocumnets")}>Upload Doc</Button>
    //     );
    //   },
    // },
    {
      headerName: "VIEWDOCS",
      headerTooltip: "VIEWDOCS",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <a
            style={{
              height: 20,
              width: "100%",
              lineHeight: 0.5,
              color: "blue",
            }}
            className="btn btn-info"
            onClick={() => managedoc(params)}
          >
            View Doc
          </a>
        );
      },
    },
  ]);

  const columns: TableColumn<any>[] = [
    {
      name: "INVOICE #",
      selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber != undefined ? row.invoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px'
    },
    {
      name: "HBL #",
      selector: row => row.hblNumber !== '' && row.hblNumber !== null && row.hblNumber != undefined ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    // {
    //   name: "HAWB #",
    //   selector: row => row.hawbNumber !== '' && row.hawbNumber !== null && row.hawbNumber != undefined ? row.hawbNumber: <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
    //   width: '200px',
    //   omit: mode == 'Ocean' ? true : false,
    // },
    {
      name: "MBL #",
      selector: row => row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber != undefined ? row.mblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "MAWB #",
      selector: row => row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber != undefined ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      
      width: '200px',
      omit: mode == 'Ocean' ? true : false,
    },
    {
      name: "BOOKING  #",
      selector: row => row.carrierReference !== '' && row.carrierReference !== null && row.carrierReference != undefined ? row.carrierReference : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "CONTAINER #",
      selector: row => row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber != undefined ? row.containerNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px',
      omit: mode == 'Air' ? true : false,
    },
    {
      name: "FLIGHT #",
      selector: row => row.flightNumber !== '' && row.flightNumber !== null && row.flightNumber != undefined ? row.flightNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      
      width: '200px',
      omit: mode == 'Ocean' ? true : false,
    },
    {
      name: "CARRIER #",
      selector: row => row.carrierName !== '' && row.carrierName !== null && row.carrierName != undefined ?  <Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip>  : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px'
    },
    {
      name: "SHIPPER #",
      selector: row => row.shipperId !== '' && row.shipperId !== null && row.shipperId != undefined ? <Tooltip title={row.shipperId} arrow><span>{row.shipperId}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px'
    },
    {
      name: "CONSIGNEE #",
      selector: row => row.consigneeName !== '' && row.consigneeName !== null && row.consigneeName != undefined ? <Tooltip title={row.consigneeName} arrow><span>{row.consigneeName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
      width: '200px'
    },
    {
      name: "ETA ",
      selector: row => row.eta !== '' && row.eta !== null && row.eta != undefined ? row.eta : <span style={{ fontStyle: 'italic' }}>- NA -</span>,   
     
      width: '200px'
    },
    {
      name: "VIEW DOCS ",

      width: '200px',
      cell: (row) => <a 
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => managedoc(row)}
      >
        View Doc
      </a>
    },
  ]
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === 'delivered') {
      getDeliveredData(newValue)
    } else {
      getTransitList()
    }
  };
  const managedoc = (params: any) => {
    let docObject: any;
    // if (params.data.purchaseOrderNumber != 'NA' && params.data.invoiceNumber != 'NA') {
    //   docObject = {
    //     docAgainst: 'PO',
    //     documentReference: params.data.purchaseOrderNumber
    //   }
    // } else if (params.data.purchaseOrderNumber && params.data.invoiceNumber === 'NA') {
    //   docObject = {
    //     docAgainst: 'PO',
    //     documentReference: params.data.purchaseOrderNumber
    //   }
    // } else if (params.data.purchaseOrderNumber === 'NA' && params.data.invoiceNumber) {
    //   docObject = {
    //     docAgainst: 'INV',
    //     documentReference: params.data.invoiceNumber
    //   }
    // }
    if(params.invoiceNumber != 'NA'){
      docObject = {
            docAgainst: 'INV',
            documentReference: params.invoiceNumber
          }
    }
    nav("/manage_documents", { state: docObject });
  };
  const getDeliveredData = (newValue: any) => {
    sessionStorage.setItem('deliveryStatus', 'delivered')
    let requestPayload = {
      type: role,
      status: "delivered",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
      shipmentType: mode
    }
    Api_Config.params = requestPayload
    setLoad(true)
    axios.get(Endpoints.Intransit_Delivery_List, Api_Config).then((res) => {
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      }
      setLoad(false);
      setDeliveredData(res.data.primary);
      console.log(res.data.primary);
      const ratingNameColumn = gridRef.current.columnApi.getAllColumns().find((x: any) =>
        x.colDef.headerName === 'ACTIONS'
      )
      console.log(ratingNameColumn, "rating names")
      gridRef.current.columnApi.setColumnVisible(ratingNameColumn, true)
    }).catch((err)=>console.log(err))
  }
  const handleData = (params: any) => {
    console.log("Event Screen Console....", params.data);
    setTransitData(params.data);
    setLineData(params.data);
    getEventData(
      params.data.containerNumber,
      params.data.hscode,
      params.data.invoiceNumber
    );
  };
  const handleLegData = (params: any) => {
    console.log("Event Screen Leg Information....", params.data.legInfo);
    setLegData(params.data.legInfo);
    setLineData(params.data);
    if (params.data.legInfo === undefined) {
      swal("info", "No Leg Information", "info");
    } else {
      setLegOpen(true);
    }
  };
  const getEventData = (data: any, hs: any, inv: any) => {
    axios.post(Endpoints.Intransit_Events, {
      containerNumber: data,
      hscode: hs,
      invoiceNumber: inv,
    }, Api_Config).then((evedata) => {
      if (evedata.data.primary.length > 0) {
        console.log("Event Data.....", evedata);
        setEventData(evedata);
        setOpenDetail(true);
        console.log("EVENTS  DATA.....");
      } else {
      }
    }).catch((Err)=>console.log(Err))
  };
  const handlemap = () => {
    setNodata(false);
  };
  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
  };
  // const handleChangeShipmentMode = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedValue(event.target.value);
  //   if (event.target.value === 'Ocean') {
  //     let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
  //       if (ele.userProvidedColDef.hide != undefined) {
  //         ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
  //       }
  //       return ele.userProvidedColDef
  //     });
  //     gridRef.current.api.setColumnDefs(a)
  //     autosize()
  //   } else {
  //     let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
  //       if (ele.userProvidedColDef.hide != undefined) {
  //         ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
  //       }
  //       return ele.userProvidedColDef
  //     });
  //     gridRef.current.api.setColumnDefs(a)
  //     autosize()
  //   }
  // };
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);
  }, []);
  const getTransitList = () => {
    sessionStorage.setItem('deliveryStatus', 'pending')
    let requestPayload = {
      type: role,
      status: "delivered",
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
      shipmentType: mode
    }
    Api_Config.params = requestPayload
    setLoad(true)
    axios.get(Endpoints.Intransit_Delivery_List, Api_Config).then((res) => {
      if (res.data.primary.length === 0) {
        setLoad(false);
        setShowGrid(false);
      }else{
        setLoad(false);
        setRowData(res.data.primary);
        console.log(res.data.primary);
        setShowGrid(true)
      }
      
      // params.columnApi.autoSizeAllColumns(false);
    }).catch((Err)=>{
      setLoad(false)
      setShowGrid(false)
    })
  };
  const handleSubmitReceipt = (params: any) => {
    let payload: any = []
    let obj = {
      carrierReference: params.data.carrierReference,
      invoiceNumber: params.data.invoiceNumber,
      hscode: params.data.hscode,
      hscodeDescription: params.data.hscodeDescription,
    };
    payload.push(obj);
    const data = {
      payload: payload,
    };
    Api_Config.params = {}
    axios.post(Endpoints.update_Shipment_Status_To_Delivery, data, Api_Config).then((res: any) => {
      console.log(res, "final resp")
    }).catch((err)=>console.log(err))
  }
  useEffect(() => {
    getTransitList();
  }, [fromDate, toDate,mode]);
  return (
    <div className={classes.root}>
      {rowData.length > 0 && showgrid && 
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Paper
            component="form"
            elevation={0}
            className="custom-search"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
          >

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={mode == 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : "Search By HAWB/MAWB/Invoice"}
              inputProps={{ 'aria-label': 'search google maps' }}
              size="small"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
              </IconButton>

          </Paper>
        </Box>}
      <Paper elevation={0}>
        <Box sx={{ p: 0, mt: 1 }}>
          {rowData.length > 0 && showgrid ?
            <DataTable
              style={{ borderRadius: 3 }}
              columns={columns}
              data={filterData}
              responsive={true}
              customStyles={customStyles}
              theme="default"
              pagination
              dense
              striped
              highlightOnHover={true}
              onRowClicked={(data) => handleData(data)}

            /> : <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>}
        </Box>

        <div className="dialog">
          {openDetail ? (
            <IntransitEventsMapping
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  {/* <img
                  src={require("../../asserts/globe1.jpg")}
                  width="200"
                  height="200"
                  alt="globe image"
                /> */}
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default DeliveredInTransitShipments;

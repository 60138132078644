const sizeConfigs ={
    sidebar :{
        width:"85px"
    },
    dashboard:{
        boxShadow:'0 1px 1px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 2px 1px -1px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 3px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2)',
        height:'150px'
    },
    pageTitle:{
        padding: '10px',
        letterSpacing: '0.0075em',
        fontSize:'1.25rem',
        fontWeight :'bold',
        

    },
    fulldialog:{
        width:'28%',
        heigth:'100%'
    }
}
export default sizeConfigs;
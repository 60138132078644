import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import React, {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import axios from "axios";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  TextField,
  Paper,
  IconButton
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { Endpoints } from "../../../Enviornment/Endpoints";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useOutletContext } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../../../Styles.css";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { ApiRequestFunction } from "../../../Services/BaseService";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    minHeight: "70%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
function BrFileupload() {
  const [rowData, setRowData] = useState<any>([]);
  const domLayout = "autoHeight";
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [filename, setFileName] = useState<string>("");
  const [isShowFile, setShowFile] = useState(false);
  const [previewData, setPreviewData] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [ExcelName, setExcelName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const masterDetail = true;
  const [validationData, setValidationData] = useState<any>([]);
  const [loader, setLoader, fromDate, toDate, format] = useOutletContext<any>();
  const Formref = useRef<any>();
  const gridRef = useRef<any>();
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  let Excel_Api_Config ={
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-date",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },

  }
  const [columnDefs] = useState([
    {
      headerName: "INVOICE #",
      headerTooltip: "INVOICE #",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      cellRenderer: "agGroupCellRenderer",
    },
    // {
    //   headerName: "VALIDATION STATUS",
    //   field: "preCheckValidationStatus",
    //   filter: true,
    //   headerTooltip: "VALIDATION STATUS",
    //   tooltipField: "preCheckValidationStatus",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === true) {
    //       return <p className="status">TRUE</p>;
    //     } else {
    //       setDisable(false);
    //       return <p className="rstatus">FALSE</p>;
    //     }
    //   },
    // },
    // {
    //   headerName: "VALIDATION MESSAGE",
    //   field: "preCheckValidationMessage",
    //   filter: true,
    //   headerTooltip: "VALIDATION MESSAGE",
    //   tooltipField: "preCheckValidationMessage",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value.length > 0) {
    //       return (
    //         <Button onClick={() => alerts(params.value)}>
    //           {params.value[0]}
    //         </Button>
    //       );
    //     } else {
    //       <p>NO VALIDATION</p>;
    //     }
    //   },
    // },

    {
      headerName: "VALIDATION STATUS",
      field: "preCheckValidationMessage",
      headerTooltip: "VALIDATION STATUS",
      tooltipField: "preCheckValidationMessage",
      cellRenderer: (params: ICellRendererParams) => {
        console.log(params, "$params");
        if (params.data.preCheckValidationStatus === true) {
          return (
            <p>
              <CheckCircleIcon sx={{ fontSize: "18px", color: "green" }} /> 
              {params.data.preCheckValidationMessage[0]}
            </p>
          );
        } else {
          if (params.value.length > 1) {
            return (
              <Button
                className="upload-error"
                sx={{ p: 0, fontSize: "12px" }}
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                <CancelIcon
                  sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                /> 
                1 or more Validations
              </Button>
            );
          } else if (params.value.length === 1) {
            return (
              <Button
                onClick={() =>
                  alerts(params.value, params.data.purchaseOrderNumber)
                }
              >
                {params.value[0]}
              </Button>
            );
          } else {
            return (
              <p>
                <CancelIcon
                  sx={{ fontSize: "18px", color: "red", lineHeight: 0 }}
                /> 
                VALIDATION ERROR
              </p>
            )

          }
        }
      },
    },

    {
      headerName: "INVOICE DATE",
      field: "invoiceDate",
      headerTooltip: "INVOICE DATE",
      tooltipField: "invoiceDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "PURCHASE ORDER #",
      field: "purchaseOrderNumber",
      headerTooltip: "PURCHASE ORDER #",
      cellClass: "ag-left-aligned-cell",
      tooltipField: "purchaseOrderNumber",
    },
    {
      headerName: "FORWARDER ID",
      headerTooltip: "FORWARDER ID",
      tooltipField: "forwarderID",
      field: "forwarderID",
    },
    {
      headerName: "FORWARDER",
      headerTooltip: "FORWARDER",
      tooltipField: "forwarderName",
      field: "forwarderName",
      columnGroupShow: "closed",
    },
    {
      headerName: "FORWARDER REFERENCE #",
      headerTooltip: "FORWARDER REFERENCE #",
      tooltipField: "forwarderBookingReference",
      field: "forwarderBookingReference",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "CREATED DATE",
    //   field: "brCreatedDate",
    //   filter: true,
    //   headerTooltip: "CREATED DATE",
    //   tooltipField: "brCreatedDate",
    //   cellClass: "ag-left-aligned-cell",
    // },

    {
      headerName: "PICK UP LOCATION",
      field: "pickupLocation",
      headerTooltip: "PICK UP LOCATION",
      tooltipField: "pickupLocation",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "PICKUP COUNTRY",
      headerTooltip: "PICKUP COUNTRY",
      tooltipField: "pickupLocation",
      field: "pickupCountry",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },
    {
      headerName: "PICKUP DATE",
      headerTooltip: "PICKUP DATE",
      tooltipField: "pickupDate",
      field: "pickupDate",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "PICKUP ZIP CODE",
      headerTooltip: "PICKUP ZIP CODE",
      tooltipField: "pickupZipCode",
      field: "pickupZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CONSIGNEE ID",
      headerTooltip: "CONSIGNEE ID",
      tooltipField: "consigneeID",
      field: "consigneeID",
      cellClass: "ag-left-aligned-cell",
    },
    {
      headerName: "CONSIGNEE",
      headerTooltip: "CONSIGNEE",
      tooltipField: "consigneeName",
      field: "consigneeName",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },
    {
      headerName: "CONSIGNEE EMAIL ADDRESS",
      headerTooltip: "CONSIGNEE EMAIL ADDRESS",
      tooltipField: "consigneeEamilAddress",
      field: "consigneeEamilAddress",
    },
    {
      headerName: "CONSIGNEE CONTACT NUMBER",
      headerTooltip: "CONSIGNEE CONTACT NUMBER",
      tooltipField: "consingneeContactNumber",
      field: "consingneeContactNumber",
      cellClass: "ag-left-aligned-cell",
      columnGroupShow: "closed",
    },

    {
      headerName: "DROPOFF LOCATION",
      headerTooltip: "DROPOFF LOCATION",
      tooltipField: "dropoffLocation",
      field: "dropoffLocation",
    },
    {
      headerName: "DROPOFF COUNTRY",
      headerTooltip: "DROPOFF COUNTRTY",
      tooltipField: "dropoffCountry",
      field: "dropoffCountry",
      columnGroupShow: "closed",
    },
    {
      headerName: "DROPOFF ZIPCODE",
      headerTooltip: "DROPOFF ZIPCODE",
      tooltipField: "dropoffZipCode",
      field: "dropoffZipCode",
      columnGroupShow: "closed",
      cellClass: "ag-left-aligned-cell",
    },

    {
      headerName: "CHA ID",
      headerTooltip: "CHA ID",
      tooltipField: "chaID",
      field: "chaID",
    },
    {
      headerName: "CHA",
      headerTooltip: "CHA",
      tooltipField: "chaName",
      field: "chaName",
      columnGroupShow: "closed",
    },

    // {
    //   headerName: "SHIPPER ID",
    //   filter: true,
    //   headerTooltip: "SHIPPER ID",
    //   tooltipField: "createdBy",
    //   field: "createdBy",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "SHIPPER  NAME",
    //   filter: true,
    //   headerTooltip: "SHIPPER NAME",
    //   tooltipField: "createdByName",
    //   field: "createdByName",
    //   columnGroupShow: "closed",
    // },
    // {
    //   headerName: "FBR STATUS",
    //   filter: true,
    //   headerTooltip: "FBR STATUS",
    //   tooltipField: "fbrStatus",
    //   field: "fbrStatus",
    // },
    {
      headerName: "MODE OF SHIPMENT ",
      headerTooltip: "MODE OF SHIPMENT",
      tooltipField: "modeofShipment",
      field: "modeofShipment",
    },
    {
      headerName: "INCO TERMS",
      field: "incoTerms",
      headerTooltip: "incoTerms",
      tooltipField: "INCO TERMS",
    },

    // {
    //   headerName: "CREATED BY",
    //   filter: true,
    //   headerTooltip: "CREATED BY",
    //   tooltipField: "createsby",
    //   field: "createdBy",
    // },
  ]);
  const detailCellRendererParams = useMemo<any>(() => {
    return {
      detailGridOptions: {
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 10,
        masterDetail:true,
        columnDefs: [
          {
            headerName: "COMMODITY TYPE",
            field: "commodityType",
            headerTooltip: "COMMODITY TYPE",
            tooltipField: "commodityType",
          },
          {
            headerName: "VALIDATION STATUS",
            field: "preCheckValidationMessage",
            headerTooltip: "VALIDATION MESSAGE",
            tooltipField: "preCheckValidationMessage",
            cellRenderer: (params: ICellRendererParams) => {
              console.log(params, "$params");
              if (params.data.preCheckValidationStatus === true) {
                return (
                  <p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green" }}
                    /> 
                    {params.data.preCheckValidationMessage[0]}
                  </p>
                );
              } else {
                if (params.value.length > 1) {
                  return (
                    <Button
                      className="upload-error"
                      sx={{ p: 0, fontSize: "12px" }}
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      <CancelIcon
                        sx={{ fontSize: "16px", color: "red", lineHeight: 0 }}
                      /> 
                      1 or more Validations
                    </Button>
                  );
                } else if (params.value.length === 1) {
                  return (
                    <Button
                      onClick={() =>
                        alerts(params.value, params.data.purchaseOrderNumber)
                      }
                    >
                      {params.value[0]}
                    </Button>
                  );
                } else {
                  return (<p>
                    <CheckCircleIcon
                      sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                    /> 
                    NO VALIDATION
                  </p>)
                }
              }
            },
          },
          {
            headerName: "ITEM NAME",
            field: "itemName",
            headerTooltip: "ITEM NAME",
            tooltipField: "itemName",
          },
          {
            headerName: "ITEM DESCRIPTION",
            field: "itemDescription",
            headerTooltip: "ITEM DESCRIPTION",
            tooltipField: "itemDescription",
          },
          {
            headerName: "BATCH NUMBER",
            field: "batchNumber",
            headerTooltip: "BATCH NUMBER",
            tooltipField: "batch number",
          },
          {
            headerName: "LOT NUMBER",
            field: "lotNumber",
            headerTooltip: "LOT NUMBER",
            tooltipField: "lotNumber",
          },
          {
            headerName: "SERIAL NUMBER",
            field: "serialNumber",
            headerTooltip: "SERIAL NUMBER",
            tooltipField: "serialNumber",
          },
          // {
          //   headerName: "FORWARD BOOKING REFERENCE",
          //   field: "forwarderBookingReference",
          //   filter: true,
          //   headerTooltip: "Forward Booking Reference",
          //   tooltipField: "forwarderBookingReference",
          // },
          // {
          //   headerName: "FORWARDER NAME",
          //   field: "forwarderName",
          //   filter: true,
          //   headerTooltip: "FORWARDER NAME",
          //   tooltipField: "forwarderName",
          // },

          {
            headerName: "HS CODE",
            field: "hscode",
            headerTooltip: "HS CODE",
            tooltipField: "hscode",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "HS CODE DESCRIPTION",
            field: "hscodeDescription",
            headerTooltip: "HS CODE DESCRIPTION",
            tooltipField: "hscodeDescription",
          },
          {
            headerName: "QUANTITY",
            field: "quantity",
            headerTooltip: "QUANTITY",
            tooltipField: "quantity",
          },
          {
            headerName: "NUMBER OF PACKAGES",
            field: "numberofPackages",
            headerTooltip: "NUMBER OF PACKAGES",
            tooltipField: "number of packages",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE TYPE",
            field: "type",
            headerTooltip: "PACKAGE TYPE",
            tooltipField: "packagetype",
          },
          {
            headerName: "PACKAGE WEIGHT",
            field: "weight",
            headerTooltip: "PACKAGE WEIGHT",
            tooltipField: "packageweight",
          },
          {
            headerName: "PACKAGE LENGTH",
            field: "length",
            headerTooltip: "PACKAGE LENGTH",
            tooltipField: "packagelength",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE WIDTH",
            field: "width",
            headerTooltip: "PACKAGE WIDTH",
            tooltipField: "packagewidth",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE HEIGHT",
            field: "height",
            headerTooltip: "PACKAGE HEIGHT",
            tooltipField: "packageheight",
            cellClass: "ag-left-aligned-cell",
          },
          {
            headerName: "PACKAGE SPECIAL INSTRUCTIONS",
            field: "specialInstructions",
            headerTooltip: "PACKAGE SPECIAL INSTRUCTIONS",
            tooltipField: "packagespecialinstructions",
          },
          {
            headerName: "HAZARDOUS GOODS",
            field: "hazardousGoods",
            headerTooltip: "hazardousGoods",
            tooltipField: "HAZARDOUS GOODS",
          },
          // {
          //   headerName: "SHIPMENT LINE STATUS",
          //   field: "lineShipStatus",
          //   filter: true,
          //   headerTooltip: "SHIPMENT LINE STATUS",
          //   tooltipField: "lineShipStatus",
          // },

          {
            headerName: "REQUESTED DATE OF DELIVERY",
            field: "requestedDateOfDelivery",
            headerTooltip: "requestedDateOfDelivery",
            tooltipField: "REQUEST DATE OF DELIVERY",
            cellClass: "ag-left-aligned-cell",
          },
          // {
          //   headerName: "DISPATCH STATUS",
          //   field: "lineDelStatus",
          //   filter: true,
          //   headerTooltip: "DISPATCH STATUS",
          //   tooltipField: "lineDelStatus",
          // },

        ],
        defaultColDef: {
          sortable: true,
          masterDetail:true
        },
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.lines);
        params.node.beans.columnApi.autoSizeAllColumns(false);
      },
      onFirstDataRendered: (params:any) => {
        params.api.sizeColumnsToFit();
      },
    };
  }, []);
  function filevalidation(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      let split = e.target.files[0].name.split(".");
      if (split[1] !== "xlsx") {
        setFileExtError(true);
        toast.error("Invalid File Format,Please Upload XLSX Files");
      } else {
        setFileExtError(false);
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setExcelName(e.target.files[0].name);
    }
  }
  const reset = () => {
    setRowData([]);
    setShowFile(false);
    Formref.current.reset();
    setExcelName("Selcet File");
    setFile(undefined);
  };
  const Preview = () => {
    setLoader(true)
    if (fileExtError == false) {
      if (file === undefined) {
        toast.warning("Please Select a File...");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
   
      axios.post(Endpoints.Booking_Request_Preview, formData, Excel_Api_Config).then((res: any) => {
        setLoader(false)
        if(res.data.status === false){
          swal('Warning',res.data.msg)
        }
        setPreviewData(res.data);
        setRowData(res.data.data);
        res.data.data.map((disables: any) => {
          if (disables.preCheckValidationStatus === false) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        });
      }).catch((e: any) => {
        setLoader(false);
      });
      setShowFile(true);
    }
  };
  function alerts(params: any, poNum: any) {
    setAlertOpen(true);
    setValidationData(params);
  }
  const closeAlert = () => setAlertOpen(false);
  const SubmitFile = () => {
    let submitPayLoad: any = {
      data: previewData.data,
      fileData: previewData.fileData,
    };
    axios.post(Endpoints.Booking_Request_Submit, submitPayLoad, Api_Config).then((res: any) => {
        if (res.data.status === true) {
          swal("Success", res.data.msg, "success");
        }
      })
      .catch((err: any) => {
        if (err.res.data.status === false) {
          swal("Error", err.res.data.msg, "error");
        }
      });
    setRowData([]);
    setShowFile(false);
    setExcelName("Selcet File");
    Formref.current.reset();
  };
  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);
  const autosize = useCallback(() => {
    console.log(rowData);
    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  useEffect(() => {
    setLoader(false)
    if (gridRef.current) {
      const api = gridRef.current.api;
      const columnApi = gridRef.current.columnApi;
      const resizeColumnsToFit = () => {
        const allColumns = columnApi.getAllColumns();
        const availableWidth = gridRef.current.clientWidth;
        let flexSum = 0;
        allColumns.forEach((column:any) => {
          if (column.flex) {
            flexSum += column.flex;
          }
        });
        allColumns.forEach((column:any) => {
          if (column.flex) {
            const newWidth = (column.flex / flexSum) * availableWidth;
            columnApi.setColumnWidth(column, newWidth);
          }
        });
      };
      window.addEventListener('resize', resizeColumnsToFit);
      resizeColumnsToFit();
      return () => {
        window.removeEventListener('resize', resizeColumnsToFit);
      };
    }
  }, [fromDate, toDate, format])
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Typography
          sx={{
            color: "black",
            flexDirection: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/BRUPLOADS.xlsx"}
            download={"BRUPLOADS.xlsx"}
            className="textFile"
          >
            <IconButton sx={{ padding: 0 }}>
              <FileDownloadIcon />
            </IconButton> 
            Download Sample Template
          </a>
        </Typography>
        <Box
          className="fileupload"
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              marginRight: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form ref={Formref}>
              <TextField
                type="file"
                placeholder="Choose file"
                name="Choose File"
                className="file"
                onChange={filevalidation}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px !important",
                  },
                }}
              />
            </form>
            {fileExtError ? (
              <span className="rstatus">*INVALID FILE FORMAT</span>
            ) : null}
          </Box>
          <Box>
            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              sx={{
                fontWeight: 500,
              }}
              onClick={(event) => Preview()}
            >
              Preview
            </LoadingButton>
            <span>&nbsp; &nbsp;</span>

            <LoadingButton
              loading={false}
              variant="contained"
              type="submit"
              className="submitbtn"
              sx={{
                justifyContent: "end",
                fontWeight: 500,
                ml: 2,
                backgroundColor: "#555a64",
                color: "#fff",
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#555a64",
                  color: "#fff",
                },
              }}
              onClick={(event) => reset()}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
        {isShowFile && rowData ? (
          <Box className="ag-theme-balham" sx={{ mt: 2 }}>
            <AgGridReact
              animateRows={true}
              defaultColDef={defaultColDef}
              detailCellRendererParams={detailCellRendererParams}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              pagination={true}
              masterDetail={masterDetail}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              {disable ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={SubmitFile}
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" type="submit" disabled>
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        <ToastContainer />
        <Dialog
          open={alertOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeAlert}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Validation Messages"}</DialogTitle>
          <DialogContent>
            {validationData?.map((data: any) => {
              return (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                >
                  <Typography>{data}</Typography>
                </DialogContentText>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Close</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

export default BrFileupload;

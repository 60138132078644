import React from 'react'
import { Bars } from 'react-loader-spinner'

export default function Loaders() {
    return (
        <div>
            <Bars
                height="30"
                width="30"
                color="#2a76f4"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

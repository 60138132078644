import { Environment_Realtraceb_Dev } from "./Environment_Realtraceb_Dev";

export const Endpoints = {
  Consignee_PO_Preview: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/PurchaseOrders',
  Consignee_PO_Submit: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/poSubmit',
  Booking_Request_Preview: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/BookingRequests',
  Booking_Request_Submit: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/brSubmit',
  Pending_Forwarder_Booking_HeaderList: process.env.REACT_APP_API_URL + 'po/bookingRequestHeaderList',
  Pending_Forwarder_Booking_ItemList: process.env.REACT_APP_BASE_URL + '/bookingRequestItemList',
  Qr_Image: process.env.REACT_APP_API_URL + "qrImage/",
  Update_Booking_Request_Status: process.env.REACT_APP_BASE_URL + '/updateBrStatusItr',
  Forwarder_Upload_Confirmation_Submit: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/forwarderConfirmationSubmit",
  Forwarder_Upload_Confirmation_Preview: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/ForwarderConfirmation',
  Carrier_Booking_Request_Preview: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/uploadFile/CarrierBookings',
  Carrier_Booking_Request_Submit: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/carrierBookingsSubmit",
  Carrier_Booking_Confirmation_Preview: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/uploadFile/CarrierConfirmation",
  Carrier_Booking_Confirmation_Submit: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/carrierconfirmationSubmit",
  Pending_Carrier_Booking_HeaderList: process.env.REACT_APP_API_URL + "po/carrierHeaderList",
  Booking_LifeCycle_TopResp_Graph: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/LifeCycleTopResponsesGraph',
  Booking_LifeCycle_InThreeHr_Graph: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/LifeCycleReponseInThreeHrGraph',
  Shipment_LifeCycle_Widgets: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/shipmentLifeCycleWidgets',
  LifeCycle_Month_Trend: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/lifeCycleMonthTrend',
  Create_Shipment_Registration: process.env.REACT_APP_API_URL + 'cargoEs/createShipmentRegistrations',
  Update_Carrier_Request_Status: process.env.REACT_APP_BASE_URL + '/updateCarrierStatusItr',
  Pending_Carrier_Item_List: process.env.REACT_APP_BASE_URL + "/carrierItemList",
  Intransit_Header_List: process.env.REACT_APP_API_URL + 'po/intransitList',
  Fetch_Shipment_Registrations: process.env.REACT_APP_API_URL + 'cargoEs/fetchShipmentRegistrations',
  Intransit_Events: process.env.REACT_APP_API_URL + "po/eventQuery",
  InTransit_Events_Submit: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/inTransitEventsSubmit",
  InTransit_Events_Upload_Preview: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/inTransitUploadFile/InTransitEvents',
  Partner_Verification: process.env.REACT_APP_API_URL + 'parties/verifyPartner',
  Verify_Email: process.env.REACT_APP_API_URL + 'parties/verifyEmail',
  Set_New_Password: process.env.REACT_APP_API_URL + 'parties/setNewPassword',
  Get_Partners: process.env.REACT_APP_API_URL + 'parties/getPartners',
  Delete_Partners: process.env.REACT_APP_API_URL + 'parties/deletePartnerAndItsSites',
  Party_Insertion: process.env.REACT_APP_API_URL + 'parties/partyInsertion',
  Partners_Update: process.env.REACT_APP_API_URL + 'parties/updatePartners',
  GetPartner_Types_By_Login: process.env.REACT_APP_API_URL + 'parties/getPartnerTypesBylogin',
  Get_Countries: process.env.REACT_APP_API_URL + 'parties/getCountries',
  Intransit_Delivery_List: process.env.REACT_APP_API_URL + 'po/intransitListForDelivery',
  CHA_Custom_List: process.env.REACT_APP_BASE_URL + '/chaCustomList',
  update_Export_Custom_Clearance: process.env.REACT_APP_API_URL + 'po/updateExportCustomClearance',
  update_Shipment_Status_To_Delivery: process.env.REACT_APP_API_URL + "po/updateShipmentStatusToDelivered",
  Get_Docs: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/documents/getDocs",
  Booking_Request_Insights: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/dashboard/bookingRequestInsights",
  Booking_Request_BarChart: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/dashboard/bookingRequestBarChart",
  Accepted_Booking_Request_MOM_Trend: process.env.REACT_APP_PREPROCESSOR_API + "api/v1/dashboard/accBRMonthOnMonthTrend",
  Carrier_Booking_Request_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/carrierBookingRequestInsights',
  Carrier_Booking_Request_MOM_Trend: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/accCarrierBRMonthOnMonthTrend',
  Carrier_Booking_Request_Bar_Chart: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/carrierBookingRequestBarChart',
  Intransit_Shipments_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/inTransitShipmentInsights',
  Intransit_Shipments_CountryWise_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/inTransitShipmentCountryWiseInsights',
  Delivered_Deviation_Shipments_CountryWise_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/deliveredDeviationShipmentCountryWiseInsights',
  ETA_Deviation_Shipments_EventsWise_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/etaDeviationShipmentInsights',
  ETA_Deviation_Shipments_CountryWise_Insights: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/etaDeviationShipmentCountryWiseInsights',
  Delivered_Deviation_Shipments_DropOff: process.env.REACT_APP_PREPROCESSOR_API + 'api/v1/dashboard/dropOffTrend',
  Po_List: process.env.REACT_APP_BASE_URL + "/purchaseOrderList",
  Po_Line_List: process.env.REACT_APP_BASE_URL + '/purchaseOrderItemList',
  Update_Po_Status: process.env.REACT_APP_BASE_URL + "/updatePOStatus",
  IncoTerms_List: process.env.REACT_APP_API_URL + 'cargoEs/fetchMasterIncoTerms',
  Carrier_List: process.env.REACT_APP_API_URL +'cargoEs/carrierListByMode',
  Signup:process.env.REACT_APP_API_URL+'parties/selfRegistration',
  Countries:process.env.REACT_APP_API_URL+'parties/countriesAndCodes',
  Validate_PromoCode: process.env.REACT_APP_API_URL + 'parties/validatePromocode',

};

import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, Paper, MenuItem, InputLabel, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Select, Tooltip, Drawer, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import axios from 'axios';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    DateTimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
import swal from "sweetalert";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LoadingButton } from '@mui/lab';
import { Endpoints } from '../../../Enviornment/Endpoints';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api'; // Import Google Autocomplete with an alias
import Autocomplete from '@mui/material/Autocomplete';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Air, RotateRight } from '@mui/icons-material';
import { useOutletContext } from 'react-router-dom';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: 'rgba(255,255,255, 0.8)',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        fontSize: "10px",
        borderRadius: '10px 0px 0px 10px',
        // fontWeight: "bold",
        minHeight: "100%",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
    tab: {
        backgroundColor: "#f1f1f1",
        marginBottom: "10px",
    },
}));
export default function ShipmentRegistrationForm(props: any) {
    useEffect(() => {
        resetField('additionalInfo')
        reset()
        handleChangeShipmentMode(props.type)
        console.log(props, "props")
    }, [props.type])
    console.log("Props data", props.tab)
    const classes = useStyles();
    const [Airline, SetAirLine] = useState("")
    const [containerNumber, SetContainerNumber] = useState("");
    const [state, setState] = React.useState(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const domLayout = "autoHeight";
    const FormRef = useRef<any>();
    const [disable, setDisable] = useState<Boolean>(true);
    const [loader, setLoader] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const [rowData, setRowData] = useState<any>([]);
    const [isShowFile, setShowFile] = useState(false);
    const [previewData, setPreviewData] = useState<any>();
    const [validationData, setValidationData] = useState<any>([]);
    const [origin, setOrigin] = useState<any>({})
    const [destination, setDestination] = useState<any>({})
    const [autocomplete, setAutocomplete] = useState(null);
    const apiKey = 'AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q';
    const [load, setLoad, mode] = useOutletContext<any>();
    let registrationType: any;
    const maxCharactersPerLine = 50;
    const maxLines = 5;
    const [loadDate, setLoadDate] = React.useState<Dayjs | any>(null);
    const [carrierList, setCarrierList] = useState<any>([])
    const [incoTerms, setIncoTerms] = useState([])
    const [etaCurrentDate, setetaCurrentDate] = useState<any>(new Date().toISOString().split('T')[0])
    const [airAwb, setAirAwb] = useState([])
    const [isTyping, setIsTyping] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const handleInputFocus = () => {
        setIsTyping(true);
    };
    const handleInputChange = (e: any) => {
        setInputValue(e.target.value);
        setIsTyping(!!e.target.value);
    };
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            notify: [
                {
                    identifier: '',
                    email: ''
                }
            ],

            additionalInfo: [
                {
                    shipmentRegistrationType: '',
                    carrierName: "",
                    shipmentReferenceNo: '',
                    invoiceNumber: '',
                    mode: '',
                    [props.type == 'Ocean' ? 'mblNumber' : 'flightNumber']: '',
                    bookingNumber: "",
                    shipmentMode: props.type == "Ocean" ? "INTERMODAL_SHIPMENT" : "AIR_SHIPMENT",
                    [props.type == 'Ocean' ? 'hblNumber' : "hawbNumber"]: '',
                    incoTerms: "",
                    shipperName: '',
                    consigneeName: '',
                    actualETA: '',
                    totalPack: '',
                    packUOM: '',
                    totalWeight: '',
                    weightUOM: '',
                    totalVolume: '',
                    volumeUOM: '',
                    [props.type == 'Ocean' ? 'containerNumber' : 'awbNumber']: '',
                    vesselImo: '',
                    vesselMmsi: '',

                    portOfOrigin: '',
                    portOfDestination: '',
                    identifier: '',
                    email: '',
                    notify: [
                        {
                            identifier: '',
                            email: ''
                        }
                    ]



                },
            ],


        },
    });

    const { fields, append, remove } = useFieldArray({

        name: "additionalInfo",
        control,
        rules: {
            required: "Enter All Details",
        },

    });
    const notify = useFieldArray({
        name: 'notify',
        control
    })
    const addMoreShipments = (index: any) => {
        append(
            {
                shipmentRegistrationType: mode,
                carrierName: "",
                mode: '',
                invoiceNumber: '',
                shipmentReferenceNo: '',
                [props.type == 'Ocean' ? 'mblNumber' : 'flightNumber']: '',
                bookingNumber: "",
                shipmentMode: props.type == "Ocean" ? "INTERMODAL_SHIPMENT" : 'AIR_SHIPMENT',
                [props.type == 'Ocean' ? 'hblNumber' : "hawbNumber"]: '',
                incoTerms: "",
                shipperName: '',
                consigneeName: '',
                actualETA: '',
                totalPack: '',
                packUOM: '',
                totalWeight: '',
                weightUOM: '',
                totalVolume: '',
                volumeUOM: '',
                [props.type == 'Ocean' ? 'containerNumber' : 'awbNumber']: '',
                vesselImo: '',
                vesselMmsi: '',
                portOfOrigin: '',
                portOfDestination: '',
                identifier: '',
                email: '',
                notify: [
                    {
                        identifier: '',
                        email: ''
                    }
                ]


            },
        )
        setValue(`additionalInfo.${index}.shipmentRegistrationType`, mode)
    }
    const appendNotify = () => {
        notify.append({
            identifier: '',
            email: ''
        })
        // setValue()
    }
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const onLoad = (autocomplete: any) => {
        setAutocomplete(autocomplete);
    };
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            console.log(autocomplete);
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };
    const readyToLoad = (newValue: Dayjs | any, index: any) => {
        let data = newValue.$d;
        var responseDate = moment(data).format("YYYY-MM-DD");
        console.log(responseDate, index);
        setLoadDate(newValue);
        setValue(`additionalInfo.${index}.actualETA`, responseDate)
    };

    // ----------------- api headers config -------------------

    const deleteShipments = (index: any) => {
        remove(index)
    }
    const selectIncoTerms = (e: any, index: any) => {
        console.log(e.target.value, "target value")
        setValue(`additionalInfo.${index}.incoTerms`, e.target.value)
        trigger(`additionalInfo.${index}.incoTerms`)
    }
    const handleChangeCarrier = (e: any, index: any) => {
        console.log(e.ta, "eeee")
        let shipmentMode = props.type == 'Ocean' ? "INTERMODAL_SHIPMENT" : 'AIR_SHIPMENT';
        setValue(`additionalInfo.${index}.shipmentMode`, shipmentMode)
        setValue(`additionalInfo.${index}.carrierName`, e.target.value)
        trigger(`additionalInfo.${index}.carrierName`)
    }
    const handleChangeShipmentMode = (event: any) => {
        console.log(event)
        config.params = {
            shipmentType: event == "Ocean" ? "INTERMODAL_SHIPMENT" : 'AIR_SHIPMENT'
        }
        let awblist: any = [];
        axios.get(Endpoints.Carrier_List, config).then((res: any) => {
            setCarrierList(res.data.primary)
            res.data.primary.map((value: any, index: any) => {

                let obj = {
                    carrierName: value.carrierName,
                    awb: value.IATA_HAWB,
                    mwb: value.IATA_MAWB,
                    carrierScac: value.carrierScac,
                }

                awblist.push(obj);
            })
        })
        setAirAwb(awblist)
        console.log("List is", awblist);
    }
    const createData = (item: any) => {
        SetAirLine("")
        SetContainerNumber("")
        console.log("notifying to", mode, item.notify)
        let payload = []
        payload.push(item.additionalInfo[0].notify = item.notify)
        if (props.type == "Ocean") {
            item.additionalInfo.filter((item: any) => {
                console.log(item.carrierName.carrierName, "selected");
                return item.carrierName = item.carrierName.carrierName
            });

            item.additionalInfo.filter((item: any) => {
                console.log(item.carrierName.carrierName, mode, "selected");
                return item.shipmentRegistrationType = props.tab
            });

            item.additionalInfo.filter((item: any) => {
                console.log(item.carrierName.carrierName, mode, "selected");
                return item.incoTerms = item.incoTerms.incotermName
            });
        }
        console.log(item.additionalInfo)
        console.log("Payoad is ...", item.additionalInfo)
        // if(item.lenth > 0){
        //   let payload =   item.additionalInfo['notify']=[...item.notify];
        //   console.log(payload)

        // }
        setLoader(true)
        resetField('additionalInfo')
        reset()
        axios.post(Endpoints.Create_Shipment_Registration, item, config).then((res: any) => {
            console.log(res.data, "shipment creation")
            try {
                if (res.data.status.code === 'SUCCESS') {
                    toast.success('Shipment registered successfully and is currently being processed.')
                    resetField('additionalInfo')
                    setLoadDate(null)
                    setLoader(false)
                    reset()
                    setRowData([])
                    setShowFile(false)
                } else {
                    if (res.data.status.code === 'Error') {
                        swal('Warning', res.data.status.message, 'warning')
                    }
                }
            } catch (error: any) {
                setLoader(false)
                swal('Warning', 'The shipment you are trying to register already exists with the same combination.', 'warning')
            }

        }).catch((e: any) => {
            setLoader(false)
        })
    }

    const getIncoTerms = () => {
        axios.get(Endpoints.IncoTerms_List, config).then((res: any) => {
            setIncoTerms(res.data.primary)
        })
    }
    useEffect(() => {
        if (props.tab != 'Shipments') {
            getIncoTerms()
            registrationType = props.tab
            setValue(`additionalInfo.${0}.shipmentRegistrationType`, mode)
        }

    }, [mode])
    const validateAWB = (value: any, index: any, type: any, tab: any) => {


        SetAirLine("")
        SetContainerNumber("")
        // Check if the value has at least 3 characters
        if (tab != "Ocean") {

            if (value.length < 3) {
                return 'MAWB number must have at least 3 characters';
            }

            // Extract the first 3 characters
            const inputValue = value.substr(0, 3);
            console.log(inputValue, "Input")

            // Check if the extracted value exists in the airAwb array
            let ind: any = airAwb.filter((awb: any) => awb.awb == inputValue || awb.mwb == inputValue)
            console.log("data is", ind, ind.length)

            if (ind.length > 0) {
                setValue(`additionalInfo.${index}.carrierName`, ind[0].carrierName);
                SetAirLine(ind[0].carrierName)
                console.log("name is...", ind[0].carrierName)
                return undefined; // Input is valid
            }

            return 'Airline not supported at this point of time'; // Input is invalid
        }
        if (tab == "Ocean" && type == "byContainer") {
            console.log("I am Here...")
            let alpha = /^[A-Za-z]+$/;
            let pattern = /(?:^\s+|\s+$)/
            let alphanumeric = /^[a-zA-Z0-9]*$/
            const Numberregex = /^[0-9]+$/

            if (pattern.test(value) == true) {
                return "Container number should not contain empty space";
            }

            if (alphanumeric.test(value) === false) {
                return "Container number should be alphanumeric"
            }


            if (value.length < 4) {
                if(alpha.test(value) == false) {  
                    return "First four characters should be alphabets";
                }
                return 'Container number must have at least 4 characters';
            }

            


            if (value.length > 4) {
                if(alpha.test(value.substring(0,4)) == false) {  
                    return "First four characters should be alphabets";
                }

                if(Numberregex.test(value.substring(4,12)) == false) {  
                    return "Should be numeric digits";
                }
                 if(value.length < 11 ){
                return "Should contain minimum 11 characters.";
            }
                  if(value.length > 13 ){
                    return "Should contain maximum 13 characters.";
                }

                return undefined

            }



            // Extract the first 3 characters
            const inputValue = value.substr(0, 4);
            console.log(inputValue, "Input")

            // Check if the extracted value exists in the airAwb array
            let ind: any = airAwb.filter((awb: any) => awb.carrierScac.toLowerCase() == inputValue.toLowerCase())
            console.log("data is", ind, ind.length)
            if (ind.length > 0) {
                // setValue(`additionalInfo.${index}.carrierName`, ind[0].carrierScac);
                SetContainerNumber(ind[0].carrierName)
                console.log("name is...", ind[0].carrierScac)
                return undefined; // Input is valid
            }
            return undefined; // Input is invalid
        }

    };
    const closeAlert = () => setAlertOpen(false);
    return (
        <div className={classes.root}>
            <Box>
                <form onSubmit={handleSubmit((data) => {
                    createData(data);
                })}>
                    {fields.map((field, index) => {
                        return (
                            <Box>
                                {/* <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography className='shipment_header' sx={{ backgroundColor: '#fff' }}>Shipment {index + 1}</Typography>
                                    <Box>
                                        <Button
                                            sx={{ textAlign: 'center' }}
                                            variant="contained"
                                            size='small'
                                            onClick={() => addMoreShipments(index)}

                                        >
                                            Add Shipment
                                        </Button>&nbsp;&nbsp;
                                        {index < fields.length - 1 || !(fields.length == 1 && index == 0) ?
                                            <Button
                                                sx={{ textAlign: 'center' }}
                                                variant="contained"
                                                size='small'
                                                color='warning'
                                                onClick={() => deleteShipments(index)}

                                            >
                                                Remove Shipment
                                            </Button> : ''}
                                    </Box>
                                </Box> */}
                                <Paper sx={{ backgroundColor: 'transparent' }} elevation={0}>
                                    <Grid container spacing={2} >
                                        {props.type === 'Ocean' && props.tab != 'byVessel' ?
                                            <Grid item xs={4}>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                    Carrier&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span></InputLabel>
                                                <Controller
                                                    name={`additionalInfo.${index}.carrierName`}
                                                    control={control}
                                                    rules={{ required: 'Carrier is required' }}
                                                    defaultValue='' // Set the default value as needed
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            disablePortal
                                                            id={`combo-box-demo-${index}`}
                                                            options={carrierList}
                                                            getOptionLabel={(option: any) => (option ? option.carrierName : "")}
                                                            value={field.value}
                                                            onChange={(_, newValue) => field.onChange(newValue)}
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}

                                                                    fullWidth
                                                                    size="small"
                                                                    className='custom-inputs'

                                                                    sx={{
                                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                                            // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                                            color: '#0B5583'
                                                                        },
                                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                            // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                                            // color:'#dd'
                                                                        },

                                                                    }}
                                                                />
                                                            )}
                                                        // getOptionSelected={(option:any, value:any) => option.carrierName === value}
                                                        // getOptionLabel={(option:any) => (option ? option.carrierName : "")} // Handle empty values
                                                        />
                                                    )}
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.additionalInfo?.[index]?.carrierName?.message}
                                                </span>

                                            </Grid> : ''}
                                        <Grid item xs={4}>
                                            <InputLabel id="demo-simple-select-label" className="custom-label" >  {props.type === 'Ocean' && props.tab === 'byContainer' ?

                                                <span>
                                                    Container&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span>

                                                : props.type === 'Ocean' && props.tab === 'byBooking' ?
                                                    <span>
                                                        Booking&nbsp;<span style={{ color: 'red' }}>*</span>
                                                    </span>

                                                    : props.type === 'Ocean' && props.tab === 'byMBL' ?
                                                        <span>
                                                            MBL&nbsp;<span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                        : props.type === 'Ocean' && props.tab === 'byVessel' ?
                                                            <span>
                                                                Vessel IMO&nbsp;<span style={{ color: 'red' }}>*</span>
                                                            </span>

                                                            :
                                                            <span>
                                                                MAWB&nbsp;<span style={{ color: 'red' }}>*</span>
                                                            </span>
                                            }</InputLabel>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                    {...register(props.type === 'Ocean' && props.tab === 'byContainer' ?
                                                        `additionalInfo.${index}.containerNumber`
                                                        : props.type === 'Ocean' && props.tab === 'byBooking' ?
                                                            `additionalInfo.${index}.bookingNumber`
                                                            : props.type === 'Ocean' && props.tab === 'byMBL' ?
                                                                `additionalInfo.${index}.mblNumber`
                                                                : props.type === 'Ocean' && props.tab === 'byVessel' ?
                                                                    `additionalInfo.${index}.vesselImo`
                                                                    : `additionalInfo.${index}.awbNumber`, {
                                                        required: props.type === 'Ocean' && props.tab === 'byContainer' ?
                                                            "Container# is required"
                                                            : props.type === 'Ocean' && props.tab === 'byBooking' ?
                                                                'Booking# is required'
                                                                : props.type === 'Ocean' && props.tab === 'byMBL' ?
                                                                    'MBL# is required'
                                                                    : props.type === 'Ocean' && props.tab === 'byVessel' ?
                                                                        'Vessel IMO  is required'
                                                                        : 'MAWB# is required',


                                                        validate: (value) => validateAWB(value, index, props.tab, props.type)
                                                    })}
                                                    id="outlined-basic"
                                                    type="text"
                                                    size='small'
                                                    className='custom-inputs'
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: '#0B5583'
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {},

                                                    }}
                                                />

                                                {props.type == 'Ocean' && props.tab === 'byContainer' ?
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.additionalInfo?.[index]?.containerNumber?.message}
                                                    </span> : props.type == 'Ocean' && props.tab === 'byBooking' ?
                                                        <span style={{ color: "red", fontSize: "12px" }}>
                                                            {errors.additionalInfo?.[index]?.bookingNumber?.message}
                                                        </span> : props.type == 'Ocean' && props.tab === 'byMBL' ?
                                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                                {errors.additionalInfo?.[index]?.mblNumber?.message}
                                                            </span> : props.type == 'Ocean' && props.tab === 'byVessel' ?
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {errors.additionalInfo?.[index]?.vesselImo?.message}
                                                                </span> :
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {errors.additionalInfo?.[index]?.awbNumber?.message}
                                                                </span>}
                                                {props.type != "Ocean" &&
                                                    <span style={{ color: "green", fontSize: "12px", fontWeight: 'bold' }}>
                                                        {Airline}
                                                    </span>
                                                }

                                                {(props.type == "Ocean" && props.tab == "byContainer") &&
                                                    <span style={{ color: "green", fontSize: "12px", fontWeight: 'bold' }}>
                                                        {containerNumber}
                                                    </span>
                                                }



                                            </FormControl>
                                        </Grid>
                                        {props.type == "Ocean" && props.tab == "byVessel" &&
                                            <Grid item xs={4}>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >Vessel MMSI</InputLabel>
                                                <FormControl fullWidth size='small'>
                                                    <TextField
                                                        disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                        fullWidth
                                                        size="small"
                                                        className='custom-inputs'
                                                        {
                                                        ...register(`additionalInfo.${index}.vesselMmsi`, {
                                                            minLength: {
                                                                value: 9,
                                                                message: 'Vessel IMSI  should be greater than 9 characters.',
                                                            },
                                                        })
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        }

                                        {props.type === 'Ocean' && props.tab === 'byVessel' ?
                                            <Grid item xs={4}>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                    Port of origin&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span></InputLabel>
                                                <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
                                                    <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} className="custom-autocomplete">
                                                        <TextField
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            fullWidth size='small' className='custom-inputs' {...register(`additionalInfo.${index}.portOfOrigin`)}
                                                            sx={{
                                                                "& .MuiInputLabel-root.Mui-focused": {
                                                                    color: '#0B5583'
                                                                },
                                                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                },

                                                            }} />
                                                    </GoogleAutocomplete>
                                                </LoadScript>
                                            </Grid> : ''}
                                        {props.type === 'Ocean' && props.tab === 'byVessel' ?
                                            <Grid xs={4} item className='tabFilter'>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                    Port of destination&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span></InputLabel>
                                                <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
                                                    <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} className="custom-autocomplete">
                                                        <TextField
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            className='custom-inputs' fullWidth size='small' {...register(`additionalInfo.${index}.portOfDestination`)}
                                                            sx={{
                                                                "& .MuiInputLabel-root.Mui-focused": {
                                                                    color: '#0B5583'
                                                                },
                                                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                },

                                                            }}
                                                        />
                                                    </GoogleAutocomplete>
                                                </LoadScript>
                                            </Grid> : ''}

                                        {props.type === 'Ocean' && props.tab !== 'byMBL' ?
                                            <Grid item xs={4}>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                    MBL&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span></InputLabel>
                                                <FormControl fullWidth size="small">
                                                    <TextField
                                                        disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                        {...register(`additionalInfo.${index}.mblNumber`, { required: 'MBL# is required' })}
                                                        id="outlined-basic"
                                                        type="text"
                                                        fullWidth
                                                        size='small'
                                                        className='custom-inputs'
                                                        sx={{
                                                            "& .MuiInputLabel-root.Mui-focused": {
                                                                // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                                color: '#0B5583'
                                                            },
                                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                                // color:'#dd'
                                                            },

                                                        }}
                                                    />
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.additionalInfo?.[index]?.mblNumber?.message}
                                                    </span>
                                                </FormControl>
                                            </Grid> : ''}
                                        <Grid item xs={4}>
                                            <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                Invoice&nbsp;<span style={{ color: 'red' }}>*</span>
                                            </span></InputLabel>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                    {...register(`additionalInfo.${index}.invoiceNumber`, {
                                                        required: 'Invoice# is required'
                                                    })}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    className='custom-inputs'
                                                    size='small'
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                            color: '#0B5583'
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                            // color:'#dd'
                                                        },

                                                    }}

                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.additionalInfo?.[index]?.invoiceNumber?.message}
                                                </span>
                                            </FormControl>
                                        </Grid>
                                        {props.type === 'Ocean' ?
                                            <Grid item xs={4}>
                                                <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                    IncoTerms&nbsp;<span style={{ color: 'red' }}>*</span>
                                                </span></InputLabel>
                                                <Controller
                                                    name={`additionalInfo.${index}.incoTerms`}
                                                    control={control}

                                                    rules={{ required: 'IncoTerms is required' }}
                                                    defaultValue='' // Set the default value as needed
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            disablePortal
                                                            id={`combo-box-demo-${index}`}
                                                            options={incoTerms}
                                                            getOptionLabel={(option: any) => (option ? option.incotermName : "")}
                                                            value={field.value}
                                                            onChange={(_, newValue) => field.onChange(newValue)}
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    className='custom-inputs'
                                                                    size="small"
                                                                    variant="outlined"
                                                                    sx={{
                                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                                            // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                                            color: '#0B5583'
                                                                        },
                                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                            // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                                            // color:'#dd'
                                                                        },

                                                                    }}
                                                                />
                                                            )}
                                                        // getOptionSelected={(option:any, value:any) => option.carrierName === value}
                                                        // getOptionLabel={(option:any) => (option ? option.carrierName : "")} // Handle empty values
                                                        />
                                                    )}
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.additionalInfo?.[index]?.incoTerms?.message}
                                                </span>


                                            </Grid> : ''}
                                        <Grid item xs={4}>
                                            <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                Shipper&nbsp;<span style={{ color: 'red' }}>*</span>
                                            </span></InputLabel>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    {...register(`additionalInfo.${index}.shipperName`,
                                                        { required: 'Shipper is required' }
                                                    )}
                                                    disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                    id="outlined-basic" type="text"
                                                    fullWidth
                                                    className='custom-inputs'
                                                    size='small'
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                            color: '#0B5583'
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                            // color:'#dd'
                                                        },

                                                    }}
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.additionalInfo?.[index]?.shipperName?.message}
                                                </span>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <InputLabel id="demo-simple-select-label" className="custom-label" >  <span>
                                                Consignee&nbsp;<span style={{ color: 'red' }}>*</span>
                                            </span></InputLabel>
                                            <FormControl fullWidth size="small">
                                                <TextField
                                                    {...register(`additionalInfo.${index}.consigneeName`,
                                                        { required: 'Consignee is required' }
                                                    )}
                                                    disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    className='custom-inputs'
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            // color: "rgb(211, 47, 47)", // Change the label color when focused
                                                            color: '#0B5583'
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                                            // color:'#dd'
                                                        },

                                                    }}
                                                    size='small'
                                                />
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.additionalInfo?.[index]?.consigneeName?.message}
                                                </span>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 2 }}>
                                        <Typography sx={{ mr: 2 }}>Additional Data</Typography>
                                        <Tooltip sx={{ backgroundColor: 'blue' }} title="To expedite tracking and enhance your experience, please supply more details about your shipment." arrow>
                                            <HelpOutlineOutlinedIcon sx={{ color: '#1976d2', width: 16, height: 16 }} />
                                        </Tooltip>
                                    </Box> */}
                                    <Accordion sx={{ mt: 2, backgroundColor: 'transparent' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ border: '1px solid #fff' }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography sx={{ mr: 2, color: '#fff' }}>Additional Data</Typography>
                                                <Tooltip sx={{ backgroundColor: 'blue' }} title="Provide additional data about your shipment to speed up the tracking and improve your experience." arrow>
                                                    <HelpOutlineOutlinedIcon sx={{ color: '#fdfdfd', width: 16, height: 16 }} />
                                                </Tooltip>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ border: '1px solid #fff' }}>
                                            <Grid container spacing={2} >
                                                {props.tab !== 'byBooking' && props.type === 'Ocean' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel
                                                            htmlFor="outlined-basic"
                                                            className="custom-label"
                                                        >
                                                            Booking #
                                                        </InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.bookingNumber`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                fullWidth
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                                size='small'
                                                                className='custom-inputs'
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}

                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >ShipmentType</InputLabel>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name={`additionalInfo.${index}.mode`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        className='custom-inputs'
                                                                        disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                                        {...field}
                                                                        MenuProps={{
                                                                            disableScrollLock: true
                                                                        }}
                                                                    >

                                                                        <MenuItem value='FCL'>FCL</MenuItem>
                                                                        <MenuItem value='LCL'>LCL</MenuItem>
                                                                        <MenuItem value='BCN'>BCN</MenuItem>
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid> : props.tab === 'byVessel' ? <Grid item xs={3}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >ShipmentType</InputLabel>
                                                        <FormControl fullWidth size='small'>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Shipment Type"
                                                                className='custom-inputs'
                                                                fullWidth
                                                                {...register(`additionalInfo.${index}.mode`)}
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                                MenuProps={{
                                                                    disableScrollLock: true
                                                                }}

                                                            >
                                                                <MenuItem value='BULK'>BULK</MenuItem>
                                                                <MenuItem value='CONTAINERIZED'>CONTAINERIZED</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid> : ''
                                                }

                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >{props.type === 'Ocean' ? 'HBL #' : 'HAWB #'}</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(props.type === "Ocean" ? `additionalInfo.${index}.hblNumber` : `additionalInfo.${index}.hawbNumber`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                className='custom-inputs'
                                                                fullWidth
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}


                                                <Grid item xs={4}>
                                                    <InputLabel id="demo-simple-select-label" className="custom-label" >ShipmentReference #</InputLabel>
                                                    <FormControl fullWidth size="small">
                                                        <TextField
                                                            {...register(props.type === "Ocean" ? `additionalInfo.${index}.shipmentReferenceNo` : `additionalInfo.${index}.hawbNumber`)}
                                                            id="outlined-basic"
                                                            type="text"
                                                            fullWidth
                                                            className='custom-inputs'
                                                            size='small'
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                        />
                                                    </FormControl>
                                                </Grid>


                                                {props.type === 'Ocean' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Promised ETA</InputLabel>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                            <DesktopDatePicker
                                                                inputFormat="DD/MM/YYYY"
                                                                value={loadDate}
                                                                minDate={etaCurrentDate}
                                                                onChange={(e: any) => readyToLoad(e, index)}
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                                renderInput={(params: any) => (
                                                                    <TextField {...params} size="small" className='custom-inputs' fullWidth sx={{ backgroundColor: '#fff !important' }} id="outlined-basic" />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Total Package</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.totalPack`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                fullWidth
                                                                className='custom-inputs'
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Package UOM</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.packUOM`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                className='custom-inputs'
                                                                fullWidth
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Total Weight</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.totalWeight`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                fullWidth
                                                                className='custom-inputs'
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Weight UOM</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.weightUOM`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                className='custom-inputs'
                                                                fullWidth
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Total Volume</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.totalVolume`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                fullWidth
                                                                className='custom-inputs'
                                                                size='small'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                                {props.tab === 'byContainer' ?
                                                    <Grid item xs={4}>
                                                        <InputLabel id="demo-simple-select-label" className="custom-label" >Volume UOM</InputLabel>
                                                        <FormControl fullWidth size="small">
                                                            <TextField
                                                                {...register(`additionalInfo.${index}.volumeUOM`)}
                                                                id="outlined-basic"
                                                                type="text"
                                                                fullWidth
                                                                size='small'
                                                                className='custom-inputs'
                                                                disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            />
                                                        </FormControl>
                                                    </Grid> : ''}
                                            </Grid>


                                        </AccordionDetails>
                                    </Accordion>

                                    {index == 0 ? <Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2, mb: 2 }}>
                                            <Typography sx={{ mr: 2, color: '#fff' }}>Notify To </Typography>
                                            <Tooltip sx={{ backgroundColor: 'blue' }} title="Inform the involved parties." arrow>
                                                <HelpOutlineOutlinedIcon sx={{ color: '#fdfdfd', width: 16, height: 16 }} />
                                            </Tooltip>
                                        </Box>
                                        {notify.fields.map((fields, indexes) => (
                                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                                <Grid item xs={4}>
                                                    <InputLabel id="demo-simple-select-label" className="custom-label" >Name</InputLabel>
                                                    <FormControl fullWidth size="small">
                                                        <TextField
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            {...register(`notify.${indexes}.identifier`,
                                                                {
                                                                    minLength: {
                                                                        value: 3,
                                                                        message: 'Name should be greater than 3 characters',
                                                                    },
                                                                    maxLength: {
                                                                        value: 25,
                                                                        message: 'Name should be lesser than 25 characters',
                                                                    },
                                                                },)}
                                                            id="outlined-basic"
                                                            type="text"
                                                            fullWidth
                                                            size='small'
                                                            className='custom-inputs'

                                                        />
                                                        <span style={{ color: "red", fontSize: "12px" }}>
                                                            {errors.notify?.[indexes]?.identifier?.message}
                                                        </span>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputLabel id="demo-simple-select-label" className="custom-label" >Email</InputLabel>
                                                    <FormControl fullWidth size="small">
                                                        <TextField
                                                            disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}
                                                            {...register(`notify.${indexes}.email`, {
                                                                pattern: {
                                                                    value:
                                                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                    message: "Please enter a valid email address.",
                                                                }
                                                            },
                                                            )}
                                                            id="outlined-basic"
                                                            type="text"
                                                            fullWidth
                                                            size='small'
                                                            className='custom-inputs'
                                                        />
                                                        <span style={{ color: "red", fontSize: "12px" }}>
                                                            {errors.notify?.[indexes]?.email?.message}
                                                        </span>
                                                    </FormControl>


                                                </Grid>
                                                <Grid item xs={3} sx={{ mt: 2 }}>
                                                    {indexes == 0 ? <IconButton onClick={() => notify.append({ identifier: '', email: '' })}><AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} /></IconButton> : null}
                                                    {indexes == 0 ? null : <IconButton onClick={() => notify.remove(indexes)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box> : null}


                                </Paper>
                            </Box>
                        )
                    })}

                    <LoadingButton
                        sx={{
                            mt: 2, textAlign: 'center', backgroundColor: props.tab === 'byContainer' || props.tab === 'byAWB' ? '#2d7fb7' : '#fff !important', color: props.tab === 'byContainer' || props.tab === 'byAWB' ? '#fff' : '#2d7fb7 !important',
                            
                            '&.MuiLoadingButton-loading': {
                                backgroundColor:'#2d7fb7 !important',
                            }
                        }}
                        variant="contained"
                        size='small'
                        type='submit'
                        loading={loader}
                        disabled={props.tab === 'byContainer' || props.tab === 'byAWB' ? false : true}

                    >
                        Submit
                    </LoadingButton>
                </form>
            </Box >
            <ToastContainer />
            <Dialog
                open={alertOpen}
                // TransitionComponent={Transition}
                keepMounted
                onClose={closeAlert}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Validation Messages"}</DialogTitle>
                <DialogContent>
                    {validationData?.map((data: any) => {
                        return (
                            <DialogContentText
                                id="alert-dialog-slide-description"
                                sx={{ fontSize: "14px", padding: "5px", color: "black" }}
                            >
                                <Typography>{data}</Typography>
                            </DialogContentText>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlert}>Close</Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}
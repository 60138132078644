import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import { sideBar,defaultColDef } from '../../common/AgGridConfig';
import "./trcuk.css";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { useOutletContext } from "react-router-dom";
import BrLineItems from "../Forwarder/BrLineItems";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function OutWardShipments() {
  const [showgrid, setShowGrid] = useState(true);
  const [load, setLoad, fromDate, toDate] = useOutletContext<any>();
  useEffect(() => {
    setLoad(true);
    setShowGrid(true);
    axios.get(process.env.REACT_APP_BASE_URL +  "/shipmentsAtWh", config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }
      setLoad(false);
      setRowData(res.data.primary);
      console.log(res.data.primary);
      // params.columnApi.autoSizeAllColumns(false);
    });
  }, [fromDate, toDate]);
  const [PoData, setPoData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);

  const [columnDefs] = useState([
    // {
    //   headerName: "SELECT ",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   // hide: sessionStorage.getItem("role") === "shipper" ? false : true,
    // },
    {
      headerName: "INVOICE NUMBER",
      headerTooltip: "INVOICE NUMBER",
      tooltipField: "invoiceNumber",
      field: "invoiceNumber",
      // cellRenderer: (params: ICellRendererParams) => {
      //   return (
      //     <a
      //       style={{ color: "blue", lineHeight: 0.5 }}
      //       onClick={() => handleData(params)}
      //       className="btn btn-info"
      //     >
      //       {params.value}
      //     </a>
      //   );
      // },
    },

    {
      headerName: "CHECKIN DATE",
      headerTooltip: "CHECKIN DATE",
      tooltipField: "checkInDate",
      field: "checkInDate",
    },
    {
      headerName: "GATEOUT DATE",
      headerTooltip: "GATEOUT DATE",
      tooltipField: "gateOutDate",
      field: "gateOutDate",
    },
    {
      headerName: "GATEOUT TIME",
      headerTooltip: "GATEOUT TIME",
      tooltipField: "gateOutTime",
      field: "gateOutTime",
    },
    {
      headerName: "WAREHOUSE ID",
      headerTooltip: "WAREHOUSE ID",
      tooltipField: "whID",
      field: "whId",
    },
    {
      headerName: "WAREHOUSE NAME",
      headerTooltip: "WAREHOUSE NAME",
      tooltipField: "whName",
      field: "whName",
    },
    {
      headerName: "WAREHOUSE BOKKING REFERENCE",
      headerTooltip: "WAREHOUSE BOKKING REFERENCE",
      tooltipField: "whID",
      field: "whBookingReference",
    },

    // {
    //   headerName: "ETD FROM WAREHOUSE",
    //   filter: true,
    //   headerTooltip: "ETD FROM WAREHOUSE",
    //   tooltipField: "etdFromWareHouse",
    //   field: "etdFromWareHouse",
    // },
    {
      headerName: "SHIPPER ID",
      headerTooltip: "SHIPPER ID",
      tooltipField: "shipperId",
      field: "carrierHeaderDetails.shipperId",
    },
    {
      headerName: "SHIPPER NAME",
      headerTooltip: "SHIPPER NAME",
      tooltipField: "shipperName",
      field: "carrierHeaderDetails.shipperName",
    },
    {
      headerName: "COMMODITY TYPE",
      headerTooltip: "COMMODITY TYPE",
      tooltipField: "commodityType",
      field: "commodityType",
    },
    {
      headerName: "ITEM NAME",
      field: "itemName",
      headerTooltip: "ITEM NAME",
      tooltipField: "itemName",
    },
    {
      headerName: "ITEM DESCRIPTION",
      field: "itemDescription",
      headerTooltip: "ITEM DESCRIPTION",
      tooltipField: "itemDescription",
    },
    {
      headerName: "BATCH NUMBER",
      field: "batchNumber",
      headerTooltip: "BATCH NUMBER",
      tooltipField: "batch number",
    },
    {
      headerName: "LOT NUMBER",
      field: "lotNumber",
      headerTooltip: "LOT NUMBER",
      tooltipField: "lotNumber",
    },
    {
      headerName: "SERIAL NUMBER",
      field: "serialNumber",
      headerTooltip: "SERIAL NUMBER",
      tooltipField: "serialNumber",
    },
    {
      headerName: "PACKAGE TYPE",
      headerTooltip: "PACKAGE TYPE",
      tooltipField: "packageType",
      field: "packageDetails.type",
    },
    {
      headerName: "PACKAGE WEIGHT",
      headerTooltip: "PACKAGE WEIGHT",
      tooltipField: "packageWeight",
      field: "packageDetails.weight",
    },

    {
      headerName: "PACKAGE LENGTH",
      headerTooltip: "LENGTH",
      tooltipField: "length",
      field: "packageDetails.length",
    },
    {
      headerName: "PACKAGE WIDTH",
      headerTooltip: "WIDTH",
      tooltipField: "width",
      field: "packageDetails.width",
    },
    {
      headerName: "PACKAGE HEIGHT",
      headerTooltip: "HEIGHT",
      tooltipField: "height",
      field: "packageDetails.height",
    },

    {
      headerName: "HAZARDOUS GOODS",
      headerTooltip: "HAZARDOUS GOODS",
      tooltipField: "hazardousGoods",
      field: "hazardousGoods",
    },
    {
      headerName: "SPECIAL INSTRUCTIONS",
      field: "packageDetails.specialInstructions",
      headerTooltip: "SPECIAL INSTRUCTIONS",
      tooltipField: "packagespecialinstructions",
    },
  ]);

  let options = {
    start: 0,
    end: 1000,
    prevStart: "",
    objectEnd: "",
    fbrStatus: "open,processed",
    brStatus: "accepted",
  };

  
  const classes = useStyles();
  const domLayout = "autoHeight";
  const [rowData, setRowData] = useState();
  const [role, setRole] = useState<any>(sessionStorage.getItem("role"));

  const gridRef = useRef<any>();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      whTruckStatus: "gateOut",
      type: role,
      fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2,'days').format('YYYY-MM-DD'),
      toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    },
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);
  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns(false);
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.maincontent}>
        <Box sx={{ p: 0, mt: 1 }}>
          {showgrid ? (
            <AgGridReact
              className="ag-theme-balham"
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              domLayout={domLayout}
              columnDefs={columnDefs}
              sideBar={sideBar}
              onGridReady={onGridReady}
              paginationPageSize={10}
              pagination={true}
              ref={gridRef}
              // onVirtualColumnsChanged={autosize}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
      </Container>
      <div className="dialog">
        {openDetail ? (
          <BrLineItems
            onOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            data={PoData}
            status={"accepted"}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default OutWardShipments;

import { Alert, AlertTitle, AppBar, Box, Button, Dialog, Slide, Stepper, Toolbar } from '@mui/material';
import React, { useRef, useState } from 'react'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DetailsInfoDeviation from './Deviations/DetailsInfoDeviation';
import CommonETADeviationInfo from './CommonETADeviationInfo';
import CommonDeliveredShipmentsInfo from './CommonDeliveredShipmentsInfo';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardDeviation(props: any) {
    const [value, setValue] = React.useState("1");
    const myRef = useRef<any>();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <div className="dialog">
            <Dialog
                fullScreen
                open={props.onOpen}
                onClose={props.onClose}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-root": {},
                    zIndex: 10000,
                }}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' component="div" sx={{ ml: 2, flex: 1 }}>
                            Delivered Shipments - Deviation Summary
                        </Typography>

                        <Button autoFocus color="inherit" onClick={props.onClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <div ref={myRef}
                    style={{ overflowY: 'scroll' }}
                >
                    <Box sx={{ width: "100%", typography: "body1", p: 3 }}>
                        <CommonDeliveredShipmentsInfo data={'true'} shipmentType={props.shipmentType} />
                    </Box>
                </div>

            </Dialog>
        </div>
    )
}
export default DashboardDeviation;
